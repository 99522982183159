import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import EventService from '../../services/event/event-service';
import {selectUser} from '../../redux/selectors';
import EventCsvService, {EventCsvRow, EventRow} from '../../services/event/event-csv-service';

type CsvDownloadContextType = {
  isProcessing: boolean;
  isFinished: boolean;
  totalCount: number;
  progressCount: number;
  // MEMO: 以下に各CSV生成処理を付け加えれば他の画面でも利用可能
  // イベント参加者CSV
  downloadEventParticipantCsv: (eventIds: string[]) => Promise<void>;
};

const ThisContext = createContext<CsvDownloadContextType | null>(null);

const CsvDownloadProvider: React.FC = ({children}) => {
  const organizationId = useSelector(selectUser).organizationId;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [progressCount, setProgressCount] = useState<number>(0);

  const progressStart = useCallback((count: number) => {
    setTotalCount(count);
    setIsProcessing(true);
    setIsFinished(false);
  }, []);

  const progressEnd = useCallback(() => {
    setIsProcessing(false);
    setIsFinished(false);
    setTotalCount(0);
    setProgressCount(0);
  }, []);

  const downloadEventParticipantCsv = useCallback(
    async (eventIds: string[]) => {
      progressStart(eventIds.length);

      let counter = 0;
      const events: EventRow[] = await Promise.all(
        eventIds.map(async id => {
          const event = await EventService.loadById(organizationId, id).then(event => {
            return event;
          });
          const eventParticipants = event.participantCustomers;
          const participants = await EventService.getParticipantsByBuilding(eventParticipants);
          counter += 1;
          setProgressCount(counter);
          return {
            id: id,
            event: event,
            participants: Object.entries(participants),
          };
        }),
      );
      const rows: EventCsvRow[] = EventCsvService.convertToEventCsvRow(events);
      EventCsvService.generateEventCsvExport(rows);
      setIsFinished(true);
      setTimeout(() => {
        progressEnd();
      }, 3000);
    },
    [organizationId, progressEnd, progressStart],
  );

  const value: CsvDownloadContextType = useMemo(
    () => ({
      isProcessing,
      isFinished,
      totalCount,
      progressCount,
      downloadEventParticipantCsv,
    }),
    [isProcessing, isFinished, totalCount, progressCount, downloadEventParticipantCsv],
  );

  return <ThisContext.Provider value={value}>{children}</ThisContext.Provider>;
};

export const useCsvDownloadContext = () => {
  const context = useContext(ThisContext);
  if (!context) {
    throw new Error('useContext must be used within CsvDownloadProvider');
  }
  return context;
};

export default CsvDownloadProvider;
