import {createSlice} from '@reduxjs/toolkit';

export interface ApiState {
  appAPIInitCount: number;
  appOldAPIInitCount: number;
  loadingCount: number;
}

const initialState: ApiState = {loadingCount: 0, appAPIInitCount: 0, appOldAPIInitCount: 0};

const apiSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    incrInitAPI(state: ApiState) {
      return {
        ...state,
        appAPIInitCount: state.appAPIInitCount + 1,
      };
    },
    incrInitOldAPI(state: ApiState) {
      return {
        ...state,
        appOldAPIInitCount: state.appOldAPIInitCount + 1,
      };
    },
    start(state: ApiState) {
      return {
        ...state,
        loadingCount: state.loadingCount + 1,
      };
    },
    stop(state: ApiState) {
      return {
        ...state,
        loadingCount: state.loadingCount - 1,
      };
    },
  },
});

export const {incrInitAPI, incrInitOldAPI, start, stop} = apiSlice.actions;

export default apiSlice.reducer;
