import type {AlgoliaSearchCondition} from '@algolia/v1/algolia';
import type {Response} from 'algoliasearch';
import {V2HomehubElasticSearchIndex} from '@firebase/v1/functions/v2-firebase-homehub-elastic-search-index';
import {toAlgoliaResponseFromElasticSearch} from '../utils/to-algolia-response-from-elastic-search-converter';
import {toElasticSearchRequestFromAlgolia} from '../utils/to-elastic-search-request-from-algolia-converter';

/**
 * Algolia -> ES の一括置き換えで生成されたレガシーなESのインデックスに対して簡易な検索を実行する処理
 * Indexごとの実装量を最小化するために、パスと条件を渡すIFとしています
 *
 */
export const searchSimplyElasticSearchLegacyIndex = async <T>(arg: {
  apiPath: string;
  condition: AlgoliaSearchCondition;
}): Promise<Response<T>> => {
  try {
    const res = await V2HomehubElasticSearchIndex.post(
      `homehubCoreBusinessIndex/${V2HomehubElasticSearchIndex.getVersion()}/legacies/${arg.apiPath}/search-simply`,
      toElasticSearchRequestFromAlgolia(arg.condition),
    );
    return toAlgoliaResponseFromElasticSearch(res.data, arg.condition);
  } catch (e) {
    // NOTE: エラーがロジック層まで行くと画面が処理落ちしてしまうので検索結果なしで返却する
    console.error(e);
    return {
      hits: [],
      page: arg.condition.offset ?? 0,
      nbHits: 0,
      nbPages: 0,
      hitsPerPage: arg.condition.offset ?? 100,
      processingTimeMS: 0,
      query: '',
      params: '',
      exhaustiveNbHits: false,
      exhaustiveFacetsCount: false,
    };
  }
};
