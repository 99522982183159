import apiReducer from './api';
import {combineReducers} from 'redux';
import {commonDictionaryReducer} from './action-reducers/common-dictionary';
import {dictionaryReducer} from './dictionary';
import {settingsReducer} from './organization-settings';
import {userReducer} from './user';

export const rootReducer = combineReducers({
  user: userReducer,
  dictionary: dictionaryReducer,
  commonDictionary: commonDictionaryReducer,
  settings: settingsReducer,
  api: apiReducer,
});
