import {EpochMillis} from '@bitkey-service/functions-common-types/lib/common/commonTypes';
import {EnumAlternativeUnion} from '@bitkey-service/functions-common-types/lib/common/typeUtils';

export interface LockDeviceLog {
    logId: string;
    actionType: string;
    bleConnectionId: string;
    centralCode: CentralCode;
    deleted: boolean;
    deviceId: string;
    deviceName: string;
    holeIndex: number;
    logIndex: number;
    notifyRes: string;
    recordType: RecordType;
    recordedAt: EpochMillis;
    timestamp: EpochMillis;
    createdAt: EpochMillis;
    updatedAt: EpochMillis;
    userInfo?: {
        personaId: string;
        name: string;
    };
    spaceInfo: {
        id: string;
        name: string;
        address: string;
        status: string;
    }
}

export const RecordType = {
    LostRecords: "0x01",
    Boot: "0x02",
    EnterDfuMode: "0x03",
    ActDevice: "0x04",
    FactoryReset: "0x05",
    SetDoorMagnet: "0x11",
    SetAutolock: "0x12",
    SetAutolockWaitTime: "0x13",
    SetCurrentTime: "0x14",
    RegThumbturn: "0x15",
    SetAutolockStillOpenedTimeout: "0x16",
    SetAutolockLeaveClosedTimeout: "0x17",
    SetAutolockStillOpenedMotion: "0x18",
    SetAutolockLeaveClosedMotion: "0x19",
    SetDoorGyro: "0x1a",
    SetAutolockSchedule: "0x1b",
    SetSoundMode: "0x1c",
    SetPressTimeout: "0x1f",
    UnlockDigitalKey: "0xa1",
    LockDigitalKey: "0xa2",
    UnlockHand: "0xa3",
    LockHand: "0xa4",
    AutolockMagnet: "0xa5",
    AutolockGyro: "0xa6",
    OpenDoorMagnet: "0xb1",
    CloseDoorMagnet: "0xb2",
    AutolockStillOpenedMagnet: "0xb3",
    AutolockLeaveClosedMagnet: "0xb4",
    OpenDoorGyro: "0xb5",
    CloseDoorGyro: "0xb6",
    AutolockStillOpenedGyro: "0xb7",
    AutolockLeaveClosedGyro: "0xb8",
    ThumbturnSafetyTimeout: "0xc1",
    ThumbturnPressDetect: "0xc2",
    BatteryReport: "0xd1",
    ExternalBattery: "0xd2",
  } as const;
export type RecordType = EnumAlternativeUnion<typeof RecordType>;

export const RecordTypeKey = {
    LostRecords: 'LostRecords',
    Boot: 'Boot',
    EnterDfuMode: 'EnterDfuMode',
    ActDevice: 'ActDevice',
    FactoryReset: 'FactoryReset',
    SetDoorMagnet: 'SetDoorMagnet',
    SetAutolock: 'SetAutolock',
    SetAutolockWaitTime: 'SetAutolockWaitTime',
    SetCurrentTime: 'SetCurrentTime',
    RegThumbturn: 'RegThumbturn',
    SetAutolockStillOpenedTimeout: 'SetAutolockStillOpenedTimeout',
    SetAutolockLeaveClosedTimeout: 'SetAutolockLeaveClosedTimeout',
    SetAutolockStillOpenedMotion: 'SetAutolockStillOpenedMotion',
    SetAutolockLeaveClosedMotion: 'SetAutolockLeaveClosedMotion',
    SetDoorGyro: 'SetDoorGyro',
    SetAutolockSchedule: 'SetAutolockSchedule',
    SetSoundMode: 'SetSoundMode',
    SetPressTimeout: 'SetPressTimeout',
    UnlockDigitalKey: 'UnlockDigitalKey',
    LockDigitalKey: 'LockDigitalKey',
    UnlockHand: 'UnlockHand',
    LockHand: 'LockHand',
    AutolockMagnet: 'AutolockMagnet',
    AutolockGyro: 'AutolockGyro',
    OpenDoorMagnet: 'OpenDoorMagnet',
    CloseDoorMagnet: 'CloseDoorMagnet',
    AutolockStillOpenedMagnet: 'AutolockStillOpenedMagnet',
    AutolockLeaveClosedMagnet: 'AutolockLeaveClosedMagnet',
    OpenDoorGyro: 'OpenDoorGyro',
    CloseDoorGyro: 'CloseDoorGyro',
    AutolockStillOpenedGyro: 'AutolockStillOpenedGyro',
    AutolockLeaveClosedGyro: 'AutolockLeaveClosedGyro',
    ThumbturnSafetyTimeout: 'ThumbturnSafetyTimeout',
    ThumbturnPressDetect: 'ThumbturnPressDetect',
    BatteryReport: 'BatteryReport',
    ExternalBattery: 'ExternalBattery',
} as const;
export type RecordTypeKey = keyof typeof RecordType;

export const CentralCode = {
    BITLOCK_APP_IOS: 1,
    BITLOCK_APP_ANDROID: 2,
    BITLOCK_APP_TEBURA_IOS: 3,
    BITLOCK_APP_TEBURA_ANDROID: 4,
    APPLE_WATCH: 5,
    HOMEHUB_IOS_APP: 6,
    HOMEHUB_ANDROID_APP: 7,
    WORKHUB_IOS_APP: 8,
    WORKHUB_ANDROID_APP: 9,
    BITREADER: 32,
    BITREADER_PLUS: 33,
    BITREADER_PLUS_NFC: 36,
    BITREADER_PLUS_PASSCODE: 37,
    BITREADER_PLUS_OTP: 38,
    BITREADER_PLUS_NFC_PASSCODE: 39,
    BITREADER_PLUS_NFC_OTP: 40,
    BITREADER_PLUS_PASSCODE_OTP: 41,
    BITREADER_PLUS_NFC_PASSCODE_OTP: 42,
    BITBUTTON: 48,
    BITBUTTON_KIDS: 49,
    BITBUTTON_CARD: 50,
    WIFI_CONNECTOR: 64,
    BITLINK_WIFI: 65,
    BITLINK_LTE: 66,
  };
export type CentralCode = EnumAlternativeUnion<typeof CentralCode>;

export const CentralCodeKey = {
    BITLOCK_APP_IOS: 'BITLOCK_APP_IOS',
    BITLOCK_APP_ANDROID: 'BITLOCK_APP_ANDROID',
    BITLOCK_APP_TEBURA_IOS: 'BITLOCK_APP_TEBURA_IOS',
    BITLOCK_APP_TEBURA_ANDROID: 'BITLOCK_APP_TEBURA_ANDROID',
    APPLE_WATCH: 'APPLE_WATCH',
    HOMEHUB_IOS_APP: 'HOMEHUB_IOS_APP',
    HOMEHUB_ANDROID_APP: 'HOMEHUB_ANDROID_APP',
    WORKHUB_IOS_APP: 'WORKHUB_IOS_APP',
    WORKHUB_ANDROID_APP: 'WORKHUB_ANDROID_APP',
    BITREADER: 'BITREADER',
    BITREADER_PLUS: 'BITREADER_PLUS',
    BITREADER_PLUS_NFC: 'BITREADER_PLUS_NFC',
    BITREADER_PLUS_PASSCODE: 'BITREADER_PLUS_PASSCODE',
    BITREADER_PLUS_OTP: 'BITREADER_PLUS_OTP',
    BITREADER_PLUS_NFC_PASSCODE: 'BITREADER_PLUS_NFC_PASSCODE',
    BITREADER_PLUS_NFC_OTP: 'BITREADER_PLUS_NFC_OTP',
    BITREADER_PLUS_PASSCODE_OTP: 'BITREADER_PLUS_PASSCODE_OTP',
    BITREADER_PLUS_NFC_PASSCODE_OTP: 'BITREADER_PLUS_NFC_PASSCODE_OTP',
    BITBUTTON: 'BITBUTTON',
    BITBUTTON_KIDS: 'BITBUTTON_KIDS',
    BITBUTTON_CARD: 'BITBUTTON_CARD',
    WIFI_CONNECTOR: 'WIFI_CONNECTOR',
    BITLINK_WIFI: 'BITLINK_WIFI',
    BITLINK_LTE: 'BITLINK_LTE',
};
export type CentralCodeKey = keyof typeof CentralCode;
