import {AxiosError, AxiosRequestConfig} from 'axios';
import React, {useCallback, useEffect} from 'react';
import {selectAppAPIInitCount, selectAppOldAPIInitCount} from '../../redux/selectors';
import {start, stop} from '../../redux/api';
import {useDispatch, useSelector} from 'react-redux';

import {V2FirebaseHomehubBackendFunctions} from '../../firebase/functions/v2-firebase-homehub-backend-functions';
import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';

/**
 * 指定の機能の権限がない場合、childをdisableする
 */
export const APIInterceptor: React.FC = ({children}) => {
  const dispatch = useDispatch();

  const appAPIInitCount = useSelector(selectAppAPIInitCount);
  const appOldAPIInitCount = useSelector(selectAppOldAPIInitCount);

  const beforeRequest = useCallback(
    (request: AxiosRequestConfig): AxiosRequestConfig => {
      dispatch(start());
      return request;
    },
    [dispatch],
  );

  const errorBeforeRequest = (error: any) => {
    console.error('An error occurred while calling an API ', error);
    throw error;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResponse = (response: AxiosRequestConfig) => {
    dispatch(stop());
    return response;
  };

  const handleErrorResponse = (error: AxiosError) => {
    console.error(error);
    throw error;
  };

  useEffect(() => {
    //APIがInitされるときにはAxiosの新しいインスタンスを使うためInterceptorの再設定が必要です
    if (appAPIInitCount > 0) {
      V2FirebaseHomehubOldApiFunctions.addInterceptors(
        beforeRequest,
        errorBeforeRequest,
        handleResponse,
        handleErrorResponse,
      );
    }
  }, [appAPIInitCount, beforeRequest, handleResponse]);

  useEffect(() => {
    //APIがInitされるときにはAxiosの新しいインスタンスを使うためInterceptorの再設定が必要です
    if (appOldAPIInitCount > 0) {
      V2FirebaseHomehubBackendFunctions.addInterceptors(
        beforeRequest,
        errorBeforeRequest,
        handleResponse,
        handleErrorResponse,
      );
    }
  }, [appOldAPIInitCount, beforeRequest, handleResponse]);

  return <>{children}</>;
};
