import {ExportToCsv} from 'export-to-csv';
import {definitions} from '../../business-types/generated/homehubEvent';
import EventService, {EventParticipantType} from './event-service';
import moment from 'moment-timezone';

export interface EventRow {
  id: string;
  event: definitions['eventDetail'];
  participants: [string, EventParticipantType[]][];
}

export interface EventCsvRow {
  eventId: string;
  eventTitle: string;
  buildingName: string;
  propertyName: string;
  propertyCode: string;
  customerName: string;
  customerEmail?: string;
  customerPhoneNumber: string;
  customerApplicationDate: string;
}

export default class EventCsvService {
  // create csv
  public static generateEventCsvExport(rows: EventCsvRow[]) {
    if (rows.length === 0) return;
    const headers = EventCsvService.getHeader();
    const options = {
      fieldSeparator: ',',
      quoteString: '"',
      filename: 'participant_list',
      showLabel: true,
      useKeysAsHeaders: true,
      useBom: true,
    };
    const csvExporters = new ExportToCsv(options);
    csvExporters.generateCsv(
      rows.map(row => {
        const copy = {};
        headers.forEach(header => {
          const id = header.id;
          const label = header.label;
          copy[label] = row[id];
        });
        return copy;
      }),
    );
    const eventIds = rows.map(r => r.eventId);
    EventService.registerActivityLog({
      type: 'CSV',
      eventIds: eventIds,
    });
  }

  public static convertToEventCsvRow(events: EventRow[]): EventCsvRow[] {
    const rows: EventCsvRow[] = [];
    events.forEach(event => {
      const eventId = event.id;
      const eventTitle = event.event.title;
      const participants = event.participants;
      if (participants.length > 0) {
        participants.forEach(customerBuilding => {
          const buildingName = customerBuilding[0].split(':')[1] as string;
          const customers = customerBuilding[1];
          customers.map(customer => {
            rows.push({
              eventId: eventId,
              eventTitle: eventTitle,
              buildingName,
              propertyName: customer.propertyName,
              propertyCode: customer.propertyCode,
              customerName: customer.name,
              customerEmail: customer.email,
              customerPhoneNumber: customer.phoneNumber,
              customerApplicationDate: moment(customer.applicationDate).format('YYYY年 MM月 DD日 HH:mm'),
            });
          });
        });
      } else {
        rows.push({
          eventId: eventId,
          eventTitle: eventTitle,
          buildingName: '-',
          propertyName: '-',
          propertyCode: '-',
          customerName: '-',
          customerEmail: '-',
          customerPhoneNumber: '-',
          customerApplicationDate: '-',
        });
      }
    });
    return rows;
  }

  //
  public static getHeader() {
    return [
      {
        id: 'eventId',
        numeric: false,
        label: 'イベントID',
      },
      {
        id: 'eventTitle',
        numeric: false,
        label: 'イベント名',
      },
      {
        id: 'buildingName',
        numeric: false,
        label: '建物名',
      },
      {
        id: 'propertyName',
        numeric: false,
        label: '物件名',
      },
      {
        id: 'propertyCode',
        numeric: false,
        label: '物件コード',
      },
      {
        id: 'customerName',
        numeric: false,
        label: '入居者',
      },
      {
        id: 'customerEmail',
        numeric: false,
        label: 'メールアドレス',
      },
      {
        id: 'customerPhoneNumber',
        numeric: false,
        label: '電話番号',
      },
      {
        id: 'customerApplicationDate',
        numeric: false,
        label: '申込日',
      },
    ];
  }
}
