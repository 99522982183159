import BitkeyPlatformSDK, {AuthenticationResult} from '@bitkey-service/bkp-sdk';
import {IssueBulkPasswordResetTokenRequest} from '@bitkey-service/bkp-sdk-types';
import Algolia from '../../algolia/algolia';
import {ServiceType} from '../../business-types/core/common/service-type';
import {ReservationServiceType} from '../../business-types/enums/core/common/reservation-type';
import {MergedBaseUserAuthority} from '../../business-types/types/core/common/merged-base-user-authority';
import {TimestampToEpochMillis} from '../../common-utils/type-utils';
import {BusinessType} from '../../domain-base/business-types';
import FirebaseAuth from '../../firebase/auth/firebase-auth';
import Firebase from '../../firebase/firebase';
import {FirestoreResponseData} from '../../firebase/firestore/firestore';
import {FirestoreOrganizationTeleconferenceSettings} from '../../firebase/firestore/references-types/organizations/commonSettings/firestore-types-organizations-teleconference-settings';
import {OrganizationAddOnSettingsResponse} from '../../firebase/firestore/references/organizations/add-ons/firestore-organization-add-ons';
import {OrganizationsReservationNotificationSettingDetectUnitKey} from '../../firebase/firestore/references/organizations/common-settings/notification-setting/reservation-notification-setting/firestore-organizations-reservation-notification-setting';
import {FirestoreOrganizationMemberData} from '../../firebase/firestore/references/organizations/members/firestore-members';
import {FirestoreOrganizationData} from '../../firebase/firestore/references/organizations/organizations';
import {FirestoreOrganizationDashboardSettingData} from '../../firebase/firestore/references/organizations/secrets/firestore-dashboard-settings';
import {FirestoreOrganizationKeyIssueSettingsData} from '../../firebase/firestore/references/organizations/secrets/firestore-keyIssue-settings';
import {FirestoreOrganizationNotificationSettingData} from '../../firebase/firestore/references/organizations/secrets/firestore-notification-settings';
import {FirestoreOrganizationSecretsUsableFunctionsData} from '../../firebase/firestore/references/organizations/usable-functions/firestore-usableFunctions';
import {OrgReservationNotificationSettingResponseData} from '../../firebase/functions/api-types/organizations/common-settings/notification-settings/reservation-notification-setting/api-types-org-reservation-notification-setting';
import ApiOrganizationsAuth from '../../firebase/functions/api/organizations/auth/api-organizations-auth';
import ApiUsers from '../../firebase/functions/api/users/api-users';
import FirebaseFunctionsAuth from '../../firebase/functions/auth/firebase-functions-auth';
import {CloudrunFunctions} from '../../firebase/functions/cloudrun-functions';
import FirebaseFunctionsOrganizations from '../../firebase/functions/organizations/firebase-functions-organizations';
import {V2FirebaseHomehubBackendFunctions} from '../../firebase/functions/v2-firebase-homehub-backend-functions';
import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';
import {V2HomehubElasticSearchIndex} from '../../firebase/functions/v2-firebase-homehub-elastic-search-index';
import history from '../../history';
import LocalStorage, {LocalStorageKey} from '../../local-storage/LocalStorage';
import {datadogRum} from '@datadog/browser-rum';
import {PrivateViewReservationSiteSettingState} from '../../redux/organization-settings';
import Redux from '../../redux/ReduxConnector';
import AddOnsService from '../add-ons/add-ons-service';
import AlgoliaSettingsService from '../algolia-settings/algolia-settings-service';
import ReservationNotificationSettingService from '../common-settings/notification-setting/reservation-notification-setting-service';
import SpacePrivateViewSettingService from '../common-settings/space-setting/space-private-view-setting-service';
import TeleconferenceSetting from '../common-settings/teleconference-settings/teleconference-setting';
import {DashboardService} from '../dashboard/dashboard-service';
import KeysService from '../keys/keys-service';
import MigrationService from '../migrations/migration-service';
import NotificationService from '../notification/notification-service';
import OrganizationService from '../organization/organization-service';
import SpacesService from '../spaces/spaces-service';
import SpecifySpacesService from '../spaces/specify-spaces-service';
import UsableFunctionsServices from '../usable-functions/usable-functions-services';
import UserAuthorityLoadService from '../user-authorities/user-authorities-load-service';
import {Datadog} from '../../monitoring/datadog';

export enum AuthVerifyProgress {
  noProgress = 'noProgress',
  start = 'start',
  authenticating_user = 'authenticating_user',
  authenticating_persona = 'authenticating_persona',
  activate_persona = 'activate_persona',
  updating_custom_tokens = 'updating_custom_tokens',
  signing_in_with_custom_token = 'signing_in_with_custom_token',
  loading_self_data = 'loading_self_data',
  initializing_algolia = 'initializing_algolia',
}

/**
 * 認証に関わるサービス
 * LoginServiceというものを作ろうとしたけど厳密にLoginではないものはこっち、みたいに分けるのが微妙だったので全てここにする
 */
export default class AuthService {
  /**
   * BKPユーザーの認証
   * 組織に所属していないペルソナは対象外なのでここでフィルタする
   * @param email
   * @param password
   * @param setProgress
   */
  public static authUser = async (
    email: string,
    password: string,
    setProgress: (progress: AuthVerifyProgress) => any,
  ) => {
    setProgress(AuthVerifyProgress.authenticating_user);
    const {accessToken} = await AuthService.authDefaultPersona({email, password});

    AuthService.setTokenToFunctions(accessToken);
    LocalStorage.set(LocalStorageKey.DEFAULT_PERSONA_ACCESS_TOKEN, accessToken);
    return ApiUsers.getSelf();
  };

  private static authDefaultPersona = async (arg: {email: string; password: string}): Promise<AuthenticationResult> => {
    const bkpSdk = new BitkeyPlatformSDK('');
    const {authenticated, multipleChoice} = await bkpSdk.authenticatePersonaDirectly(arg);

    // 個人ペルソナしかない場合（後続の組織選択で詰むはず）
    if (authenticated) {
      return authenticated;
    }

    // 候補のペルソナがある場合、その中から個人ペルソナを探す。100%あるので find で無駄に消耗しない。
    const defaultPersona = multipleChoice.personas.filter(p => !p.organizationId)[0];
    return bkpSdk.authenticatePersona(defaultPersona.id, {
      multipleChoiceSessionKey: multipleChoice.sessionKey,
    });
  };

  /**
   * 認証後のトークン更新
   * 読めばすぐわかるけどBitkeyPlatformAPIのupdateTokensにこの処理をセットするのは絶対だめ
   * @param accessToken
   * @param refreshToken
   */
  public static updateTokens = (accessToken, refreshToken) => {
    LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, accessToken);
    LocalStorage.set(LocalStorageKey.REFRESH_TOKEN, refreshToken);
    AuthService.setTokenToFunctions(accessToken);
  };

  public static loginToFirebase = async (setProgress: (progress: AuthVerifyProgress) => any) => {
    setProgress(AuthVerifyProgress.updating_custom_tokens);
    const customToken = await FirebaseFunctionsAuth.getCustomToken();
    setProgress(AuthVerifyProgress.signing_in_with_custom_token);
    return AuthService.updateFirebaseCustomToken(customToken);
  };

  /**
   * FirebaseのcustomTokenを更新する
   * updateTokensと一緒にしないのはユーザー作成時はauthenticateにユーザーがいなくてtoken作れないのと、
   * FirebaseのtokenだけCloud Functionsで生成するため、通信がいる処理だからレイヤが違う。
   * @param customToken
   */
  public static updateFirebaseCustomToken = customToken => {
    // TODO 削除
    console.log('custom token', customToken);
    LocalStorage.set(LocalStorageKey.CUSTOM_TOKEN, customToken);
    return FirebaseAuth.signInWithCustomToken(customToken);
  };

  public static loadSelf = () => {
    return FirebaseFunctionsOrganizations.getSelf();
  };

  public static setUser = (self: {
    organization: FirestoreOrganizationData | TimestampToEpochMillis<FirestoreOrganizationData>;
    member: FirestoreOrganizationMemberData | TimestampToEpochMillis<FirestoreOrganizationMemberData>;
    userAuthority?: MergedBaseUserAuthority;
  }) => {
    const {organization, member, userAuthority} = self;
    Redux.actions.user.setUser({
      personaId: member.personaId,
      userId: member.externalId,
      email: member.email,
      name: member.name,
      photoUri: member.photoUri,
      organizationId: organization.id,
      organizationName: organization.name,
      // 先にFirestoreに持っているspaceIdのみ詰めておく
      inChargeOfSpaces: member.inChargeOfSpaceIds
        ? member.inChargeOfSpaceIds.map(id => ({
            id: id,
            name: '',
          }))
        : [],
      originalInChargeOfSpaces: member.inChargeOfSpaceIds
        ? member.inChargeOfSpaceIds.map(id => ({
            id: id,
            name: '',
          }))
        : [],
      useReception: organization.useReception,
      // isAdmin: self.member.isAdmin,
      userAuthority: userAuthority,
      departmentName: member.departmentName,
    });
    // TODO 業種なかったらCO_WORKINGじゃなくてCOREにする。
    if (!organization.businessType) {
      Redux.actions.dictionary.setBusinessType(BusinessType.CORE);
    } else {
      Redux.actions.dictionary.setBusinessType(organization.businessType);
    }

    Redux.actions.dictionary.setUseTobilifeCrew(!!organization.useTobilifeCrew);
    Datadog.setContext(organization, member, userAuthority);
  };

  public static setUserAuthority = (userAuthority?: MergedBaseUserAuthority) => {
    Redux.actions.user.setUser({
      ...Redux.getStore().user,
      // isAdmin: self.member.isAdmin,
      userAuthority: userAuthority,
    });
  };
  // account-service的なクラスに切り出しても良いかも
  public static setInChargeOfSpaces = (spaces: {id: string; name: string}[]) => {
    Redux.actions.user.setInChargeOfSpaces(spaces);
  };
  public static setOriginalInChargeOfSpaces = (spaces: {id: string; name: string}[]) => {
    Redux.actions.user.setOriginalInChargeOfSpaces(spaces);
  };
  public static setInChargeOfBuildings = (ids: string[]) => {
    Redux.actions.user.setInChargeOfBuildingIds(ids);
  };
  public static setSpaceGroup = (spaceGroup: any) => {
    Redux.actions.user.setSpaceGroup(spaceGroup);
  };
  public static setSettings = (settings: {
    notification?: FirestoreResponseData<FirestoreOrganizationNotificationSettingData>;
    addOns?: OrganizationAddOnSettingsResponse;
    keyIssueSettings?: FirestoreResponseData<FirestoreOrganizationKeyIssueSettingsData>;
    usableFunctionsSettings?: FirestoreOrganizationSecretsUsableFunctionsData;
    privateViewReservationSettings?: PrivateViewReservationSiteSettingState;
    teleconferenceSettings?: FirestoreOrganizationTeleconferenceSettings;
    dashboardSettings?: FirestoreOrganizationDashboardSettingData;
    reservationNotificationSetting?: OrgReservationNotificationSettingResponseData;
  }) => {
    const notification = settings.notification;
    const addOns = settings.addOns;
    const departureNoticeConfig =
      settings.notification && settings.notification.departureNoticeConfig
        ? settings.notification.departureNoticeConfig
        : undefined;
    const keyIssueSettings = settings.keyIssueSettings;
    const usableFunctions = settings.usableFunctionsSettings
      ? settings.usableFunctionsSettings.usableFunctions
      : undefined;
    const privateViewReservationSettings = settings.privateViewReservationSettings || undefined;
    const teleconferenceSettings = settings.teleconferenceSettings;
    const rowBatteryNoticeConfig =
      settings.notification && settings.notification.rowBatteryNoticeConfig
        ? settings.notification.rowBatteryNoticeConfig
        : undefined;
    const dashboardSettings = settings.dashboardSettings || undefined;
    const reservationNotificationSetting = settings.reservationNotificationSetting || undefined;
    Redux.actions.settings.setSettings({
      notificationEmail: notification ? notification.notificationEmail : '',
      rowBatteryNotificationEmail: notification ? notification.rowBatteryNotificationEmail : '',
      autoLoggingFailureNotificationEmail: notification ? notification.autoLoggingFailureNotificationEmail : '',
      addOns: {
        sumamoruData:
          addOns && addOns.sumamoru
            ? {
                sumamoruLinkable: addOns.sumamoru.sumamoruLinkableOrg,
                sumamoruProjectTypes: addOns.sumamoru.sumamoruProjectTypes,
                sumamoruPlans: addOns.sumamoru.sumamoruPlans,
                managementCompanyName: addOns.sumamoru.managementCompanyName,
                managementCompanyAddress: addOns.sumamoru.managementCompanyAddress,
                managementCompanyPhoneNumber: addOns.sumamoru.managementCompanyPhoneNumber,
              }
            : undefined,
      },
      departureNoticeConfig: departureNoticeConfig ? departureNoticeConfig : undefined,
      rowBatteryNoticeConfig: rowBatteryNoticeConfig ? rowBatteryNoticeConfig : undefined,
      keyIssueSettings: keyIssueSettings ? keyIssueSettings : undefined,
      usableFunctions: usableFunctions ? usableFunctions : [],
      privateViewReservationSiteSetting: privateViewReservationSettings || undefined,
      teleconferenceSettings: teleconferenceSettings ? teleconferenceSettings : undefined,
      dashboardSettings: dashboardSettings || undefined,
      reservationNotificationSetting: reservationNotificationSetting || undefined,
    });
    Datadog.addContext('usableFunctions', usableFunctions);
    Datadog.addContext('teleconferenceSettings', teleconferenceSettings);
    Datadog.addContext('dashboardSettings', dashboardSettings);
  };

  /**
   * domainアクセス時に前回ログイン情報をもとに自動ログインするための処理
   * @param accessToken
   * @param refreshToken
   */
  public static authPersonaFromStoredToken = async (accessToken: string, refreshToken: string) => {
    const {member, organization, termsOfServicesNotAgreed, customToken} = await AuthService.newLoginToOrganization(
      accessToken,
      refreshToken,
    );

    await AuthService.retryOperation(() => AuthService.updateFirebaseCustomToken(customToken), 500, 3).catch(e => {
      console.log('failed to login to firebase.', e);
      throw e;
    });
    // 非同期読み込みにすると駄目なやつ
    const {
      0: userAuthority,
      1: addOnSettings,
      2: dashboardSettings,
      3: teleconferenceSettings,
    } = await AuthService.retryOperation(
      () =>
        Promise.all([
          UserAuthorityLoadService.getMergedDataForClient(
            member.userAuthorityIds,
            ServiceType.BITLOCK_MANAGER,
            organization.businessType,
          ),
          AddOnsService.loadAddOnSettings(),
          DashboardService.loadDashboardSettings(),
          TeleconferenceSetting.loadTeleconferenceSetting(),
        ]),
      500,
      3,
    )
      .then(
        result =>
          result as [
            MergedBaseUserAuthority | undefined,
            OrganizationAddOnSettingsResponse,
            FirestoreOrganizationDashboardSettingData | undefined,
            FirestoreOrganizationTeleconferenceSettings | undefined,
          ],
      )
      .catch(e => {
        console.log('failed to load setting.', e);
        throw e;
      });

    // 先にReduxにsetしておかないと不都合のある設定データをReduxにセットする
    AuthService.setSettings({
      addOns: addOnSettings,
      dashboardSettings: dashboardSettings,
      teleconferenceSettings: teleconferenceSettings,
    });
    AuthService.setUser({member, organization, userAuthority});

    // 非同期でその他の設定データを読み込んでおく
    Promise.all([
      NotificationService.loadNotificationSettings(),
      KeysService.loadKeyIssueSettings(),
      UsableFunctionsServices.loadUsableFunctionsServices(),
      SpacePrivateViewSettingService.getSiteSetting(),
      ReservationNotificationSettingService.getReservationNotificationSetting({
        reservationServiceType: ReservationServiceType.PrivateView,
        detectUnit: OrganizationsReservationNotificationSettingDetectUnitKey.Organization,
        detectId: organization.id,
      }),
    ]).then(
      ({
        0: notificationSettings,
        1: keyIssueSettings,
        2: usableFunctionsSettings,
        3: privateViewReservationSettings,
        4: reservationNotificationSetting,
      }) => {
        if (
          notificationSettings ||
          addOnSettings ||
          keyIssueSettings ||
          privateViewReservationSettings ||
          teleconferenceSettings ||
          reservationNotificationSetting ||
          dashboardSettings
        ) {
          AuthService.setSettings({
            notification: notificationSettings,
            addOns: addOnSettings,
            keyIssueSettings: keyIssueSettings,
            usableFunctionsSettings: usableFunctionsSettings,
            privateViewReservationSettings: privateViewReservationSettings,
            teleconferenceSettings: teleconferenceSettings,
            dashboardSettings: dashboardSettings,
            reservationNotificationSetting: reservationNotificationSetting,
          });
        }
        MigrationService.checkMigration().catch(e => {
          // 一旦無視で。
          console.log('failed to check migration.', e);
        });

        AlgoliaSettingsService.updateAlgoliaIndexSettings().catch(e => {
          // 一旦無視で。
          console.log('failed to update algolia.', e);
        });

        datadogRum.setUser({
          id: member.personaId,
          organization_id: organization.id,
        });
      },
    );
    const credentials = await OrganizationService.generateAlgoliaSearchApiKey(['BbsTopics', 'Faq']);
    Algolia.init(organization.id, organization.algoliaApplicationId, organization.algoliaSearchOnlyAPIKey, credentials);
    // 担当する空間が存在する場合はAlgoliaから読み込んだ値をReduxに持っておく
    if (member.inChargeOfSpaceIds.length) {
      AuthService.setUsersInChargeOfSpace(member.inChargeOfSpaceIds);
    }

    // 神用 (社内作業で規約同意回避するため)
    if (
      member.email === 'takaaki.machida@bitkey.jp' ||
      member.email === 'sumamoru-staff@bitkey.jp' ||
      member.email === 'sumamoru-team@bitkey.jp'
    ) {
      return {};
    } else {
      return {
        termsOfServicesNotAgreed,
      };
    }
  };

  public static logout = async (options?: {sessionError?: boolean}) => {
    const {sessionError = false} = options || {};
    // LocalStorageの削除
    LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN);
    LocalStorage.remove(LocalStorageKey.REFRESH_TOKEN);
    LocalStorage.remove(LocalStorageKey.CUSTOM_TOKEN);
    LocalStorage.remove(LocalStorageKey.DEFAULT_PERSONA_ACCESS_TOKEN);
    LocalStorage.remove(LocalStorageKey.DEFAULT_PERSONA_REFRESH_TOKEN);
    // Reduxで管理しているStateの削除
    Redux.actions.user.clear();
    Redux.actions.settings.clear();
    Redux.actions.dictionary.clear();
    // firebaseからlogout
    FirebaseAuth.signOut(); // ログアウトが処理がもたつくのでawaitしない。
    // v2組織 -> v1組織にAPIの向き先を戻す
    AuthService.changeVersionToDefault();
    // Datadog の RUM・Logs のセッション情報削除
    Datadog.clearContext();
    if (sessionError) {
      history.push('/login?session_error=true');
    } else {
      history.push('/login');
    }
  };

  public static newLoginToOrganization = async (accessToken, refreshToken) => {
    AuthService.setTokenToFunctions(accessToken);
    const {
      data: {customToken, member, organization, refreshedAccessToken, refreshedRefreshToken, termsOfServicesNotAgreed},
    } = await ApiOrganizationsAuth.auth(accessToken, refreshToken);
    // こっちに寄せるつもり。色々同時にやりすぎてるからあとで本番まで整えてから差し替え。
    // } = await ApiAuth.login();
    if (refreshedAccessToken && refreshedRefreshToken) {
      AuthService.setTokenToFunctions(refreshedAccessToken);
      LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, refreshedAccessToken);
      LocalStorage.set(LocalStorageKey.REFRESH_TOKEN, refreshedRefreshToken);
    } else {
      AuthService.setTokenToFunctions(accessToken);
    }

    // 認証時に非同期でESのtokenも発行しておく
    V2HomehubElasticSearchIndex.refreshToken().catch(e => {
      console.error('error occur in authentication', e);
    });

    // v2 NOTE: v2組織だった場合はAPI versionをv2に切り替え
    if (organization.migratedV2DataModel) {
      Firebase.changeApiToV2();
    }
    // sublease NOTE: sublease組織だと画面切り替える必要あるので...

    Redux.actions.settings.setSettings({
      useAuthDeviceOccupancyMode: !!organization.useAuthDeviceOccupancyMode,
      useSpaceUsageForOccupancy: !!organization.useSpaceUsageForOccupancy,
    });

    return {
      member,
      organization,
      termsOfServicesNotAgreed,
      customToken,
    };
  };

  /**
   * パスワード再発行のためのtoken発行処理
   * 指定されたメールアドレスに紐づくペルソナ全てのパスワードをリセットする
   * mailTemplateはいったんapp
   * 現状、BKPのAPIを実行した結果、アプリのDynamicLinkが返却されるので、
   * BKPが改修されるまでは管理画面でパスワード再発行のtoken処理を実行した後にアプリ側で新しいパスワードを入力する必要がある
   * @param email
   */
  public static issueBulkPasswordResetToken = async (email: string) => {
    if (email === '') {
      throw new Error('メールアドレスを入力してください');
    }
    const issueBulkPasswordResetTokenRequest: IssueBulkPasswordResetTokenRequest = {
      email,
      mailTemplate: 'homehub',
    };
    try {
      /**
       * サーバサイド経由させるとBKPのversion違いによるバグ踏むので、クライアントから直接実行する
       */
      return await new BitkeyPlatformSDK('').issueBulkPasswordResetToken(issueBulkPasswordResetTokenRequest);
      // return await V2FirebaseHomehubOldApiFunctions.post<FirestoreUser>(
      //   `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-users/resetPassword',
      //   issueBulkPasswordResetTokenRequest,
      // );
    } catch (e) {
      console.log(e);
      throw new Error('パスワードの再発行に失敗しました');
    }
  };

  public static async setUsersInChargeOfSpace(inChargeOfSpaceIds: string[]) {
    await SpecifySpacesService.loadAllSpacesByInChargeOfSpaceIds(inChargeOfSpaceIds).then(allInChargeOfSpaces => {
      AuthService.setInChargeOfSpaces(allInChargeOfSpaces.map(s => ({id: s.id, name: s.name})));
    });

    await SpecifySpacesService.loadInChargeOfSpaces(inChargeOfSpaceIds).then(inChargeOfSpaces => {
      AuthService.setOriginalInChargeOfSpaces(inChargeOfSpaces);
    });

    const allBranches = Redux.getStore().user.inChargeOfSpaces;
    if (allBranches) {
      await SpacesService.getBuildingsByBranchIds(allBranches.map(b => b.id)).then(buildings => {
        const buildingIds = buildings.map(building => building.id);
        AuthService.setInChargeOfBuildings(buildingIds);
      });
    }
  }

  private static setTokenToFunctions = (accessToken: string) => {
    V2FirebaseHomehubOldApiFunctions.setToken(accessToken);
    V2FirebaseHomehubBackendFunctions.setToken(accessToken);
    V2HomehubElasticSearchIndex.setBkpToken(accessToken);
    CloudrunFunctions.setToken(accessToken);
  };

  private static changeVersionToDefault = () => {
    const v1OldApiHost = process.env.REACT_APP_V1_BITLOCK_API_DOMAIN;
    V2FirebaseHomehubOldApiFunctions.init(v1OldApiHost || '');
  };

  private static retryOperation(operation: () => Promise<any>, delay: number, retries: number) {
    return new Promise((resolve, reject) => {
      return operation()
        .then(resolve)
        .catch(error => {
          if (retries === 1) {
            return reject(error);
          }
          console.log(`Retry...: ${retries - 1}`);
          setTimeout(() => {
            this.retryOperation(operation, delay, retries - 1).then(resolve, reject);
          }, delay);
        });
    });
  }
}
