import {Client} from 'algoliasearch';
import Algolia, {
    AlgoliaIndexName,
    AlgoliaNumericFilter,
    AlgoliaNumericFilterSign,
    AlgoliaObject,
    AlgoliaSearchCondition,
} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import {
    DeviceLogActionSource,
    DeviceLogActionType,
} from '../../../firebase/firestore/references/devices/logs/firestore-device-logs';
import AlgoliaBase from '../../algolia-base';
import moment from 'moment';

export enum AlgoliaDeviceLogAttributes {
  id = 'id',
  deviceId = 'deviceId',
  holeIndex = 'holeIndex',
  bkpHoleId = 'bkpHoleId',
  setUseAutolockTo = 'setUseAutolockTo',
  actionType = 'actionType',
  logIndex = 'logIndex',
  recordedAt = 'recordedAt',
  readedAt = 'readedAt',
  toggleLockRecordedAt = 'toggleLockRecordedAt',
  actionSource = 'actionSource',
  uid = 'uid',
  buttonId = 'buttonId',
  deviceOrganizationId = 'deviceOrganizationId',

  deviceName = 'deviceName',
  operatorName = 'operatorName',
  spaceName = 'spaceName',
  spaceId = 'spaceId',
}

export interface AlgoliaDeviceLogData extends AlgoliaObject {
  deviceId: string;
  holeIndex?: number;
  bkpHoleId?: string;
  setUseAutolockTo?: boolean;
  actionType: DeviceLogActionType;
  logIndex: number;
  recordedAt: EpochMillis;
  readedAt: EpochMillis;
  toggleLockRecordedAt?: EpochMillis;
  actionSource: DeviceLogActionSource;
  uid?: string;
  buttonId?: string;
  deviceOrganizationId?: string;

  deviceName: string;
  operatorName: string;
  spaceName: string;
  spaceId: string;
}

const DISPLAYING_LOGS_LOWER_LIMIT = '2019-06-01';

export default class AlgoliaDeviceLogs extends AlgoliaBase<AlgoliaDeviceLogData> {
  // filterに2019-06-01~現在日時+1日のログのみ取得するように補正をかける
  private logDisplayingPeriodLimit: AlgoliaNumericFilter[] = [
    {
      filterAttributeName: AlgoliaDeviceLogAttributes.recordedAt,
      sign: AlgoliaNumericFilterSign.greater_than_or_equal,
      value: moment(DISPLAYING_LOGS_LOWER_LIMIT).valueOf(),
    },
    {
      filterAttributeName: AlgoliaDeviceLogAttributes.recordedAt,
      sign: AlgoliaNumericFilterSign.less_than_or_equal,
      value: moment().add(1, 'd').valueOf(), // 現在日時+1日で
    },
  ];

  public static readonly facets = () => ({
    actionType: AlgoliaDeviceLogAttributes.actionType,
    actionSource: AlgoliaDeviceLogAttributes.actionSource,
    // for relation
    id: AlgoliaDeviceLogAttributes.id,
    deviceId: AlgoliaDeviceLogAttributes.deviceId,
    holeIndex: AlgoliaDeviceLogAttributes.holeIndex,
    bkpHoleId: AlgoliaDeviceLogAttributes.bkpHoleId,
    setUseAutolockTo: AlgoliaDeviceLogAttributes.setUseAutolockTo,
    logIndex: AlgoliaDeviceLogAttributes.logIndex,
    recordedAt: AlgoliaDeviceLogAttributes.recordedAt,
    readedAt: AlgoliaDeviceLogAttributes.readedAt,
    toggleLockRecordedAt: AlgoliaDeviceLogAttributes.toggleLockRecordedAt,
    uid: AlgoliaDeviceLogAttributes.uid,
    buttonId: AlgoliaDeviceLogAttributes.buttonId,
    deviceOrganizationId: AlgoliaDeviceLogAttributes.deviceOrganizationId,
    spaceId: AlgoliaDeviceLogAttributes.spaceId,
    spaceName: AlgoliaDeviceLogAttributes.spaceName,
    operatorName: AlgoliaDeviceLogAttributes.operatorName,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaDeviceLogs(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.deviceLogs;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaDeviceLogs.facets();
  // search系はfilterに2019-06-01~現在日時+1日の範囲のログのみ取得するように補正をかける
  public search = async (condition: AlgoliaSearchCondition) =>
    Algolia.search<AlgoliaDeviceLogData>(this.index, {
      ...condition,
      numericFilters: condition.numericFilters
        ? [...condition.numericFilters, ...this.logDisplayingPeriodLimit]
        : [...this.logDisplayingPeriodLimit],
    });
  public browse = async (condition: AlgoliaSearchCondition) =>
    Algolia.browse<AlgoliaDeviceLogData>(this.index, {
      ...condition,
      numericFilters: condition.numericFilters
        ? [...condition.numericFilters, ...this.logDisplayingPeriodLimit]
        : [...this.logDisplayingPeriodLimit],
    });
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaDeviceLogData>(this.index, {
      ...condition,
      numericFilters: condition.numericFilters
        ? [...condition.numericFilters, ...this.logDisplayingPeriodLimit]
        : [...this.logDisplayingPeriodLimit],
    });
}
