import 'firebase/compat/functions';
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {incrInitAPI} from '../../redux/api';
import {store} from '../../redux/store';

enum HomehubOldApiVersions {
  DefaultApiVersion = 'v1',
  V2DataModelVersion = 'v2',
}

/**
 * ElasticSearchを使って検索する際に利用するClient
 */
export class V2HomehubElasticSearchIndex {
  private static version: HomehubOldApiVersions;
  /**
   * ESによる検索等で利用するaxios
   * headerに格納するx-api-keyはESのtoken
   */
  private static axios: AxiosInstance;
  /**
   * token発行のためのaxios
   * headerに格納するx-api-keyはbkpのaccess token
   */
  private static authAxios: AxiosInstance;
  /**
   * ESのtokenの有効期限
   * 有効期限切れの場合にはrefreshしてから処理を実行する
   */
  private static expiresAt: number | undefined;

  /** 初期化 */
  public static init = (baseURL: string) => {
    this.version = HomehubOldApiVersions.DefaultApiVersion;
    this.axios = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.authAxios = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    store.dispatch(incrInitAPI());
  };

  public static getVersion = () => V2HomehubElasticSearchIndex.version;

  /**
   * Postの処理を実施
   * Tokenの有効期限が切れていた場合には、tokenを取得し直してから実行する
   */
  public static post = async <T = any, Q = any>(
    url: string,
    data?: Q,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T, any>> => {
    const expiresAt = this.expiresAt;
    if (!expiresAt || expiresAt < new Date().valueOf() - 5000) {
      await this.refreshToken();
    }

    const result = await this.axios.post<T>(url, data, config);
    return result;
  };

  public static setBkpToken = (token: string) => {
    this.authAxios.defaults.headers.common['X-Api-Key'] = token;
  };

  public static refreshToken = async (): Promise<void> => {
    const tokenResult = await this.authAxios.get(`homehubCoreBusinessIndex/${this.getVersion()}/es-token`);
    this.setEsToken({
      token: tokenResult.data.es_token,
      expiresAt: tokenResult.data.expires_at,
    });
  };

  private static setEsToken = (arg: {token: string; expiresAt: number}) => {
    this.expiresAt = arg.expiresAt;
    this.axios.defaults.headers.common['X-Api-Key'] = arg.token;
  };
}
