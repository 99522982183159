import ErrorDictionary, {ErrorDictKey} from '../dictionary/error-dictionary';

/**
 * サービスレイヤでの例外がそのまま画面表示につながる場合、ディクショナリが必要なので。
 */
export default class DictError extends Error {
  private readonly error?: Error;

  constructor(dictKey: ErrorDictKey, e?: Error) {
    super(ErrorDictionary.getWord(dictKey));
    this.error = e;
  }
}
