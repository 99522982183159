import {
    ApiOrganizationsAuthRequest,
    ApiOrganizationsAuthResponse,
} from '../../../api-types/organizations/auth/api-types-organizations-auth';
import {V2FirebaseHomehubOldApiFunctions} from '../../../v2-firebase-homehub-old-api-functions';

export default class ApiOrganizationsAuth {
  public static auth = async (accessToken, refreshToken) => {
    V2FirebaseHomehubOldApiFunctions.setToken(accessToken);
    const data: ApiOrganizationsAuthRequest = {refreshToken};
    // return V2FirebaseHomehubOldApiFunctions.post<ApiOrganizationsAuthResponse>(
    //   `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/auth`,
    //   data,
    // );
    return V2FirebaseHomehubOldApiFunctions.post<ApiOrganizationsAuthResponse>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/auth`,
      data,
    );
  };
}
