import axios, {AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';
import 'firebase/compat/functions';

export class CloudrunFunctions {
  private static axios: AxiosInstance;

  public static init = (baseURL: string) => {
    CloudrunFunctions.axios = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  public static get = <T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> =>
    CloudrunFunctions.axios.get(url, config);

  public static setToken = token => {
    CloudrunFunctions.axios.defaults.headers['x-api-key'] = token;
  };
}
