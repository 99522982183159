import type {AlgoliaObject} from '../../algolia';
import {
  FirestoreOrganizationCustomerClassification,
  FirestoreOrganizationCustomerStatus,
} from '../../../firebase/firestore/references/organizations/customers/firestore-customers';
import {PaymentMethodType} from '../../../firebase/firestore/references/organizations/customers/payment-method/firestore-organization-customers-payment-method';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

export enum AlgoliaCustomerAttributes {
  id = 'id',
  name = 'name',
  kanaName = 'kanaName',
  representativeEmail = 'representativeEmail',
  representativePersonaId = 'representativePersonaId',
  representativeName = 'representativeName',
  representativeKanaName = 'representativeKanaName',
  representativePhoneNumber = 'representativePhoneNumber',
  representativeMobilePhoneNumber = 'representativeMobilePhoneNumber',
  classification = 'classification',
  status = 'status',
  inServiceDate = 'inServiceDate',
  settlementType = 'settlementType',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  belongToSpaceIds = 'belongToSpaceIds',
  contractIds = 'contractIds',
  contractSpaceIds = 'contractSpaceIds',
  contractSpaceNames = 'contractSpaceNames',
  photoUri = 'photoUri',
  profile = 'profile',
  occupancyStatus = 'occupancyStatus',
}

export enum OccupancyStatus {
  occupy_scheduled = 'occupy_scheduled', // 入居予定
  occupied = 'occupied', // 入居中
  leave_scheduled = 'leave_scheduled', // 退去予定
  already_leaved = 'already_leaved', // 退去済
  no_contract = 'no_contract', // 契約なし
}

export interface AlgoliaCustomerData extends AlgoliaObject {
  name: string; // 会社名
  code?: string; // 顧客番号
  kanaName?: string; // 会社名(カナ)
  representativeEmail: string;
  representativePersonaId: string;
  representativeName: string; // 代表者の氏名
  representativeKanaName?: string; // 代表者氏名(カナ)
  representativePhoneNumber?: string;
  representativeMobilePhoneNumber?: string;
  classification: FirestoreOrganizationCustomerClassification;
  // 初めてカギかチケットを渡したタイミングでguestsにpersonaまたは代表者personaが登録されるため、そのタイミングでactivatedに変わる
  status: FirestoreOrganizationCustomerStatus;
  inServiceDate?: number;
  settlementType?: PaymentMethodType;
  createdAt: number;
  updatedAt: number;
  belongToSpaceIds?: string[]; // 所属する空間（レジデンスでの担当支店）
  contractIds: string[]; // 契約ID
  contractSpaceIds: string[]; // 契約している空間のID
  contractSpaceNames: string[]; // 契約している空間名(検索用)
  // コワーキング
  photoUri?: string; // 会社アイコン
  profile?: string; // 会社説明文
  // Residence
  occupancyStatus?: OccupancyStatus; // 入居ステータス
  privateGroupId?: string;
}

export default class AlgoliaCustomers extends LegacyIndexBase<AlgoliaCustomerData> {
  protected name = LegacyIndexName.customers;
  protected rawIndexName: string;

  public static readonly facets = () => ({
    // for facet
    representativeEmail: AlgoliaCustomerAttributes.representativeEmail,
    classification: AlgoliaCustomerAttributes.classification,
    status: AlgoliaCustomerAttributes.status,
    activateDate: AlgoliaCustomerAttributes.inServiceDate,
    settlementType: AlgoliaCustomerAttributes.settlementType,
    // for filter
    representativePersonaId: AlgoliaCustomerAttributes.representativePersonaId,
    id: AlgoliaCustomerAttributes.id,
    belongToSpaceIds: AlgoliaCustomerAttributes.belongToSpaceIds,
    occupancyStatus: AlgoliaCustomerAttributes.occupancyStatus,
    contractSpaceIds: AlgoliaCustomerAttributes.contractSpaceIds,
  });

  public static getIndex = (organizationId: string) => new AlgoliaCustomers(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaCustomers.facets();
}
