import type {AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import {
  StoreTypesOrgLinkDeviceSetting,
  StoreTypesOrgLinkDeviceStatus,
} from '../../../business-types/things/storeTypesOrgLinkDeviceSetting';
import {CommonThingFeatures, CommonThingType} from '@next/modules/core/model/thing/enums/commonThingEnums';
import {StoreTypesOrgHomehubEntranceTabletSetting} from '../../../business-types/things/homehubEntranceTabletSettings/storeTypesOrgHomehubEntranceTabletSetting';
import {StoreTypesOrgAuthDeviceSetting} from '../../../business-types/things/storeTypesOrgAuthDeviceSetting';
import {StoreTypesOrgLockDeviceSetting} from '../../../business-types/things/storeTypesOrgLockDeviceSetting';
import {CommonThingAddress, CommonThingHardware} from '@next/modules/core/model/thing/commonThingModel';
import {StatusChangeLog} from '../../../business-types/things/storeTypesOrgThing';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

export enum AlgoliaThingsAttributes {
  id = 'id',
  name = 'name',
  nameEn = 'nameEn',
  nameJp = 'nameJp',
  code = 'code',
  spaceId = 'spaceId',
  parentSpaceId = 'parentSpaceId',
  organizationId = 'organizationId',
  no = 'no',
  address = 'address',
  hardware = 'hardware',
  features = 'features',
  types = 'types',
  concurrentThingIds = 'concurrentThingIds',
  heartbeat = 'heartbeat',
  lockDeviceStatus = 'lockDeviceStatus',
  linkDeviceStatus = 'linkDeviceStatus',
  createdPersonaId = 'createdPersonaId',
  updatedPersonaId = 'updatedPersonaId',
  createdAt = 'createdAt',
  createOperator = 'createOperator',
  updatedAt = 'updatedAt',
  lastUpdateOperator = 'lastUpdateOperator',
  deleted = 'deleted',
  deletedAt = 'deletedAt',
  deletedOperator = 'deletedOperator',
  serialNo = 'hardware.serialNo',
  model = 'hardware.model',
  relatedToLinkDevice = 'relatedToLinkDevice',
  spaceAttributes = 'spaceAttributes',
}
declare type FileName = string;
export type ThingStatusHeartbeatType = 'alive';
export type StoreTypesOrgLockDeviceStatus = {
  locked?: StatusChangeLog<boolean>;
};
export interface AlgoliaThingsData extends AlgoliaObject {
  code: string;
  spaceId?: string;
  parentSpaceId?: string;
  lockDeviceSetting?: StoreTypesOrgLockDeviceSetting;
  authDeviceSetting?: StoreTypesOrgAuthDeviceSetting;
  linkDeviceSetting?: StoreTypesOrgLinkDeviceSetting;
  homehubEntranceTabletSetting?: StoreTypesOrgHomehubEntranceTabletSetting;
  organizationId: string;
  name: string;
  nameEn?: string;
  nameJp?: string;
  no?: string;
  address?: CommonThingAddress;
  hardware?: CommonThingHardware;
  features: CommonThingFeatures[];
  relatedToLinkDevice?: boolean;
  spaceAttributes?: string;
  types: CommonThingType[];
  images?: {
    icons?: FileName[];
    installations?: FileName[];
  };
  concurrentThingIds?: string[];
  battery?: StatusChangeLog<number> & {
    level?: 0 | 1 | 2 | 3;
  };
  heartbeat?: StatusChangeLog<ThingStatusHeartbeatType>;
  latestStatus?: {
    status: 'alive' | 'dead';
    timestamp: number;
  };
  lockDeviceStatus?: StoreTypesOrgLockDeviceStatus;
  linkDeviceStatus?: StoreTypesOrgLinkDeviceStatus;
  createdPersonaId: string;
  updatedPersonaId: string;
  createdAt: EpochMillis;
  updatedAt: EpochMillis;
}

const searchableAttributes = {
  name: AlgoliaThingsAttributes.name,
  nameEn: AlgoliaThingsAttributes.nameEn,
  nameJp: AlgoliaThingsAttributes.nameJp,
  serialNo: AlgoliaThingsAttributes.serialNo,
};

export default class AlgoliaThings extends LegacyIndexBase<AlgoliaThingsData> {
  protected name = LegacyIndexName.things;
  protected rawIndexName: string;

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;
  public static readonly facets = () => ({
    id: AlgoliaThingsAttributes.id,
    code: AlgoliaThingsAttributes.code,
    model: AlgoliaThingsAttributes.model,
    createdPersonaId: AlgoliaThingsAttributes.createdPersonaId,
    features: AlgoliaThingsAttributes.features,
    organizationId: AlgoliaThingsAttributes.organizationId,
    spaceId: AlgoliaThingsAttributes.spaceId,
    types: AlgoliaThingsAttributes.types,
    parentSpaceId: AlgoliaThingsAttributes.parentSpaceId,
    updatedPersonaId: AlgoliaThingsAttributes.updatedPersonaId,
    relatedToLinkDevice: AlgoliaThingsAttributes.relatedToLinkDevice,
    spaceAttributes: AlgoliaThingsAttributes.spaceAttributes,
  });

  public static getIndex = (organizationId: string) => new AlgoliaThings(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaThings.facets();
}
