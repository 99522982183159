import './App.css';

import React, {lazy, Suspense, useEffect} from 'react';

import {APIInterceptor} from './components/api/APIInterceptor';
import {Datadog} from './monitoring/datadog';
import Env from './env';
import NetWorkWatcher from './components/network-watcher/NetworkWatcher';
import {Provider} from 'react-redux';
import {ReduxConnectorComponent} from './redux/ReduxConnector';
import {Router} from 'react-router-dom';
import history from './history';
import {store} from './redux/store';

import CsvDownloadProvider from './providers/csv/CsvDownloadProvider';
import CsvDownloadIndicator from './components/indicator/CsvDownloadIndicator';
import {SnackbarWrapper} from './components/snackbars/SnackBarWrapper';
import MuiThemeProvider from '@styles/v2/muiThemeProvider';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

Datadog.startMonitoring();

const RootRouter = lazy(() => import('./router/RootRouter'));
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReduxWrappedApp />
      </QueryClientProvider>
    </Provider>
  );
};

const ReduxWrappedApp: React.FC = () => {
  useEffect(() => {
    Env.init();
  }, []);

  return (
    <>
      <ReduxConnectorComponent />
      <MuiThemeProvider>
        <SnackbarWrapper>
          <APIInterceptor>
            <CsvDownloadProvider>
              <Router history={history}>
                <Suspense fallback={null}>
                  <RootRouter />
                </Suspense>
              </Router>
              <CsvDownloadIndicator />
            </CsvDownloadProvider>
          </APIInterceptor>
        </SnackbarWrapper>
        <NetWorkWatcher />
      </MuiThemeProvider>
      <ReactQueryDevtools />
    </>
  );
};

export default App;
