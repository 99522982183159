import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

export default class FirebaseStorage {
  public static rootRef = (path: string) => firebase.storage().ref().child(path);

  public static upload = (ref: firebase.storage.Reference, data: any) => ref.put(data);

  public static getUrl = (ref: firebase.storage.Reference): Promise<string> => ref.getDownloadURL();

  public static refFromURL = (url: string) => firebase.storage().refFromURL(url);

  public static delete = (ref: firebase.storage.Reference) => ref.delete();
}
