import {Client} from 'algoliasearch';
import {
    BitlinkLinkDevices,
    MacAddress,
    Status,
} from '../../../firebase/firestore/references/bitlinks/firestore-bitlinks';
import {Timestamp} from '../../../firebase/firestore/firestore';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {BleDeviceType} from '../../../firebase/firestore/references/devices/firestore-devices';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaBitlinkAttributes {
  id = 'id',
  name = 'name',
  mac_address = 'mac_address',
  organization_id = 'organization_id',
  firmware_version = 'firmware_version',
  bitlink_type = 'bitlink_type',
  linkDevices = 'link_devices',
  ble_settings = 'ble_settings',
  led_on = 'led_on',
  status = 'status',
  space_name = 'space_name',
  space_id = 'space_id',
  space = 'space',
  created_at = 'created_at',
}

export interface AlgoliaBitlinkData extends AlgoliaObject {
  name: string;
  macAddress: MacAddress;
  organizationId?: string;
  firmwareVersion?: string; // version1.0.18まで入る
  socVersion?: string; // version1.0.21以降入る
  bleVersion?: string; // version1.0.21以降入る
  bitlinkType: BleDeviceType;
  linkDevices: BitlinkLinkDevices;
  bleSettings: {
    scanInterval: number;
    scanWindow: number;
    advertiseInterval: number;
    txPower: number;
    maxConnectionNumber: number;
  };
  advSettings: {
    batteryValue?: boolean;
    log?: boolean;
    doorStatus?: boolean;
    lockStatus?: boolean;
    batteryExpired?: boolean;
    registration?: boolean;
  };
  ledOn: boolean;
  status: Status;
  spaceName?: string;
  spaceId?: string;
  space?: {
    name: string;
    id: string;
  };
  createdAt?: Timestamp;
}

const searchableAttributes = {
  id: AlgoliaBitlinkAttributes.id,
  name: AlgoliaBitlinkAttributes.name,
  spaceName: AlgoliaBitlinkAttributes.space_name,
};

export default class AlgoliaBitlinks extends AlgoliaBase<AlgoliaBitlinkData> {
  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.bitlinks;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public static readonly facets = () => ({
    id: AlgoliaBitlinkAttributes.id,
    name: AlgoliaBitlinkAttributes.name,
    spaceName: AlgoliaBitlinkAttributes.space_name,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaBitlinks(client, organizationId);

  public facets = () => AlgoliaBitlinks.facets();

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;
  public readonly searchableAttributes = AlgoliaBitlinks.searchableAttributes;
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaBitlinkData>(this.index, condition);
}
