import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../../algolia';
import AlgoliaBase from '../../../algolia-base';

export enum AlgoliaSiteGuideAttributes {
  id = 'id',
  title = 'title',
  body = 'body',
  publisherUid = 'publisherUid',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export interface AlgoliaSiteGuideData extends AlgoliaObject {
  title: string;
  body: string;
  publisherUid: string;
  createdAt: number;
  updatedAt: number;
}

export default class AlgoliaSiteGuide extends AlgoliaBase<AlgoliaSiteGuideData> {
  public static readonly facets = () => ({
    id: AlgoliaSiteGuideAttributes.id,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaSiteGuide(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.siteGuide;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaSiteGuide.facets();
}
