/**
 * ファイルの拡張子を定義する
 * 必要な拡張子があれば、適宜定義していく
 */
export enum FileExtension {
  Pdf = 'pdf',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
  Gif = 'gif',
}

export enum ImageExtension {
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
  Gif = 'gif',
}
