import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {EpochMillis} from '../../../common-types/numbers';
import {
    BbsOpenType,
    PublishStatus,
} from '../../../firebase/functions/api-types/organizations/bbs-topics/api-types-organizations-bbs-topics';

export enum AlgoliaBbsTopicAttributes {
  id = 'id',
  updatedAt = 'updatedAt',
  title = 'title',
  content = 'content',
  categoryId = 'categoryId',
  mainImages = 'mainImages',
  documents = 'documents',
  postedPersonaId = 'postedPersonaId',
  postedByDefaultPersona = 'postedByDefaultPersona',
  lastUpdatedAt = 'lastUpdatedAt',
  lastCommentPostedAt = 'lastCommentPostedAt',
  commentCount = 'commentCount',
  openType = 'openType',
  openTargetBuildingIds = 'openTargetBuildingIds',
  status = 'status',
  readableUserAuthorityIds = 'readableUserAuthorityIds',
}

export enum FilterAttributes {
  title = 'title',
}

export enum FacetAttributes {
  categoryId = 'categoryId',
  status = 'status',
  postedByDefaultPersona = 'postedByDefaultPersona',
  postedPersonaId = 'postedPersonaId',
}

export interface AlgoliaBbsTopic extends AlgoliaObject {
  id: string;
  /** 組織ID */
  organizationId: string;
  /** 投稿時間 */
  createdAt: EpochMillis;
  /** 更新時間 */
  updatedAt: EpochMillis;
  /** タイトル */
  title: string;
  /** コンテンツ読み込み権限を持つUserAuthorityの一覧 */
  readableUserAuthorityIds: string[];
  /** カテゴリID */
  categoryId: string;
  /** 公開ステータス */
  status: PublishStatus;
  /** 投稿したペルソナID */
  postedPersonaId: string;
  /** 投稿した人が一般ユーザーか */
  postedByDefaultPersona: boolean;
  /** 最終更新日 */
  lastUpdatedAt: EpochMillis;
  /** コメント最終投稿日 */
  lastCommentPostedAt?: EpochMillis;
  /** コメント数 */
  commentCount: number;
  /** 公開範囲設定(default: Public) */
  openType?: BbsOpenType;
  /** 公開条件 */
  openTargetBuildingIds?: string[];
}

const searchableAttributes = {
  title: AlgoliaBbsTopicAttributes.title,
};

export default class AlgoliaBbsTopics extends AlgoliaBase<AlgoliaBbsTopic> {
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    categoryId: AlgoliaBbsTopicAttributes.categoryId,
    title: AlgoliaBbsTopicAttributes.title,
    postedByDefaultPersona: AlgoliaBbsTopicAttributes.postedByDefaultPersona,
    postedPersonaId: AlgoliaBbsTopicAttributes.postedPersonaId,
    openTargetBuildingIds: AlgoliaBbsTopicAttributes.openTargetBuildingIds,
    status: AlgoliaBbsTopicAttributes.status,
  });

  public static getIndex = (organizationId: string) => new AlgoliaBbsTopics(organizationId);

  private constructor(organizationId: string) {
    super();
    this.name = AlgoliaIndexName.bbsTopics;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);

    // Secured Algolia API key を使っているため、indexに紐づいたAlgolia Clientを取得
    const client = Algolia.getInstance(this.rawIndexName).getClient();
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaBbsTopics.facets();
  public readonly searchableAttributes = AlgoliaBbsTopics.searchableAttributes;
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaBbsTopic>(this.index, condition);
}
