import type {AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import {EnumAlternativeUnion} from '../../../common-utils/type-utils';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

// こういうところ別で持ったほうがいいと思うけど一旦ここで。
export const SpaceLeaseContractStatus = {
  /** 申請済み、未承認。まだ契約としてActiveでない */
  Applied: 'Applied',
  /** 承認済み、であるがまだ契約としてActiveでない。日付等をトリガーにActivateされるようなケースを相手して用意 */
  Approved: 'Approved',
  /** 契約が有効な場合 */
  Active: 'Active',
  /** 破棄(取消)された場合 */
  Discarded: 'Discarded',
  /** 解約は完了していないが、空間の引き渡しは完了済みの場合 */
  HandOvered: 'HandOvered',
  /** 解約された場合 */
  Canceled: 'Canceled',
} as const;
export type SpaceLeaseContractStatus = EnumAlternativeUnion<typeof SpaceLeaseContractStatus>;

export enum AlgoliaSpaceContractsAttributes {
  id = 'id',
  no = 'no',
  customerId = 'customerId',
  customerMemberId = 'customerMemberId',
  spaceId = 'spaceId',
  from = 'from',
  to = 'to',
  contractedAt = 'contractedAt',
  appliedAt = 'appliedAt',
  status = 'status',
}

type YYYYMMDD = string;
type YYYYMMDDHHmmss = string;

export interface AlgoliaSpaceContractsData extends AlgoliaObject {
  no?: string;
  customerId: string;
  customerMemberId?: string;
  spaceId: string;

  to?: EpochMillis;
  toDateString?: YYYYMMDD;
  toDateTimeString?: YYYYMMDDHHmmss;
  from: EpochMillis;
  fromDateString: YYYYMMDD;
  fromDateTimeString: YYYYMMDDHHmmss;
  contractedAt: EpochMillis;
  appliedAt?: EpochMillis;
  cancelAppliedAt?: EpochMillis;
  scheduledCancelAt?: EpochMillis;
  canceledAt?: EpochMillis;
  scheduledHandoverAt?: EpochMillis;
  status: SpaceLeaseContractStatus;
}

export default class AlgoliaSpaceContracts extends LegacyIndexBase<AlgoliaSpaceContractsData> {
  protected name = LegacyIndexName.spaceLeaseContacts;
  protected rawIndexName: string;

  public static readonly facets = () => ({
    // for facet
    id: AlgoliaSpaceContractsAttributes.id,
    no: AlgoliaSpaceContractsAttributes.no,
    customerId: AlgoliaSpaceContractsAttributes.customerId,
    customerMemberId: AlgoliaSpaceContractsAttributes.customerMemberId,
    spaceId: AlgoliaSpaceContractsAttributes.spaceId,
    from: AlgoliaSpaceContractsAttributes.from,
    to: AlgoliaSpaceContractsAttributes.to,
    contractedAt: AlgoliaSpaceContractsAttributes.contractedAt,
    appliedAt: AlgoliaSpaceContractsAttributes.appliedAt,
    status: AlgoliaSpaceContractsAttributes.status,
    // for filter
  });

  public static getIndex = (organizationId: string) => new AlgoliaSpaceContracts(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaSpaceContracts.facets();
}
