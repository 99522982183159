import {
    ApiOrgTeleconferenceSettingGetResponse
} from '../../../firebase/functions/api-types/organizations/common-settings/teleconference-settings/api-types-org-teleconference-settings';
import {V2FirebaseHomehubOldApiFunctions} from '../../../firebase/functions/v2-firebase-homehub-old-api-functions';

export default class TeleconferenceSetting {
  public static loadTeleconferenceSetting = async (): Promise<ApiOrgTeleconferenceSettingGetResponse | undefined> => {
    const res = await V2FirebaseHomehubOldApiFunctions.get<{
      boardMeetingSettingData: ApiOrgTeleconferenceSettingGetResponse;
    }>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/teleconference-settings/`,
    ).catch(e => {
      console.log('Failed to load teleconference setting data.', e);
    });
    if (!res || !res.data) {
      return;
    }
    return res.data.boardMeetingSettingData;
  };
}
