import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import AlgoliaBase from '../../algolia-base';

type CommonTypesName = {
  nameJp: string;
  nameEn: string;
  name: string; // home領域ではまだnameを利用しているため暫定で追加
};

export enum AlgoliaKeyBundleAttributes {
  id = 'id',
  code = 'code',
  name = 'name',
  nameJp = 'nameJp',
  nameEn = 'nameEn',
  memo = 'memo',
  spaceNames = 'spaceNames',
  inChargeOfSpaceIds = 'inChargeOfSpaceIds',
  spaceIds = 'spaceIds',
  organizationId = 'organizationId',
  createdPersonaId = 'createdPersonaId',
  updatedPersonaId = 'updatedPersonaId',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export interface AlgoliaKeyBundle extends AlgoliaObject, CommonTypesName {
  id: string;
  code: string;
  /**
   * セキュリティデバイスが設置されている空間のID
   */
  spaceIds: string[];
  /**
   * セキュリティデバイスが設置されている空間の名称
   */
  spaceNames: string[];
  /**
   * 担当支店ID（検索用にフラットに保持する）
   */
  inChargeOfSpaceIds: string[];

  //--------------------------------------------------------
  // Algolia連携の共通項目
  //--------------------------------------------------------
  /** 組織ID */
  organizationId: string;
  /** 作成者のペルソナID */
  createdPersonaId: string;
  /** 更新者のペルソナID */
  updatedPersonaId: string;
  /** 作成日時 */
  createdAt: EpochMillis;
  /** 更新日時 */
  updatedAt: EpochMillis;
}

const searchableAttributes = {
  code: AlgoliaKeyBundleAttributes.code,
  name: AlgoliaKeyBundleAttributes.name,
  nameJp: AlgoliaKeyBundleAttributes.nameJp,
  nameEn: AlgoliaKeyBundleAttributes.nameEn,
  spaceNames: AlgoliaKeyBundleAttributes.spaceNames,
};
export default class AlgoliaKeyBundles extends AlgoliaBase<AlgoliaKeyBundle> {
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    id: AlgoliaKeyBundleAttributes.id,
    code: AlgoliaKeyBundleAttributes.code,
    name: AlgoliaKeyBundleAttributes.name,
    spaceIds: AlgoliaKeyBundleAttributes.spaceIds,
    inChargeOfSpaceIds: AlgoliaKeyBundleAttributes.inChargeOfSpaceIds,
    spaceNames: AlgoliaKeyBundleAttributes.spaceNames,
    createdPersonaId: AlgoliaKeyBundleAttributes.createdPersonaId,
    updatedPersonaId: AlgoliaKeyBundleAttributes.updatedPersonaId,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaKeyBundles(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.keyBundles;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }
  public searchableAttributes = AlgoliaKeyBundles.searchableAttributes;
  public facets = () => AlgoliaKeyBundles.facets();
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaKeyBundle>(this.index, condition);

}
