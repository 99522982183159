import algoliasearch, {BrowseParameters, BrowseResponse, Client, Index, QueryParameters, Response} from 'algoliasearch';
import AlgoliaMembers from './references/members/algolia-members';
import AlgoliaBbsTopics from './references/bbs-topics/algolia-bbs-topics';
import AlgoliaDevices from './references/devices/algolia-devices';
import AlgoliaThings from './references/things/algolia-things';
import CommonError from '../errors/common-error';
import AlgoliaSpaces from './references/spaces/algolia-spaces';
import AlgoliaSpacesDeleted from './references/spaces/algolia-space-deleted-data';
import AlgoliaCustomers from './references/customers/algolia-customers';
import AlgoliaWifiConnectors from './references/wifi-connector/algolia-wifi-connectors';
import AlgoliaSiteAnnounce from './references/site/announce/algolia-site-announce';
import AlgoliaSiteGuide from './references/site/guide/algolia-site-guide';
import AlgoliaSiteFaq from './references/site/faq/algolia-site-faq';
import AlgoliaGuests from './references/guests/algolia-guests';
import AlgoliaGuestsDeleted from './references/guests/algolia-guests-deleted';
import AlgoliaSiteContacts from './references/site/contacts/algolia-site-contacts';
import AlgoliaSpaceTypes from './references/space-types/algolia-spaceTypes';
import AlgoliaKeys from './references/keys/algolia-keys';
import AlgoliaCustomerContracts from './references/customer-contracts/algolia-customer-contracts';
import AlgoliaDeviceLogs from './references/device-logs/algolia-deviceLogs';
import AlgoliaConnectableDevices from './references/connectable-devices/algolia-connectable-devices';
import AlgoliaSpaceAttributes from './references/space-attributes/algolia-space-attributes';
import AlgoliaReaders from './references/readers/algolia-readers';
import AlgoliaTreasureDeals from './references/treasure-deals/algolia-treasure-deals';
import AlgoliaNfcCards from './references/nfc-cards/algolia-nfc-cards';
import AlgoliaMemberPropertyLabels from './references/member-property-labels/algolia-member-property-labels';
import AlgoliaReaderLogs from './references/reader-logs/algolia-readerLogs';
import AlgoliaRoutes from './references/routes/algolia-routes';
import {ReplaceType} from '../common-utils/type-utils';
import AlgoliaReceptions from './references/receptions/algolia-receptions';
import AlgoliaTreasureDataPresets from './references/treasure-data-presets/algolia-treasure-data-presets';
import AlgoliaDeviceButtonRelations from './references/device-button-relations/algolia-device-button-relations';
import AlgoliaNfcCardsDeleted from './references/nfc-cards/algolia-nfc-cards-deleted';
import AlgoliaReadersDeleted from './references/readers/algolia-readers-deleted';
import AlgoliaDevicesDeleted from './references/devices/algolia-devices-deleted';
import AlgoliaMembersDeleted from './references/members/algolia-members-deleted';
import AlgoliaReservations from './references/reservations/algolia-reservations';
import AlgoliaOrders from './references/orders/algolia-orders';
import AlgoliaRegions from './references/regions/algolia-regions';
import AlgoliaBoardMembers from './references/board-members/algolia-board-members';
import AlgoliaBoardMeetings from './references/board-meetings/algolia-board-meetings';
import Logger from '../common/logger/logger';
import AlgoliaFaceImageAuthContractsSummaries from './references/face-image-auth-contracts-summaries/algolia-face-image-auth-contracts-summaries';
import AlgoliaOnsiteReports from './references/onsite-reports/algolia-onsite-reports';
import AlgoliaOnsiteReportSettings from './references/onsite-report-settings/algolia-onsite-report-settings';
import AlgoliaDashboardContents from './references/dashbord/algolia-dashboard-contents';
import AlgoliaBitlinks from './references/bitlinks/algolia-bitlinks';
import {SystemAuditLogObserveService} from '../services/system-audit-logs/system-audit-log-observe-service';
import AlgoliaFaqs from './references/faqs/algolia-faqs';
import AlogiaFaqTags from './references/faq-tags/algolia-faq-tags';
import AlgoliaFaqContacts from './references/faq-contacts/algolia-faq-contacts';
import AlgoliaAnnouncements from './references/announcements/algolia-announcements';
import AlgoliaOrganizationNfcCards from './references/organization-nfc-cards/algolia-organizationNfcCards';
import AlgoliaEquipments from './references/equipments/algolia-equipments';
import AlgoliaKeyBundles from './references/keyBundles/algolia-keyBundles';
import AlgoliaSpaceUsages from './references/space-usages/algolia-spaceUsages';
import AlgoliaSpaceContracts from './references/space-contracts/algolia-space-contracts';
import AlgoliaCustomerMembers from './references/customer-members/algolia-customer-members';
import AlgoliaCondominiumAssociations from './references/condominium-associations/algolia-condominium-associations';
import AlgoliaThingStatus from './references/thingStatus/algolia-thingStatus';
import AlgoliaResidents from './references/residents/algolia-residents';

export interface AlgoliaObject {
  id: string;
}

export type AlgoliaFilters = {
  [filterAttributeName: string]: (string | number | boolean)[];
};

export enum AlgoliaNumericFilterSign {
  equaul = '=',
  not_equal = '!=',
  greater_than = '>',
  greater_than_or_equal = '>=',
  less_than = '<',
  less_than_or_equal = '<=',
}

export type AlgoliaNumericFilter = {
  filterAttributeName: string;
  sign: AlgoliaNumericFilterSign;
  value: number;
};

export type AlgoliaFromToDateFilter = {
  filterAttributeName: string;
  fromValue: number;
  toValue: number;
};

export type AlgoliaSort = {
  field: string;
  order: 'asc' | 'desc';
};

export interface AlgoliaIndex<T extends AlgoliaObject> {
  getName: () => string;
  search: (condition: AlgoliaSearchCondition) => Promise<Response<T>>;
  relation: (filters: AlgoliaFilters) => Promise<Response<T> | undefined>;
  facets: () => {[facetName: string]: string};
  clearCache: () => void;
  browse: (condition: BrowseCondition) => Promise<InitialBrowseData<T>>;
  browseNext: (cursor: string) => Promise<BrowseData<T>>;
}

export type BrowseData<T> = ReplaceType<BrowseResponse, {}, {hits: T[]}>;
export type InitialBrowseData<T = any> = {searchData: Response<T>; browseData: BrowseData<T>};
export type BrowseCondition = {
  searchWord?: string;
  filters?: AlgoliaFilters;
  multipleFilters?: AlgoliaFilters;
  excludeFilters?: AlgoliaFilters;
  numericFilters?: AlgoliaNumericFilter[];
  fromToDateFilter?: AlgoliaFromToDateFilter;
  facets?: AlgoliaFilters;
  length?: number;
  offset?: number;
  allFacets?: boolean;
  getFacets?: string[]; // 常に全facet用attributeを受け取ると絞り込まない場合に負荷高いので必要なものだけに絞れるようにする
  restrictSearchableAttributes?: string[];
  additionalFacetsFields?: string[]; // [deprecated]: 既存動作担保のためやむをえずfacetを利用したい箇所でのみ指定する
};
export type AlgoliaSearchCondition = {
  searchWord?: string;
  filters?: AlgoliaFilters;
  multipleFilters?: AlgoliaFilters;
  excludeFilters?: AlgoliaFilters;
  numericFilters?: AlgoliaNumericFilter[];
  fromToDateFilter?: AlgoliaFromToDateFilter;
  facets?: AlgoliaFilters;
  length?: number;
  offset?: number;
  sort?: AlgoliaSort[];
  restrictSearchableAttributes?: string[];
  additionalFacetsFields?: string[]; // [deprecated]: 既存動作担保のためやむをえずfacetを利用したい箇所でのみ指定する
};

export enum AlgoliaEmptyValue {
  null = 'null',
  emptystring = 'emptystring',
  emptyarray = 'emptyarray',
  emptyobject = 'emptyobject',
}

export type AlgoliaCredentials = {
  indexName: string;
  apiKey: string;
  applicationId: string;
};

const FORCE_EMPTY_FILTER = ['xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'];
const logger = Logger.create('algolia');
export default class Algolia {
  private static instances: Map<string, Algolia> | undefined;

  private readonly organizationId: string;
  private readonly client: algoliasearch.Client;

  public static init = (
    organizationId: string,
    applicationId: string,
    searchOnlyApiKey: string,
    credentials: AlgoliaCredentials[],
  ) => {
    const defaultInstance: [string, Algolia] = [
      'default',
      new Algolia(organizationId, applicationId, searchOnlyApiKey),
    ];
    const securedInstances: [string, Algolia][] = credentials.map(cred => [
      cred.indexName,
      new Algolia(organizationId, cred.applicationId, cred.apiKey),
    ]);

    Algolia.instances = new Map([defaultInstance, ...securedInstances]);
  };

  public static hasInitialized = () => !!Algolia.instances;

  public static getInstance = (indexName: string = 'default'): Algolia => {
    const instance = Algolia.instances?.get(indexName);
    if (!instance) {
      throw new CommonError('', 'Algolia not initialized.');
    }
    return instance;
  };

  public getClient = (): Client => {
    return this.client;
  };

  constructor(organizationId: string, applicationId: string, searchOnlyApiKey: string) {
    this.organizationId = organizationId;
    this.client = algoliasearch(applicationId, searchOnlyApiKey, {});

    // TODO: algolia 4.xに更新した時にまだ以下のバグが発生したら対応
    // this.fixLongApiKeyBug()
  }

  public static clear = () => (Algolia.instances = undefined);

  public static toIndexName = (organizationId: string, indexName: string) => `${organizationId}_${indexName}`;

  private static makeNativeFilters = (
    filters?: AlgoliaFilters,
    excludeFilters?: AlgoliaFilters,
    numericFilters?: AlgoliaNumericFilter[],
    fromToDateFilter?,
    multipleFilters?: AlgoliaFilters,
  ): string => {
    let nativeFilter: string = '';
    if (filters) {
      for (const filterAttributeName of Object.keys(filters)) {
        let attributeFilter: string = '';
        const values = filters[filterAttributeName];
        for (const value of values) {
          const paddedValue = typeof value === 'string' ? `"${value}"` : value;
          attributeFilter += ` OR ${filterAttributeName}:${paddedValue}`;
        }
        if (attributeFilter) {
          nativeFilter += ` AND (${attributeFilter.slice(4)})`;
        }
      }
    }
    // fill multiple filters
    // for difference keys
    // ex: "author:Stephen King" OR "genre:Horror" AND "publisher:Penguin"
    // refer: https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/filters-and-facetfilters/
    if (multipleFilters) {
      for (const filterAttributeName of Object.keys(multipleFilters)) {
        let attributeFilter: string = '';
        const values = multipleFilters[filterAttributeName];
        for (const value of values) {
          const paddedValue = typeof value === 'string' ? `"${value}"` : value;
          attributeFilter += ` OR ${filterAttributeName}:${paddedValue}`;
        }
        if (attributeFilter) {
          // the last slice 5 chars -> line 271
          // nativeFilter = nativeFilter.slice(5); -> line 271
          nativeFilter += `  OR (${attributeFilter.slice(4)})`;
        }
      }
    }
    if (excludeFilters) {
      for (const filterAttributeName of Object.keys(excludeFilters)) {
        let attributeFilter: string = '';
        const values = excludeFilters[filterAttributeName];
        for (const value of values) {
          const paddedValue = typeof value === 'string' ? `"${value}"` : value;
          attributeFilter += ` AND NOT ${filterAttributeName}:${paddedValue}`;
        }
        if (attributeFilter) {
          nativeFilter += ` AND (${attributeFilter.slice(4)})`;
        }
      }
    }
    if (numericFilters) {
      for (const numericFilter of numericFilters) {
        switch (numericFilter.sign) {
          case AlgoliaNumericFilterSign.equaul:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.equaul} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.not_equal:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.not_equal} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.greater_than:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.greater_than} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.greater_than_or_equal:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.greater_than_or_equal} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.less_than:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.less_than} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.less_than_or_equal:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.less_than_or_equal} ${numericFilter.value}`;
            break;
          default:
            console.log('algolia numeric filter sign is invalid.');
        }
      }
    }

    if (fromToDateFilter) {
      nativeFilter += ` AND ${fromToDateFilter.filterAttributeName}:${fromToDateFilter.fromValue} TO ${fromToDateFilter.toValue}`;
    }
    if (nativeFilter) {
      nativeFilter = nativeFilter.slice(5);
    }
    return nativeFilter;
  };

  private static makeNativeFacetFilters = (facets?: AlgoliaFilters): string[][] => {
    const nativeFacetFilters: string[][] = [];
    if (facets) {
      for (const facetAttributeName of Object.keys(facets)) {
        const values = facets[facetAttributeName];
        nativeFacetFilters.push(values.map(v => `${facetAttributeName}:${v}`));
      }
    }
    return nativeFacetFilters;
  };

  public static convertHits = <T extends AlgoliaObject>(res: Response<T>): void => {
    if (!res || !res.hits) return;
    for (const hit of res.hits) {
      for (const k of Object.keys(hit)) {
        const value = hit[k];
        if (value === AlgoliaEmptyValue.null) {
          hit[k] = undefined;
        } else if (value === AlgoliaEmptyValue.emptystring) {
          hit[k] = '';
        } else if (value === AlgoliaEmptyValue.emptyarray) {
          hit[k] = [];
        } else if (value === AlgoliaEmptyValue.emptyobject) {
          hit[k] = {};
        }
      }
    }
  };

  // browse用
  public static convertBrowseHits = <T extends BrowseResponse>(res: T): void => {
    if (!res || !res.hits) return;
    for (const hit of res.hits) {
      for (const k of Object.keys(hit)) {
        const value = hit[k];
        if (value === AlgoliaEmptyValue.null) {
          hit[k] = undefined;
        } else if (value === AlgoliaEmptyValue.emptystring) {
          hit[k] = '';
        } else if (value === AlgoliaEmptyValue.emptyarray) {
          hit[k] = [];
        } else if (value === AlgoliaEmptyValue.emptyobject) {
          hit[k] = {};
        }
      }
    }
  };

  public static search = async <T extends AlgoliaObject>(
    index: Index,
    condition: AlgoliaSearchCondition,
  ): Promise<Response<T>> => {
    if (!!condition.filters && !!Object.keys(condition.filters).length) {
      for (const k of Object.keys(condition.filters)) {
        const f = condition.filters[k];
        if (!f || !f.length) {
          // これだとfacet返ってきちゃうからだめ
          // condition.length = 0;
          condition.filters[k] = FORCE_EMPTY_FILTER;
        }
      }
    }
    const searchParameters = {
      query: condition.searchWord,
      filters: Algolia.makeNativeFilters(
        condition.filters,
        condition.excludeFilters,
        condition.numericFilters,
        condition.fromToDateFilter,
        condition.multipleFilters,
      ),
      facetFilters: Algolia.makeNativeFacetFilters(condition.facets),
      length: condition.length,
      offset: condition.offset,
      facets: ['*'],
      restrictSearchableAttributes: condition.restrictSearchableAttributes
        ? condition.restrictSearchableAttributes
        : undefined,
    };
    Algolia.checkSystemAuditLog({
      indexName: index.indexName as AlgoliaIndexName,
      searchParameters,
    });
    const res = await index.search<T>(searchParameters);
    Algolia.convertHits(res);
    console.log(index.indexName, res);
    return res;
  };

  /**
   * 検索結果から何らかのidでリレーションしたいときに。
   * やってることはsearchと同じ。
   * algoliaの仕様上、filterが空の場合は全部返ってくるが、ここでやりたいことはリレーションなので
   * filterが空の場合は何も返さないようにする
   * @param index
   * @param filters
   * @param length
   */
  public static relation = async <T extends AlgoliaObject>(
    index: Index,
    filters: AlgoliaFilters,
    length?: number,
  ): Promise<Response<T>> => {
    if (!filters) filters = {};
    let filterExists = false;
    for (const k of Object.keys(filters)) {
      if (filters[k] && filters[k].length) {
        filterExists = true;
        break;
      }
    }
    if (!!filters && !!Object.keys(filters).length) {
      for (const k of Object.keys(filters)) {
        const f = filters[k];
        if (!f || !f.length) {
          // これだとfacet返ってきちゃうからだめ
          // length = 0;
          filters[k] = FORCE_EMPTY_FILTER;
        }
      }
    }
    const searchParameters: QueryParameters = {
      filters: Algolia.makeNativeFilters(filters),
      length: filterExists ? (length ? length : 1000) : 1,
      offset: 0,
      // これは使えないか、使うとしてもかなり難しいので使い方間違えないように使えないようにしておく。
      // facets: ['*'],
    };
    Algolia.checkSystemAuditLog({
      indexName: index.indexName as AlgoliaIndexName,
      searchParameters,
    });
    const res = await index.search<T>(searchParameters);
    Algolia.convertHits(res);
    console.log(index.indexName, res);
    return res;
  };

  // state用
  public static getEmptyBrowseData = (): InitialBrowseData => ({
    searchData: {
      hits: [],
      params: 'initial',
      query: '',
      processingTimeMS: -1,
      nbHits: 0,
      cursor: '',
      facets: {},
      facets_stats: {},
      hitsPerPage: 0,
      index: '',
      nbPages: 0,
      page: 0,
      exhaustiveNbHits: false,
      exhaustiveFacetsCount: false,
    },
    browseData: {
      hits: [],
      params: 'initial',
      query: '',
      processingTimeMS: -1,
      // nbHits: 0,
      cursor: '',
      // facets: {},
      // facets_stats: {},
      // hitsPerPage: 0,
      // index: '',
      // nbPages: 0,
      // page: 0,
    },
  });

  public static browse = async <T extends AlgoliaObject>(
    index: Index,
    condition: BrowseCondition,
  ): Promise<InitialBrowseData<T>> => {
    // browseにnbHitsとfacetsが含まれないためlimit=1のsearchを実行して補完する。
    // 一発で取れる方法があれば修正。
    const searchParameters = {
      query: condition.searchWord ? condition.searchWord : '',
      filters: Algolia.makeNativeFilters(
        condition.filters,
        condition.excludeFilters,
        condition.numericFilters,
        condition.fromToDateFilter,
        condition.multipleFilters,
      ),
      facetFilters: Algolia.makeNativeFacetFilters(condition.facets),
      length: 1,
      offset: condition.offset ? condition.offset : 0,
      facets: condition.getFacets ? condition.getFacets : ['*'],
    };

    Algolia.checkSystemAuditLog({
      indexName: index.indexName as AlgoliaIndexName,
      searchParameters: searchParameters,
    });

    const searchData = await index.search(searchParameters);
    logger.log('[search_for_nbHits_facets]', {
      indexName: index.indexName,
      nbHits: searchData.nbHits,
      facets: searchData.facets,
    });
    // facetによる絞り込みをfacetの一覧に影響させたくないとき
    if (condition.allFacets) {
      const searchParametersWithoutFacet = {
        query: condition.searchWord ? condition.searchWord : '',
        filters: Algolia.makeNativeFilters(
          condition.filters,
          condition.excludeFilters,
          condition.numericFilters,
          condition.fromToDateFilter,
          condition.multipleFilters,
        ),
        facetFilters: [],
        length: 1,
        offset: condition.offset ? condition.offset : 0,
        facets: condition.getFacets ? condition.getFacets : ['*'],
      };
      Algolia.checkSystemAuditLog({
        indexName: index.indexName as AlgoliaIndexName,
        searchParameters: searchParametersWithoutFacet,
      });
      const {facets: allFacets, facets_stats: allFacetsStats} = await index.search(searchParametersWithoutFacet);
      searchData.facets = allFacets;
      searchData.facets_stats = allFacetsStats;
      logger.log('[search_for_allFacets]', {indexName: index.indexName, facets: searchData.facets});
    }

    const browseQuery = condition.searchWord ? condition.searchWord : '';
    const browseParameters = {
      filters: Algolia.makeNativeFilters(
        condition.filters,
        condition.excludeFilters,
        condition.numericFilters,
        condition.fromToDateFilter,
        condition.multipleFilters,
      ),
      facetFilters: Algolia.makeNativeFacetFilters(condition.facets),
      hitsPerPage: condition.length ? condition.length : 200,
      page: condition.offset,
      restrictSearchableAttributes: condition.restrictSearchableAttributes
        ? condition.restrictSearchableAttributes
        : undefined,
    };

    Algolia.checkSystemAuditLog({
      indexName: index.indexName as AlgoliaIndexName,
      browseConditions: {
        query: browseQuery,
        browseParameters,
      },
    });

    const browseData = (await index.browse(browseQuery, browseParameters)) as BrowseData<T>;
    // このままだとemptyStringとかの文字列が入ってしまうので、削除する。
    Algolia.convertBrowseHits(browseData);
    // Logger.logにまるごとbrowseDataとか渡すと多すぎてやばいのであえてconsole.logで
    console.log('browse', index.indexName, searchData, browseData);
    return {searchData, browseData};
  };

  private static checkSystemAuditLog = (data: {
    indexName: AlgoliaIndexName;
    searchParameters?: QueryParameters;
    browseConditions?: {
      query: string;
      browseParameters: BrowseParameters;
    };
  }) => {
    if (SystemAuditLogObserveService.getInstance().isObserving()) {
      SystemAuditLogObserveService.getInstance().setAlgoliaRequests(data);
    }
  };

  public static browseNext = async <T extends AlgoliaObject>(index: Index, cursor: string) => {
    const nextRes = await index.browseFrom(cursor);
    // このままだとemptyStringとかの文字列が入ってしまうので、削除する。
    Algolia.convertBrowseHits(nextRes);
    console.log('browseNext', index.indexName, nextRes);
    return nextRes as BrowseData<T>;
  };

  /**
   * browseを利用して全件取得したい時（利用にはパフォーマンス要注意）
   * 利用にはAlgoliaの管理コンソールからSearch-Only API keyに対してbrowseの権限付与をする必要あり
   * @param index
   * @param condition
   */
  public static getAllHitsByBrowseResponse = async <T extends AlgoliaObject>(
    index: Index,
    condition: {
      searchWord: string;
      filters: AlgoliaFilters;
      excludeFilters?: AlgoliaFilters;
      numericFilters?: AlgoliaNumericFilter[];
      fromToDateFilter?: AlgoliaFromToDateFilter;
      facets: AlgoliaFilters;
      length: number;
      offset: number;
    },
  ): Promise<T[]> => {
    const {browseData: res} = await Algolia.browse(index, condition);
    let allHits = res.hits;
    let hasNextCursor = !!res.cursor;
    if (!res.cursor) {
      return allHits as T[];
    } else {
      let cursor = res.cursor;
      console.log('browse response has next cursor. cursor is: ', cursor);
      while (hasNextCursor) {
        const nextRes = await Algolia.browseNext(index, cursor);
        allHits = [...allHits, ...nextRes.hits];
        if (nextRes.cursor) {
          cursor = nextRes.cursor;
          console.log('browse response has next cursor. cursor is: ', cursor);
        } else {
          hasNextCursor = false;
          console.log('browse is finished.');
        }
      }
      return allHits as T[];
    }
  };

  public static indices = () => {
    const instance = Algolia.getInstance();
    if (instance) {
      return instance.indices;
    }
    throw new Error('Algolia has not initialized.');
  };

  public static clearCache = (index: Index) => {
    index.clearCache();
  };

  public readonly indices = {
    devices: () => AlgoliaDevices.getIndex(this.organizationId),
    things: () => AlgoliaThings.getIndex(this.organizationId),
    thingStatus: () => AlgoliaThingStatus.getIndex(this.organizationId),
    devicesDeleted: () => AlgoliaDevicesDeleted.getIndex(this.client, this.organizationId),
    deviceLogs: () => AlgoliaDeviceLogs.getIndex(this.client, this.organizationId),
    keys: () => AlgoliaKeys.getIndex(this.organizationId),
    members: () => AlgoliaMembers.getIndex(this.organizationId),
    bbsTopics: () => AlgoliaBbsTopics.getIndex(this.organizationId),
    membersDeleted: () => AlgoliaMembersDeleted.getIndex(this.client, this.organizationId),
    spaces: () => AlgoliaSpaces.getIndex(this.organizationId),
    spacesDeleted: () => AlgoliaSpacesDeleted.getIndex(this.client, this.organizationId),
    spaceTypes: () => AlgoliaSpaceTypes.getIndex(this.client, this.organizationId),
    spaceAttributes: () => AlgoliaSpaceAttributes.getIndex(this.client, this.organizationId),
    routes: () => AlgoliaRoutes.getIndex(this.client, this.organizationId),
    customers: () => AlgoliaCustomers.getIndex(this.organizationId),
    customerContracts: () => AlgoliaCustomerContracts.getIndex(this.organizationId),
    faceImageAuthContractsSummaries: () =>
      AlgoliaFaceImageAuthContractsSummaries.getIndex(this.client, this.organizationId),
    wifiConnectors: () => AlgoliaWifiConnectors.getIndex(this.client, this.organizationId),
    guests: () => AlgoliaGuests.getIndex(this.organizationId),
    guestsDeleted: () => AlgoliaGuestsDeleted.getIndex(this.client, this.organizationId),
    siteAnnounce: () => AlgoliaSiteAnnounce.getIndex(this.client, this.organizationId),
    siteGuide: () => AlgoliaSiteGuide.getIndex(this.client, this.organizationId),
    siteFaq: () => AlgoliaSiteFaq.getIndex(this.client, this.organizationId),
    siteContacts: () => AlgoliaSiteContacts.getIndex(this.client, this.organizationId),
    connectableDevices: () => AlgoliaConnectableDevices.getIndex(this.client, this.organizationId),
    readers: () => AlgoliaReaders.getIndex(this.client, this.organizationId),
    readersDeleted: () => AlgoliaReadersDeleted.getIndex(this.client, this.organizationId),
    readerLogs: () => AlgoliaReaderLogs.getIndex(this.client, this.organizationId),
    nfcCards: () => AlgoliaNfcCards.getIndex(this.client, this.organizationId),
    nfcCardsDeleted: () => AlgoliaNfcCardsDeleted.getIndex(this.client, this.organizationId),
    organizationNfcCards: () => AlgoliaOrganizationNfcCards.getIndex(this.organizationId),
    treasureDeals: () => AlgoliaTreasureDeals.getIndex(this.client, this.organizationId),
    treasureDataPresets: () => AlgoliaTreasureDataPresets.getIndex(this.client, this.organizationId),
    memberPropertyLabels: () => AlgoliaMemberPropertyLabels.getIndex(this.client, this.organizationId),
    receptions: () => AlgoliaReceptions.getIndex(this.client, this.organizationId),
    deviceButtonRelations: () => AlgoliaDeviceButtonRelations.getIndex(this.client, this.organizationId),
    reservations: () => AlgoliaReservations.getIndex(this.organizationId),
    dashboardContents: () => AlgoliaDashboardContents.getIndex(this.client, this.organizationId),
    bitlinks: () => AlgoliaBitlinks.getIndex(this.client, this.organizationId),
    orders: () => AlgoliaOrders.getIndex(this.client, this.organizationId),
    regions: () => AlgoliaRegions.getIndex(this.client, this.organizationId),
    boardMembers: () => AlgoliaBoardMembers.getIndex(this.client, this.organizationId),
    boardMeetings: () => AlgoliaBoardMeetings.getIndex(this.organizationId),
    onsiteReports: () => AlgoliaOnsiteReports.getIndex(this.client, this.organizationId),
    onsiteReportSettings: () => AlgoliaOnsiteReportSettings.getIndex(this.client, this.organizationId),
    faqs: () => AlgoliaFaqs.getIndex(this.organizationId),
    faqTags: () => AlogiaFaqTags.getIndex(this.organizationId),
    faqContacts: () => AlgoliaFaqContacts.getIndex(this.organizationId),
    announcements: () => AlgoliaAnnouncements.getIndex(this.client, this.organizationId),
    equipments: () => AlgoliaEquipments.getIndex(this.client, this.organizationId),
    keyBundles: () => AlgoliaKeyBundles.getIndex(this.client, this.organizationId),
    spaceUsages: () => AlgoliaSpaceUsages.getIndex(this.organizationId),
    spaceContracts: () => AlgoliaSpaceContracts.getIndex(this.organizationId),
    customerMembers: () => AlgoliaCustomerMembers.getIndex(this.organizationId),
    condominiumAssociation: () => AlgoliaCondominiumAssociations.getIndex(this.client, this.organizationId),
    residents: () => AlgoliaResidents.getIndex(this.organizationId),
  };

  public static readonly facets = () => ({
    devices: () => AlgoliaDevices.facets(),
    devicesDeleted: () => AlgoliaDevicesDeleted.facets(),
    deviceLogs: () => AlgoliaDeviceLogs.facets(),
    keys: () => AlgoliaKeys.facets(),
    members: () => AlgoliaMembers.facets(),
    bbsToics: () => AlgoliaBbsTopics.facets(),
    spaces: () => AlgoliaSpaces.facets(),
    spaceTypes: () => AlgoliaSpaceTypes.facets(),
    spaceAttributes: () => AlgoliaSpaceAttributes.facets(),
    routes: () => AlgoliaRoutes.facets(),
    customers: () => AlgoliaCustomers.facets(),
    customerContracts: () => AlgoliaCustomerContracts.facets(),
    faceImageAuthContractsSummaries: () => AlgoliaFaceImageAuthContractsSummaries.facets(),
    wifiConnectors: () => AlgoliaWifiConnectors.facets(),
    guests: () => AlgoliaGuests.facets(),
    siteAnnounce: () => AlgoliaSiteAnnounce.facets(),
    siteGuide: () => AlgoliaSiteGuide.facets(),
    siteFaq: () => AlgoliaSiteFaq.facets(),
    siteContacts: () => AlgoliaSiteContacts.facets(),
    connectableDevices: () => AlgoliaConnectableDevices.facets(),
    readers: () => AlgoliaReaders.facets(),
    readerLogs: () => AlgoliaReaderLogs.facets(),
    treasureDeals: () => AlgoliaTreasureDeals.facets(),
    nfcCards: () => AlgoliaNfcCards.facets(),
    nfcCardsDeleted: () => AlgoliaNfcCardsDeleted.facets(),
    organizationNfcCards: () => AlgoliaOrganizationNfcCards.facets(),
    treasureDataPresets: () => AlgoliaTreasureDataPresets.facets(),
    memberPropertyLabels: () => AlgoliaMemberPropertyLabels.facets(),
    receptions: () => AlgoliaReceptions.facets(),
    deviceButtonRelations: () => AlgoliaDeviceButtonRelations.facets(),
    reservations: () => AlgoliaReservations.facets(),
    bitlinks: () => AlgoliaBitlinks.facets(),
    orders: () => AlgoliaOrders.facets(),
    regions: () => AlgoliaRegions.facets(),
    boardMembers: () => AlgoliaBoardMembers.facets(),
    boardMeetings: () => AlgoliaBoardMeetings.facets(),
    onsiteReports: () => AlgoliaOnsiteReports.facets(),
    onsiteReportSettings: () => AlgoliaOnsiteReportSettings.facets(),
    faqs: () => AlgoliaFaqs.facets(),
    faqContacts: () => AlgoliaFaqContacts.facets(),
    keyBundles: () => AlgoliaKeyBundles.facets(),
    spaceUsages: () => AlgoliaSpaceUsages.facets(),
    spaceContracts: () => AlgoliaSpaceContracts.facets(),
    customerMembers: () => AlgoliaCustomerMembers.facets(),
    condominiumAssociations: () => AlgoliaCondominiumAssociations.facets(),
    residents: () => AlgoliaResidents.facets(),
  });

  /**
   * AlgoliaのAPI keyをbodyで送るようにする
   *
   * Algolia 4.xで必ずAPI keyがヘッダーで送られ、keyが長い場合はエラーが発生しうる
   * @see https://github.com/algolia/algoliasearch-client-javascript/issues/1035
   */
  // private fixLongApiKeyBug = () => {
  //   const anyTransporter = this.client.transporter as any; // readonly項目上書きのため
  //   delete anyTransporter.queryParameters['x-algolia-api-key'];
  //   delete anyTransporter.headers['x-algolia-api-key'];
  //   const originalRead = this.client.transporter.read;
  //   anyTransporter.read = (request: Request, requestOptions?: RequestOptions) => {
  //     return originalRead(request, {apiKey: algoliaAPIKey, ...requestOptions});
  //   };
  // };
}

export enum AlgoliaIndexName {
  devices = 'devices',
  devicesDeleted = 'devicesDeleted',
  deviceLogs = 'deviceLogs',
  keys = 'keys',
  members = 'members',
  bbsTopics = 'BbsTopics',
  membersDeleted = 'membersDeleted',
  spaces = 'spaces',
  spacesDeleted = 'spacesDeleted',
  spaceTypes = 'spaceTypes',
  spaceAttributes = 'spaceAttributes',
  routes = 'routes',
  customers = 'customers',
  customerContracts = 'customerContracts',
  faceImageAuthContractsSummaries = 'faceImageAuthContractsSummaries',
  wifiConnectors = 'wifiConnectors',
  guests = 'guests',
  guestsDeleted = 'guestsDeleted',
  siteAnnounce = 'siteAnnounce',
  siteGuide = 'siteGuide',
  siteFaq = 'siteFaq',
  siteContacts = 'siteContacts',
  connectableDevices = 'connectableDevices',
  readers = 'readers',
  readersDeleted = 'readersDeleted',
  readerLogs = 'readerLogs',
  treasureDeals = 'treasureDeals',
  organizationNfcCards = 'organizationNfcCards',
  nfcCards = 'nfcCards',
  nfcCardsDeleted = 'nfcCardsDeleted',
  treasureDataPresets = 'treasureDataPresets',
  contractApplications = 'contractApplications',
  memberPropertyLabels = 'memberPropertyLabels',
  receptions = 'receptions',
  deviceButtonRelations = 'deviceButtonRelations',
  reservations = 'reservations',
  dashboardContents = 'dashboardContents',
  bitlinks = 'bitlinks',
  orders = 'orders',
  regions = 'regions',
  boardMembers = 'boardMembers',
  boardMeetings = 'boardMeetings',
  onsiteReports = 'onsiteReports',
  onsiteReportSettings = 'onsiteReportSettings',
  faqs = 'Faq',
  things = 'things',
  thingStatus = 'thingStatus',
  faqTags = 'FaqTags',
  faqContacts = 'FaqContacts',
  announcements = 'announcements',
  equipments = 'tmpEquipments',
  keyBundles = 'keyBundles',
  spaceUsages = 'spaceUsages',
  people = 'people',
  spaceContracts = 'spaceLeaseContracts',
  customerMembers = 'customerMembers',
  condominiumAssociations = 'CondominiumAssociations',
  residents = 'residents',
}
