import ReduxUtil from './ReduxUtil';
import {BusinessType} from '../domain-base/business-types';
import {Locale} from '../dictionary/dictionary-common';
import LocalStorage, {LocalStorageKey} from '../local-storage/LocalStorage';
import moment from 'moment/moment';
import 'moment/locale/ja';

export interface DictionaryState {
  businessType: BusinessType;
  useTobilifeCrew: boolean;
  locale: Locale;
}

const localLocale = LocalStorage.get(LocalStorageKey.LOCALE) || Locale.ja_JP;
const selectedLocale = typeof Locale[localLocale] !== 'undefined' ? Locale[localLocale] : Locale.ja_JP;

const getInitialState = (): DictionaryState => ({
  businessType: BusinessType.CORE,
  useTobilifeCrew: false,
  locale: selectedLocale,
});

const TYPE_PREFIX = 'DICT';
const type = t => TYPE_PREFIX + t;

export const DictionaryActions = {
  clear: () => ({type: type('clear')}),
  setBusinessType: (businessType: BusinessType) => ({
    type: type('setBusinessType'),
    businessType: businessType,
  }),
  setUseTobilifeCrew: (isUse: boolean) => ({
    type: type('setUseTobilifeCrew'),
    useTobilifeCrew: isUse,
  }),
  setLang: (locale: Locale) => {
    // reduxでこういうことやるのよくないのかな・・・
    switch (locale) {
      case Locale.ja_JP:
        moment.locale('ja');
        break;
      case Locale.en_US:
        moment.locale('en');
        break;
      case Locale.en_AU:
        moment.locale('en');
        break;
      default:
        moment.locale('ja');
    }
    return {
      type: type('setLang'),
      locale: locale,
    };
  },
};

const DictionaryReducerFunctions = {
  clear: () => {
    LocalStorage.remove(LocalStorageKey.LOCALE);
    return getInitialState();
  },
  setBusinessType: (state, action) => ({...state, businessType: action.businessType}),
  setUseTobilifeCrew: (state, action) => ({...state, useTobilifeCrew: action.useTobilifeCrew}),
  setLang: (state, action) => {
    LocalStorage.set(LocalStorageKey.LOCALE, action.locale);
    return {...state, locale: action.locale};
  },
};

const dictionaryReducer = ReduxUtil.createReducer(getInitialState(), DictionaryReducerFunctions, TYPE_PREFIX);
export {dictionaryReducer};
