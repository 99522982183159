import {EnumAlternativeUnion} from '../common-utils/type-utils';

export const LegacyIndexName = {
  boardMeetings: 'boardMeetings',
  contractApplications: 'contractApplications',
  customerContracts: 'customerContracts',
  customerMembers: 'customerMembers',
  customers: 'customers',
  devices: 'devices',
  faqContacts: 'FaqContacts',
  faqs: 'Faqs',
  faqTags: 'FaqTags',
  guests: 'guests',
  keys: 'keys',
  members: 'members',
  organizationNfcCards: 'organizationNfcCards',
  people: 'people',
  reservations: 'reservations',
  residents: 'residents',
  spaceLeaseContacts: 'spaceLeaseContacts',
  spaces: 'spaces',
  spaceUsages: 'spaceUsages',
  things: 'things',
  thingStatuses: 'thingStatuses',
} as const;
export type LegacyIndexName = EnumAlternativeUnion<typeof LegacyIndexName>;

export const toApiPathFromIndexName = (indexName: LegacyIndexName): string => {
  switch (indexName) {
    case LegacyIndexName.boardMeetings:
      return 'organizations/board-meetings';
    case LegacyIndexName.contractApplications:
      return 'organizations/contract-applications';
    case LegacyIndexName.customerContracts:
      return 'organizations/customer-contracts';
    case LegacyIndexName.customers:
      return 'organizations/customers';
    case LegacyIndexName.customerMembers:
      return 'organizations/customer-members';
    case LegacyIndexName.devices:
      return 'organizations/devices';
    case LegacyIndexName.faqContacts:
      return 'organizations/faq-contacts';
    case LegacyIndexName.faqs:
      return 'organizations/faqs';
    case LegacyIndexName.faqTags:
      return 'organizations/faq-tags';
    case LegacyIndexName.guests:
      return 'organizations/guests';
    case LegacyIndexName.keys:
      return 'organizations/keys';
    case LegacyIndexName.members:
      return 'organizations/members';
    case LegacyIndexName.organizationNfcCards:
      return 'organizations/nfc-cards';
    case LegacyIndexName.people:
      return 'organizations/people';
    case LegacyIndexName.reservations:
      return 'organizations/reservations';
    case LegacyIndexName.residents:
      return 'organizations/residents';
    case LegacyIndexName.spaceLeaseContacts:
      return 'organizations/space-lease-contacts';
    case LegacyIndexName.spaces:
      return 'organizations/spaces';
    case LegacyIndexName.spaceUsages:
      return 'organizations/space-usages';
    case LegacyIndexName.things:
      return 'organizations/things';
    case LegacyIndexName.thingStatuses:
      return 'organizations/thing-statuses';
    default: {
      const other: never = indexName;
      throw new Error('unexpected-index-name', other);
    }
  }
};
