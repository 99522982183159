import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {EpochMillis} from '../../../common-types/numbers';
import {
    DeviceSet,
    FirestoreOrganizationsFaceImageAuthInvitationStatus,
    FirestoreOrganizationsFaceImageAuthTreasureBoxLendContract,
    PersonalityType,
} from '../../../firebase/firestore/references/organizations/face-image-auth-contracts-summaries/firestore-organization-face-image-contract-summaries';

export enum AlgoliaFaceImageAuthContractsSummaryAttributes {
  id = 'id',
  summaryId = 'summaryId',
  organizationId = 'organizationId',
  privateGroupId = 'privateGroupId',
  representativePersonaId = 'representativePersonaId',
  representativeDefaultPersonaId = 'representativeDefaultPersonaId',
  representativeEmail = 'representativeEmail',
  representativePhoneNumber = 'representativePhoneNumber',
  borrowerType = 'borrowerType',
  deviceSetList = 'deviceSetList',
  contracts = 'contracts',
  invitationStatus = 'invitationStatus',
  approvedAt = 'approvedAt',
  registeredAt = 'registeredAt',
  canceledAt = 'canceledAt',
  createdAt = 'createdAt',
  isSynchronize = 'isSynchronize',
  registeredStatus = 'registeredStatus',
  spaceId = 'contracts.spaceId',
  contractId = 'contracts.contractId',
}

export enum AlgoliaOrganizationsFaceImageSummaryStatus {
  INVITING = 'INVITING',
  ACCEPTED = 'ACCEPTED',
  ALL_REGISTERED = 'ALL_REGISTERED',
  PARTIAL_REGISTERED = 'PARTIAL_REGISTERED',
  REJECTED = 'REJECTED',
}

export interface AlgoliaFaceImageAuthContractsSummaryData extends AlgoliaObject {
  id: string;
  summaryId: string;
  organizationId?: string;
  privateGroupId?: string;
  representativePersonaId?: string;
  representativeDefaultPersonaId: string;
  representativeEmail: string;
  representativePhoneNumber: string;
  borrowerType: PersonalityType;
  deviceSetList: DeviceSet[];
  contracts: FirestoreOrganizationsFaceImageAuthTreasureBoxLendContract[];
  invitationStatus: FirestoreOrganizationsFaceImageAuthInvitationStatus;
  approvedAt?: EpochMillis;
  registeredAt?: EpochMillis;
  canceledAt?: EpochMillis;
  createdAt?: EpochMillis;
  isSynchronize: boolean;
  // 顔認証全体の登録状況
  registeredStatus: AlgoliaOrganizationsFaceImageSummaryStatus;
  email: string;
}

export default class AlgoliaFaceImageAuthContractsSummaries extends AlgoliaBase<
  AlgoliaFaceImageAuthContractsSummaryData
> {
  public static readonly facets = () => ({
    // for facet
    summaryId: AlgoliaFaceImageAuthContractsSummaryAttributes.summaryId,
    email: AlgoliaFaceImageAuthContractsSummaryAttributes.representativeEmail,
    phoneNumber: AlgoliaFaceImageAuthContractsSummaryAttributes.representativePhoneNumber,
    // for filter
    personType: AlgoliaFaceImageAuthContractsSummaryAttributes.borrowerType,
    invitationStatus: AlgoliaFaceImageAuthContractsSummaryAttributes.invitationStatus,
    registeredStatus: AlgoliaFaceImageAuthContractsSummaryAttributes.registeredStatus,
    isSynchronize: AlgoliaFaceImageAuthContractsSummaryAttributes.isSynchronize,
    registeredAt: AlgoliaFaceImageAuthContractsSummaryAttributes.registeredAt,
    approvedAt: AlgoliaFaceImageAuthContractsSummaryAttributes.approvedAt,
    spaceId: AlgoliaFaceImageAuthContractsSummaryAttributes.spaceId,
    contractId: AlgoliaFaceImageAuthContractsSummaryAttributes.contractId,
    privateGroupId: AlgoliaFaceImageAuthContractsSummaryAttributes.privateGroupId,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaFaceImageAuthContractsSummaries(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.faceImageAuthContractsSummaries;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaFaceImageAuthContractsSummaries.facets();
}
