import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

export default class FirebaseAdminFunctions {
  private static instance: FirebaseAdminFunctions;
  private axiosInstance: AxiosInstance;

  private constructor(host: string) {
    this.axiosInstance = axios.create({
      baseURL: host + '/adminApi',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': 'kf:aposkdfpAS)DIpo:kfg:e3pwaKSdf:9[-0i243l;ASDL123_',
      },
    });
  }

  public static init = (host: string) => {
    FirebaseAdminFunctions.instance = new FirebaseAdminFunctions(host);
  };

  public static getInstance = () => FirebaseAdminFunctions.instance;

  public post = <T>(url: string, data?: any, config?: AxiosRequestConfig) =>
    this.axiosInstance.post<T>(url, data, config);
  public get = <T>(url: string, config?: AxiosRequestConfig) => this.axiosInstance.get<T>(url, config);
  public put = <T>(url: string, data?: any, config?: AxiosRequestConfig) =>
    this.axiosInstance.put<T>(url, data, config);
  public patch = <T>(url: string, data?: any, config?: AxiosRequestConfig) =>
    this.axiosInstance.patch<T>(url, data, config);
}
