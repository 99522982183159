import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';
import {getCurrentVersion} from '../common/utils/version';

type PropagatorType = 'datadog' | 'b3' | 'b3multi' | 'tracecontext';

export class Datadog {
  private static initialized = false;
  private constructor() {
    // disable constructor to prevend unnecessary initialization.
  }

  public static startMonitoring() {
    if (!this.initialized) {
      const version = getCurrentVersion();
      const {env, service, clientToken, site, applicationId, enabled, allowedTracingUrls} = readVariables();
      if (enabled) {
        datadogRum.init({
          env,
          service,
          clientToken,
          site,
          applicationId,
          allowedTracingUrls,
          // Specify a version number to identify the deployed version of your application in Datadog
          version: version,
          sessionSampleRate: 100,
          sessionReplaySampleRate: env === 'production' ? 20 : 100,
          defaultPrivacyLevel: env === 'production' ? 'mask' : 'allow',
          traceSampleRate: 20,
          trackResources: true,
          trackLongTasks: true,
          trackUserInteractions: true,
          trackFrustrations: true,
          // https://github.com/DataDog/browser-sdk/pull/2378
          storeContextsAcrossPages: true,
        });
        datadogRum.startSessionReplayRecording();
        // ref. https://docs.datadoghq.com/ja/logs/log_collection/javascript/
        datadogLogs.init({
          env,
          service,
          clientToken,
          site,
          version,
          forwardErrorsToLogs: true,
          sessionSampleRate: 100,
          // https://github.com/DataDog/browser-sdk/pull/2378
          storeContextsAcrossPages: true,
          beforeSend: log => {
            if (log.http && [0, 401, 404].includes(log.http.status_code)) {
              return false;
            }
          },
        });
        // datadogLogs.setUser(user);
      }
      Datadog.initialized = true;
    }
  }

  public static setContext(
    organization: {
      id: string | undefined;
      name: string | undefined;
    },
    member: {
      id: string | undefined;
      personaId: string | undefined;
      personType: string | undefined;
    },
    userAuthority?: {
      userAuthorityIds: string[] | undefined;
      isAdministrator: boolean | undefined;
    },
  ) {
    const user = {
      id: member.id,
      persona_id: member.personaId,
      organization_id: organization.id,
      type: member.personType,
      is_administrator: userAuthority?.isAdministrator,
    };
    const context = {
      organization: {
        id: organization.id,
        name: organization.name,
      },
      userAuthority: {
        user_authority_ids: userAuthority?.userAuthorityIds,
      },
    };
    datadogRum.setUser(user);
    datadogRum.setGlobalContext(context);
    datadogLogs.setUser(user);
    datadogLogs.setGlobalContext(context);
  }

  public static addContext(key: string, value: unknown) {
    datadogRum.addRumGlobalContext(key, value);
    datadogLogs.addLoggerGlobalContext(key, value);
  }

  public static clearContext() {
    datadogRum.removeUser();
    datadogRum.clearGlobalContext();
    datadogLogs.clearUser();
    datadogLogs.clearGlobalContext();
  }
}

export const readVariables = () => {
  const env = process.env['REACT_APP_DATADOG_ENV'] as string;
  const service = process.env['REACT_APP_DATADOG_SERVICE'] as string;
  const clientToken = process.env['REACT_APP_DATADOG_CLIENT_TOKEN'] as string;
  const site = process.env['REACT_APP_DATADOG_SITE'] as string;
  const applicationId = process.env['REACT_APP_DATADOG_APPLICATION_ID'] as string;
  const enabled = process.env['REACT_APP_DATADOG_ENABLED'] as string;
  const bkpHost = process.env['REACT_APP_BKP_HOST'] as string;
  const _bitlockApiDomain = process.env['REACT_APP_V1_BITLOCK_API_DOMAIN'] as string;
  const homehubBackendApiDomain = process.env['REACT_APP_V1_HOMEHUB_BACKEND_API_DOMAIN'] as string;
  const homehubCloudRunDomain = process.env['REACT_APP_V1_HOMEHUB_CLOUD_RUN_DOMAIN'] as string;
  const apiHostDomain = process.env['REACT_APP_V2_HOMEHUB_API_DOMAIN'] as string;
  return {
    env,
    service,
    clientToken,
    site,
    applicationId,
    enabled: enabled === 'true',
    allowedTracingUrls: [
      {match: bkpHost, propagatorTypes: ['datadog'] as PropagatorType[]},
      // NOTE: bitlock-service の古いAPIは allowedHeaders に x-datadog-origin を含めていないため、traceするとAPIがエラーになる
      //   サーバサイドを修正するまで、ここはコメントアウトしておく
      // {match: bitlockApiDomain, propagatorTypes: ['datadog'] as PropagatorType[]},
      {match: homehubBackendApiDomain, propagatorTypes: ['datadog'] as PropagatorType[]},
      {match: homehubCloudRunDomain, propagatorTypes: ['datadog'] as PropagatorType[]},
      {match: apiHostDomain, propagatorTypes: ['datadog'] as PropagatorType[]},
    ],
  };
};
