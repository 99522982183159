import type {AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

export enum AlgoliaCustomerMembersAttributes {
  code = 'code',
  contactEmail = 'contactEmail',
  contactPhoneNumber = 'contactPhoneNumber',
  peopleId = 'peopleId',
  customerId = 'customerId',
}

export interface AlgoliaCustomerMemberData extends AlgoliaObject {
  id: string;
  organizationId: string;

  customerId: string;
  /** コード */
  code?: string;
  /** ヒトとしての情報 */
  peopleId: string;
  /** 連絡用のEmail アカウント連携用のemailと別で管理したい場合に利用 */
  contactEmail?: string;
  /** 連絡用の電話番号 */
  contactPhoneNumber?: string;
  updatedAt: EpochMillis;
}

export default class AlgoliaCustomerMembers extends LegacyIndexBase<AlgoliaCustomerMemberData> {
  protected name = LegacyIndexName.customerMembers;
  protected rawIndexName: string;

  public static readonly facets = () => ({
    // for facet
    code: AlgoliaCustomerMembersAttributes.code,
    contactEmail: AlgoliaCustomerMembersAttributes.contactEmail,
    contactPhoneNumber: AlgoliaCustomerMembersAttributes.contactPhoneNumber,
    peopleId: AlgoliaCustomerMembersAttributes.peopleId,
    customerId: AlgoliaCustomerMembersAttributes.customerId,
  });

  public static getIndex = (organizationId: string) => new AlgoliaCustomerMembers(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaCustomerMembers.facets();
}
