import {V2FirebaseHomehubOldApiFunctions} from '../../../firebase/functions/v2-firebase-homehub-old-api-functions';
import CommonError from '../../../errors/common-error';
import {
    ApiOrgSpacePrivateViewSettingPostRequest,
    ApiOrgSpacePrivateViewSettingPostResponse,
} from '../../../firebase/functions/api-types/organizations/common-settings/space-setting/space-private-view-settings/api-types-org-space-private-view-settings';
import {
    FirestoreOrganizationSpacePrivateViewSettingData,
    FirestorePrivateViewBrokerageType,
    FirestorePrivateViewContactSettings,
    FirestorePrivateViewNotesSettings,
    PrivateViewSettingTargetSpaceUnitKey,
    ReservationDuplicateSettings,
    SpacePrivateViewSettingCsvRequestData,
} from '../../../firebase/firestore/references/organizations/common-settings/space-setting/space-private-view-settings/firestore-organization-space-private-view-settings';
import {PrivateViewReservationSiteSettingState} from '../../../redux/organization-settings';
import Redux from '../../../redux/ReduxConnector';

export default class SpacePrivateViewSettingService {
  /**
   * ログイン中の組織が内見予約サイトを利用するかどうか
   */
  public static isUsablePrivateViewReservationSite = (): boolean => {
    if (!Redux || !Redux.getStore()) {
      return false;
    }
    const privateViewReservationSiteSetting = Redux.getStore().settings.privateViewReservationSiteSetting;
    return privateViewReservationSiteSetting ? privateViewReservationSiteSetting.isUsable : false;
  };
  /**
   * 内見予約サイトを利用するかしないか、サイト名など全体の設定
   * @param params
   */
  public static setSiteSetting = async (params: {siteName: string; isUsable: boolean}) => {
    await V2FirebaseHomehubOldApiFunctions.post<ApiOrgSpacePrivateViewSettingPostRequest>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/space-private-view-settings/site`,
      {
        siteName: params.siteName,
        isUsable: params.isUsable,
      },
    ).catch(e => {
      throw new CommonError(e);
    });
  };
  /**
   * 内見予約サイトの全体の設定の取得
   */
  public static getSiteSetting = async (): Promise<PrivateViewReservationSiteSettingState | undefined> => {
    const res = await V2FirebaseHomehubOldApiFunctions.get<PrivateViewReservationSiteSettingState>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/space-private-view-settings/site`,
    ).catch(e => {
      // ログイン時非同期読み込み処理なので、エラーはthrowしない
      console.log('Failed to load private view reservation site setting data.', e);
    });
    if (!res || !res.data) {
      return;
    }
    return res.data;
  };
  /**
   * 内見予約サイトの物件ごとの設定の取得
   * @param spaceId
   */
  public static getSpaceSiteSettingBySpaceId = async (
    spaceId: string,
  ): Promise<FirestoreOrganizationSpacePrivateViewSettingData | undefined> => {
    const res = await V2FirebaseHomehubOldApiFunctions.get<{
      privateViewSetting: FirestoreOrganizationSpacePrivateViewSettingData;
    }>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/spaces/${spaceId}/private_view_settings`,
    ).catch(e => {
      // ログイン時非同期読み込み処理なので、エラーはthrowしない
      console.log('Failed to load space private view reservation site setting data.', e);
    });
    if (!res || !res.data) {
      return;
    }
    return res.data.privateViewSetting;
  };
  /**
   * 内見予約サイトの物件に個別設定されている内見予約設定とその空間に紐づく親空間に対して個別設定されている内見予約設定を全て取得する
   * @param spaceId
   */
  public static getSpaceAllSettingBySpaceId = async (
    spaceId: string,
  ): Promise<FirestoreOrganizationSpacePrivateViewSettingData[] | undefined> => {
    const res = await V2FirebaseHomehubOldApiFunctions.get<{
      parentsPrivateViewSettings: FirestoreOrganizationSpacePrivateViewSettingData[];
    }>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/spaces/${spaceId}/private_view_all_settings`,
    ).catch(e => {
      // ログイン時非同期読み込み処理なので、エラーはthrowしない
      console.log('Failed to load space private view reservation site setting data.', e);
    });
    if (!res || !res.data) {
      return;
    }
    return res.data.parentsPrivateViewSettings;
  };
  /**
   * 内見予約サイトの物件ごとの設定の登録
   * @param params
   */
  public static registerPrivateViewSetting = async (params: {
    brokerageType: FirestorePrivateViewBrokerageType;
    exclusiveExternalOrganizationIds: string[];
    enableApprovalFlow?: boolean;
    canSelfView: boolean;
    notesSettings: FirestorePrivateViewNotesSettings;
    contactSettings?: FirestorePrivateViewContactSettings;
    canReserveNoBitlockSpace?: boolean;
    reservationDuplicateSettings?: ReservationDuplicateSettings;
    targetSpaceUnit: PrivateViewSettingTargetSpaceUnitKey;
    targetSpaceDetectId?: string;
    isValid: boolean;
  }): Promise<string> => {
    const res = await V2FirebaseHomehubOldApiFunctions.post<ApiOrgSpacePrivateViewSettingPostResponse>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/space-private-view-settings/`,
      {
        brokerageType: params.brokerageType,
        exclusiveExternalOrganizationIds: params.exclusiveExternalOrganizationIds,
        enableApprovalFlow: !!params.enableApprovalFlow,
        canSelfView: params.canSelfView,
        notesSettings: params.notesSettings,
        contactSettings: params.contactSettings,
        canReserveNoBitlockSpace: params.canReserveNoBitlockSpace,
        reservationDuplicateSettings: params.reservationDuplicateSettings,
        targetSpaceUnit: params.targetSpaceUnit,
        targetSpaceDetectId: params.targetSpaceDetectId,
        isValid: params.isValid,
      },
    ).catch(e => {
      throw new CommonError(e);
    });
    if (!res || !res.data) {
      return '';
    }
    return res.data.newSettingId;
  };
  /**
   * 対象の設定の更新
   * @param settingId
   * @param params
   */
  public static updatePrivateViewSetting = async (
    settingId: string,
    params: {
      brokerageType: FirestorePrivateViewBrokerageType;
      exclusiveExternalOrganizationIds: string[];
      enableApprovalFlow?: boolean;
      canSelfView: boolean;
      notesSettings: FirestorePrivateViewNotesSettings;
      contactSettings?: FirestorePrivateViewContactSettings;
      canReserveNoBitlockSpace?: boolean;
      reservationDuplicateSettings?: ReservationDuplicateSettings;
      targetSpaceUnit: PrivateViewSettingTargetSpaceUnitKey;
      targetSpaceDetectId?: string;
      isValid: boolean;
    },
  ) => {
    await V2FirebaseHomehubOldApiFunctions.put<ApiOrgSpacePrivateViewSettingPostRequest>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/space-private-view-settings/${settingId}`,
      {
        brokerageType: params.brokerageType,
        exclusiveExternalOrganizationIds: params.exclusiveExternalOrganizationIds,
        enableApprovalFlow: !!params.enableApprovalFlow,
        canSelfView: params.canSelfView,
        notesSettings: params.notesSettings,
        contactSettings: params.contactSettings,
        canReserveNoBitlockSpace: params.canReserveNoBitlockSpace,
        reservationDuplicateSettings: params.reservationDuplicateSettings,
        targetSpaceUnit: params.targetSpaceUnit,
        targetSpaceDetectId: params.targetSpaceDetectId,
        isValid: params.isValid,
      },
    ).catch(e => {
      throw new CommonError(e);
    });
  };
  /**
   * 対象の設定の削除
   * @param settingId
   */
  public static deletePrivateViewSetting = async (settingId: string) => {
    await V2FirebaseHomehubOldApiFunctions.delete(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/space-private-view-settings/${settingId}`,
    ).catch(e => {
      throw new CommonError(e);
    });
  };

  /**
   * updateSelfViewingReservationSettingsByCsv is used to update self view reservation setting using csv
   * @param data
   */
  public static updateSelfViewingReservationSettingsByCsv = async ({
    privateViewSettings,
  }: {
    privateViewSettings: SpacePrivateViewSettingCsvRequestData[];
  }) => {
    const response = await V2FirebaseHomehubOldApiFunctions.post(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/space-private-view-settings/updateSelfViewingReservationSettingsByCsv`,
      {
        privateViewSettings,
      },
    );
    return response.data;
  };
}
