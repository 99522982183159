import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaOnsiteReportSettingAttributes {
  code = 'code',
}

export interface AlgoliaOnsiteReportSettingData extends AlgoliaObject {
  code: string;
  title: string;
  description: string;
  status: 'PUBLISH' | 'DRAFT' | 'DELETED';
}

export default class AlgoliaOnsiteReportSettings extends AlgoliaBase<AlgoliaOnsiteReportSettingData> {
  public static readonly facets = () => ({
    code: AlgoliaOnsiteReportSettingAttributes.code,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaOnsiteReportSettings(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.onsiteReports;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaOnsiteReportSettingData>(this.index, condition);
  public facets = () => AlgoliaOnsiteReportSettings.facets();
}
