// generated by gen/color
const core = {
  White: '#ffffff',
  Black: '#000000',
  KeyColor50: '#fff8e0',
  KeyColor100: '#ffebb2',
  KeyColor200: '#ffde80',
  KeyColor300: '#ffd24c',
  KeyColor400: '#ffc723',
  KeyColor500: '#ffbd00',
  KeyColor600: '#ffaf00',
  KeyColor700: '#ff9c00',
  KeyColor800: '#ff8b00',
  KeyColor900: '#ff6b00',
  Yellow50: '#faf9c8',
  Yellow100: '#faf887',
  Yellow200: '#f5f071',
  Yellow300: '#ede461',
  Yellow400: '#e8dd46',
  Yellow500: '#ded037',
  Yellow600: '#d0c226',
  Yellow700: '#d0b217',
  Yellow800: '#cf9b00',
  Yellow900: '#cc7600',
  Mango50: '#fff1da',
  Mango100: '#ffdca3',
  Mango200: '#ffc466',
  Mango300: '#fdac22',
  Mango400: '#fb9b00',
  Mango500: '#fa8a00',
  Mango600: '#f77d00',
  Mango700: '#f26c00',
  Mango800: '#ec5a00',
  Mango900: '#e43800',
  Red50: '#fadbd9',
  Red100: '#f5a09d',
  Red200: '#f28480',
  Red300: '#f06965',
  Red400: '#e35f5b',
  Red500: '#d4514c',
  Red600: '#cc4841',
  Red700: '#c6423d',
  Red800: '#b93c36',
  Red900: '#a8352d',
  Pink50: '#fbe7eb',
  Pink100: '#f6c2ce',
  Pink200: '#f09daf',
  Pink300: '#e97a90',
  Pink400: '#e2647a',
  Pink500: '#e15365',
  Pink600: '#d04e62',
  Pink700: '#ba475c',
  Pink800: '#a64158',
  Pink900: '#82364e',
  Purple50: '#eee3f1',
  Purple100: '#d6badd',
  Purple200: '#bb8dc7',
  Purple300: '#a261b2',
  Purple400: '#9040a5',
  Purple500: '#7f2097',
  Purple600: '#721d91',
  Purple700: '#611989',
  Purple800: '#511481',
  Purple900: '#320d72',
  Blue50: '#e6eaf8',
  Blue100: '#c1c9ef',
  Blue200: '#96a6e3',
  Blue300: '#6984d9',
  Blue400: '#4268d1',
  Blue500: '#0a54c7',
  Blue600: '#0046be',
  Blue700: '#003cb2',
  Blue800: '#0032a7',
  Blue900: '#001f91',
  Sky50: '#e4f2fc',
  Sky100: '#bddef8',
  Sky200: '#95cbf3',
  Sky300: '#6eb6ee',
  Sky400: '#53a6eb',
  Sky500: '#3c98e8',
  Sky600: '#368ada',
  Sky700: '#2e78c7',
  Sky800: '#2967b5',
  Sky900: '#1e4a96',
  SeaGreen50: '#d8eff0',
  SeaGreen100: '#9bd7db',
  SeaGreen200: '#6ec7cc',
  SeaGreen300: '#56bcc2',
  SeaGreen400: '#35acb0',
  SeaGreen500: '#00a1a7',
  SeaGreen600: '#008e93',
  SeaGreen700: '#007b7d',
  SeaGreen800: '#006f71',
  SeaGreen900: '#006060',
  Green50: '#dff3f0',
  Green100: '#b0e1d8',
  Green200: '#7ccec0',
  Green300: '#44baa6',
  Green400: '#02aa94',
  Green500: '#009a81',
  Green600: '#00917a',
  Green700: '#007d65',
  Green800: '#006d56',
  Green900: '#00513b',
  Kiwi50: '#f3f9e8',
  Kiwi100: '#e1f0c6',
  Kiwi200: '#cde6a1',
  Kiwi300: '#b8dc7a',
  Kiwi400: '#a8d45b',
  Kiwi500: '#98cc3b',
  Kiwi600: '#88bc33',
  Kiwi700: '#72a829',
  Kiwi800: '#5d941f',
  Kiwi900: '#39720d',
  Warmgray50: '#fcfbf8',
  Warmgray100: '#f7f6f3',
  Warmgray200: '#f2f1ee',
  Warmgray300: '#e5e4e1',
  Warmgray400: '#c2c1bf',
  Warmgray500: '#a4a3a0',
  Warmgray600: '#7a7a77',
  Warmgray700: '#666563',
  Warmgray800: '#474644',
  Warmgray900: '#252522',
  Gray50: '#f7f7f7',
  Gray100: '#eeeeee',
  Gray200: '#e3e3e3',
  Gray300: '#d1d1d1',
  Gray400: '#acacac',
  Gray500: '#8b8b8b',
  Gray600: '#646464',
  Gray700: '#515151',
  Gray800: '#333333',
  Gray900: '#131313',
  GrayAlpha50: '#1313130a',
  GrayAlpha100: '#13131312',
  GrayAlpha200: '#1313131f',
  GrayAlpha300: '#13131333',
  GrayAlpha400: '#13131359',
  GrayAlpha500: '#1313137d',
  GrayAlpha600: '#131313a8',
  GrayAlpha700: '#131313bd',
  GrayAlpha800: '#131313de',
  GrayAlpha900: '#131313f5',
  WhiteAlpha50: '#ffffff0a',
  WhiteAlpha100: '#ffffff12',
  WhiteAlpha200: '#ffffff1f',
  WhiteAlpha300: '#ffffff33',
  WhiteAlpha400: '#ffffff59',
  WhiteAlpha500: '#ffffff7d',
  WhiteAlpha600: '#ffffffa8',
  WhiteAlpha700: '#ffffffbd',
  WhiteAlpha800: '#ffffffde',
  WhiteAlpha900: '#fffffff5',
  HoverPrimary: '#ffde8033',
  HoverAccent: '#9bd7db33',
  Focus: '#c1c9ef33',
  HighlightSearch: '#c1c9ef33',
  HighlightError: '#f5a09d33',
};

export const HHColor = {
  background: {
    default: core.Warmgray100,
    overlay: core.Warmgray200,
    brandPrimary: core.KeyColor50,
  },
  Surface: {
    neutralLow: core.White,
    neutralMiddle: core.Warmgray100,
    neutralHigh: core.Warmgray200,
    neutralDark: core.GrayAlpha800,
    brandPrimary: core.KeyColor900,
    brandSecondary: core.KeyColor700,
    brandAccent: core.Mango200,
    brandAccentLight: core.Mango100,
    accentDefault: core.SeaGreen100,
    accentLight: core.SeaGreen50,
    error: core.Red400,
    errorLight: core.Red50,
    warning: core.Yellow700,
    warningLight: core.Yellow50,
    success: core.Green400,
    successLite: core.Green50,
    info: core.Blue300,
    infoLight: core.Blue50,
    expressiveRed: core.Red300,
    expressiveRedLight: core.Red50,
    expressivePink: core.Pink300,
    expressivePinkLight: core.Pink50,
    expressivePurple: core.Purple200,
    expressivePurpleLight: core.Purple50,
    expressiveBlue: core.Blue300,
    expressiveBlueLight: core.Blue50,
    expressiveSky: core.Sky200,
    expressiveSkyLight: core.Sky50,
    expressiveSeagreen: core.SeaGreen200,
    expressiveSeagreenLight: core.SeaGreen50,
    expressiveGreen: core.Green300,
    expressiveGreenLight: core.Green50,
    expressiveKiwi: core.Kiwi300,
    expressiveKiwiLight: core.Kiwi50,
    expressiveMango: core.Mango300,
    expressiveMangoLight: core.Mango50,
  },
  Status: {
    hoverDefault: core.GrayAlpha50,
    hoverOnDark: core.WhiteAlpha200,
    hoverPrimary: core.HoverPrimary,
    hoverAccent: core.HoverAccent,
    disabled: core.GrayAlpha100,
    focus: core.Focus,
    highlightSearch: core.HighlightSearch,
    highlightError: core.HighlightError,
  },
  Text: {
    neutralPrimary: core.GrayAlpha800,
    neutralSecondary: core.GrayAlpha600,
    neutralDisabled: core.GrayAlpha300,
    neutralWhitePrimary: core.White,
    neutralWhiteSecondary: core.WhiteAlpha600,
    linkPrimary: core.Sky600,
    linkPrimaryHover: core.Sky500,
    semanticError: core.Red700,
    semanticWarning: core.Yellow900,
    semanticSuccess: core.Green700,
    semanticInfo: core.Blue500,
    brandPrimary: core.KeyColor900,
    brandSecondary: core.KeyColor700,
    brandAccent: core.SeaGreen400,
    expressiveRed: core.Red500,
    expressivePink: core.Pink600,
    expressivePurple: core.Purple400,
    expressiveBlue: core.Blue400,
    expressiveSky: core.Sky500,
    expressiveSeagreen: core.SeaGreen600,
    expressiveGreen: core.Green600,
    expressiveKiwi: core.Kiwi600,
    dark: core.Black,
  },
  Object: {
    neutralPrimary: core.GrayAlpha800,
    neutralSecondary: core.GrayAlpha600,
    neutralDisabled: core.GrayAlpha300,
    neutralWhitePrimary: core.White,
    neutralWhiteSecondary: core.WhiteAlpha600,
    brandPrimary: core.KeyColor900,
    brandSecondary: core.KeyColor700,
    brandAccent: core.SeaGreen300,
    semanticError: core.Red500,
    semanticWarning: core.Yellow800,
    semanticSuccess: core.Green600,
    semanticInfo: core.Blue400,
    expressiveRed: core.Red300,
    expressivePink: core.Pink300,
    expressivePurple: core.Purple200,
    expressiveBlue: core.Blue300,
    expressiveSky: core.Sky200,
    expressiveSeagreen: core.SeaGreen200,
    expressiveGreen: core.Green300,
    expressiveKiwi: core.Kiwi300,
    expressiveGray: core.Warmgray400,
  },
  Border: {
    onLightDefault: core.Gray300,
    onLightLight: core.Gray100,
    onLightStrong: core.Gray400,
    onDarkDefault: core.WhiteAlpha300,
    onDarkStrong: core.WhiteAlpha400,
    brandPrimary: core.KeyColor900,
    brandSecondary: core.KeyColor700,
    brandAccent: core.SeaGreen300,
    semanticError: core.Red400,
    semanticWarning: core.Yellow700,
    semanticSuccess: core.Green400,
    semanticInfo: core.Blue400,
    expressiveRed: core.Red300,
    expressivePink: core.Pink200,
    expressivePurple: core.Purple200,
    expressiveBlue: core.Blue300,
    expressiveSky: core.Sky200,
    expressiveSeagreen: core.SeaGreen200,
    expressiveGreen: core.Green300,
    expressiveKiwi: core.Kiwi300,
  },
};
