import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {EpochMillis} from '../../../common-types/numbers';
import {AlgoliaDevice} from './algolia-devices';

export interface AlgoliaDeviceDeletedData extends AlgoliaObject, AlgoliaDevice {
  deletedAt: EpochMillis;
  hideLogs: boolean;
}

export enum AlgoliaDevicesDeletedAttributes {
  name = 'name',
  bkpObjectId = 'bkpObjectId',
  originDeviceId = 'originDeviceId',
  address = 'address',
  spaceId = 'spaceId',
  id = 'id',

  // ネスト系全部バラす。オブジェクト存在確認漏れが多すぎる
  firmware = 'firmware',
  model = 'model',
  bitkeyUdid = 'bitkeyUdid',
  battery = 'battery',
  serialNo = 'serialNo',

  addressCountry = 'addressCountry',
  addressPrefectures = 'addressPrefectures',
  addressCities = 'addressCities',
  addressSublocality = 'addressSublocality',
  addressOthers = 'addressOthers',
  deviceAddress = 'deviceAddress',
  readerIds = 'readerIds',
  connectorId = 'connectorId',
  hideLogs = 'hideLogs',
}

export default class AlgoliaDevicesDeleted extends AlgoliaBase<AlgoliaDeviceDeletedData> {
  public static readonly facets = () => ({
    id: AlgoliaDevicesDeletedAttributes.id,
    name: AlgoliaDevicesDeletedAttributes.name,
    spaceId: AlgoliaDevicesDeletedAttributes.spaceId,
    firmware: AlgoliaDevicesDeletedAttributes.firmware,
    model: AlgoliaDevicesDeletedAttributes.model,
    bitkeyUdid: AlgoliaDevicesDeletedAttributes.bitkeyUdid,
    battery: AlgoliaDevicesDeletedAttributes.battery,
    serialNo: AlgoliaDevicesDeletedAttributes.serialNo,
    deviceAddress: AlgoliaDevicesDeletedAttributes.deviceAddress,
    readerIds: AlgoliaDevicesDeletedAttributes.readerIds,
    connectorId: AlgoliaDevicesDeletedAttributes.connectorId,
    hideLogs: AlgoliaDevicesDeletedAttributes.hideLogs,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaDevicesDeleted(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.devicesDeleted;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaDevicesDeleted.facets();
}
