import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaTreasureDealAttributes {
  id = 'id',
}

export interface AlgoliaTreasureDeal extends AlgoliaObject {
  id: string;
  holeId: string;
  treasureBoxId: string;
  deleted: boolean;
  startAt: number;
  endAt: number;
}

export default class AlgoliaTreasureDeals extends AlgoliaBase<AlgoliaTreasureDeal> {
  public static readonly facets = () => ({
    id: AlgoliaTreasureDealAttributes.id,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaTreasureDeals(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.devices;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaTreasureDeals.facets();
}
