import CommonError from '../../errors/common-error';
import {V2FirebaseHomehubBackendFunctions} from '../../firebase/functions/v2-firebase-homehub-backend-functions';
import StorageOrgEvent from '../../firebase/storage/references/event/storage-org-event';
import {definitions} from '../../business-types/generated/homehubEvent';
import Algolia from '../../algolia/algolia';
import Redux from '../../redux/ReduxConnector';
import {AlgoliaCustomerData} from '../../algolia/references/customers/algolia-customers';
import {AlgoliaGuestData} from '../../algolia/references/guests/algolia-guests';

const BASE_PATH = `homehubEvent`;

export type EventBuilding = {
  id: string;
  name: string;
  code?: string;
  photoUri: string;
  totalPropertyCount?: number;
};

export type EventCustomerType = definitions['eventPaticipantCustomer'] | definitions['eventWishPaticipantCustomer'];
export type EventParticipantType = {
  id: string;
  name: string;
  email?: string;
  phoneNumber: string;
  buildingId: string;
  buildingSpaceName: string;
  propertyCode: string;
  propertyName: string;
  applicationDate: number;
};

export type EventRequestBody = {
  customerIds: string[];
};

export type CustomerDetails = {
  customerName: string;
  customerEmailId: string;
};
export type PersonaDetails = {
  personaName: string;
  personaMailId?: string;
  personaPhoneNumber?: string;
};
export default class EventService {
  public static loadAll = async (
    organizationId: string,
    offset?: number,
    eventId?: string,
  ): Promise<definitions['eventRow'][]> => {
    const response = await V2FirebaseHomehubBackendFunctions.get<definitions['eventRow'][]>(
      `${BASE_PATH}/${V2FirebaseHomehubBackendFunctions.getVersion()}/organizations/${organizationId}/events`,
      {
        params: {
          startId: eventId,
          offset,
        },
      },
    ).catch(e => {
      throw new CommonError(e);
    });
    return response.data;
  };

  public static loadById = async (organizationId: string, eventId: string): Promise<definitions['eventDetail']> => {
    const response = await V2FirebaseHomehubBackendFunctions.get<definitions['eventDetail']>(
      `${BASE_PATH}/${V2FirebaseHomehubBackendFunctions.getVersion()}/organizations/${organizationId}/event/${eventId}`,
    ).catch(e => {
      throw new CommonError(e);
    });
    return response.data;
  };

  public static loadByIds = async (
    organizationId: string,
    eventIds: string[],
  ): Promise<definitions['eventDetail'][]> => {
    return Promise.all(
      eventIds.map(id => {
        return EventService.loadById(organizationId, id);
      }),
    );
  };

  public static register = async (organizationId: string, data: definitions['eventCreateRequest']) => {
    await V2FirebaseHomehubBackendFunctions.post(
      `${BASE_PATH}/${V2FirebaseHomehubBackendFunctions.getVersion()}/organizations/${organizationId}/event`,
      data,
    ).catch(e => {
      throw new CommonError(e);
    });
  };

  public static update = async (organizationId: string, eventId: string, data: definitions['eventUpdateRequest']) => {
    await V2FirebaseHomehubBackendFunctions.patch(
      `${BASE_PATH}/${V2FirebaseHomehubBackendFunctions.getVersion()}/organizations/${organizationId}/event/${eventId}`,
      data,
    ).catch(e => {
      throw new CommonError(e);
    });
  };

  public static delete = async (organizationId: string, eventId: string) => {
    await V2FirebaseHomehubBackendFunctions.delete(
      `${BASE_PATH}/${V2FirebaseHomehubBackendFunctions.getVersion()}/organizations/${organizationId}/event/${eventId}`,
    ).catch(e => {
      throw new CommonError(e);
    });
  };

  public static cancelParticipantReservation = async (eventId: string, req: EventRequestBody) => {
    return V2FirebaseHomehubBackendFunctions.post(
      `${BASE_PATH}/${V2FirebaseHomehubBackendFunctions.getVersion()}/organizations/event/${eventId}/reserve/cancel`,
      req,
    ).catch(e => {
      throw new CommonError(e.response.data.code, e.response.data.message, e);
    });
  };

  public static registerActivityLog = async (data: definitions['activityLogRequest']) => {
    const organizationId = Redux.getStore().user.organizationId;
    await V2FirebaseHomehubBackendFunctions.post(
      `${BASE_PATH}/${V2FirebaseHomehubBackendFunctions.getVersion()}/organizations/${organizationId}/event/activityLog`,
      data,
    ).catch(e => {
      throw new CommonError(e);
    });
  };

  public static uploadFile = async (file: File): Promise<{id: string; uri: string; name: string}> => {
    if (!file) {
      throw new Error('uploading file required');
    }
    const result = await StorageOrgEvent.uploadFile(file.name, file);
    if (!result) {
      throw new Error('failed to upload an event photo.');
    }

    return {
      id: result.ref.name,
      uri: result.ref.fullPath,
      name: file.name,
    };
  };

  public static uploadFileForPreview = async (file: File): Promise<{id: string; uri: string; name: string}> => {
    if (!file) {
      throw new Error('uploading file required');
    }
    const result = await StorageOrgEvent.uploadFile(file.name, file);
    if (!result) {
      throw new Error('failed to upload an event photo.');
    }
    const downloadUrl = await result.ref.getDownloadURL();
    return {
      id: result.ref.name,
      uri: downloadUrl as string,
      name: file.name,
    };
  };

  public static deleteFile = async (uri: string): Promise<{uri: string}> => {
    try {
      await StorageOrgEvent.deleteFile(uri);
    } catch (e) {
      // 何もしない
    }
    return {
      uri: uri,
    };
  };

  public static getParticipantsByBuilding = async (
    customers: EventCustomerType[],
  ): Promise<Record<string, EventParticipantType[]>> => {
    const customerIds = customers.map(c => c.customerId);
    const customerIndex = Algolia.indices().customers();
    const guestIndex = Algolia.indices().guests();
    const algoliaCustomers = await customerIndex.getAllHitsByBrowseResponse({
      searchWord: '',
      filters: {
        [customerIndex.facets().id]: customerIds,
      },
      facets: {},
      offset: 0,
    });
    const algoliaGuests = await guestIndex.getAllHitsByBrowseResponse({
      searchWord: '',
      filters: {
        [guestIndex.facets().id]: customers.map(c => c.personaId),
      },
      facets: {},
      offset: 0,
    });

    const record: Record<string, EventParticipantType[]> = {};
    customers.forEach(customer => {
      algoliaGuests.map(guestData => {
        if (customer.personaId === guestData.personaId) {
          const participatedCustomer = algoliaCustomers.find(c => c.id === customer.customerId);
          const actualCustomer: PersonaDetails = EventService.getPersonaDetails(guestData, participatedCustomer);
          const participant: EventParticipantType = {
            id: guestData.personaId,
            name: actualCustomer && actualCustomer.personaName ? actualCustomer.personaName : '',
            email: actualCustomer && actualCustomer.personaMailId ? actualCustomer.personaMailId : '',
            phoneNumber: actualCustomer?.personaPhoneNumber || actualCustomer?.personaPhoneNumber || '',
            buildingId: customer.buildingSpaceId,
            buildingSpaceName: customer.buildingSpaceName,
            propertyName: customer.propertySpaceName ? customer.propertySpaceName : '',
            propertyCode: customer.propertySpaceCode ? customer.propertySpaceCode : '',
            applicationDate: customer.createdAt,
          };
          const key = `${customer.customerId}:${guestData.personaId}`;
          const array = record[key];
          if (array) {
            !array.find(data => data.id) && array.push(participant);
            record[key] = array;
          } else {
            record[key] = [participant];
          }
        }
      });
    });
    return record;
  };
  public static getPersonaDetails = (
    algoliaGuestData: AlgoliaGuestData,
    participatedCustomer?: AlgoliaCustomerData,
  ): PersonaDetails => {
    return {
      personaName: algoliaGuestData.name || participatedCustomer?.name || '',
      personaMailId: algoliaGuestData.email || participatedCustomer?.representativeEmail,
      personaPhoneNumber: algoliaGuestData.phoneNumber || participatedCustomer?.representativePhoneNumber,
    };
  };
}
