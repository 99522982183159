import {AlgoliaSearchCondition} from '@algolia/v1/algolia';

export const toElasticSearchRequestFromAlgolia = (condition: AlgoliaSearchCondition) => {
  const body: any = {};
  if (condition.searchWord) {
    body.words_search = {
      words: condition.searchWord,
    };
  }

  const filters = condition.filters
    ? Object.entries(condition.filters).map(([key, value]) => ({
        join_type: 'and',
        parameter: {
          attribute: key,
          operator: '=',
          operands: value,
        },
      }))
    : [];

  const facetsFilter = condition.facets
    ? Object.entries(condition.facets)
        // NOTE: facetsFilter は空（未選択）の時にfilter条件にしてはいけない
        .filter(([_key, value]) => value.length)
        .map(([key, value]) => ({
          join_type: 'and',
          parameter: {
            attribute: key,
            operator: '=',
            operands: value,
          },
        }))
    : [];

  const multipleFilters = condition.multipleFilters
    ? Object.entries(condition.multipleFilters).map(([key, value]) => ({
        join_type: 'or',
        parameter: {
          attribute: key,
          operator: '=',
          operands: value,
        },
      }))
    : [];

  const excludeFilters = condition.excludeFilters
    ? Object.entries(condition.excludeFilters).map(([key, value]) => ({
        join_type: 'and',
        parameter: {
          attribute: key,
          operator: '!=',
          operands: value,
        },
      }))
    : [];

  const numericFilters =
    condition.numericFilters?.map(filter => ({
      join_type: 'and',
      parameter: {
        attribute: filter.filterAttributeName,
        operator: filter.sign,
        operand: filter.value,
      },
    })) ?? [];

  const fromToDateFilters = condition.fromToDateFilter
    ? [
        {
          join_type: 'and',
          parameter: {
            attribute: condition.fromToDateFilter.filterAttributeName,
            operator: '>=',
            operand: condition.fromToDateFilter.fromValue,
          },
        },
        {
          join_type: 'and',
          parameter: {
            attribute: condition.fromToDateFilter.filterAttributeName,
            operator: '<=',
            operand: condition.fromToDateFilter.toValue,
          },
        },
      ]
    : [];

  return {
    words_search: !condition.searchWord
      ? undefined
      : {
          words: condition.searchWord,
        },
    filters: [
      ...filters,
      ...facetsFilter,
      ...multipleFilters,
      ...excludeFilters,
      ...numericFilters,
      ...fromToDateFilters,
    ],
    options: {
      length: condition.length,
      offset: condition.offset,
      require_facets: true,
      additional_facets_fields: condition.additionalFacetsFields,
      sort: condition.sort,
    },
  };
};
