import {FirestoreDocumentData, Timestamp} from '../../../firestore';

export enum PassportAuthority {
  UPDATE_FIRMWARE = 'UPDATE_FIRMWARE',
  CHANGE_DEVICE_NAME = 'CHANGE_DEVICE_NAME',
  CHANGE_DEVICE_ADDRESS = 'CHANGE_DEVICE_ADDRESS',
  CHANGE_AUTOLOCK = 'CHANGE_AUTOLOCK',
  ADJUST_THUMBTURN = 'ADJUST_THUMBTURN',
  REGISTER_BUTTONS = 'REGISTER_BUTTONS',
  SHOW_KEY_RECEIVERS = 'SHOW_KEY_RECEIVERS',
  SHOW_DEVICE_LOG = 'SHOW_DEVICE_LOG',
  SHARE_TICKETS = 'SHARE_TICKETS',
  USE_WIFI_CONNECTOR = 'USE_WIFI_CONNECTOR',
  CREATE_SPARE_KEY = 'CREATE_SPARE_KEY',
}

export interface FirestoreUsersReceivedPassportData extends FirestoreDocumentData {
  senderUid: string;
  receiverUid: string;
  deviceId: string;
  linkId?: string;
  authorities: PassportAuthority[];
  contractIds: {holeId: string; contractId: string}[];
  notificationOnOpen: boolean;
  received: boolean;
  receivedAt?: Timestamp;
  deleted: boolean;
  deletedAt?: Timestamp;
}
