import * as React from 'react';
import {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {Snackbar} from '@material-ui/core';
import styled from 'styled-components';

import {styles} from './styles';

// ========
// Types
// ========
export interface SnackbarState {
  open: boolean;
  message: string;
  success: boolean;
  // snackBarを解除する時間を指定する時に利用。
  customAutoHideDuration?: number;
  warning?: boolean;
  onExited?: () => void;
}
interface SimpleSnackbarProps {
  snackbarState: SnackbarState;
  setSnackbarState: Dispatch<SetStateAction<SnackbarState>>;
}
interface UseSimpleSnackbar {
  setSuccessSnackbar: (message: string, option?: {customAutoHideDuration?: number}) => void;
  setErrorSnackbar: (message: string, option?: {customAutoHideDuration?: number}) => void;
  simpleSnackbarProps: SimpleSnackbarProps;
}

// ========
// Variables
// ========
export const INITIALIZED_SNACKBAR_STATE: SnackbarState = {
  open: false,
  message: '',
  success: true,
};
const defaultAutoHideDuration = 6000;

const PreWrapSpan = styled.span`
  white-space: pre-wrap;
`;

// =======
// Custom Hook
// =======
export const useSimpleSnackBar = (): UseSimpleSnackbar => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>(INITIALIZED_SNACKBAR_STATE);

  const setErrorSnackbar = useCallback(
    (message: string, option?: {customAutoHideDuration?: number}) =>
      setSnackbarState({
        open: true,
        message,
        success: false,
        customAutoHideDuration: option?.customAutoHideDuration || defaultAutoHideDuration,
      }),
    [],
  );
  const setSuccessSnackbar = useCallback(
    (message: string, option?: {customAutoHideDuration?: number}) =>
      setSnackbarState({
        open: true,
        message,
        success: true,
        customAutoHideDuration: option?.customAutoHideDuration || defaultAutoHideDuration,
      }),
    [],
  );
  const simpleSnackbarProps = useMemo(
    () => ({
      snackbarState,
      setSnackbarState,
    }),
    [snackbarState],
  );

  return {setErrorSnackbar, setSuccessSnackbar, simpleSnackbarProps};
};

// =======
// Default Export Component
// =======
const SimpleSnackbar: React.FC<SimpleSnackbarProps> = props => {
  const classes = styles();

  const {snackbarState, setSnackbarState} = props;

  const onClose = useCallback(
    () =>
      setSnackbarState(s => ({
        open: false,
        message: '',
        success: s.success,
        warning: s.warning,
        onExited: s.onExited,
      })),
    [setSnackbarState],
  );

  return (
    <Snackbar
      id={snackbarState.success ? 'successSnackBar' : 'errorSnackBar'}
      open={snackbarState.open}
      onClose={onClose}
      autoHideDuration={
        snackbarState.customAutoHideDuration ? snackbarState.customAutoHideDuration : defaultAutoHideDuration
      }
      message={<PreWrapSpan>{snackbarState.message}</PreWrapSpan>}
      ContentProps={{
        className: snackbarState.warning ? classes.warning : snackbarState.success ? classes.success : classes.error,
      }}
      onExit={snackbarState.onExited}
    />
  );
};

export default SimpleSnackbar;
