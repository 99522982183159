import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {Timestamp} from '../../../firebase/firestore/firestore';
import {EpochMillis} from '../../../common-types/numbers';
import {Days, ListTreasureDataDatasourceType, RepeatTypes} from '@bitkey-service/bkp-sdk-types';

export enum AlgoliaTreasureDataPresetsAttributes {
  id = 'id',
  holeId = 'holeId',
  organizationId = 'organizationId',
  treasureBoxId = 'treasureBoxId',
  treasureDataPresetId = 'treasureDataPresetId',
  treasureDataSourceType = 'treasureDataSourceType',
  memberPropertyId = 'memberPropertyId',
  propertyValue = 'propertyValue',
  condition = 'condition',
}

interface RepeatCondition {
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: RepeatTypes;
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];
  repeatStart?: string; // ex) HH:mm:ss
  repeatEnd?: string; // ex) HH:mm:ss
  standardTime?: number; // ex) 9 <- JST
  usageLimit?: number;
  usageStart?: Timestamp;
  usageEnd?: Timestamp;
}

export interface AlgoliaTreasureDataPresetsData extends AlgoliaObject {
  holeId: string;
  organizationId: string;
  treasureBoxId: string;
  treasureDataPresetId: string;
  treasureDataSourceType: ListTreasureDataDatasourceType;
  memberPropertyId: string;
  propertyValue: string;
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: RepeatTypes;
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];
  repeatStart?: string; // ex) HH:mm:ss
  repeatEnd?: string; // ex) HH:mm:ss
  standardTime?: number; // ex) 9 <- JST
  usageLimit?: number;
  usageStart?: EpochMillis;
  usageEnd?: EpochMillis;
}

export default class AlgoliaTreasureDataPresets extends AlgoliaBase<AlgoliaTreasureDataPresetsData> {
  public static readonly facets = () => ({
    // search
    id: AlgoliaTreasureDataPresetsAttributes.id,
    organizationId: AlgoliaTreasureDataPresetsAttributes.organizationId,
    treasureBoxId: AlgoliaTreasureDataPresetsAttributes.treasureBoxId,
    treasureDataPresetId: AlgoliaTreasureDataPresetsAttributes.treasureDataPresetId,
    treasureDataSourceType: AlgoliaTreasureDataPresetsAttributes.treasureDataSourceType,
    memberPropertyId: AlgoliaTreasureDataPresetsAttributes.memberPropertyId,
    propertyValue: AlgoliaTreasureDataPresetsAttributes.propertyValue,
    holeId: AlgoliaTreasureDataPresetsAttributes.holeId,
    condition: AlgoliaTreasureDataPresetsAttributes.condition,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaTreasureDataPresets(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.treasureDataPresets;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaTreasureDataPresets.facets();
}
