import React from 'react';
import {ThemeProvider as OldThemeProvider} from '@material-ui/styles';
import {ThemeProvider as NewThemeProvider} from '@mui/material/styles';
import {HHDefaultTheme} from '@styles/v2/themes';
import {DefaultTheme} from '@styles/v1/themes';
// import {CssBaseline} from '@mui/material';

/**
 * mui(5系以上) と material-ui(4系以下) が混合していて、
 * コンポーネント毎にtheme当たったり当たらなかったりで管理が辛かったのでまとめる
 */
const MuiThemeProvider: React.FC<{children?: React.ReactNode}> = props => {
  return (
    <>
      {/* 新コンポーネント(mui)を利用している部分は新themeが適用されるように */}
      <NewThemeProvider theme={HHDefaultTheme}>
        {/* <CssBaseline /> このcss resetを入れるとbox-sizing: inheritが入る。その結果、一部のデザインが崩れたため、入れないことに*/}
        {/* 旧コンポーネント(material-ui)を利用している部分は旧themeが適用されるように。v2系のthemeをmaterial-uiに適用しちゃうと既存の画面がデザイン崩れを起こす */}
        <OldThemeProvider theme={DefaultTheme}>{props.children}</OldThemeProvider>
      </NewThemeProvider>
    </>
  );
};

export default MuiThemeProvider;
