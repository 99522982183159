import FirebaseStorage from '../../firebase-storage';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';

const ref = (dataId: string) => FirebaseStorage.rootRef('announcements').child(dataId);
const refAttachments = () => FirebaseStorage.rootRef('announcements').child('attachments');
const getFileByUri = (uri: string) => FirebaseStorage.refFromURL(uri);
const fileRef = (uri?: string) => (uri ? getFileByUri(uri) : refAttachments().child(uuidv4()));

/**
 * これ、bM管理画面のお知らせ通知に利用してたやつなので、
 * コミュニケーション > アナウンスメントでは利用しないこと
 * @deprecated
 */
export default class StorageAnnouncements {
  public static uploadPhoto = (fileName: string, data: any) =>
    FirebaseStorage.upload(ref(moment().format('YYYYMMDDHHss') + '_' + fileName), data);
  public static updatePhoto = (dataId: string, data: any) => FirebaseStorage.upload(ref(dataId), data);
  public static deletePhoto = (dataId: string) => FirebaseStorage.delete(ref(dataId));
  public static getUrl = (dataId: string) => FirebaseStorage.getUrl(ref(dataId));
  public static uploadFile = (data: any, uri?: string) => FirebaseStorage.upload(fileRef(uri), data);
  public static deleteFile = (uri: string) => FirebaseStorage.delete(fileRef(uri));
}
