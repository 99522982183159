import type {EpochMillis} from '../../../common-types/numbers';
import type {EnumAlternativeUnion} from '../../../common-utils/type-utils';

// 以下ES依存
import type {AlgoliaObject} from '@algolia/v1/legacy-index-type';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';

export enum AlgoliaSpaceUsagesAttributes {
  id = 'id',
  type = 'type',
  scene = 'scene',
  spaceId = 'spaceId',
  peopleId = 'peopleId',
  contractIds = 'contractIds',
  from = 'from',
  to = 'to',
}

export const SpaceUsageType = {
  // 入居
  Occupancy: 'Occupancy',
  RestorationWork: 'RestorationWork',
} as const;
export type SpaceUsageType = EnumAlternativeUnion<typeof SpaceUsageType>;

export const SpaceUsageKeyStatus = {
  /** 招待中(カギは未発行) */
  Inviting: 'Inviting',
  /**
   * カギを発行する必要がなく、発行を行っていないもの
   */
  None: 'None',
  /**
   * カギの発行処理中...
   * 一部発行済の場合にもこのステータス
   * BKPに対する処理とのトランザクション不整合を検知するために本ステータスを用意していおく
   * BKPに対して処理を開始するタイミングで、Sendingに更新をしておき、
   * BKPに対する処理が完了したタイミングで、Sendに更新する
   */
  Sending: 'Sending',
  /**
   * カギの再発行処理中...
   * 条件の変更などに伴い再度発行する必要がある場合の処理中のステータス
   * 再発行が完了した場合には、Sendのステータスに更新する
   */
  Resending: 'Resending',
  /**
   * カギの発行済み
   */
  Send: 'Send',
  /**
   * カギの削除中...
   * 一部削除済みの場合にもこのステータス
   * BKPに対する処理とのトランザクション不整合を検知するために本ステータスを用意していおく
   * BKPに対して処理を開始するタイミングで、Deletingに更新をしておき、
   * BKPに対する処理が完了したタイミングで、Deletedに更新する
   */
  Deleting: 'Deleting',
  /** カギの削除済み */
  Deleted: 'Deleted',
} as const;
export type SpaceUsageKeyStatus = EnumAlternativeUnion<typeof SpaceUsageKeyStatus>;

export interface AlgoliaSpaceUsagesData extends AlgoliaObject {
  id: string;
  organizationId: string;
  /** 種別 */
  type: SpaceUsageType;
  /** 利用対象の空間 */
  spaceId: string;
  /** 利用者 */
  peopleId: string;
  /** 関連する契約 */
  contractIds?: readonly string[];
  /** 利用開始日 */
  from?: EpochMillis;
  /** 利用終了予定日 */
  to?: EpochMillis;
  keyStatus: SpaceUsageKeyStatus;
  updatedAt: EpochMillis;
  createdAt: EpochMillis;
}

const searchableAttributes = {
  from: AlgoliaSpaceUsagesAttributes.from,
  to: AlgoliaSpaceUsagesAttributes.to,
};

export default class AlgoliaSpaceUsages extends LegacyIndexBase<AlgoliaSpaceUsagesData> {
  protected name = LegacyIndexName.spaceUsages;
  protected rawIndexName: string;

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;
  public static readonly facets = () => ({
    // for facet
    type: AlgoliaSpaceUsagesAttributes.type,
    scene: AlgoliaSpaceUsagesAttributes.scene,
    spaceId: AlgoliaSpaceUsagesAttributes.spaceId,
    peopleId: AlgoliaSpaceUsagesAttributes.peopleId,
    contractIds: AlgoliaSpaceUsagesAttributes.contractIds,
    from: AlgoliaSpaceUsagesAttributes.from,
    to: AlgoliaSpaceUsagesAttributes.to,
    // for filter
    id: AlgoliaSpaceUsagesAttributes.id,
  });

  public static getIndex = (organizationId: string) => new AlgoliaSpaceUsages(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaSpaceUsages.facets();
}
