import {V2FirebaseHomehubOldApiFunctions} from '../v2-firebase-homehub-old-api-functions';

export default class FirebaseFunctionsAuth {
  public static getCustomToken = async (): Promise<string> => {
    let res;
    try {
      res = await V2FirebaseHomehubOldApiFunctions.get(
        `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-auth/token`,
      );
    } catch (e) {
      console.log('Error occurred on getting token', e);
      throw e;
    }
    if (!res || !res.data || !res.data.customToken) {
      throw new Error('Failed to get custom token');
    }
    return res.data.customToken;
  };
}
