import ReduxUtil from '../ReduxUtil';

export interface CommonDictionaryState {
  viewDetails: string;
}

const getInitialState = (): CommonDictionaryState => ({
  viewDetails: '',
});

const TYPE_PREFIX = 'COMMON_DICT';
const type = t => TYPE_PREFIX + t;

export const CommonDictionaryActions = {
  update: (nextState: CommonDictionaryState) => ({
    type: type('update'),
    nextState: nextState,
  }),
};

const CommonDictionaryReducerFunctions = {
  update: (_, action) => action.nextState,
};

const commonDictionaryReducer = ReduxUtil.createReducer(
  getInitialState(),
  CommonDictionaryReducerFunctions,
  TYPE_PREFIX,
);
export {commonDictionaryReducer};
