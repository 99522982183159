import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {
    DeviceType
} from '../../../firebase/firestore/references/organizations/connectable-devices/firestore-connectable-devices';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaConnectableDeviceAttributes {
  id = 'id',
  adv_data_len = 'adv_data_len',
  bda = 'bda',
  ble_addr_type = 'ble_addr_type',
  ble_adv = 'ble_adv',
  ble_evt_type = 'ble_evt_type',
  dev_type = 'dev_type',
  flag = 'flag',
  num_resps = 'num_resps',
  rssi = 'rssi',
  scan_rsp_len = 'scan_rsp_len',
  search_evt = 'search_evt',
}

export interface AlgoliaConnectableDeviceData extends AlgoliaObject {
  id: string;
  adv_data_len: number;
  bda: string;
  ble_addr_type: number;
  ble_adv: string;
  ble_evt_type: number;
  dev_type: number;
  flag: number;
  num_resps: number;
  rssi: number;
  scan_rsp_len: number;
  search_evt: number;
  deviceId: string;
  deviceType: DeviceType;
}

export default class AlgoliaConnectableDevices extends AlgoliaBase<AlgoliaConnectableDeviceData> {
  public static readonly facets = () => ({});

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaConnectableDevices(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.connectableDevices;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaConnectableDevices.facets();
}
