import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaDeviceButtonRelationAttributes {
  id = 'id',
  deviceId = 'deviceId',
  passportId = 'passportId',
  buttonId = 'buttonId',
}

export interface AlgoliaDeviceButtonRelationData extends AlgoliaObject {
  deviceId: string;
  passportId: string;
  buttonId: string;
}

export default class AlgoliaDeviceButtonRelations extends AlgoliaBase<AlgoliaDeviceButtonRelationData> {
  public static readonly facets = () => ({
    id: AlgoliaDeviceButtonRelationAttributes.id,
    deviceId: AlgoliaDeviceButtonRelationAttributes.deviceId,
    passportId: AlgoliaDeviceButtonRelationAttributes.passportId,
    buttonId: AlgoliaDeviceButtonRelationAttributes.buttonId,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaDeviceButtonRelations(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.deviceButtonRelations;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaDeviceButtonRelations.facets();
}
