import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {AlgoliaNfcCard, AlgoliaNfcCardAttributes} from './algolia-nfc-cards';
import {EpochMillis} from '../../../common-types/numbers';

export interface AlgoliaNfcCardDeletedData extends AlgoliaNfcCard, AlgoliaObject {
  deletedAt: EpochMillis;
}

export default class AlgoliaNfcCardsDeleted extends AlgoliaBase<AlgoliaNfcCardDeletedData> {
  public static readonly facets = () => ({
    id: AlgoliaNfcCardAttributes.id,
    propertyValue: AlgoliaNfcCardAttributes.propertyValue,
    personaId: AlgoliaNfcCardAttributes.personaId,
    personType: AlgoliaNfcCardAttributes.personType,
    lendable: AlgoliaNfcCardAttributes.lendable,
    lendingPersonaId: AlgoliaNfcCardAttributes.lendingPersonaId,
    isLending: AlgoliaNfcCardAttributes.isLending,
    readerIds: AlgoliaNfcCardAttributes.readerIds,
    usageRegistered: AlgoliaNfcCardAttributes.usageRegistered,
    memberPropertyLabelId: AlgoliaNfcCardAttributes.memberPropertyLabelId,
    organizationId: AlgoliaNfcCardAttributes.organizationId,
    memberPropertyLabelName: AlgoliaNfcCardAttributes.memberPropertyLabelName,
    lendLimitPeriod: AlgoliaNfcCardAttributes.lendLimitPeriod,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaNfcCardsDeleted(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.nfcCardsDeleted;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaNfcCardsDeleted.facets();
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaNfcCardDeletedData>(this.index, condition);
}
