import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../../algolia';
import AlgoliaBase from '../../../algolia-base';

export enum AlgoliaSiteContactAttributes {
  id = 'id',
  content = 'content',
  email = 'email',
  phoneNumber = 'phoneNumber',
  senderUid = 'senderUid',
  senderName = 'senderName',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export interface AlgoliaSiteContactData extends AlgoliaObject {
  content: string;
  email: string;
  phoneNumber: string;
  senderUid: string;
  senderName: string;
  createdAt: number;
  updatedAt: number;
}

export default class AlgoliaSiteContacts extends AlgoliaBase<AlgoliaSiteContactData> {
  public static readonly facets = () => ({
    id: AlgoliaSiteContactAttributes.id,
    category: AlgoliaSiteContactAttributes.senderUid,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaSiteContacts(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.siteContacts;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaSiteContacts.facets();
}
