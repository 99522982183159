import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {BusinessType} from '../../../business-types/core/common/business-type';
import {FirestoreDocumentData} from '../../../firebase/firestore/firestore';
import {DashboardElementStatus} from '../../../business-types/residence/organizations/dashboard/enums/dashboardContentType';
import {DashboardUnitType} from '../../../business-types/residence/organizations/dashboard/enums/dashboardUnitType';
import {ResidenceDashboardContentType} from '../../../business-types/residence/organizations/dashboard/enums/residenceDashboardContentType';

export enum AlgoliaDashboardContentAttributes {
  id = 'id',
  unitType = 'unitType',
  unitId = 'unitId',
  businessType = 'businessType',
  dashboardType = 'dashboardType',
  conditions = 'conditions',
  summaryValue = 'summaryValue',
  elementDetectIds = 'elementDetectIds',
  elements = 'elements',
}

export interface AlgoliaDashboardContentElement extends AlgoliaObject {
  // 集計後のアクションを受けた結果 (初期はNotConfirmed)
  status: DashboardElementStatus;
  key: {
    spaceId?: string;
    deviceId?: string;
    contractId?: string;
    customerId?: string;
  };
  attributes: {
    spaceIds?: string[];
    deviceIds?: string[];
    contractIds?: string[];
    customerIds?: string[];
  };
}

export interface FirestoreOrganizationDashboardContentElementData extends FirestoreDocumentData {
  unitType: DashboardUnitType;
  unitId: string;
  businessType: BusinessType.RESIDENCE;
  dashboardType: ResidenceDashboardContentType;
  status: DashboardElementStatus;

  key: {
    spaceId?: string;
    deviceId?: string;
    keyId?: string;
    contractId?: string;
    customerId?: string;
  };
  attributes: {
    spaceIds?: string[];
    deviceIds?: string[];
    keyIds?: string[];
    contractIds?: string[];
    customerIds?: string[];
  };
}

export interface AlgoliaDashboardContentData extends AlgoliaObject {
  unitType: DashboardUnitType;
  unitId: string;

  // ひとまずResidenceのみの対応
  businessType: BusinessType.RESIDENCE;
  dashboardType: ResidenceDashboardContentType;

  // 条件
  conditions: Array<{
    key: string;
    operator: 'Equal' | 'NotEqual' | 'MoreThan' | 'LessThan';
    values: Array<string>;
  }>;

  // ダッシュボードに表示用のデータ、対象件数とか
  summaryValue?: {
    number?: number;
  };
  // 関連するデータのキー特定のためのIDリスト
  elementDetectIds: Array<string>;
  elements: Array<AlgoliaDashboardContentElement>;
}

export interface AlgoliaDeviceButtonRelationData extends AlgoliaObject {
  deviceId: string;
  passportId: string;
  buttonId: string;
}

export default class AlgoliaDashboardContents extends AlgoliaBase<AlgoliaDashboardContentData> {
  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaDashboardContents(client, organizationId);
  public static readonly facets = () => ({
    businessType: AlgoliaDashboardContentAttributes.businessType,
    dashboardType: AlgoliaDashboardContentAttributes.dashboardType,
    unitType: AlgoliaDashboardContentAttributes.unitType,
    unitId: AlgoliaDashboardContentAttributes.unitId,
  });

  public facets = () => AlgoliaDashboardContents.facets();

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.dashboardContents;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }
}
