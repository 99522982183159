import {DefaultThemeColor, ThemeColor} from '../../styles/themes';
import {
    SpaceStatus
} from '../../firebase/firestore/references/organizations/spaces/status-histories/firestore-organizations-spaces-status-histories';
import {AlgoliaSpace} from '../../algolia/references/spaces/algolia-spaces';
import moment from 'moment';
import SpacesService from '../../services/spaces/spaces-service';

export default class SpaceStatusUtil {
  public static statusToColor = (status?: SpaceStatus) => {
    if (!status) {
      return DefaultThemeColor.default;
    }
    switch (status) {
      case SpaceStatus.vacant:
        return ThemeColor.info;
      case SpaceStatus.occupyScheduled:
        return ThemeColor.success;
      case SpaceStatus.occupied:
        return ThemeColor.success;
      case SpaceStatus.leaveScheduled:
        return ThemeColor.error;
      case SpaceStatus.leaveProcessing:
        return ThemeColor.error;
      case SpaceStatus.closed:
        return DefaultThemeColor.default;
      case SpaceStatus.applied:
        return ThemeColor.warning;
      default:
        return DefaultThemeColor.default;
    }
  };

  /**
   * spaceのオブジェクトから現在のstatusの値を返却する
   * scheduledStatusで取得した未来の履歴のstartAtを過ぎていたら、
   * scheduledStatusのstatusを返却し、spaceで保持しているstatusの値を更新する
   * @param space
   */
  public static convertToCurrentStatus = (space: AlgoliaSpace) => {
    if (!space.status) {
      return SpaceStatus.none;
    }
    // スケジューリングされた空間ステータスの未来の履歴が無ければstatusで持っているステータスの値をそのまま返却する
    if (!space.scheduledStatuses || !space.scheduledStatuses[0]) {
      return space.status;
    }
    let resultStatus: SpaceStatus = space.status;
    let newCurrentStatusId = '';
    const now = moment().valueOf();
    const newCurrentStatuses = space.scheduledStatuses.filter(s => s.startAt <= now);
    if (!newCurrentStatuses || !newCurrentStatuses[0]) {
      return space.status;
    }
    // startAtを過ぎている履歴が2件以上存在していたらcreatedAtが最新のものを優先する
    if (newCurrentStatuses && newCurrentStatuses[0] && newCurrentStatuses.length > 1) {
      const recentlyCurrentStatus = newCurrentStatuses.reduce((pre, cur) => {
        return cur.createdAt > pre.createdAt ? cur : pre;
      });
      resultStatus = recentlyCurrentStatus.status;
      newCurrentStatusId = recentlyCurrentStatus.id;
    } else {
      resultStatus = newCurrentStatuses[0].status;
      newCurrentStatusId = newCurrentStatuses[0].id;
    }
    // 未来の履歴から取得したステータスとspaceで保持しているステータスの値に差異があれば非同期で更新処理を投げておく
    if (resultStatus !== space.status) {
      SpacesService.updateCurrentStatus(space.id, newCurrentStatusId)
        .then(() => {
          console.log(
            'updated current status. spaceId: ',
            space.id,
            ' current: ',
            space.status,
            ' next: ',
            resultStatus,
          );
        })
        .catch(e => {
          console.log(
            'failed to update current status. spaceId: ',
            space.id,
            ' current: ',
            space.status,
            ' next: ',
            resultStatus,
            e,
          );
        });
    }
    return resultStatus;
  };
}
