import {
    FirestoreOrganizationSpaceData,
    OrganizationPropertyRequestData,
    OrganizationSpaceRequestData,
    SpaceAttribute,
} from '../../firebase/firestore/references/organizations/spaces/firestore-spaces';
import {
    FirestoreOrganizationsSpacesSendKeyToOccupantSettingData,
    OrganizationsSpaceSendKeyToOccupantSettingsRequest,
} from '../../firebase/firestore/references/organizations/spaces/send-key-to-occupant-settings/firestore-organizations-spaces-send-key-to-occupant-settings';
import LocalStorage, {LocalStorageKey} from '../../local-storage/LocalStorage';
import {
    OrganizationsSpaceStatusHistoryResponse,
    SpaceStatus,
} from '../../firebase/firestore/references/organizations/spaces/status-histories/firestore-organizations-spaces-status-histories';

import Algolia from '../../algolia/algolia';
import {AlgoliaDevice} from '../../algolia/references/devices/algolia-devices';
import {AlgoliaSpace} from '../../algolia/references/spaces/algolia-spaces';
import CommonError from '../../errors/common-error';
import DictError from '../../errors/dict-error';
import {ErrorDictKey} from '../../dictionary/error-dictionary';
import {ReaderSide} from '../../algolia/references/readers/algolia-readers';
import Redux from '../../redux/ReduxConnector';
import SpacePhotoUriUtils from '../../algolia/references/spaces/space-photo-uri-utils';
import StorageSpaces from '../../firebase/storage/references/organizations/spaces/storage-spaces';
import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';

export default class SpacesService {
  public static registerSpace = async (space: AlgoliaSpace, photoData?: File): Promise<AlgoliaSpace> => {
    space = await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-register', space).catch(e => {
      if (e && e.code === 'already-exists') {
        throw new CommonError('duplicate', '', e);
      }
      throw new CommonError('register', '', e);
    });
    if (photoData) {
      const result = await StorageSpaces.uploadPhoto(space.id, photoData).catch(e => {
        throw new CommonError('upload', '', e);
      });
      space.photoUri = await StorageSpaces.getDownloadUrl(result.ref._location.path).catch(e => {
        throw new CommonError('url', '', e);
      });
      await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-update', {
        spaceId: space.id,
        updateSpace: {
          photoUri: space.photoUri,
        },
      }).catch(e => {
        throw new CommonError('update', '', e);
      });
    }
    return space;
  };

  public static registerSpacesByCsv = async ({spaces}: {spaces: OrganizationSpaceRequestData[]}) => {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-registerSpacesByCsv', {spaces});
  };

  public static updatePropertiesByCsv = async ({spaces}: {spaces: OrganizationPropertyRequestData[]}) => {
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-updatePropertiesByCsv', {
      accessToken,
      spaces,
    })
      .then(async result => {
        console.log('updatePropertiesByCsv_result', result);
        return result;
      })
      .catch(e => {
        throw new DictError(ErrorDictKey.failedToUpdateSpace, e);
      });
  };

  public static addBitlocks = async (spaceId: string, bitlockIds: string[]): Promise<AlgoliaDevice[]> => {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-addBitlocks', {
      spaceId: spaceId,
      bitlockIds: bitlockIds,
    }).catch(e => {
      throw new DictError(ErrorDictKey.spaceRegistrationFailure, e);
    });
  };

  public static removeBitlocks = async (spaceId: string, bitlockIds: string[]) => {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-removeBitlocks', {
      spaceId,
      bitlockIds,
    }).catch(e => {
      console.log('failed to remove bitlock.', e);
      throw new CommonError('fail');
    });
  };

  private static async deletePhotoFromStorage(spaceId: string, photoUri: string) {
    await StorageSpaces.deletePhoto(spaceId, photoUri).catch(e => {
      throw new CommonError('delete', '', e);
    });
  }

  public static async deleteSpacePhotos(spaceId: string, photoUris: string[]) {
    return Promise.all(photoUris.map(photoUri => this.deletePhotoFromStorage(spaceId, photoUri))).catch(e => {
      throw new CommonError('deleteSpaceGroupPhoto', '', e);
    });
  }

  public static async deleteSpaces(
    deleteSpaceInfo: Array<{spaceId: string; bitlockIds: string[]; photoUris: string[]}>,
    notUpdateParent?: boolean,
  ) {
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    await Promise.all(deleteSpaceInfo.map(({spaceId, photoUris}) => this.deleteSpacePhotos(spaceId, photoUris))).catch(
      e => {
        throw new CommonError('deleteSpace', '', e);
      },
    );

    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-delete', {
      accessToken: accessToken,
      deleteSpaceInfo: deleteSpaceInfo,
      notUpdateParent: notUpdateParent,
    }).catch(e => {
      throw new DictError(ErrorDictKey.failedToDeleteSpace, e);
    });
  }

  public static async updateSpace(spaceId: string, space: Partial<FirestoreOrganizationSpaceData>) {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-update', {
      spaceId: spaceId,
      updateSpace: space,
    }).catch(e => {
      throw new DictError(ErrorDictKey.failedToUpdateSpace, e);
    });
  }
  /**
   * 物件画像の追加・更新
   * @param spaceId
   * @param photoIndex
   * @param photoData
   * @param photoUri - あれば画像更新。なければ画像新規作成。
   */
  public static uploadPhoto = async (
    spaceId: string,
    photoIndex,
    photoData: File,
    photoUri?: string,
  ): Promise<string> => {
    const result = await StorageSpaces.uploadPhoto(spaceId, photoData, photoUri).catch(e => {
      throw new CommonError('upload', '', e);
    });

    const newPhotoUri = await StorageSpaces.getDownloadUrl(result.ref._location.path).catch(e => {
      throw new CommonError('url', '', e);
    });
    const updateSpace: Partial<AlgoliaSpace> = {id: spaceId};
    const updatedPhotoUriKey = SpacePhotoUriUtils.generatePhotoUriKeyString(photoIndex);
    updateSpace[updatedPhotoUriKey] = newPhotoUri;
    await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-update', {
      spaceId: spaceId,
      updateSpace: updateSpace,
    }).catch(e => {
      throw new CommonError('update', '', e);
    });
    return newPhotoUri;
  };

  /**
   * uploadPhotos
   * @param space
   * @param startPhotoIndex
   * @param photoData
   * Process:
   * 1. storageに画像をアップロード(一つでもアップロードに失敗したら、アップロードに成功した画像をstorageから削除)
   * 2. 画像のUrlを取得。
   * 3. firestoreのspaceに画像データ保存。
   * ※ updating photos functionality is not supported yet.)
   */
  public static uploadPhotos = async (
    space: AlgoliaSpace,
    startPhotoIndex: number,
    photosData?: File[],
  ): Promise<string[]> => {
    if (!photosData || !photosData.length) {
      return [];
    }
    const updateSpace: Partial<AlgoliaSpace> = {id: space.id};
    const newPhotoUris: string[] = [];
    const errorList: CommonError[] = [];
    await Promise.all(
      Object.values(photosData).map(async photoData => {
        const result = await StorageSpaces.uploadPhoto(space.id, photoData).catch(e => {
          errorList.push(new CommonError('upload', '', e));
          return;
        });

        await StorageSpaces.getDownloadUrl(result.ref._location.path)
          .then(newPhotoUri => {
            newPhotoUris.push(newPhotoUri);
          })
          .catch(e => {
            errorList.push(new CommonError('url', '', e));
          });
        return;
      }),
    );
    if (errorList.length) {
      if (newPhotoUris.length) {
        SpacesService.deleteSpacePhotos(space.id, newPhotoUris).catch(e => {
          console.log('failed to delete photos.', e);
        });
      }
      return [];
    }
    let photoIndexValue = startPhotoIndex;
    for (const newPhotoUri of newPhotoUris) {
      const updatedPhotoUriKey = SpacePhotoUriUtils.generatePhotoUriKeyString(photoIndexValue);
      updateSpace[updatedPhotoUriKey] = newPhotoUri;
      photoIndexValue++;
    }
    await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-update', {
      spaceId: space.id,
      updateSpace: updateSpace,
    }).catch(e => {
      throw new CommonError('update', '', e);
    });
    return newPhotoUris;
  };

  /**
   * deletePhoto
   * @param spaceId
   * @param photoUriToDelete - 削除する画像のUri。
   * @param newPartialSpace - 新しく保存されるphotoUri系のpropertyデータ。
   * Process:
   * 1. 指定URIの画像を削除
   * 2. 新しいphotoUrisを保存。
   */
  public static async deletePhoto(
    spaceId: string,
    photoUriToDelete: string,
    newPartialSpace: Partial<AlgoliaSpace>,
  ): Promise<void> {
    try {
      await this.deletePhotoFromStorage(spaceId, photoUriToDelete);
      const updateSpace: Partial<AlgoliaSpace> = {id: spaceId, ...newPartialSpace};
      await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-update', {
        spaceId,
        updateSpace: updateSpace,
      });
      console.log('update space on firestore finished');
    } catch (error) {
      throw new CommonError('delete', '', error);
    }
  }

  /**
   * 現在の空間ステータスをFirestoreのレコードに問い合わせをする処理（まだ未使用なのでコメントアウトしておく）
   * @param spaceId
   * @param statusId
   */
  //
  // public static getCurrentStatus = async (spaceId: string): Promise<FirestoreOrganizationsSpaceStatusData> => {
  //   return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-getCurrentStatus', {
  //     spaceId: spaceId,
  //   }).catch(e => {
  //     throw new CommonError('get-current-status', 'failed to get current space status.', e);
  //   });
  // };

  public static updateCurrentStatus = async (spaceId: string, statusId: string) => {
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-updateCurrentStatus', {
      accessToken,
      spaceId: spaceId,
      nextStatusId: statusId,
    }).catch(e => {
      throw new CommonError('update-current-status', 'failed to update current space status.', e);
    });
  };

  /**
   * 特定の空間のdeleted:trueでない全てのステータス履歴を取得する（過去分も含めて）
   * @param spaceId
   */
  public static getAllStatusHistories = async (spaceId: string): Promise<OrganizationsSpaceStatusHistoryResponse[]> => {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-getAllStatusHistories', {
      spaceId: spaceId,
    }).catch(e => {
      throw new DictError(ErrorDictKey.failedToPublishSpace, e);
    });
  };

  public static publishSpace = async (data: {spaceId: string}) => {
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-publishSpace', {
      accessToken,
      spaceId: data.spaceId,
    }).catch(e => {
      throw new DictError(ErrorDictKey.failedToPublishSpace, e);
    });
  };

  public static closeSpace = async (data: {spaceId: string; customerId?: string; contractId?: string}) => {
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-closeSpace', {
      accessToken,
      spaceId: data.spaceId,
      customerId: data.customerId,
      contractId: data.contractId,
    }).catch(e => {
      throw new DictError(ErrorDictKey.failedToCloseSpace, e);
    });
  };

  /**
   * 入居時カギ送信時に自動で送信するカギの設定
   * @param data
   */
  public static addSendKeyToOccupantSettings = async (data: {
    spaceId: string;
    settings: OrganizationsSpaceSendKeyToOccupantSettingsRequest;
  }) => {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-addSendKeyToOccupantSettings', {
      spaceId: data.spaceId,
      settings: data.settings,
    }).catch(e => {
      throw new DictError(ErrorDictKey.failedToAddSpaceSendKeySettings, e);
    });
  };

  public static deleteSendKeyToOccupantSettings = async (data: {spaceId: string}) => {
    // 文字数超えてるので最後のs削ります。
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-deleteSendKeyToOccupantSetting', {
      spaceId: data.spaceId,
    }).catch(e => {
      throw new DictError(ErrorDictKey.failedToDeleteSpaceSendKeySettings, e);
    });
  };

  public static getSendKeyToOccupantSettings = async (data: {
    spaceId: string;
  }): Promise<FirestoreOrganizationsSpacesSendKeyToOccupantSettingData[]> => {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-getSendKeyToOccupantSettings', {
      spaceId: data.spaceId,
    }).catch(e => {
      throw new DictError(ErrorDictKey.failedToGetSpaceSendKeySettings, e);
    });
  };

  public static async removeReaderLogTargets(bitlockId: string, bitreaderId: string, side?: ReaderSide) {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-removeLogTargets', {
      bitlockId: bitlockId,
      bitreaderId: bitreaderId,
      side: side,
    }).catch(_ => {
      throw new CommonError('error', 'ログ取得対象から外す処理に失敗しました。');
    });
  }

  public static async sendDepartureDateNotice(
    personaId: string,
    title: string,
    body: string,
    contractId: string,
    pushNotification: boolean,
    mailNotification: boolean,
    email: string,
  ) {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-customers-sendDepartureDateNotice', {
      personaId: personaId,
      title: title,
      body: body,
      contractId: contractId,
      pushNotification: pushNotification,
      mailNotification: mailNotification,
      mailAddress: email,
    }).catch(_ => {
      throw new CommonError('error', '退去予定通知が送信できませんでした。');
    });
  }

  // 退去予定通知を一斉送信する
  public static async sendDepartureDateNotices() {
    return V2FirebaseHomehubOldApiFunctions.call('organizations-customers-sendDepartureDateNotices', {
      organizationId: Redux.getStore().user.organizationId,
    }).catch(_ => {
      throw new CommonError('error', '退去予定通知が送信できませんでした。');
    });
  }

  public static async updateEnterSpaceUser(bitlockIds: string[], spaceId: string) {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-spaces-updateEnterSpaceUser', {
      bitlockIds: bitlockIds,
      spaceId: spaceId,
    }).catch(e => {
      console.log(e);
      throw new DictError(ErrorDictKey.failedToUpdateSpace, e);
    });
  }

  // TODO: アナウンスメント・議事録・広報誌の方が、支店IDを保持するように変更したら、いらなくなる。
  public static async getBuildingsByBranchIds(branchIds: string[]) {
    const spaceIndex = Algolia.indices().spaces();
    const buildings = await spaceIndex.getAllHitsByBrowseResponse({
      searchWord: '',
      filters: {
        [spaceIndex.facets().attributes]: [SpaceAttribute.building],
        [spaceIndex.facets().parents]: branchIds, // 支店グループが渡されることを想定して、parentIdではなく、parentsに含まれるかどうかにしている。
      },
      facets: {},
      offset: 0,
    });
    return buildings;
  }

  public static async getSpacesByParentId(parentId: string) {
    const spaceIndex = Algolia.indices().spaces();
    const {hits} = await spaceIndex.search({
      searchWord: '',
      filters: {
        [spaceIndex.facets().parentId]: [parentId],
      },
      facets: {},
      length: 500,
      offset: 0,
    });
    return hits;
  }

  public static async getAllBySpaceIds(spaceIds: string[]): Promise<AlgoliaSpace[]> {
    const spaceIndex = Algolia.indices().spaces();
    return spaceIndex
      .search({
        filters: {
          [spaceIndex.facets().id]: spaceIds,
        },
      })
      .then(space => space.hits);
  }

  /**
   * 指定されたspaceIdの親空間のIDを返します。
   * @param spaceIds
   */
  public static async getParentsBySpaceIds(
    spaceIds: string[],
  ): Promise<{
    [spaceId: string]: string[]; // {SPACE_ID_A: [parentID_A, parentID_B], SPACE_ID_B: [], ...}
  }> {
    if (!spaceIds.length) {
      return {};
    }
    const spaceIndex = Algolia.indices().spaces();
    const spaces: AlgoliaSpace[] = await spaceIndex
      .search({
        filters: {
          [spaceIndex.facets().id]: spaceIds,
        },
      })
      .then(space => space.hits);
    return Object.fromEntries(spaces.map(space => [space.id, space.parents]));
  }

  // bmResidenceの物件のみに対応
  public static validateDeletingSpaces(spaces: AlgoliaSpace[]) {
    return spaces.every(s => {
      const validStatus = !s.status || [SpaceStatus.none, SpaceStatus.vacant, SpaceStatus.closed].includes(s.status);
      const noDevices = !Array.isArray(s.bitlockIds) || !s.bitlockIds.length;
      console.log(validStatus, noDevices);
      return validStatus && noDevices;
    });
  }

  /**
   * 指定されたspaceIdのSpace情報を返す。
   * @param spaceId
   */
  public static getSpaceBySpaceId = async (spaceId: string): Promise<AlgoliaSpace | undefined> => {
    const spaceIndex = Algolia.indices().spaces();
    return await spaceIndex
      .search({
        searchWord: '',
        filters: {
          [spaceIndex.facets().id]: [spaceId],
        },
        facets: {},
        length: 1,
        offset: 0,
      })
      .then(spaces => {
        const result = spaces.hits.filter(hit => hit.id == spaceId);
        return result.length > 0 ? result[0] : undefined;
      })
      .catch(err => {
        throw err;
      });
  };
}
