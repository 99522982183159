import Redux from '../redux/ReduxConnector';
import DictionaryCommon, {BusinessDictDef, DefaultBusinessDictDef, Locale} from './dictionary-common';
import {BusinessType} from '../domain-base/business-types';

export enum ErrorDictKey {
  failedToRegisterGuest = 'failedToRegisterGuest',
  failedToRegisterCustomer = 'failedToRegisterCustomer',
  lendHoleFailure = 'lendHoleFailure',
  spaceRegistrationFailure = 'spaceRegistrationFailure',
  failedToUploadImage = 'failedToUploadImage',
  algoliaNotInitialized = 'algoliaNotInitialized',
  spaceBitlocksBindingFailure = 'spaceBitlocksBindingFailure',
  memberInvitationFailure = 'memberInvitationFailure',
  userNotFound = 'userNotFound',
  invitationDataNotFound = 'invitationDataNotFound',
  addingMemberFailure = 'addingMemberFailure',
  failedToSetTenantId = 'failedToSetTenantId',
  failedToGetSellingEngineData = 'failedToGetSellingEngineData',
  failedToPostSiteAnnouncement = 'failedToPostSiteAnnouncement',
  failedToUpdateSiteAnnouncement = 'failedToUpdateSiteAnnouncement',
  failedToDeleteSiteAnnouncement = 'failedToDeleteSiteAnnouncement',
  failedToPostSiteGuide = 'failedToPostSiteGuide',
  failedToUpdateSiteGuide = 'failedToUpdateSiteGuide',
  failedToPostSiteFaq = 'failedToPostSiteFaq',
  failedToUpdateSiteFaq = 'failedToUpdateSiteFaq',
  failedToAddContractType = 'failedToAddContractType',
  failedToUpdateContractPlan = 'failedToUpdateContractPlan',
  failedToInvite = 'failedToInvite',
  failedToContract = 'failedToContract',
  failedToSend = 'failedToSend',
  addingWifiConnectorFailure = 'addingWifiConnectorFailure',
  getLoginInfoFailure = 'getLoginInfoFailure',
  failedToRegisterBeforeAccept = 'failedToRegisterBeforeAccept',
  failedToCreateClientAccount = 'failedToCreateClientAccount',
  failedToGetClientAccount = 'failedToGetClientAccount',
  failedToDeleteWifiConnector = 'failedToDeleteWifiConnector',
  failedToGetLog = 'failedToGetLog',
  updateDeviceInfo = 'updateDeviceInfo',
  failedToGetConnectableDevice = 'failedToGetConnectableDevice',
  failedRegisterPersonaPublicKey = 'failedRegisterPersonaPublicKey',
  failedGetPersonaPublicKey = 'failedGetPersonaPublicKey',
  failedToGetCustomerPayPlanData = 'failedToGetCustomerPayPlanData',
  failedToGetCustomerMainPaymentMethod = 'failedToGetCustomerMainPaymentMethod',
  failedToRegisterSpaceGroup = 'failedToRegisterSpaceGroup',
  failedToDeleteSpaceGroup = 'failedToDeleteSpaceGroup',
  failedToDeleteSpace = 'failedToDeleteSpace',
  failedToUpdateSpace = 'failedToUpdateSpace',
  failedToUpdateSpaceGroup = 'failedToUpdateSpaceGroup',
  failedToDeleteMember = 'failedToDeleteMember',
  failedToDeleteGuest = 'failedToDeleteGuest',
  failedToDeleteDevice = 'failedToDeleteDevice',
  failedToUpdateDevice = 'failedToUpdateDevice',
  failedToRegisterTreasureBroker = 'failedToRegisterTreasureBroker',
  failedToAddTreasureDeal = 'failedToAddTreasureDeal',
  failedToApproveTreasureDeal = 'failedToApproveTreasureDeal',
  alreadyRegisterGuest = 'alreadyRegisterGuest',
  alreadyRegisterMember = 'alreadyRegisterMember',
  alreadyRegisterCustomer = 'alreadyRegisterCustomer',
  alreadyInvitation = 'alreadyInvitation',
  failedToDeleteWifiConnectorAccountData = 'failedToDeleteWifiConnectorAccountData',
  failedToRegisterSpaceAttribute = 'failedToRegisterSpaceAttribute',
  alreadyRegisterSpaceAttribute = 'alreadyRegisterSpaceAttribute',
  failedToUpdateSpaceAttribute = 'failedToUpdateSpaceAttribute',
  failedToGetContractApplication = 'failedToGetContractApplication',
  failedRegisterApplication = 'failedRegisterApplication',
  failedUpdateApplication = 'failedUpdateApplication',
  failedDeleteApplication = 'failedDeleteApplication',
  failedToGetGuest = 'failedToGetGuest',
  failedToSetLeaveReservationDate = 'failedToSetLeaveReservationDate',
  failedToUpdateLeaveReservationDate = 'failedToUpdateLeaveReservationDate',
  failedToCancelLeaveReservationDate = 'failedToCancelLeaveReservationDate',
  failedToGetSpaceStatusHistories = 'failedToGetSpaceStatusHistories',
  failedToPublishSpace = 'failedToPublishSpace',
  failedToCloseSpace = 'failedToCloseSpace',
  failedToUpdateMember = 'failedToUpdateMember',
  failedToKillConnection = 'failedToKillConnection',
  failedToExecuteSettlement = 'failedToExecuteSettlement',
  failedToAddSpaceSendKeySettings = 'failedToAddSpaceSendKeySettings',
  failedToAddSpaceLendAuthDeviceSettings = 'failedToAddSpaceLendAuthDeviceSettings',
  failedToDeleteSpaceSendKeySettings = 'failedToDeleteSpaceSendKeySettings',
  failedToGetSpaceSendKeySettings = 'failedToGetSpaceSendKeySettings',
  failedToDeleteSpaceLendAuthDeviceSettings = 'failedToDeleteSpaceLendAuthDeviceSettings',
  failedToGetSpaceLendAuthDeviceSettings = 'failedToGetSpaceLendAuthDeviceSettings',
  wrongMailAddress = 'wrongMailAddress',
  wrongPhoneNumber = 'wrongPhoneNumber',
  serverConnectFailure = 'serverConnectFailure',
  failedToDeleteApprovedApplication = 'failedToDeleteApprovedApplication',
  faieldToRenewContract = 'faieldToRenewContract',
  faieldToModifyContract = 'faieldToModifyContract',
  failedToRegisterNotificationSettings = 'failedToRegisterNotificationSettings',
  failedToGetNotificationSettings = 'failedToGetNotificationSettings',
  failedToDeleteReception = 'failedToDeleteReception',
  failedToDeleteCustomer = 'failedToDeleteCustomer',
  failedToDeleteKeyAndTicket = 'failedToDeleteKeyAndTicket',
  failedToRegisterKeyIssueSettings = 'failedToRegisterKeyIssueSettings',
  failedToGetKeyIssueSettings = 'failedTGetKeyIssueSettings',
  failedToRegisterLinkageSettings = 'failedToRegisterLinkageSettings',
  failedToLinkToSlack = 'failedToLinkToSlack',
  failedToUnlinkToSlack = 'failedToUnlinkToSlack',
  failedToGetLinkageSettings = 'failedToGetLinkageSettings',
  failedToPublishUrlToOccupant = 'failedToPublishUrlToOccupant',
  failedToInsertReception = 'failedToInsertReception',
  failedToUpdateReception = 'failedToUpdateReception',
  failedToInsertReceptionSettings = 'failedToInsertReceptionSettings',
  failedToGetReceptionSettings = 'failedToGetReceptionSettings',
  failedToInsertSlackTargetIds = 'failedToInsertSlackTargetIds',
  failedToInsertChatWorksTargetIds = 'failedToInsertChatWorksTargetIds',
  failedToInsertLineWorksTargetIds = 'failedToInsertLineWorksTargetIds',
  failedToInsertSmsTargetIds = 'failedToInsertSmsTargetIds',
  failedToGetSlackTargetIds = 'failedToGetSlackTargetIds',
  failedToInsertFacetimeIds = 'failedToInsertFacetimeIds',
  failedToGetReceptionData = 'failedToGetReceptionData',
  failedToRegisterUserAuthority = 'failedToRegisterUserAuthority',
  failedToGetUserAuthorities = 'failedToGetUserAuthorities',
  failedToUpdateUserAuthority = 'failedToUpdateUserAuthority',
  failedToDeleteUserAuthority = 'failedToDeleteUserAuthority',
  failedToDeleteUserAuthorityCurrentlyUsed = 'failedToDeleteUserAuthorityCurrentlyUsed',
  alreadyExistEmail = 'alreadyExistEmail',
  alreadyExistPhoneNumber = 'alreadyExistPhoneNumber',
  inputRequiredFields = 'input-required-fields',
  noTargetOrganization = 'no-target-organization',
  noTargetSpace = 'no-target-space',
  notReservable = 'not-reservable',
  minimumReservationTimeRequired = 'minimum-reservation-time-required',
  unReservableTime = 'un-reservable-time',
  exceedMaxCapacity = 'exceed-max-capacity',
  spaceNotAvailable = 'space-not-available',
  requiredHostParticipant = 'required-host-participant',
  participantEmpty = 'participant-empty',
  targetReservationNotFound = 'target-reservation-not-found',
  noAuthorityToUpdate = 'no-authority-to-update',
  invalidChangeOption = 'invalid-change-option',
  noChangeReservationData = 'no-change-reservation-data',
  pastDateCannotUpdate = 'past-date-cannot-update',
  pastDateCannotDelete = 'past-date-cannot-delete',
  noAuthorityToDelete = 'no-authority-to-delete',
  pastDateCannotInsert = 'past-date-cannot-insert',
  reservationInternalError = 'internal',
  reservationInternalInsertError = 'internal-insert',
  reservationInternalUpdateError = 'internal-update',
  reservationInternalDeleteError = 'internal-delete',
  reservationInternalApproveError = 'internal-approve',
  reservationInternalRejectError = 'internal-reject',
  insertLinkReceptionFailed = 'insert-link-reception-failed',
  updateLinkReceptionFailed = 'update-link-reception-failed',
  noBitlockDevice = 'no-bitlock-device',
  invalidParticipant = 'invalid-participant',
  noGroupSpaceAllowed = 'no-group-space-allowed',
  unReservableDayOfWeek = 'un-reservable-day-of-week',
  incorrectUsageConditionTime = 'incorrect-usage-condition-time',
  serviceAccountNotFound = 'service-account-not-found',
  participantNotBelongsToReservation = 'participant-not-belongs-to-reservation',
  approveFailed = 'approve-failed',
  rejectFailed = 'reject-failed',
  alreadyApproved = 'already-approved',
  noAuthorityApproveOrReject = 'no-authority-approve-or-reject',
  failedToGetPrivateViewSitedomain = 'failed-to-get-private-site-domain',
}

export const ErrorDictionaryDefinition: {[K in ErrorDictKey]: DefaultBusinessDictDef & BusinessDictDef} = {
  [ErrorDictKey.failedToRegisterCustomer]: {
    default: {
      default: '顧客登録に失敗しました。',
      [Locale.en_US]: 'Failed to register customer.',
    },
  },
  [ErrorDictKey.lendHoleFailure]: {
    default: {
      default: 'カギの貸し出しに失敗しました',
      [Locale.en_US]: 'Failed to lend keys.',
    },
  },
  [ErrorDictKey.spaceRegistrationFailure]: {
    default: {
      default: '空間の登録に失敗しました',
      [Locale.en_US]: 'Failed to register new space.',
    },
  },
  [ErrorDictKey.failedToUploadImage]: {
    default: {
      default: '画像をアップロードできませんでした。',
      [Locale.en_US]: 'Failed to upload image.',
    },
  },
  [ErrorDictKey.algoliaNotInitialized]: {
    default: {
      default: '',
    },
  },
  [ErrorDictKey.spaceBitlocksBindingFailure]: {
    default: {
      default: '空間にbitlockを登録できませんでした。',
      [Locale.en_US]: 'Failed to bind bitlocks to space.',
    },
  },
  [ErrorDictKey.memberInvitationFailure]: {
    default: {
      default: 'メンバーの招待に失敗しました。',
      [Locale.en_US]: 'Failed to invite member.',
    },
  },
  [ErrorDictKey.userNotFound]: {
    default: {
      default: '利用者が見つかりませんでした。',
      [Locale.en_US]: 'User not found.',
    },
  },
  [ErrorDictKey.invitationDataNotFound]: {
    default: {
      default: '招待情報が見つかりませんでした。',
      [Locale.en_US]: 'Invitation data not found.',
    },
  },
  [ErrorDictKey.addingMemberFailure]: {
    default: {
      default: 'メンバーを追加できませんでした。',
      [Locale.en_US]: 'Failed to add new member.',
    },
  },
  [ErrorDictKey.failedToSetTenantId]: {
    default: {
      default: 'テナントIDの更新に失敗しました。',
      [Locale.en_US]: 'Failed to set Tenant ID.',
    },
  },
  [ErrorDictKey.failedToGetSellingEngineData]: {
    default: {
      default: 'Selling Engineの情報の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get Selling Engine data.',
    },
  },
  [ErrorDictKey.failedToPostSiteAnnouncement]: {
    default: {
      default: 'お知らせの投稿ができませんでした。',
      [Locale.en_US]: 'Failed to post new announcement.',
    },
  },
  [ErrorDictKey.failedToUpdateSiteAnnouncement]: {
    default: {
      default: 'お知らせの更新ができませんでした。',
      [Locale.en_US]: 'Failed to update new announcement.',
    },
  },
  [ErrorDictKey.failedToDeleteSiteAnnouncement]: {
    default: {
      default: 'お知らせの削除ができませんでした。',
      [Locale.en_US]: 'Failed to delete new announcement.',
    },
  },
  [ErrorDictKey.failedToPostSiteGuide]: {
    default: {
      default: '利用ガイドの追加ができませんでした。',
      [Locale.en_US]: 'Failed to post new guide.',
    },
  },
  [ErrorDictKey.failedToUpdateSiteGuide]: {
    default: {
      default: '利用ガイドの更新ができませんでした。',
      [Locale.en_US]: 'Failed to update new guide.',
    },
  },
  [ErrorDictKey.failedToPostSiteFaq]: {
    default: {
      default: 'FAQの追加ができませんでした。',
      [Locale.en_US]: 'Failed to post new FAQ.',
    },
  },
  [ErrorDictKey.failedToUpdateSiteFaq]: {
    default: {
      default: 'FAQの更新ができませんでした。',
      [Locale.en_US]: 'Failed to update new FAQ.',
    },
  },
  [ErrorDictKey.failedToAddContractType]: {
    default: {
      default: '契約タイプを追加できませんでした。',
      [Locale.en_US]: 'Failed to add contract type.',
    },
  },
  [ErrorDictKey.failedToUpdateContractPlan]: {
    default: {
      default: '契約プラン情報を更新できませんでした。',
      [Locale.en_US]: 'Failed to update contract plan.',
    },
  },
  [ErrorDictKey.failedToInvite]: {
    default: {
      default: '招待に失敗しました。',
      [Locale.en_US]: 'Failed to invite.',
    },
  },
  [ErrorDictKey.failedToContract]: {
    default: {
      default: '契約情報の登録に失敗しました。',
      [Locale.en_US]: 'Failed to register contract data.',
    },
  },
  [ErrorDictKey.failedToSend]: {
    default: {
      default: '送信に失敗しました。',
      [Locale.en_US]: 'Failed to send.',
    },
  },
  [ErrorDictKey.addingWifiConnectorFailure]: {
    default: {
      default: 'WiFi コネクタを追加できませんでした。',
      [Locale.en_US]: 'Failed to add new wifi connector.',
    },
  },
  [ErrorDictKey.getLoginInfoFailure]: {
    default: {
      default: 'ログイン情報の取得に失敗しました',
      [Locale.en_US]: 'Failed to get login info.',
    },
  },
  [ErrorDictKey.failedToRegisterGuest]: {
    default: {
      default: 'ゲスト招待に失敗しました。',
      [Locale.en_US]: 'Failed to invite guest.',
    },
  },
  [ErrorDictKey.failedToRegisterBeforeAccept]: {
    default: {
      default: '招待がまだ承諾されていません。',
      [Locale.en_US]: 'Invitation not yet accepted',
    },
  },
  [ErrorDictKey.failedToCreateClientAccount]: {
    default: {
      default: 'アカウントの作成に失敗しました。',
      [Locale.en_US]: 'Failed create client account',
    },
  },
  [ErrorDictKey.failedToGetClientAccount]: {
    default: {
      default: 'アカウントの取得に失敗しました。',
      [Locale.en_US]: 'Failed get client account',
    },
  },
  [ErrorDictKey.failedToDeleteWifiConnector]: {
    default: {
      default: 'Wi-Fiコネクタの削除に失敗しました。',
      [Locale.en_US]: 'Failed delete Wi-Fi connector',
    },
  },
  [ErrorDictKey.failedToGetLog]: {
    default: {
      default: 'ログの取得に失敗しました。',
      [Locale.en_US]: 'Failed get Log',
    },
  },
  [ErrorDictKey.updateDeviceInfo]: {
    default: {
      default: 'デバイス情報の更新に失敗しました。',
      [Locale.en_US]: 'Failed update device info',
    },
  },
  [ErrorDictKey.failedToGetConnectableDevice]: {
    default: {
      default: '接続できるデバイス情報の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get device information that can be connected.',
    },
  },
  [ErrorDictKey.failedGetPersonaPublicKey]: {
    default: {
      default: '公開鍵の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get persona public key.',
    },
  },
  [ErrorDictKey.failedRegisterPersonaPublicKey]: {
    default: {
      default: '公開鍵の登録に失敗しました。',
      [Locale.en_US]: 'Failed to register persona public key.',
    },
  },
  [ErrorDictKey.failedToGetCustomerPayPlanData]: {
    default: {
      default: '顧客の支払い情報の取得に失敗しました。',
      [Locale.en_US]: 'Failed to  get customer pay plan data.',
    },
  },
  [ErrorDictKey.failedToGetCustomerMainPaymentMethod]: {
    default: {
      default: '顧客の決済方法の取得に失敗しました。',
      [Locale.en_US]: 'Failed to  get customer main payment method.',
    },
  },
  [ErrorDictKey.failedToRegisterSpaceGroup]: {
    default: {
      default: '空間グループの登録に失敗しました。',
      [Locale.en_US]: 'Failed to add space group',
    },
    [BusinessType.RESIDENCE]: {
      default: '建物の登録に失敗しました。',
      [Locale.en_US]: 'Failed to add building',
    },
  },
  [ErrorDictKey.failedToDeleteSpaceGroup]: {
    default: {
      default: '空間グループの削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete space group',
    },
  },
  [ErrorDictKey.failedToDeleteSpace]: {
    default: {
      default: '空間の削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete space',
    },
  },
  [ErrorDictKey.failedToUpdateSpace]: {
    default: {
      default: '空間の更新に失敗しました。',
      [Locale.en_US]: 'Failed to update space',
    },
  },
  [ErrorDictKey.failedToUpdateSpaceGroup]: {
    default: {
      default: '空間グループの更新に失敗しました。',
      [Locale.en_US]: 'Failed to update space group',
    },
  },
  [ErrorDictKey.failedToDeleteMember]: {
    default: {
      default: 'メンバーの削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete member',
    },
  },
  [ErrorDictKey.failedToDeleteGuest]: {
    default: {
      default: 'ゲストの削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete guest',
    },
  },
  [ErrorDictKey.failedToDeleteDevice]: {
    default: {
      default: 'デバイスの削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete device',
    },
  },
  [ErrorDictKey.failedToUpdateDevice]: {
    default: {
      default: 'デバイスの更新に失敗しました。',
      [Locale.en_US]: 'Failed to update device',
    },
  },
  [ErrorDictKey.failedToRegisterTreasureBroker]: {
    default: {
      default: 'bitreaderへの解錠データの送信に失敗しました。',
      [Locale.en_US]: 'Failed to register treasure broker',
    },
  },
  [ErrorDictKey.failedToAddTreasureDeal]: {
    default: {
      default: 'bitreader の設定に失敗しました。',
      [Locale.en_US]: 'Failed to add treasure deal',
    },
  },
  [ErrorDictKey.failedToApproveTreasureDeal]: {
    default: {
      default: 'bitreader の承認に失敗しました。',
      [Locale.en_US]: 'Failed to approve treasure deal',
    },
  },
  [ErrorDictKey.alreadyRegisterGuest]: {
    default: {
      default: 'すでにゲストに登録されています。',
      [Locale.en_US]: 'Already registered Guest',
    },
  },
  [ErrorDictKey.alreadyRegisterMember]: {
    default: {
      default: 'すでにメンバーに登録されています。',
      [Locale.en_US]: 'Already registered Member',
    },
  },
  [ErrorDictKey.alreadyInvitation]: {
    default: {
      default: 'すでに招待されています。',
      [Locale.en_US]: 'Already invited',
    },
  },
  [ErrorDictKey.alreadyRegisterCustomer]: {
    default: {
      default: 'すでに同じ顧客名で登録されています。',
      [Locale.en_US]: 'Already registered same customer name',
    },
  },
  [ErrorDictKey.failedToDeleteWifiConnectorAccountData]: {
    default: {
      default: 'Wi-Fi コネクタのアカウントの削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete Wi-Fi connector account.',
    },
  },
  [ErrorDictKey.failedToRegisterSpaceAttribute]: {
    default: {
      default: '空間属性の登録に失敗しました。',
      [Locale.en_US]: 'Failed to register Space Attribute.',
    },
    [BusinessType.RESIDENCE]: {
      default: '建物・物件属性の登録に失敗しました',
      [Locale.en_US]: 'Failed to register Building and Property Attribute.',
    },
  },
  [ErrorDictKey.alreadyRegisterSpaceAttribute]: {
    default: {
      default: 'すでに同じ属性コードで登録されています。',
      [Locale.en_US]: 'Already registered same attribute code',
    },
  },
  [ErrorDictKey.failedToUpdateSpaceAttribute]: {
    default: {
      default: '空間属性の更新に失敗しました。',
      [Locale.en_US]: 'Failed to update Space Attribute.',
    },
    [BusinessType.RESIDENCE]: {
      default: '建物・物件属性の更新に失敗しました',
      [Locale.en_US]: 'Failed to update Building and Property Attribute.',
    },
  },
  [ErrorDictKey.failedRegisterApplication]: {
    default: {
      default: '申請に失敗しました。',
      [Locale.en_US]: 'Application failed',
    },
  },
  [ErrorDictKey.failedUpdateApplication]: {
    default: {
      default: '申請情報の更新に失敗しました。',
      [Locale.en_US]: 'Update application failed',
    },
  },
  [ErrorDictKey.failedToGetContractApplication]: {
    default: {
      default: '申し込み',
      [Locale.en_US]: 'Get all application failed',
    },
  },
  [ErrorDictKey.failedDeleteApplication]: {
    default: {
      default: '申請情報の削除に失敗しました。',
      [Locale.en_US]: 'Delete application failed',
    },
  },
  [ErrorDictKey.failedToGetGuest]: {
    default: {
      default: 'ゲストの取得に失敗しました。',
      [Locale.en_US]: 'Failed to get guest',
    },
  },
  [ErrorDictKey.failedToSetLeaveReservationDate]: {
    default: {
      default: '退去予定日の登録に失敗しました。',
      [Locale.en_US]: 'Failed to set reservation leave date.',
    },
  },
  [ErrorDictKey.failedToUpdateLeaveReservationDate]: {
    default: {
      default: '退去予定日の更新に失敗しました。',
      [Locale.en_US]: 'Failed to update reservation leave date.',
    },
  },
  [ErrorDictKey.failedToCancelLeaveReservationDate]: {
    default: {
      default: '退去予定のキャンセルに失敗しました。',
      [Locale.en_US]: 'Failed to cancel reservation leave date.',
    },
  },
  [ErrorDictKey.failedToGetSpaceStatusHistories]: {
    default: {
      default: '空間ステータス履歴の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get space status histories.',
    },
  },
  [ErrorDictKey.failedToPublishSpace]: {
    default: {
      default: '空間の公開に失敗しました。',
      [Locale.en_US]: 'Failed to publish space.',
    },
    [BusinessType.RESIDENCE]: {
      default: '物件の公開に失敗しました。',
      [Locale.en_US]: 'Failed to publish property.',
    },
  },
  [ErrorDictKey.failedToCloseSpace]: {
    default: {
      default: '空間の停止に失敗しました。',
      [Locale.en_US]: 'Failed to close space.',
    },
    [BusinessType.RESIDENCE]: {
      default: '物件の停止に失敗しました。',
      [Locale.en_US]: 'Failed to close property.',
    },
  },
  [ErrorDictKey.failedToKillConnection]: {
    default: {
      default: '接続の切断に失敗しました。',
      [Locale.en_US]: 'Failed to kill connection.',
    },
  },
  [ErrorDictKey.failedToUpdateMember]: {
    default: {
      default: 'メンバー情報の更新に失敗しました。',
      [Locale.en_US]: 'Failed to update member.',
    },
  },
  [ErrorDictKey.failedToExecuteSettlement]: {
    default: {
      default: '決済処理の実行に失敗しました。',
      [Locale.en_US]: 'Failed to execute settlement.',
    },
  },
  [ErrorDictKey.failedToAddSpaceSendKeySettings]: {
    default: {
      default: '入居時カギ発行設定の設定に失敗しました。',
      [Locale.en_US]: 'Failed to set send key settings at occupied.',
    },
  },
  [ErrorDictKey.failedToDeleteSpaceSendKeySettings]: {
    default: {
      default: '入居時カギ発行設定の更新に失敗しました。',
      [Locale.en_US]: 'Failed to update send key settings at occupied.',
    },
  },
  [ErrorDictKey.failedToGetSpaceSendKeySettings]: {
    default: {
      default: '入居時カギ発行設定の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get send key settings at occupied.',
    },
  },
  [ErrorDictKey.failedToAddSpaceLendAuthDeviceSettings]: {
    default: {
      default: '入居時認証デバイス権限設定の設定に失敗しました。',
      [Locale.en_US]: 'Failed to set lend authDevice settings at occupied.',
    },
  },
  [ErrorDictKey.failedToDeleteSpaceLendAuthDeviceSettings]: {
    default: {
      default: '入居時認証デバイス権限設定の更新に失敗しました。',
      [Locale.en_US]: 'Failed to update lend authDevice settings at occupied.',
    },
  },
  [ErrorDictKey.failedToGetSpaceLendAuthDeviceSettings]: {
    default: {
      default: '入居時認証デバイス権限設定の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get lend authDevice settings at occupied.',
    },
  },
  [ErrorDictKey.serverConnectFailure]: {
    default: {
      default: 'サーバーへの接続に失敗しました。',
      [Locale.en_US]: 'Server connection failure.',
    },
  },
  [ErrorDictKey.wrongMailAddress]: {
    default: {
      default: '誤ったメールアドレスです。',
      [Locale.en_US]: 'Failed to mail address.',
    },
  },
  [ErrorDictKey.wrongPhoneNumber]: {
    default: {
      default: '誤った電話番号です。',
      [Locale.en_US]: 'Failed to phone number.',
    },
  },
  [ErrorDictKey.failedToDeleteApprovedApplication]: {
    default: {
      default: '契約情報の削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete contract.',
    },
  },
  [ErrorDictKey.faieldToRenewContract]: {
    default: {
      default: '契約情報の更新に失敗しました。',
      [Locale.en_US]: 'Failed to renew contract.',
    },
  },
  [ErrorDictKey.faieldToModifyContract]: {
    default: {
      default: '契約情報の編集に失敗しました。',
      [Locale.en_US]: 'Failed to modify contract.',
    },
  },
  [ErrorDictKey.failedToRegisterNotificationSettings]: {
    default: {
      default: '通知先メールアドレス設定の登録に失敗しました。',
      [Locale.en_US]: 'Failed to register notification settings.',
    },
  },
  [ErrorDictKey.failedToGetNotificationSettings]: {
    default: {
      default: '通知先メールアドレス設定の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get notification settings.',
    },
  },
  [ErrorDictKey.failedToDeleteReception]: {
    default: {
      default: '受付データの削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete reception data.',
    },
  },
  [ErrorDictKey.failedToDeleteCustomer]: {
    default: {
      default: '顧客データの削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete customer data.',
    },
  },
  [ErrorDictKey.failedToDeleteKeyAndTicket]: {
    default: {
      default: 'カギ・チケットの削除に失敗しました。',
      [Locale.en_US]: 'Failed to delete keys and tickets.',
    },
  },
  [ErrorDictKey.failedToRegisterKeyIssueSettings]: {
    default: {
      default: 'カギ発行設定の登録に失敗しました。',
      [Locale.en_US]: 'Failed to register key issue settings.',
    },
  },
  [ErrorDictKey.failedToGetKeyIssueSettings]: {
    default: {
      default: 'カギ発行設定の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get key issue settings.',
    },
  },
  [ErrorDictKey.failedToRegisterLinkageSettings]: {
    default: {
      default: '連携設定の登録に失敗しました。',
      [Locale.en_US]: 'Failed to register linkage settings.',
    },
  },
  [ErrorDictKey.failedToLinkToSlack]: {
    default: {
      default: 'Slackとの連携に失敗しました。',
      [Locale.en_US]: 'Failed to link Slack.',
    },
  },
  [ErrorDictKey.failedToUnlinkToSlack]: {
    default: {
      default: 'Slackとの連携解除に失敗しました。',
      [Locale.en_US]: 'Failed to link Slack.',
    },
  },
  [ErrorDictKey.failedToGetLinkageSettings]: {
    default: {
      default: '連携設定の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get linkage settings.',
    },
  },
  [ErrorDictKey.failedToPublishUrlToOccupant]: {
    default: {
      default: '入居者への申込みURL送信に失敗しました',
      [Locale.en_US]: 'Failed to publish url to occupant.',
    },
  },
  [ErrorDictKey.failedToInsertReception]: {
    default: {
      default: '予定の登録に失敗しました。',
      [Locale.en_US]: 'Failed to insert schedule.',
    },
  },
  [ErrorDictKey.failedToUpdateReception]: {
    default: {
      default: '予定の更新に失敗しました。',
      [Locale.en_US]: 'Failed to update schedule.',
    },
  },
  [ErrorDictKey.failedToInsertReceptionSettings]: {
    default: {
      default: '設定の更新に失敗しました。',
      [Locale.en_US]: 'Failed to inserrt reception settings.',
    },
  },
  [ErrorDictKey.failedToGetReceptionSettings]: {
    default: {
      default: '設定の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get reception settings.',
    },
  },
  [ErrorDictKey.failedToInsertSlackTargetIds]: {
    default: {
      default: 'Slack設定の登録に失敗しました。',
      [Locale.en_US]: 'Failed to register slack settings.',
    },
  },
  [ErrorDictKey.failedToInsertLineWorksTargetIds]: {
    default: {
      default: 'LINE WORKSの設定に失敗しました。',
      [Locale.en_US]: 'Failed to register line works settings.',
    },
  },
  [ErrorDictKey.failedToInsertChatWorksTargetIds]: {
    default: {
      default: 'Chatworkの設定に失敗しました。',
      [Locale.en_US]: 'Failed to register chat works settings.',
    },
  },
  [ErrorDictKey.failedToInsertSmsTargetIds]: {
    default: {
      default: 'SMSの設定に失敗しました。',
      [Locale.en_US]: 'Failed to register sms settings.',
    },
  },
  [ErrorDictKey.failedToGetSlackTargetIds]: {
    default: {
      default: 'Slack設定の取得に失敗しました。',
      [Locale.en_US]: 'Failed to get slack settings.',
    },
  },
  [ErrorDictKey.failedToInsertFacetimeIds]: {
    default: {
      default: 'FacetimeId設定の登録に失敗しました。',
      [Locale.en_US]: 'Failed to register facetime id settings.',
    },
  },
  [ErrorDictKey.failedToGetReceptionData]: {
    default: {
      default: '受付データの取得に失敗しました。',
      [Locale.en_US]: 'Failed to get reception data.',
    },
  },
  [ErrorDictKey.failedToRegisterUserAuthority]: {
    default: {
      default: '権限の登録に失敗しました',
      [Locale.en_US]: 'Failed to register user authority.',
    },
  },
  [ErrorDictKey.failedToGetUserAuthorities]: {
    default: {
      default: '権限の取得に失敗しました',
      [Locale.en_US]: 'Failed to get user authority.',
    },
  },
  [ErrorDictKey.failedToUpdateUserAuthority]: {
    default: {
      default: '権限の更新に失敗しました',
      [Locale.en_US]: 'Failed to update user authority.',
    },
  },
  [ErrorDictKey.failedToDeleteUserAuthority]: {
    default: {
      default: '権限の削除に失敗しました',
      [Locale.en_US]: 'Failed to delete user authority.',
    },
  },
  [ErrorDictKey.failedToDeleteUserAuthorityCurrentlyUsed]: {
    default: {
      default: '対象の権限が担当者に付与されているため削除できませんでした。',
      [Locale.en_US]: 'Failed to delete because the authority is currently used',
    },
  },
  [ErrorDictKey.alreadyExistEmail]: {
    default: {
      default: 'このメールアドレスは既に利用されています',
      [Locale.en_US]: 'Already registered this email address.',
    },
  },
  [ErrorDictKey.alreadyExistPhoneNumber]: {
    default: {
      default: 'この電話番号は既に利用されています',
      [Locale.en_US]: 'Already registered this phone number.',
    },
  },
  [ErrorDictKey.inputRequiredFields]: {
    default: {
      default: '必須項目が入力されていません。',
      [Locale.en_US]: `Please input required fields.
      (targetOrganizationId,targetSpaceId,usageStart,usageEnd,keyType,participants)`,
    },
  },
  [ErrorDictKey.noTargetOrganization]: {
    default: {
      default: '指定の組織は存在しません。',
      [Locale.en_US]: 'Target Organization does not exist.',
    },
  },
  [ErrorDictKey.noTargetSpace]: {
    default: {
      default: '指定の物件が存在しません。',
      [Locale.en_US]: 'Space does not exist !',
    },
  },
  [ErrorDictKey.notReservable]: {
    default: {
      default: 'この時間は予約できません。',
      [Locale.en_US]: 'Space is not reservable.',
    },
  },
  [ErrorDictKey.minimumReservationTimeRequired]: {
    default: {
      default: '予約時間は(minimum reservation time)分以上に指定してください。',
      [Locale.en_US]: 'Time does not meet the reservation time constraints for the space.',
    },
  },
  [ErrorDictKey.unReservableTime]: {
    default: {
      default: '予約可能時間内である必要があります。',
      [Locale.en_US]: 'Reservable time should be within space reservable time.',
    },
  },
  [ErrorDictKey.exceedMaxCapacity]: {
    default: {
      default: `参加可能人数を超えました。
      社内参加者とゲストは合わせて(max capacity)人以下になるよう設定してください。`,
      [Locale.en_US]: 'Max Capacity of the space exceeded.',
    },
  },
  [ErrorDictKey.spaceNotAvailable]: {
    default: {
      default: 'この時間はすでに予約されています。',
      [Locale.en_US]: 'This space is already booked for the particular time !',
    },
  },
  [ErrorDictKey.requiredHostParticipant]: {
    default: {
      default: '主催者の詳細の入力が必要です。',
      [Locale.en_US]: 'Host details required to proceed with linkReception !',
    },
  },
  [ErrorDictKey.participantEmpty]: {
    default: {
      default: '参加者が追加されていません。',
      [Locale.en_US]: "Participant can't be empty",
    },
  },
  [ErrorDictKey.targetReservationNotFound]: {
    default: {
      default: '指定の予約は存在しません。',
      [Locale.en_US]: 'Target Reservation does not exist.',
    },
  },
  [ErrorDictKey.noAuthorityToUpdate]: {
    default: {
      default: '変更権限がありません。',
      [Locale.en_US]: "You don't have authority to update",
    },
  },
  [ErrorDictKey.invalidChangeOption]: {
    default: {
      default: '入力項目が不正です。',
      [Locale.en_US]: 'Invalid changeOptions.',
    },
  },
  [ErrorDictKey.noChangeReservationData]: {
    default: {
      default: '予約データに変更がありません。',
      [Locale.en_US]: 'No changes in reservation data to update !',
    },
  },
  [ErrorDictKey.pastDateCannotUpdate]: {
    default: {
      default: '過去の日時は更新できません。',
      [Locale.en_US]: 'Past date cannot be updated.',
    },
  },
  [ErrorDictKey.pastDateCannotDelete]: {
    default: {
      default: '過去の日時は更新できません。',
      [Locale.en_US]: 'Past date cannot be deleted.',
    },
  },
  [ErrorDictKey.noAuthorityToDelete]: {
    default: {
      default: '削除権限がありません。',
      [Locale.en_US]: "You don't have authority to delete.",
    },
  },
  [ErrorDictKey.pastDateCannotInsert]: {
    default: {
      default: '過去の日付は挿入できません。',
      [Locale.en_US]: 'Past date cannot be inserted.',
    },
  },
  [ErrorDictKey.reservationInternalError]: {
    default: {
      default: '予約登録に失敗しました。再度お試しください。',
      [Locale.en_US]: 'Failed to insert Reservation.',
    },
  },
  [ErrorDictKey.reservationInternalInsertError]: {
    default: {
      default: '予約登録に失敗しました。再度お試しください。',
      [Locale.en_US]: 'Failed to insert Reservation.',
    },
  },
  [ErrorDictKey.reservationInternalUpdateError]: {
    default: {
      default: '予約更新に失敗しました。再度お試しください。',
      [Locale.en_US]: 'Failed to update Reservation.',
    },
  },
  [ErrorDictKey.reservationInternalDeleteError]: {
    default: {
      default: '予約削除に失敗しました。再度お試しください。',
      [Locale.en_US]: 'Failed to delete Reservation.',
    },
  },
  [ErrorDictKey.reservationInternalApproveError]: {
    default: {
      default: '予約申請の承認に失敗しました。再度お試しください。',
      [Locale.en_US]: 'Failed to approve Reservation.',
    },
  },
  [ErrorDictKey.reservationInternalRejectError]: {
    default: {
      default: '予約申請の却下に失敗しました。再度お試しください。',
      [Locale.en_US]: 'Failed to reject Reservation.',
    },
  },
  [ErrorDictKey.insertLinkReceptionFailed]: {
    default: {
      default: '受付の登録に失敗しました。',
      [Locale.en_US]: 'Insert link reception failed.',
    },
  },
  [ErrorDictKey.updateLinkReceptionFailed]: {
    default: {
      default: '受付の更新に失敗しました。',
      [Locale.en_US]: 'Update link reception failed.',
    },
  },
  [ErrorDictKey.noBitlockDevice]: {
    default: {
      default: '指定の空間にはbitlockが設置されていません。',
      [Locale.en_US]: 'Space does not have bitlock device!',
    },
  },
  [ErrorDictKey.invalidParticipant]: {
    default: {
      default: '予約の参加者情報に誤りがあります。',
      [Locale.en_US]: 'Participant who are added in the reservation are not valid !',
    },
  },
  [ErrorDictKey.noGroupSpaceAllowed]: {
    default: {
      default: '空間グループが指定されています。',
      [Locale.en_US]: 'Group is selected instead of space.',
    },
  },
  [ErrorDictKey.unReservableDayOfWeek]: {
    default: {
      default: '指定の日時は予約不可の時間帯です。',
      [Locale.en_US]: 'Reservation day is not matched in space reservable day of week.',
    },
  },
  [ErrorDictKey.incorrectUsageConditionTime]: {
    default: {
      default: '終了日時は開始日時以降の日時を指定してください。',
      [Locale.en_US]: 'End Time should not be lesser than Start Time !',
    },
  },
  [ErrorDictKey.serviceAccountNotFound]: {
    default: {
      default: 'サービスアカウントが存在しません。',
      [Locale.en_US]: 'Approval service account data not found.',
    },
  },
  [ErrorDictKey.participantNotBelongsToReservation]: {
    default: {
      default: '指定の参加者は予約に登録されていません。',
      [Locale.en_US]: "This PersonaId doesn't a participant of this reservation.",
    },
  },
  [ErrorDictKey.approveFailed]: {
    default: {
      default: '予約申請の承認に失敗しました。再度お試しください。',
      [Locale.en_US]: 'Reservation data approval unsuccessful !',
    },
  },
  [ErrorDictKey.rejectFailed]: {
    default: {
      default: '予約申請の却下に失敗しました。再度お試しください。',
      [Locale.en_US]: 'Reservation reject failed !',
    },
  },
  [ErrorDictKey.alreadyApproved]: {
    default: {
      default: '指定の予約は既に承認済みです。',
      [Locale.en_US]: 'Reservation data already approved !',
    },
  },
  [ErrorDictKey.noAuthorityApproveOrReject]: {
    default: {
      default: '指定の予約を承認する権限がありません。',
      [Locale.en_US]: "You don't have permission to access this reservation.",
    },
  },
  [ErrorDictKey.failedToGetPrivateViewSitedomain]: {
    default: {
      default: '内見予約サイトのdomainの取得に失敗しました。',
      [Locale.en_US]: 'Failed to Get domain of the private view site.',
    },
  },
};

export default class ErrorDictionary {
  private static dict = new DictionaryCommon(ErrorDictionaryDefinition);

  public static getWord = (dictKey: ErrorDictKey) => {
    const dictionary = Redux.getStore().dictionary;
    return ErrorDictionary.dict.getDictValue(dictKey, dictionary.businessType, dictionary.locale);
  };
}
