import {
  DeviceLogActionSource,
  DeviceLogActionType,
} from '../firebase/firestore/references/devices/logs/firestore-device-logs';
import DictionaryCommon, {Dict, DictionaryDefinition, Locale} from './dictionary-common';
import {
  SumamoruPlan,
  SumamoruPlanMap,
  SumamoruPlanMapEn,
} from '../business-types/residence/organizations/add-ons/SumamoruPlan';
import {useEffect, useMemo, useState} from 'react';

import {BleDeviceType} from '../firebase/firestore/references/devices/firestore-devices';
import {BusinessType} from '../domain-base/business-types';
import {DictionaryState} from '../redux/dictionary';
import {PersonType} from '../firebase/firestore/references/organizations/space-types/firestore-spaceTypes';
import {Store} from '../redux/store';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {CentralCode, RecordType} from '../firebase/functions/api-types/organizations/devices/lockDevices/logs';
import {BitlinkStatus} from '../firebase/firestore/references/organizations/things/firestore-organization-things';
import {EpochMillis} from '@bitkey-service/functions-common-types/lib/common/commonTypes';
import {DateUtils} from '../basic-utils/date-utils';
import {HHEncoding} from '../common-types/encoding';

const commonDictDef = {
  get: {
    default: {
      default: (dict: {jp?: string; en?: string} | undefined, defaultValue?: string) => dict?.jp ?? defaultValue,
      [Locale.en_US]: (
        dict:
          | {
              jp?: string;
              en?: string;
            }
          | undefined,
        defaultValue?: string,
      ) => dict?.en ?? defaultValue,
    },
  },
  items: {
    default: {
      default: count => `${count}件`,
      [Locale.en_US]: count => `${count} items`,
    },
  },
  type: {
    default: {
      default: '種別',
      [Locale.en_US]: 'Type',
    },
  },
  branch: {
    default: {
      default: '支店',
      [Locale.en_US]: 'Branch',
    },
  },
  branchGroup: {
    default: {
      default: '支店グループ',
      [Locale.en_US]: 'Branch group',
    },
  },
  building: {
    default: {
      default: '建物',
      [Locale.en_US]: 'Building',
    },
  },
  facility: {
    default: {
      default: '施設',
      [Locale.en_US]: 'Facility',
    },
  },
  facilityName: {
    default: {
      default: '施設名',
      [Locale.en_US]: 'Facility name',
    },
  },
  space: {
    default: {
      default: '設置場所',
      [Locale.en_US]: 'Installation place',
    },
  },
  search: {
    default: {
      default: '検索',
      [Locale.en_US]: 'Search',
    },
  },
  searching: {
    default: {
      default: '検索中...',
      [Locale.en_US]: 'Searching...',
    },
  },
  clearSelection: {
    default: {
      default: '選択を解除',
      [Locale.en_US]: 'Clear selection',
    },
  },
  reset: {
    default: {
      default: 'リセット',
      [Locale.en_US]: 'Reset',
    },
  },
  clear: {
    default: {
      default: 'クリア',
      [Locale.en_US]: 'Clear',
    },
  },
  seeAll: {
    default: {
      default: 'すべて見る',
      [Locale.en_US]: 'See all',
    },
  },
  showMore: {
    default: {
      default: 'さらに表示',
      [Locale.en_US]: 'Show more',
    },
  },
  spaceName: {
    default: {
      default: '設置場所名',
      [Locale.en_US]: 'Installation place name',
    },
  },
  spaceGroup: {
    default: {
      default: '空間グループ',
      [Locale.en_US]: 'Space Group',
    },
  },
  bitlock: {
    default: {
      default: 'bitlock',
      [Locale.en_US]: 'bitlock',
    },
  },
  bitlockName: {
    default: {
      default: 'bitlock名',
      [Locale.en_US]: 'bitlock名',
    },
  },
  wifiConnector: {
    default: {
      default: 'Wi-Fiコネクタ',
      [Locale.en_US]: 'Wi-Fi connector',
    },
  },
  keyBundle: {
    default: {
      default: 'カギ束',
      [Locale.en_US]: 'Key Bundle',
    },
  },
  bitreader: {
    default: {
      default: 'bitreader',
      [Locale.en_US]: 'bitreader',
    },
  },
  bitreaderPlus: {
    default: {
      default: 'bitreader+',
      [Locale.en_US]: 'bitreader+',
    },
  },
  nfcCard: {
    default: {
      default: 'NFCカード',
      [Locale.en_US]: 'NFC card',
    },
  },
  nfcCardIDm: {
    default: {
      default: 'IDm',
      [Locale.en_US]: 'IDm',
    },
  },
  nameOf: {
    default: {
      default: text => `${text}名`,
      [Locale.en_US]: (text: string) => `${text} name`,
    },
  },
  officialNameOf: {
    default: {
      default: text => `${text}の名称`,
      [Locale.en_US]: (text: string) => `${text} Name`,
    },
  },
  registerOf: {
    default: {
      default: text => `${text}の登録`,
      [Locale.en_US]: (text: string) => `Register ${text}`,
    },
  },
  editOf: {
    default: {
      default: text => `${text}の編集`,
      [Locale.en_US]: (text: string) => `Edit ${text}`,
    },
  },
  updateOf: {
    default: {
      default: text => `${text}の更新`,
      [Locale.en_US]: (text: string) => `Update ${text}`,
    },
  },
  deleteOf: {
    default: {
      default: text => `${text}の削除`,
      [Locale.en_US]: (text: string) => `Delete ${text}`,
    },
  },
  deleteMessageOf: {
    default: {
      default: text => `${text}を削除します。`,
      [Locale.en_US]: (text: string) => `Delete the ${text}`,
    },
  },
  editMessageOf: {
    default: {
      default: text => `${text}を編集します。`,
      [Locale.en_US]: (text: string) => `Edit the ${text}`,
    },
  },
  updateMessageOf: {
    default: {
      default: text => `${text}を更新します。`,
      [Locale.en_US]: (text: string) => `Update the ${text}`,
    },
  },
  updateThisMessageOf: {
    default: {
      default: text => `該当の${text}を更新します。`,
      [Locale.en_US]: (text: string) => `Update this ${text}`,
    },
  },
  registerMessageOf: {
    default: {
      default: text => `${text}を登録します。`,
      [Locale.en_US]: (text: string) => `Register the ${text}`,
    },
  },
  codeOf: {
    default: {
      default: (text?: string) => `${text}コード`,
      [Locale.en_US]: (text?: string) => (text ? `${text} Code` : 'Code'),
    },
  },
  managementCode: {
    default: {
      default: '管理番号',
      [Locale.en_US]: 'Management Id',
    },
  },
  listOf: {
    default: {
      default: text => `${text}一覧`,
      [Locale.en_US]: (text: string) => `${text} List`,
    },
  },
  property: {
    default: {
      default: '物件',
      [Locale.en_US]: 'Property',
    },
  },
  spaceStatus_vacant: {
    default: {
      default: '空室',
      [Locale.en_US]: 'Vacant',
    },
    [BusinessType.RESIDENCE]: {
      default: '公開中',
      [Locale.en_US]: 'Vacant',
    },
  },
  spaceStatus_occupyScheduled: {
    default: {
      default: '入居予定',
      [Locale.en_US]: 'Occupy Scheduled',
    },
  },
  spaceStatus_occupied: {
    default: {
      default: '入居中',
      [Locale.en_US]: 'Occupied',
    },
  },
  spaceStatus_leaveScheduled: {
    default: {
      default: '退去予定',
      [Locale.en_US]: 'Leave Scheduled',
    },
  },
  spaceStatus_leaveProcessing: {
    default: {
      default: '退去処理中',
      [Locale.en_US]: 'Leave Processing',
    },
  },
  spaceStatus_applied: {
    default: {
      default: '申込有',
      [Locale.en_US]: 'Applied',
    },
  },
  spaceStatus_closed: {
    default: {
      default: '停止中',
      [Locale.en_US]: 'Closed',
    },
  },
  occupancyStatus_alreadyLeaved: {
    default: {
      default: '退去済',
      [Locale.en_US]: 'Already Leaved',
    },
  },
  occupancyStatus_noContract: {
    default: {
      default: '契約なし',
      [Locale.en_US]: 'No contract',
    },
  },
  noStatus: {
    default: {
      default: 'ステータスなし',
      [Locale.en_US]: 'No status',
    },
  },
  chargeCycle: {
    default: {
      default: '決済サイクル',
      [Locale.en_US]: 'Settlement Cycle',
    },
  },
  contractStatus: {
    default: {
      default: '契約状況',
      [Locale.en_US]: 'Contract Status',
    },
  },
  monthlyCycle: {
    default: {
      default: '月次',
      [Locale.en_US]: 'Monthly',
    },
  },
  annualCycle: {
    default: {
      default: '年次',
      [Locale.en_US]: 'Annual',
    },
  },
  oneshotCycle: {
    default: {
      default: 'ワンショット',
      [Locale.en_US]: 'Oneshot',
    },
  },
  serialNo: {
    default: {
      default: 'シリアルNo.',
      [Locale.en_US]: 'Serial No.',
    },
  },
  version: {
    default: {
      default: 'バージョン',
      [Locale.en_US]: 'Version',
    },
  },
  battery: {
    default: {
      default: 'バッテリー',
      [Locale.en_US]: 'Battery',
    },
  },
  registeredSuccessfully: {
    default: {
      default: '登録に成功しました。',
      [Locale.en_US]: 'Registered successfully.',
    },
  },
  invitedSuccessfully: {
    default: {
      default: '招待に成功しました。',
      [Locale.en_US]: 'Invited successfully.',
    },
  },
  failureDataExists: {
    default: {
      default: '失敗したデータがあります。',
      [Locale.en_US]: 'Failure data exists.',
    },
  },
  updatedSuccessfully: {
    default: {
      default: '更新に成功しました。',
      [Locale.en_US]: 'Updated successfully.',
    },
  },
  deletedSuccessfully: {
    default: {
      default: '削除しました。',
      [Locale.en_US]: 'Deleted successfully.',
    },
  },
  failedToDelete: {
    default: {
      default: '削除できませんでした。',
      [Locale.en_US]: 'Failed to delete.',
    },
  },
  warnForDelete: {
    default: {
      default: 'この操作はもとに戻すことができません。',
      [Locale.en_US]: 'This operation cannot be undone.',
    },
  },
  logs: {
    default: {
      default: 'ログ',
      [Locale.en_US]: 'Logs',
    },
  },
  user: {
    default: {
      default: '利用者',
      [Locale.en_US]: 'User',
    },
  },
  userName: {
    default: {
      default: '利用者名',
      [Locale.en_US]: 'User name',
    },
  },
  member: {
    default: {
      default: 'メンバー',
      [Locale.en_US]: 'Members',
    },
    [BusinessType.RESIDENCE]: {
      default: '担当者',
      [Locale.en_US]: 'Person In Change',
    },
  },
  faceImageAuth: {
    default: {
      default: '顔認証',
      [Locale.en_US]: 'Face Image Auth',
    },
  },
  personType: {
    default: {
      default: (personType: PersonType) => {
        switch (personType) {
          case PersonType.cast:
            return '業者';
          case PersonType.guest_customer_member:
            return '顧客';
          case PersonType.guest_customer_representative:
            return '顧客代表';
          case PersonType.guest_temporary:
            return 'ゲスト';
          case PersonType.member:
            return 'メンバー';
          default:
            return '-';
        }
      },
      [Locale.en_US]: (personType: PersonType) => {
        switch (personType) {
          case PersonType.cast:
            return 'Cast';
          case PersonType.guest_customer_member:
            return 'Customer Member';
          case PersonType.guest_customer_representative:
            return 'Customer Representative';
          case PersonType.guest_temporary:
            return 'Guest';
          case PersonType.member:
            return 'Member';
          default:
            return '-';
        }
      },
    },
    [BusinessType.RESIDENCE]: {
      default: (personType: PersonType) => {
        switch (personType) {
          case PersonType.cast:
            return '業者';
          case PersonType.guest_customer_member:
            return '同居者';
          case PersonType.guest_customer_representative:
            return '契約者';
          case PersonType.guest_temporary:
            return 'ゲスト';
          case PersonType.member:
            return '担当者';
          default:
            return '-';
        }
      },
      [Locale.en_US]: (personType: PersonType) => {
        switch (personType) {
          case PersonType.cast:
            return 'Cast';
          case PersonType.guest_customer_member:
            return 'Customer Member';
          case PersonType.guest_customer_representative:
            return 'Customer Representative';
          case PersonType.guest_temporary:
            return 'Guest';
          case PersonType.member:
            return 'Person In Charge';
          default:
            return '-';
        }
      },
    },
  },
  officialName: {
    default: {
      default: '名称',
      [Locale.en_US]: 'Name',
    },
  },
  id: {
    default: {
      default: 'ID',
      [Locale.en_US]: 'ID',
    },
  },
  name: {
    default: {
      default: '名前',
      [Locale.en_US]: 'Name',
    },
  },
  fullName: {
    default: {
      default: '氏名',
      [Locale.en_US]: 'Fullname',
    },
  },
  emailAddress: {
    default: {
      default: 'メールアドレス',
      [Locale.en_US]: 'Email',
    },
  },
  phoneNumber: {
    default: {
      default: '電話番号',
      [Locale.en_US]: 'Phone Number',
    },
  },
  representativeName: {
    default: {
      default: '代表者氏名',
      [Locale.en_US]: 'epresentative Name',
    },
  },
  status: {
    default: {
      default: 'ステータス',
      [Locale.en_US]: 'Status',
    },
  },
  register: {
    default: {
      default: '登録する',
      [Locale.en_US]: 'Register',
    },
  },
  // TODO: ↑と↓統合したい
  registration: {
    default: {
      default: '登録',
      [Locale.en_US]: 'Register',
    },
  },
  issue: {
    default: {
      default: '発行',
      [Locale.en_US]: 'Issue',
    },
  },
  noData: {
    default: {
      default: '-',
      [Locale.en_US]: '-',
    },
  },
  openAndClose: {
    default: {
      default: '解錠/施錠',
      [Locale.en_US]: 'Open/Close',
    },
  },
  ok: {
    default: {
      default: 'OK',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel',
    },
  },
  edit: {
    default: {
      default: '編集',
      [Locale.en_US]: 'Edit',
    },
  },
  editText: {
    default: {
      default: '編集する',
      [Locale.en_US]: 'Edit',
    },
  },
  doEdit: {
    default: {
      default: 'データを編集する',
      [Locale.en_US]: 'Edit data',
    },
  },
  update: {
    default: {
      default: '更新',
      [Locale.en_US]: 'Update',
    },
  },
  updateText: {
    default: {
      default: '更新する',
      [Locale.en_US]: 'Update',
    },
  },
  close: {
    default: {
      default: '閉じる',
      [Locale.en_US]: 'Close',
    },
  },
  add: {
    default: {
      default: '追加する',
      [Locale.en_US]: 'Add',
    },
  },
  addNew: {
    default: {
      default: '新規追加',
      [Locale.en_US]: 'Add New',
    },
  },
  deliveryAdd: {
    default: {
      default: '登録・配信する',
      [Locale.en_US]: 'Add',
    },
  },
  delete: {
    default: {
      default: '削除する',
      [Locale.en_US]: 'Delete',
    },
  },
  or: {
    default: {
      default: 'または',
      [Locale.en_US]: 'or',
    },
  },
  selectFile: {
    default: {
      default: 'ファイルを選択',
      [Locale.en_US]: 'Select your file',
    },
  },
  preview: {
    default: {
      default: 'プレビュー',
      [Locale.en_US]: 'Preview',
    },
  },
  role: {
    default: {
      default: '役割',
      [Locale.en_US]: 'Role',
    },
  },
  jobTitle: {
    default: {
      default: '役職',
      [Locale.en_US]: 'JobTitle',
    },
  },
  results: {
    default: {
      default: '結果',
      [Locale.en_US]: 'Results',
    },
  },
  csvTemplate: {
    default: {
      default: 'CSVテンプレート',
      [Locale.en_US]: 'CSV Template',
    },
  },
  csvDownload: {
    default: {
      default: 'CSVダウンロード',
      [Locale.en_US]: 'CSV Download',
    },
  },
  csvKeySend: {
    default: {
      default: 'CSVカギ発行',
      [Locale.en_US]: 'CSV Key Send',
    },
  },
  csvTicketSend: {
    default: {
      default: 'CSVチケット発行',
      [Locale.en_US]: 'CSV Ticket Send',
    },
  },
  csvKeyDelete: {
    default: {
      default: 'CSVカギ削除',
      [Locale.en_US]: 'CSV Key Delete',
    },
  },
  csvTicketDelete: {
    default: {
      default: 'CSVチケット削除',
      [Locale.en_US]: 'CSV Ticket Delete',
    },
  },
  // 登録済み、みたいな表現があるからこういうのcommonにするかどうかほんと悩む。登録済み、のほうはstatus_をprefixにするとか？
  alreadyRegistered: {
    default: {
      default: '既に登録されています。',
      [Locale.en_US]: 'Already registered.',
    },
  },
  unknownErrorOccurred: {
    default: {
      default: '不明なエラーが発生しました。',
      [Locale.en_US]: 'Unknown error occurred.',
    },
  },
  emailIsInvalid: {
    default: {
      default: '無効なメールアドレスです。',
      [Locale.en_US]: 'Email is invalid.',
    },
  },
  roleIsInvalid: {
    default: {
      default: '無効な役割です。',
      [Locale.en_US]: 'Role is invalid.',
    },
  },
  bitkeyAccountNotRegistered: {
    default: {
      default: 'bitkeyアカウントが登録されていません。',
      [Locale.en_US]: 'bitkey account not registered.',
    },
  },
  failedToSendInvitationEmail: {
    default: {
      default: '招待メールの送信に失敗しました。',
      [Locale.en_US]: 'Failed to send invitation email.',
    },
  },
  issueDate: {
    default: {
      default: '発行日',
      [Locale.en_US]: 'Issue date',
    },
  },
  usablePeriod: {
    default: {
      default: '利用期間:',
      [Locale.en_US]: 'Usable Period:',
    },
  },
  usableTimes: {
    default: {
      default: '利用可能回数:',
      [Locale.en_US]: 'Usable Times:',
    },
  },
  action: {
    default: {
      default: 'アクション',
      [Locale.en_US]: 'Action',
    },
  },
  datetime: {
    default: {
      default: '日時',
      [Locale.en_US]: 'Datetime',
    },
  },
  usageCondition: {
    default: {
      default: '利用条件',
      [Locale.en_US]: 'Usage Condition',
    },
  },
  usagePeriod: {
    default: {
      default: '利用期間',
      [Locale.en_US]: 'Usage Period',
    },
  },
  repeatCondition: {
    default: {
      default: '繰り返し条件',
      [Locale.en_US]: 'Repeat Condition',
    },
  },
  period: {
    default: {
      default: '期間',
      [Locale.en_US]: 'period',
    },
  },
  weekday: {
    default: {
      default: '曜日',
      [Locale.en_US]: 'weekday',
    },
  },
  message: {
    default: {
      default: 'メッセージ',
      [Locale.en_US]: 'Message',
    },
  },
  sender: {
    default: {
      default: '送信者',
      [Locale.en_US]: 'Sender',
    },
  },
  administrator: {
    default: {
      default: '管理者',
      [Locale.en_US]: 'Administrator',
    },
  },
  send: {
    default: {
      default: '送信',
      [Locale.en_US]: 'Send',
    },
  },
  // 言語上の揺れがあるやつはこういう感じでprefixつけたcommonDictのkeyで統一するのがいいかも？
  role_normal: {
    default: {
      default: '一般メンバー',
      [Locale.en_US]: 'Normal member',
    },
  },
  role_administrator: {
    default: {
      default: '管理画面利用者',
      [Locale.en_US]: 'Administrator',
    },
  },
  role_installation: {
    default: {
      default: '設置担当者',
      [Locale.en_US]: 'Installation staff',
    },
  },
  role_reception: {
    default: {
      default: '受付システム利用者',
      [Locale.en_US]: 'Reception staff',
    },
  },
  role_serviceCast: {
    default: {
      default: 'サービスキャスト',
      [Locale.en_US]: 'Service Cast',
    },
  },
  unselected: {
    default: {
      default: '未選択',
      [Locale.en_US]: 'Unselected',
    },
  },
  structure_RC: {
    default: {
      default: '鉄筋コンクリート造',
      [Locale.en_US]: 'Reinforced concrete',
    },
  },
  structure_H_S: {
    default: {
      default: '重量鉄骨造',
      [Locale.en_US]: 'Heavy steel structure',
    },
  },
  structure_L_S: {
    default: {
      default: '軽量鉄骨造',
      [Locale.en_US]: 'Light steel structure',
    },
  },
  structure_W: {
    default: {
      default: '木造',
      [Locale.en_US]: 'Wooden',
    },
  },
  structure_SRC: {
    default: {
      default: '鉄骨鉄筋コンクリート造',
      [Locale.en_US]: 'Steel-framed reinforced concrete',
    },
  },
  structure_CFT: {
    default: {
      default: 'コンクリート充填鋼管構造',
      [Locale.en_US]: 'Concrete filled steel pipe structure',
    },
  },
  structure_AL: {
    default: {
      default: 'アルミ造',
      [Locale.en_US]: 'Aluminum construction',
    },
  },
  structure_CB: {
    default: {
      default: 'コンクリートブロック造',
      [Locale.en_US]: 'Concrete block construction',
    },
  },
  determine: {
    default: {
      default: '確定する',
      [Locale.en_US]: 'Determine',
    },
  },
  unsettled: {
    default: {
      default: '未確定',
      [Locale.en_US]: 'Unsettled',
    },
  },
  key: {
    default: {
      default: 'カギ',
      [Locale.en_US]: 'Key',
    },
  },
  ticket: {
    default: {
      default: 'チケット',
      [Locale.en_US]: 'Ticket',
    },
  },
  linkedDevices: {
    default: {
      default: '連携デバイス',
      [Locale.en_US]: 'Linked Devices',
    },
  },
  deviceList: {
    default: {
      default: 'デバイス一覧',
      [Locale.en_US]: 'Device List',
    },
  },
  alive: {
    default: {
      default: '待機中',
      [Locale.en_US]: 'Alive',
    },
  },
  dead: {
    default: {
      default: '停止中',
      [Locale.en_US]: 'Dead',
    },
  },
  device: {
    default: {
      default: 'デバイス',
      [Locale.en_US]: 'Device',
    },
  },
  deviceSetting: {
    default: {
      default: 'bitlock本体設定',
      [Locale.en_US]: 'Change bitlock setting',
    },
  },
  viewLogAndReceivers: {
    default: {
      default: 'ログとカギ・チケット保有者参照',
      [Locale.en_US]: 'View logs and receivers',
    },
  },
  shareTickets: {
    default: {
      default: 'チケットシェア',
      [Locale.en_US]: 'Share tickets',
    },
  },
  registerSmartKeys: {
    default: {
      default: 'bitbutton登録',
      [Locale.en_US]: 'Register bitbutton',
    },
  },
  useWifiConnector: {
    default: {
      default: '遠隔施錠/解錠',
      [Locale.en_US]: 'Use remote lock/unlock',
    },
  },
  sendKeyOrTicket: {
    default: {
      default: 'カギ・チケットを渡す',
      [Locale.en_US]: 'Send key or ticket',
    },
  },
  createSpareKey: {
    default: {
      default: '合カギの発行',
      [Locale.en_US]: 'Issuing a duplicate key',
    },
  },
  lendAuthDevice: {
    default: {
      default: 'NFCカード/パスコード設定権限',
      [Locale.en_US]: 'Use Authentication Devices',
    },
  },
  NfcCardSetting: {
    default: {
      default: 'NFCカード設定権限',
      [Locale.en_US]: 'Set NFC card',
    },
  },
  FixedPasscodeSetting: {
    default: {
      default: 'パスコード設定権限',
      [Locale.en_US]: 'Set passcode',
    },
  },
  OnetimePasscodeSetting: {
    default: {
      default: 'パスコード設定権限',
      [Locale.en_US]: 'Set passcode',
    },
  },
  TotpSetting: {
    default: {
      default: 'TOTP参照権限',
      [Locale.en_US]: 'Refer onetime passcode',
    },
  },
  DeviceSetting: {
    default: {
      default: 'デバイス設定権限',
      [Locale.en_US]: 'Set device config',
    },
  },
  SpareSetting: {
    default: {
      default: '貸出権限',
      [Locale.en_US]: 'Set lending',
    },
  },
  PasscodeSetting: {
    default: {
      default: 'パスコード設定権限',
      [Locale.en_US]: 'Set passcode',
    },
  },
  contractTerm: {
    default: {
      default: '契約期間',
      [Locale.en_US]: 'Contract term',
    },
  },
  contractStartDate: {
    default: {
      default: '契約開始日',
      [Locale.en_US]: 'Contract start date',
    },
  },
  contractEndDate: {
    default: {
      default: '契約終了日',
      [Locale.en_US]: 'Contract end date',
    },
  },
  occupyScheduledDate: {
    default: {
      default: '入居予定日',
      [Locale.en_US]: ' Move in date',
    },
  },
  yen: {
    default: {
      default: '円',
      [Locale.en_US]: 'yen',
    },
  },
  gotoDetail: {
    default: {
      default: '詳細へ',
      [Locale.en_US]: 'Goto edit',
    },
  },
  enterFullWidthCharRequired: {
    default: {
      default: '全角で入力してください',
      [Locale.en_US]: 'please enter in full-width characters.',
    },
  },
  enterFullWidthCharRequiredWithMaxLength: {
    default: {
      default: num => '全角' + num + '文字以内で入力してください',
      [Locale.en_US]: num => 'please enter in full-width characters under' + num + 'chars.',
    },
  },
  enterHalfWidthCharRequired: {
    default: {
      default: '半角で入力してください',
      [Locale.en_US]: 'please enter in half-width characters.',
    },
  },
  enterHalfWidthDigitCharRequired: {
    default: {
      default: '半角数字で入力してください',
      [Locale.en_US]: 'please enter in half-width digit characters.',
    },
  },
  condiminiumAssociation: {
    default: {
      default: '管理組合',
      [Locale.en_US]: 'Condominium Association',
    },
  },
  address: {
    default: {
      default: '住所',
      [Locale.en_US]: 'address',
    },
  },
  notFoundPostCode: {
    default: {
      default: '入力された郵便番号は存在しません',
      [Locale.en_US]: 'Not found entered post code.',
    },
  },
  notFound: {
    default: {
      default: '対象のデータが見つかりませんでした。参照する権限がないか削除された可能性があります。',
      [Locale.en_US]: 'Not found entered post code.',
    },
  },
  noMatchesFound: {
    default: {
      default: '一致する結果はありませんでした',
      [Locale.en_US]: 'No matched found',
    },
  },
  standardAdoptedBuilding: {
    default: {
      default: '標準採用建物',
      [Locale.en_US]: 'Standard adopted building',
    },
  },
  standardAdoptedBuildingOnlyElectricity: {
    default: {
      default: '標準採用建物 (電気のみ）',
      [Locale.en_US]: 'Standard adopted building only electricity',
    },
  },
  // OG域外のプランはスマモルプランと表示している
  ogStandardAdoptedBuildingOGExternal: {
    default: {
      default: 'スマモルプラン',
      [Locale.en_US]: 'Sumamoru Plan Building',
    },
  },
  standardAdoptedBuildingCDE: {
    default: {
      default: '標準採用建物 (CDE)',
      [Locale.en_US]: 'Standard adopted building',
    },
  },
  standardAdoptedBuildingCDEOnlyElectricity: {
    default: {
      default: '標準採用建物 (CDE、電気のみ)',
      [Locale.en_US]: 'Standard adopted building only electricity',
    },
  },
  standardAdoptedBuildingKeiyouGas: {
    default: {
      default: '標準採用建物 (京葉ガス)',
      [Locale.en_US]: 'Standard adopted building',
    },
  },
  standardAdoptedBuildingSaibuGas: {
    default: {
      default: '標準採用建物 (西部ガス)',
      [Locale.en_US]: 'Standard adopted building',
    },
  },
  allow: {
    default: {
      default: '許可する',
      [Locale.en_US]: 'Allow',
    },
  },
  disallow: {
    default: {
      default: '許可しない',
      [Locale.en_US]: 'Disallow',
    },
  },
  startDate: {
    default: {
      default: (prefix?: string) => (prefix ? `${prefix}（開始日）` : '開始日'),
      [Locale.en_US]: (prefix?: string) => (prefix ? `${prefix}(Start Date)` : 'Start Date'),
    },
  },
  endDate: {
    default: {
      default: (prefix?: string) => (prefix ? `${prefix}（終了日）` : '終了日'),
      [Locale.en_US]: (prefix?: string) => (prefix ? `${prefix}(End Date)` : 'End Date'),
    },
  },
  workStart: {
    default: {
      default: '作業開始日',
      [Locale.en_US]: 'Working start date',
    },
  },
  workEnd: {
    default: {
      default: '作業終了日',
      [Locale.en_US]: 'Working end date',
    },
  },
  usageStart: {
    default: {
      default: '使用開始日',
      [Locale.en_US]: 'Usage start date',
    },
  },
  usageEnd: {
    default: {
      default: '使用終了日',
      [Locale.en_US]: 'Usage end date',
    },
  },
  registrationDate: {
    default: {
      default: '登録日',
      [Locale.en_US]: 'Registration date',
    },
  },
  receiver: {
    default: {
      default: '所有者',
      [Locale.en_US]: 'Receiver',
    },
  },
  noExpirationDate: {
    default: {
      default: '制限なし',
      [Locale.en_US]: 'No expiration data',
    },
  },
  useNumberOfTimes: {
    default: {
      default: '何度でも使える',
      [Locale.en_US]: 'Can be used any number of times',
    },
  },
  noExpirationdate: {
    default: {
      default: '使用期限なし',
      [Locale.en_US]: 'No expiration data',
    },
  },
  valid: {
    default: {
      default: '有効',
      [Locale.en_US]: 'Valid',
    },
  },
  invalid: {
    default: {
      default: '無効',
      [Locale.en_US]: 'Invalid',
    },
  },
  departmentName: {
    default: {
      default: '部署',
      [Locale.en_US]: 'Department name',
    },
  },
  userTypeInfo: {
    default: {
      default: '属性',
      [Locale.en_US]: 'User Type Info',
    },
  },
  inviteStatus: {
    default: {
      default: '招待状況',
      [Locale.en_US]: 'Invite status',
    },
  },
  invite: {
    default: {
      default: '招待',
      [Locale.en_US]: 'Invite',
    },
  },
  postCode: {
    default: {
      default: '郵便番号',
      [Locale.en_US]: 'Post Code',
    },
  },
  country: {
    default: {
      default: '国',
      [Locale.en_US]: 'Country',
    },
  },
  prefecture: {
    default: {
      default: '都道府県',
      [Locale.en_US]: 'Prefecture',
    },
  },
  cityAddress: {
    default: {
      default: '市区町村',
      [Locale.en_US]: 'City',
    },
  },
  houseNumberAddress: {
    default: {
      default: '番地',
      [Locale.en_US]: 'House Number',
    },
  },
  otherAddress: {
    default: {
      default: '番地以外の住所',
      [Locale.en_US]: 'Other Address',
    },
  },
  remarks: {
    default: {
      default: '施設利用用途',
      [Locale.en_US]: 'Usage Purpose',
    },
  },
  requiredMark: {
    default: {
      default: '*',
      [Locale.en_US]: '*',
    },
  },
  required: {
    default: {
      default: '必須',
      [Locale.en_US]: 'Required',
    },
  },
  back: {
    default: {
      default: '戻る',
      [Locale.en_US]: 'Back',
    },
  },
  stepBack: {
    default: {
      default: '前へ',
      [Locale.en_US]: 'Back to previous',
    },
  },
  stepNext: {
    default: {
      default: '次へ',
      [Locale.en_US]: 'Next',
    },
  },
  authorities: {
    default: {
      default: '権限',
      [Locale.en_US]: 'Authorities',
    },
  },
  macAddress: {
    default: {
      default: 'MACアドレス',
      [Locale.en_US]: 'MAC Address',
    },
  },
  displayQRCode: {
    default: {
      default: 'QRコードを表示する',
      [Locale.en_US]: 'Display QR Code',
    },
  },
  everyDay: {
    default: {
      default: '毎日',
      [Locale.en_US]: 'Everyday',
    },
  },
  model: {
    default: {
      default: 'モデル',
      [Locale.en_US]: 'Model',
    },
  },
  nfcGroup: {
    default: {
      default: 'NFCグループ',
      [Locale.en_US]: 'NFC group',
    },
  },
  nfcGroupName: {
    default: {
      default: 'NFCグループ名',
      [Locale.en_US]: 'NFC group name',
    },
  },
  calenderHelperText: {
    default: {
      default: 'MMMM YYYY',
      [Locale.ja_JP]: 'YYYY年 M月',
    },
  },
  formatMilliseconds: {
    default: {
      default: millis => (millis ? moment(millis).format('lll') : '-'),
      [Locale.en_US]: millis => (millis ? moment(millis).format('lll') : '-'),
    },
  },
  formatDateRange: {
    default: {
      default: (arg: {startAt: EpochMillis; endAt: EpochMillis; format?: string}) =>
        `${moment(arg.startAt).format(arg.format ?? 'lll')}〜${
          DateUtils.isSameDay(arg.startAt, arg.endAt)
            ? moment(arg.endAt).format('LT')
            : moment(arg.endAt).format(arg.format ?? 'lll')
        }`,
      [Locale.en_US]: (arg: {startAt: EpochMillis; endAt: EpochMillis; format?: string}) =>
        `${moment(arg.startAt).format(arg.format ?? 'lll')} ~ ${
          DateUtils.isSameDay(arg.startAt, arg.endAt)
            ? moment(arg.endAt).format('LT')
            : moment(arg.endAt).format(arg.format ?? 'lll')
        }`,
    },
  },
  noTimeLimit: {
    default: {
      default: '制限なし',
      [Locale.en_US]: 'No time period',
    },
  },
  menu: {
    default: {
      default: 'メニュー',
      [Locale.en_US]: 'Menu',
    },
  },
  confirm: {
    default: {
      default: '確認',
      [Locale.en_US]: 'Confirm',
    },
  },
  doConfirm: {
    default: {
      default: '確認する',
      [Locale.en_US]: 'Confirm',
    },
  },
  invalidPhoneNumber: {
    default: {
      default: '無効な電話番号です。',
      [Locale.en_US]: 'Phone number is invalid.',
    },
  },
  birthday: {
    default: {
      default: '生年月日',
      [Locale.en_US]: 'Birthday',
    },
  },
  year: {
    default: {
      default: '年',
      [Locale.en_US]: 'year',
    },
  },
  month: {
    default: {
      default: '月',
      [Locale.en_US]: 'month',
    },
  },
  day: {
    default: {
      default: '日',
      [Locale.en_US]: 'day',
    },
  },
  invalidBirthday: {
    default: {
      default: '無効な生年月日です。',
      [Locale.en_US]: 'birthday is invalid.',
    },
  },
  failedRegistered: {
    default: {
      default: '登録に失敗しました。',
      [Locale.en_US]: 'Failed to Register.',
    },
  },
  failedDraft: {
    default: {
      default: '下書き保存に失敗しました。',
      [Locale.en_US]: 'Failed to Draft.',
    },
  },
  failedUpdate: {
    default: {
      default: '更新に失敗しました。',
      [Locale.en_US]: 'Failed to Update.',
    },
  },
  status_unapproved: {
    default: {
      default: '未承認',
      [Locale.en_US]: 'Unapproved',
    },
  },
  status_approved: {
    default: {
      default: '承認済',
      [Locale.en_US]: 'Approved',
    },
  },
  status_rejected: {
    default: {
      default: '却下済',
      [Locale.en_US]: 'Rejected',
    },
  },
  status_canceled: {
    default: {
      default: 'キャンセル済',
      [Locale.en_US]: 'Canceled',
    },
  },
  approve: {
    default: {
      default: '承認する',
      [Locale.en_US]: 'Approve',
    },
  },
  remand: {
    default: {
      default: '差し戻す',
      [Locale.en_US]: 'Feedback',
    },
  },
  reject: {
    default: {
      default: '却下する',
      [Locale.en_US]: 'Reject',
    },
  },
  propertyName: {
    default: {
      default: '物件名',
      [Locale.en_US]: 'Property Name',
    },
  },
  deleteAccount: {
    default: {
      default: 'アカウント削除',
      [Locale.en_US]: 'Delete Account',
    },
  },
  allSpaces: {
    default: {
      default: '全物件',
      [Locale.en_US]: 'All spaces',
    },
  },
  contact: {
    default: {
      default: '問い合わせ先',
      [Locale.en_US]: 'contact',
    },
  },
  order: {
    default: {
      default: '表示順',
      [Locale.en_US]: 'order',
    },
  },
  scope: {
    default: {
      default: '適用範囲',
      [Locale.en_US]: 'Scope of Application',
    },
  },
  actionType: {
    default: {
      default: (type: DeviceLogActionType, source?: DeviceLogActionSource) => {
        switch (type) {
          case DeviceLogActionType.UNLOCK_BY_HAND:
            return '手動解錠';
          case DeviceLogActionType.LOCK_BY_HAND:
            return '手動施錠';
          case DeviceLogActionType.UNLOCK_BY_DIGITAL_KEY:
            switch (source) {
              case DeviceLogActionSource.BITBUTTON_KIDS:
                return 'bitbutton KIDSによる解錠';
              case DeviceLogActionSource.BITBUTTON_ADULT:
                return 'bitbuttonによる解錠';
              case DeviceLogActionSource.BITREADER:
              case DeviceLogActionSource.BITREADER_G2:
                return 'bitreaderによる解錠';
              case DeviceLogActionSource.BITLOCK_APP_ANDROID:
              case DeviceLogActionSource.BITLOCK_APP_IOS:
                return 'アプリによる解錠';
              case DeviceLogActionSource.CONNECTOR:
                return 'Wi-Fiコネクタによる解錠';
              case DeviceLogActionSource.BITLOCK_APP_TEBURA_ANDROID:
              case DeviceLogActionSource.BITLOCK_APP_TEBURA_IOS:
                return '手ぶら解錠';
              case DeviceLogActionSource.APPLE_WATCH:
                return 'Apple Watchによる解錠';
              default:
                return 'デジタルキー解錠';
            }
          case DeviceLogActionType.LOCK_BY_DIGITAL_KEY:
            switch (source) {
              case DeviceLogActionSource.BITBUTTON_KIDS:
                return 'bitbutton KIDSによる施錠';
              case DeviceLogActionSource.BITBUTTON_ADULT:
                return 'bitbuttonによる施錠';
              case DeviceLogActionSource.BITREADER:
              case DeviceLogActionSource.BITREADER_G2:
                return 'bitreaderによる施錠';
              case DeviceLogActionSource.BITLOCK_APP_ANDROID:
              case DeviceLogActionSource.BITLOCK_APP_IOS:
                return 'アプリによる施錠';
              case DeviceLogActionSource.CONNECTOR:
                return 'Wi-Fiコネクタによる施錠';
              case DeviceLogActionSource.APPLE_WATCH:
                return 'Apple Watchによる施錠';
              default:
                return 'デジタルキー施錠';
            }
          case DeviceLogActionType.UNKNOWN:
            return '不明';
          case DeviceLogActionType.SET_KEY_HOLE_DATA:
            return 'ホール情報更新';
          case DeviceLogActionType.SET_CURRENT_TIME:
            return '時刻設定';
          case DeviceLogActionType.SET_AUTO_LOCK:
            return 'オートロック設定';
          case DeviceLogActionType.REGISTER_THUMB_TURN_UNLOCK_POSITION:
            return 'サムターン解錠位置設定';
          case DeviceLogActionType.REGISTER_THUMB_TURN_LOCK_POSITION:
            return 'サムターン施錠位置設定';
          case DeviceLogActionType.FACTORY_RESET:
            return '工場リセット';
          case DeviceLogActionType.ENTER_DFU_MODE:
            return 'DFUモード';
          case DeviceLogActionType.AUTO_LOCK:
            return 'オートロック';
          case DeviceLogActionType.ACTIVATE_DEVICE:
            return 'デバイス登録';
          default:
            return '不明';
        }
      },
      [Locale.en_US]: (type: DeviceLogActionType, source?: DeviceLogActionSource) => {
        switch (type) {
          case DeviceLogActionType.UNLOCK_BY_HAND:
            return 'Unlocked by hand';
          case DeviceLogActionType.LOCK_BY_HAND:
            return 'Locked by hand';
          case DeviceLogActionType.UNLOCK_BY_DIGITAL_KEY:
            switch (source) {
              case DeviceLogActionSource.BITBUTTON_KIDS:
                return 'Unlocked by bitbutton KIDS';
              case DeviceLogActionSource.BITBUTTON_ADULT:
                return 'Unlocked by bitbutton';
              case DeviceLogActionSource.BITREADER:
              case DeviceLogActionSource.BITREADER_G2:
                return 'Unlocked by bitreader';
              case DeviceLogActionSource.BITLOCK_APP_ANDROID:
              case DeviceLogActionSource.BITLOCK_APP_IOS:
                return 'Unlocked by App';
              case DeviceLogActionSource.CONNECTOR:
                return 'Unlocked by Wi-F Connector';
              case DeviceLogActionSource.BITLOCK_APP_TEBURA_ANDROID:
              case DeviceLogActionSource.BITLOCK_APP_TEBURA_IOS:
                return 'Unlocked by Tebura';
              case DeviceLogActionSource.APPLE_WATCH:
                return 'Unlocked by Apple watch';
              default:
                return 'Unlocked by digital key';
            }
          case DeviceLogActionType.LOCK_BY_DIGITAL_KEY:
            switch (source) {
              case DeviceLogActionSource.BITBUTTON_KIDS:
                return 'Locked by bitbutton KIDS';
              case DeviceLogActionSource.BITBUTTON_ADULT:
                return 'Locked by bitbutton';
              case DeviceLogActionSource.BITREADER:
              case DeviceLogActionSource.BITREADER_G2:
                return 'Locked by bitreader';
              case DeviceLogActionSource.BITLOCK_APP_ANDROID:
              case DeviceLogActionSource.BITLOCK_APP_IOS:
                return 'Locked by App';
              case DeviceLogActionSource.CONNECTOR:
                return 'Locked by Wi-F Connector';
              case DeviceLogActionSource.APPLE_WATCH:
                return 'Locked by Apple watch';
              default:
                return 'Locked by digital key';
            }
          case DeviceLogActionType.UNKNOWN:
            return 'Unknown';
          case DeviceLogActionType.SET_KEY_HOLE_DATA:
            return 'Hole data updated';
          case DeviceLogActionType.SET_CURRENT_TIME:
            return 'Time set';
          case DeviceLogActionType.SET_AUTO_LOCK:
            return 'Autolock set';
          case DeviceLogActionType.REGISTER_THUMB_TURN_UNLOCK_POSITION:
            return 'Thumb turn unlock position set';
          case DeviceLogActionType.REGISTER_THUMB_TURN_LOCK_POSITION:
            return 'Thumb turn lock position set';
          case DeviceLogActionType.FACTORY_RESET:
            return 'Factory reset';
          case DeviceLogActionType.ENTER_DFU_MODE:
            return 'DFU mode';
          case DeviceLogActionType.AUTO_LOCK:
            return 'Auto lock';
          case DeviceLogActionType.ACTIVATE_DEVICE:
            return 'Device registered';
          default:
            return 'Unknown';
        }
      },
    },
  },

  lockDeviceRecordType: {
    default: {
      default: (type: RecordType, centralCode?: CentralCode) => {
        switch (type) {
          case RecordType.AutolockGyro:
          case RecordType.AutolockMagnet:
            return 'オートロック';
          case RecordType.UnlockHand:
            return '手動による解錠';
          case RecordType.LockHand:
            return '手動による施錠';
          case RecordType.UnlockDigitalKey:
            switch (centralCode) {
              case CentralCode.BITBUTTON_KIDS:
                return 'bitbutton KIDSによる解錠';
              case CentralCode.BITBUTTON:
                return 'bitbuttonによる解錠';
              case CentralCode.BITBUTTON_CARD:
                return 'bitbutton CARDによる解錠';
              case CentralCode.BITREADER:
              case CentralCode.BITREADER_PLUS:
                return 'bitreaderによる解錠';
              case CentralCode.BITREADER_PLUS_NFC:
                return 'NFCによる解錠';
              case CentralCode.BITREADER_PLUS_NFC_PASSCODE:
                return 'NFCパスコードによる解錠';
              case CentralCode.BITREADER_PLUS_PASSCODE:
                return 'パスコードによる解錠';
              case CentralCode.BITREADER_PLUS_OTP:
              case CentralCode.BITREADER_PLUS_NFC_OTP:
              case CentralCode.BITREADER_PLUS_NFC_PASSCODE_OTP:
              case CentralCode.BITREADER_PLUS_PASSCODE_OTP:
                return 'ワンタイムパスコードによる解錠';
              case CentralCode.BITLOCK_APP_ANDROID:
              case CentralCode.BITLOCK_APP_IOS:
              case CentralCode.HOMEHUB_ANDROID_APP:
              case CentralCode.HOMEHUB_IOS_APP:
              case CentralCode.WORKHUB_ANDROID_APP:
              case CentralCode.WORKHUB_IOS_APP:
                return 'アプリによる解錠';
              case CentralCode.WIFI_CONNECTOR:
                return 'Wi-Fiコネクタによる解錠';
              case CentralCode.BITLOCK_APP_TEBURA_ANDROID:
              case CentralCode.BITLOCK_APP_TEBURA_IOS:
                return '手ぶら解錠';
              case CentralCode.BITLINK_LTE:
              case CentralCode.BITLINK_WIFI:
                return 'bitlinkによる解錠';
              case CentralCode.APPLE_WATCH:
                return 'Apple Watchによる解錠';
              default:
                return 'デジタルキーによる解錠';
            }
          case RecordType.LockDigitalKey:
            switch (centralCode) {
              case CentralCode.BITBUTTON_KIDS:
                return 'bitbutton KIDSによる施錠';
              case CentralCode.BITBUTTON:
                return 'bitbuttonによる施錠';
              case CentralCode.BITBUTTON_CARD:
                return 'bitbutton CARDによる施錠';
              case CentralCode.BITREADER:
              case CentralCode.BITREADER_PLUS:
                return 'bitreaderによる施錠';
              case CentralCode.BITREADER_PLUS_NFC:
                return 'NFCによる施錠';
              case CentralCode.BITREADER_PLUS_NFC_PASSCODE:
                return 'NFCパスコードによる施錠';
              case CentralCode.BITREADER_PLUS_PASSCODE:
                return 'パスコードによる施錠';
              case CentralCode.BITREADER_PLUS_OTP:
              case CentralCode.BITREADER_PLUS_NFC_OTP:
              case CentralCode.BITREADER_PLUS_NFC_PASSCODE_OTP:
              case CentralCode.BITREADER_PLUS_PASSCODE_OTP:
                return 'ワンタイムパスコードによる施錠';
              case CentralCode.BITLOCK_APP_ANDROID:
              case CentralCode.BITLOCK_APP_IOS:
              case CentralCode.HOMEHUB_ANDROID_APP:
              case CentralCode.HOMEHUB_IOS_APP:
              case CentralCode.WORKHUB_ANDROID_APP:
              case CentralCode.WORKHUB_IOS_APP:
                return 'アプリによる施錠';
              case CentralCode.WIFI_CONNECTOR:
                return 'Wi-Fiコネクタによる施錠';
              case CentralCode.BITLOCK_APP_TEBURA_ANDROID:
              case CentralCode.BITLOCK_APP_TEBURA_IOS:
                return '手ぶら施錠';
              case CentralCode.BITLINK_LTE:
              case CentralCode.BITLINK_WIFI:
                return 'bitlinkによる施錠';
              case CentralCode.APPLE_WATCH:
                return 'Apple Watchによる施錠';
              default:
                return 'デジタルキーによる施錠';
            }
          default:
            return '不明';
        }
      },
      [Locale.en_US]: (type: RecordType, centralCode?: CentralCode) => {
        switch (type) {
          case RecordType.AutolockGyro:
          case RecordType.AutolockMagnet:
            return 'AutoLock';
          case RecordType.UnlockHand:
            return 'Unlocked By Hand';
          case RecordType.LockHand:
            return 'Locked By Hand';
          case RecordType.UnlockDigitalKey:
            switch (centralCode) {
              case CentralCode.BITBUTTON_KIDS:
                return 'Unlocked by bitbutton KIDS';
              case CentralCode.BITBUTTON:
                return 'Unlocked by bitbutton';
              case CentralCode.BITBUTTON_CARD:
                return 'Unlocked by bitbutton CARD';
              case CentralCode.BITREADER:
              case CentralCode.BITREADER_PLUS:
                return 'Unlocked by bitreader';
              case CentralCode.BITREADER_PLUS_NFC:
                return 'Unlocked by NFC';
              case CentralCode.BITREADER_PLUS_PASSCODE:
                return 'Unlocked by Passcode';
              case CentralCode.BITREADER_PLUS_NFC_PASSCODE:
                return 'Unlocked by NFC Passcode';
              case CentralCode.BITREADER_PLUS_OTP:
              case CentralCode.BITREADER_PLUS_NFC_OTP:
              case CentralCode.BITREADER_PLUS_NFC_PASSCODE_OTP:
              case CentralCode.BITREADER_PLUS_PASSCODE_OTP:
                return 'Unlocked by One Time Passcode';
              case CentralCode.BITLOCK_APP_ANDROID:
              case CentralCode.BITLOCK_APP_IOS:
              case CentralCode.HOMEHUB_ANDROID_APP:
              case CentralCode.HOMEHUB_IOS_APP:
              case CentralCode.WORKHUB_ANDROID_APP:
              case CentralCode.WORKHUB_IOS_APP:
                return 'Unlocked by App';
              case CentralCode.WIFI_CONNECTOR:
                return 'Unlocked by Wi-Fi Connector';
              case CentralCode.BITLOCK_APP_TEBURA_ANDROID:
              case CentralCode.BITLOCK_APP_TEBURA_IOS:
                return 'Unlocked by Tebura';
              case CentralCode.BITLINK_LTE:
              case CentralCode.BITLINK_WIFI:
                return 'Unlocked by bitlink';
              case CentralCode.APPLE_WATCH:
                return 'Unlocked by Apple Watch';
              default:
                return 'Unlocked by digital key';
            }
          case RecordType.LockDigitalKey:
            switch (centralCode) {
              case CentralCode.BITBUTTON_KIDS:
                return 'Locked by bitbutton KIDS';
              case CentralCode.BITBUTTON:
                return 'Locked by bitbutton';
              case CentralCode.BITBUTTON_CARD:
                return 'Locked by bitbutton CARD';
              case CentralCode.BITREADER:
              case CentralCode.BITREADER_PLUS:
                return 'Locked by bitreader';
              case CentralCode.BITREADER_PLUS_NFC:
                return 'Locked by NFC';
              case CentralCode.BITREADER_PLUS_NFC_PASSCODE:
                return 'Locked by NFC Passcode';
              case CentralCode.BITREADER_PLUS_PASSCODE:
                return 'Locked by Passcode';
              case CentralCode.BITREADER_PLUS_OTP:
              case CentralCode.BITREADER_PLUS_NFC_OTP:
              case CentralCode.BITREADER_PLUS_NFC_PASSCODE_OTP:
              case CentralCode.BITREADER_PLUS_PASSCODE_OTP:
                return 'Unlocked by One Time Passcode';
              case CentralCode.BITLOCK_APP_ANDROID:
              case CentralCode.BITLOCK_APP_IOS:
              case CentralCode.HOMEHUB_ANDROID_APP:
              case CentralCode.HOMEHUB_IOS_APP:
              case CentralCode.WORKHUB_ANDROID_APP:
              case CentralCode.WORKHUB_IOS_APP:
                return 'Locked by App';
              case CentralCode.WIFI_CONNECTOR:
                return 'Locked by Wi-Fi Connector';
              case CentralCode.BITLOCK_APP_TEBURA_ANDROID:
              case CentralCode.BITLOCK_APP_TEBURA_IOS:
                return 'Locked by Tebura';
              case CentralCode.BITLINK_LTE:
              case CentralCode.BITLINK_WIFI:
                return 'Locked by bitlink';
              case CentralCode.APPLE_WATCH:
                return 'Locked by Apple Watch';
              default:
                return 'Locked by digital key';
            }
          default:
            return 'Unknown';
        }
      },
    },
  },
  processingMessage: {
    default: {
      default: '処理中です...',
      [Locale.en_US]: 'Processing...',
    },
  },
  commonSuccessfully: {
    default: {
      default: '成功しました',
      [Locale.en_US]: 'Successfully',
    },
  },
  confirmInputInformation: {
    default: {
      default: '入力内容の確認',
      [Locale.en_US]: 'Confirm input data',
    },
  },
  confirmInputMessage: {
    default: {
      default: '入力内容にお間違いがないかご確認の上、確定をお願いいたします。',
      [Locale.en_US]: 'Confirm input data and submit.',
    },
  },
  noImage: {
    default: {
      default: 'No image',
      [Locale.en_US]: 'No image',
    },
  },
  buildingName: {
    default: {
      default: '建物名',
      [Locale.en_US]: 'Building name',
    },
  },
  loadingDeleteMessage: {
    default: {
      default: '削除中です...',
      [Locale.en_US]: 'Deleting...',
    },
  },
  loadingMessage: {
    default: {
      default: '読み込み中です...',
      [Locale.en_US]: 'Loading...',
    },
  },
  failedToLoad: {
    default: {
      default: '読み込みに失敗しました',
      [Locale.en_US]: 'Failed to load data.',
    },
  },
  download: {
    default: {
      default: 'ダウンロード',
      [Locale.en_US]: 'Download',
    },
  },
  displayName: {
    default: {
      default: '表示名',
      [Locale.en_US]: 'Display Name',
    },
  },
  display: {
    default: {
      default: '表示する',
      [Locale.en_US]: 'View',
    },
  },
  registerProcessing: {
    default: {
      default: '登録中です...',
      [Locale.en_US]: 'Register Processing...',
    },
  },
  updateProcessing: {
    default: {
      default: '更新中です...',
      [Locale.en_US]: 'Update Processing...',
    },
  },
  loadProcessing: {
    default: {
      default: '読み込み中です...',
      [Locale.en_US]: 'Loading...',
    },
  },
  // TODO:以下の文言を整えた上でスナックバーのテキストを統合する
  successToLoadText: {
    default: {
      default: (text?: string) => (text ? `${text}の読み込みが成功しました` : `読み込みが成功しました`),
      [Locale.en_US]: (text: string) => (text ? ` Managed to load ${text}` : `Managed to load data`),
    },
  },
  failedToLoadText: {
    default: {
      default: (text?: string) => (text ? `${text}の読み込みが失敗しました` : `読み込みが失敗しました`),
      [Locale.en_US]: (text?: string) => (text ? `Failed to load ${text}` : `Failed to load data`),
    },
  },
  csvBulkLoadSuccess: {
    default: {
      default: (text?: string) => (text ? `${text}のCSVデータの読込が成功しました` : `データの読込が成功しました`),
      [Locale.en_US]: (text?: string) => (text ? `${text} csv import was successful` : `Csv import was successful`),
    },
  },
  successToRegister: {
    default: {
      default: (text?: string) => (text ? `${text}の登録に成功しました` : `登録に成功しました`),
      [Locale.en_US]: (text?: string) => (text ? `Success to register ${text}` : `Success to register`),
    },
  },
  failedToRegister: {
    default: {
      default: (text?: string) => (text ? `${text}の登録に失敗しました` : `登録に失敗しました`),
      [Locale.en_US]: (text?: string) => (text ? `Failed to register ${text}` : `Failed to register`),
    },
  },
  failedToRegisterSome: {
    default: {
      default: (text?: string) => (text ? `複数${text}を登録できませんでした` : `複数データを登録できませんでした`),
      [Locale.en_US]: (text: string) => (text ? `Failed to register all ${text}` : `Failed to register all data`),
    },
  },
  successToUpdate: {
    default: {
      default: (text?: string) => (text ? `${text}の更新に成功しました` : `更新に成功しました`),
      [Locale.en_US]: (text?: string) => (text ? `Success to update ${text}` : `Success to update`),
    },
  },
  failedToUpdate: {
    default: {
      default: (text?: string) => (text ? `${text}の更新に失敗しました` : `更新に失敗しました`),
      [Locale.en_US]: (text?: string) => (text ? `Failed to update ${text}` : `Failed to update`),
    },
  },
  successToSend: {
    default: {
      default: (text?: string) => (text ? `${text}の送信に成功しました` : `送信に成功しました`),
      [Locale.en_US]: (text?: string) => (text ? `Success to send ${text}` : `Success to send`),
    },
  },
  failedToSend: {
    default: {
      default: (text?: string) => (text ? `${text}の送信に失敗しました` : `送信に失敗しました`),
      [Locale.en_US]: (text?: string) => (text ? `Failed to send ${text}` : `Failed to send`),
    },
  },
  failedToSendSome: {
    default: {
      default: (text?: string) => (text ? `一部${text}の送信に失敗しました` : `一部送信に失敗しました`),
      [Locale.en_US]: (text?: string) => (text ? `Failed to send some ${text}` : `Failed to send some`),
    },
  },
  startedToDelete: {
    default: {
      default: (text?: string) => (text ? `${text}削除を開始しました。` : `削除を開始しました`),
      [Locale.en_US]: (text?: string) => (text ? `Started to delete ${text}` : `Started to delete`),
    },
  },
  successToDelete: {
    default: {
      default: (text?: string) => (text ? `${text}の削除に成功しました` : `削除に成功しました`),
      [Locale.en_US]: (text?: string) => (text ? `Success to delete ${text}` : `Success to delete`),
    },
  },
  failedToDeleteText: {
    default: {
      default: (text?: string) => (text ? `${text}の削除に失敗しました` : `削除に失敗しました`),
      [Locale.en_US]: (text?: string) => (text ? `Failed to delete ${text}` : `Failed to delete`),
    },
  },
  failedToDeleteTextWithCount: {
    default: {
      default: (text: string, count: number) => `削除に失敗した${text}が${count}件あります。`,
      [Locale.en_US]: (text: string, count: number) => `Failed to delete ${count} ${text}.`,
    },
  },
  failedToDeleteSome: {
    default: {
      default: (text?: string) => (text ? `一部${text}の削除に失敗しました` : `一部削除に失敗しました`),
      [Locale.en_US]: (text?: string) => (text ? `Failed to delete some ${text}` : `Failed to delete some`),
    },
  },
  receiverEmail: {
    default: {
      default: '受信者メールアドレス',
      [Locale.en_US]: 'Receiver email',
    },
  },
  bitlockSerialNo: {
    default: {
      default: 'bitlock シリアルNo',
      [Locale.en_US]: 'bitlock Serial No',
    },
  },
  bitlockSerialNoExample: {
    default: {
      default: 'bitlockシリアルNo（例：000190601203）',
      [Locale.en_US]: 'bitlock serial No. (Ex:000190601203)',
    },
  },
  usageStartTime: {
    default: {
      default: '使用開始日時',
      [Locale.en_US]: 'Use start time',
    },
  },
  usageStartTimeExample: {
    default: {
      default: `使用開始日時（例：2018/05/16 00:00 / 未入力=制限なし）`,
      [Locale.en_US]: `Use start time (Ex:05/16/2018 00:00 / No Input=No expiration data)`,
    },
  },
  usageStartTimeDownloadExample: {
    default: {
      default: `使用開始日時（未入力=制限なし）`,
      [Locale.en_US]: `Use start time (No Input=No expiration data)`,
    },
  },
  usageEndTime: {
    default: {
      default: '使用終了日時',
      [Locale.en_US]: 'Use end time',
    },
  },
  usageEndTimeExample: {
    default: {
      default: `使用終了日時（例：2018/05/16 01:00 / 未入力=制限なし）`,
      [Locale.en_US]: `Use end time (Ex:05/16/2018 01:00 / No Input=No expiration data)`,
    },
  },
  usageEndTimeDownloadExample: {
    default: {
      default: `使用終了日時（未入力=制限なし）`,
      [Locale.en_US]: `Use end time (No Input=No expiration data)`,
    },
  },
  usageTimes: {
    default: {
      default: '使用回数',
      [Locale.en_US]: 'Usage times',
    },
  },
  usageTimesExample: {
    default: {
      default: '使用回数 (1~100 / 未入力=制限なし)',
      [Locale.en_US]: 'Usage times (1~100 / No Input=No time period)',
    },
  },
  deviceSettingExample: {
    default: {
      default: `bitlock本体設定（0=許可する / 1=許可しない）`,
      [Locale.en_US]: `Change bitlock setting（0=Allow / 1=Disallow）`,
    },
  },
  viewLogAndReceiversExample: {
    default: {
      default: `ログとカギ所有者の閲覧（0=許可する / 1=許可しない）`,
      [Locale.en_US]: `View logs and receivers'（0=Allow / 1=Disallow）`,
    },
  },
  shareTicketsExample: {
    default: {
      default: `チケット共有（0=許可する / 1=許可しない）`,
      [Locale.en_US]: `Share tickets（0=Allow / 1=Disallow）`,
    },
  },
  registerSmartKeysExample: {
    default: {
      default: `bitbutton登録（0=許可する / 1=許可しない）`,
      [Locale.en_US]: `Register bitbutton（0=Allow / 1=Disallow）`,
    },
  },
  useWifiConnectorExample: {
    default: {
      default: `遠隔解錠/施錠（0=許可する / 1=許可しない）`,
      [Locale.en_US]: `Use remote lock/unlock（0=Allow / 1=Disallow）`,
    },
  },
  grantNfcCardSettingExample: {
    default: {
      default: `NFCカード設定権限（0=付与する / 1=付与しない）`,
      [Locale.en_US]: `Grant fixed NFC card setting（0=Allow / 1=Disallow）`,
    },
  },
  grantFixedPasscodeSettingExample: {
    default: {
      default: `固定パスコード設定権限（0=付与する / 1=付与しない）`,
      [Locale.en_US]: `Grant fixed passcode setting（0=Allow / 1=Disallow）`,
    },
  },
  grantTotpSettingExample: {
    default: {
      default: `TOTP参照権限（0=付与する / 1=付与しない）`,
      [Locale.en_US]: `Grant TOTP setting（0=Allow / 1=Disallow）`,
    },
  },
  grantDeviceSettingExample: {
    default: {
      default: `認証デバイスの設定権限（0=付与する / 1=付与しない）`,
      [Locale.en_US]: `Grant device setting（0=Allow / 1=Disallow）`,
    },
  },
  grantSpareSettingExample: {
    default: {
      default: `認証デバイスの貸出設定権限（0=付与する / 1=付与しない）`,
      [Locale.en_US]: `Grant lend setting（0=Allow / 1=Disallow）`,
    },
  },
  numberOfCases: {
    default: {
      default: (number?: number) => `件数: ${number ? number : commonDictDef.noData.default.default}件`,
      [Locale.en_US]: (number?: number) =>
        `Number of cases: ${number ? number : commonDictDef.noData.default[Locale.en_US]}`,
    },
  },
  toDateTimeFormat: {
    default: {
      default: (epochMillis: number, format?: string) =>
        epochMillis ? moment(epochMillis).format(format || 'YYYY/MM/DD HH:mm') : '',
      [Locale.en_US]: (epochMillis: number, format?: string) =>
        epochMillis ? moment(epochMillis).format(format || 'YYYY/MM/DD HH:mm') : '',
    },
  },
  toConditionPeriod: {
    default: {
      default: (start, end) =>
        start || end
          ? (start ? moment(start).format('YYYY/MM/DD HH:mm') : '') +
            ' - ' +
            (end ? moment(end).format('YYYY/MM/DD HH:mm') : '')
          : commonDictDef.noTimeLimit.default.default,
      [Locale.en_US]: (start, end) =>
        start || end
          ? (start ? moment(start).format('DD/MM/YYYY HH:mm') : '') +
            ' - ' +
            (end ? moment(end).format('DD MM YYYY HH:mm') : '')
          : commonDictDef.noTimeLimit.default[Locale.en_US],
    },
  },
  createdAt: {
    default: {
      default: '作成日',
      [Locale.en_US]: 'Created date',
    },
  },
  getBitlinkType: {
    default: {
      default: (bitlinkType: string) =>
        bitlinkType === BleDeviceType.BITLINK_WIFI
          ? 'bitlink (wifi)'
          : bitlinkType === BleDeviceType.BITLINK_LTE
          ? 'bitlink (LTE)'
          : '',
      [Locale.en_US]: (bitlinkType: string) =>
        bitlinkType === BleDeviceType.BITLINK_WIFI
          ? 'bitlink (wifi)'
          : bitlinkType === BleDeviceType.BITLINK_LTE
          ? 'bitlink (LTE)'
          : '',
    },
  },
  getDeviceName: {
    default: {
      default: (thing: any) => thing?.nameJp,
      [Locale.en_US]: (thing: any) => thing?.nameEn,
    },
  },
  toLocaleName: {
    default: {
      default: (arg: {nameJp?: string; nameEn?: string}) => arg.nameJp ?? '',
      [Locale.en_US]: (arg: {nameJp?: string; nameEn?: string}) => arg.nameEn ?? '',
    },
  },
  getBitlinkStatus: {
    default: {
      default: (status: BitlinkStatus | undefined) => {
        switch (status) {
          case BitlinkStatus.Alive:
            return commonDictDef.alive.default.default;
          case BitlinkStatus.Dead:
            return commonDictDef.dead.default.default;
          default:
            return undefined;
        }
      },
      [Locale.en_US]: (status: BitlinkStatus | undefined) => {
        switch (status) {
          case BitlinkStatus.Alive:
            return commonDictDef.alive.default[Locale.en_US];
          case BitlinkStatus.Dead:
            return commonDictDef.dead.default[Locale.en_US];
          default:
            return undefined;
        }
      },
    },
  },
  sumamoruPlanOgWLock: {
    default: {
      default: SumamoruPlanMap[SumamoruPlan.OG_WLock],
      [Locale.en_US]: SumamoruPlanMapEn[SumamoruPlan.OG_WLock],
    },
  },
  sumamoruPlanOgViewn: {
    default: {
      default: SumamoruPlanMap[SumamoruPlan.OG_Viewn],
      [Locale.en_US]: SumamoruPlanMapEn[SumamoruPlan.OG_Viewn],
    },
  },
  networkConnectionSuccess: {
    default: {
      default: 'ネットワークへ接続されました',
      [Locale.en_US]: 'Network connection success.',
    },
  },
  networkConnectionError: {
    default: {
      default: 'ネットワークへ接続されていません',
      [Locale.en_US]: 'Network connection error.',
    },
  },
  code: {
    default: {
      default: 'コード',
      [Locale.en_US]: 'Codes',
    },
  },
  description: {
    default: {
      default: '説明',
      [Locale.en_US]: 'Descriptions',
    },
  },
  uploadPhoto: {
    default: {
      default: '写真をアップロード',
      [Locale.en_US]: 'Upload Photo',
    },
  },
  note: {
    default: {
      default: 'メモ',
      [Locale.en_US]: 'Not',
    },
  },
  adminNote: {
    default: {
      default: '管理者メモ',
      [Locale.en_US]: 'Admin Note',
    },
  },
  flatParking: {
    default: {
      default: '平面',
      [Locale.en_US]: 'Flat Parking',
    },
  },
  multiStoreyParking: {
    default: {
      default: '立体',
      [Locale.en_US]: 'Multi Storey Parking',
    },
  },
  mechanicalParking: {
    default: {
      default: '機械式',
      [Locale.en_US]: 'Mechanical Parking',
    },
  },
  towerParking: {
    default: {
      default: 'タワー式',
      [Locale.en_US]: 'Tower Parking',
    },
  },
  width: {
    default: {
      default: '幅',
      [Locale.en_US]: 'Width',
    },
  },
  height: {
    default: {
      default: '高さ',
      [Locale.en_US]: 'Height',
    },
  },
  depth: {
    default: {
      default: '奥行き',
      [Locale.en_US]: 'Depth',
    },
  },
  weight: {
    default: {
      default: '重さ',
      [Locale.en_US]: 'Weight',
    },
  },
  car: {
    default: {
      default: '自動車',
      [Locale.en_US]: 'Car',
    },
  },
  moto: {
    default: {
      default: 'バイク',
      [Locale.en_US]: 'Moto Cycle',
    },
  },
  bicycle: {
    default: {
      default: '自転車',
      [Locale.en_US]: 'Bicycle',
    },
  },
  smallCar: {
    default: {
      default: '小型車',
      [Locale.en_US]: 'Small Car',
    },
  },
  regularCar: {
    default: {
      default: '普通車',
      [Locale.en_US]: 'Regular Car',
    },
  },
  largeCar: {
    default: {
      default: '大型車',
      [Locale.en_US]: 'Large Car',
    },
  },
  isPrioritySection: {
    default: {
      default: '優先区画',
      [Locale.en_US]: 'Priority Section',
    },
  },
  existRoof: {
    default: {
      default: '屋根の有無',
      [Locale.en_US]: 'Availability of Roof',
    },
  },
  canRePark: {
    default: {
      default: '再駐車可能か',
      [Locale.en_US]: 'Can Re-Park',
    },
  },
  vehicleType: {
    default: {
      default: '車両規格',
      [Locale.en_US]: 'Vehicle Type',
    },
  },
  parkableCarType: {
    default: {
      default: '駐車可能車両',
      [Locale.en_US]: 'Parkable Car Type',
    },
  },
  refinement: {
    default: {
      default: '絞り込む',
      [Locale.en_US]: 'Refinement',
    },
  },
  displayAll: {
    default: {
      default: 'すべて表示',
      [Locale.en_US]: 'Display All',
    },
  },
  dateTimeSearchLabel: {
    default: {
      default: 'から',
      [Locale.en_US]: '〜',
    },
  },
  between: {
    default: {
      default: '〜',
      [Locale.en_US]: '~',
    },
  },
  monthlyCharge: {
    default: {
      default: '月額',
      [Locale.en_US]: 'Monthly Charge',
    },
  },
  homehubTmpSpaceGroup: {
    default: {
      default: '空間グループ',
      [Locale.en_US]: 'Space Group',
    },
  },
  all: {
    default: {
      default: 'すべて',
      [Locale.en_US]: 'All',
    },
  },
  newVersionIsAvailable: {
    default: {
      default: (latestFirmwareVersion: string) => `最新のファームウェア(${latestFirmwareVersion})が公開されています`,
      [Locale.en_US]: (latestFirmwareVersion: string) =>
        `The latest firmware-${latestFirmwareVersion} is now available`,
    },
  },
  howToUpdateFirmwareFromApp: {
    default: {
      default: (deviceName: string) =>
        `アプリから${deviceName}のファームウェアを更新できます。\nアプリの操作手順に従ってアップデートを行なってください。`,
      [Locale.en_US]: (deviceName: string) =>
        `You can update the ${deviceName} firmware from the app.\n Follow the steps detailed in the mobile app.`,
    },
  },
  howToUpdateFirmwareFromWeb: {
    default: {
      default: (deviceName: string) =>
        `本画面で${deviceName}のファームウェアを更新できます。\n右上の三点リーダーのデバイスの更新を選択してアップデートを行なってください。`,
      [Locale.en_US]: deviceName =>
        `You can update the ${deviceName} firmware from this screen.\nClick the update menu on the right-up to update.`,
    },
  },
  updateFirmware: {
    default: {
      default: 'デバイスの更新',
      [Locale.en_US]: 'Firmware Update',
    },
  },
  firmware: {
    default: {
      default: 'ファームウェア',
      [Locale.en_US]: 'firmware',
    },
  },
  start: {
    default: {
      default: '開始する',
      [Locale.en_US]: 'Start',
    },
  },
  upToDate: {
    default: {
      default: (deviceName: string) => `${deviceName}が最新バージョンです`,
      [Locale.en_US]: (deviceName: string) => `$This ${deviceName} is up to date`,
    },
  },
  alreadyUpToDate: {
    default: {
      default: '既に指定のバージョンに更新済みです。',
      [Locale.en_US]: 'The current device is already up to date.',
    },
  },
  startAction: {
    default: {
      default: (action: string) => `${action}を開始しました。`,
      [Locale.en_US]: (action: string) => `${action} has just started.`,
    },
  },
  updateOnClick: {
    default: {
      default: (name: string) => `クリックすると表示名を変更できます。\n現在の表示名: ${name}`,
      [Locale.en_US]: (name: string) => `Click to change display name.\nCurrent display name: ${name}`,
    },
  },
  encoding: {
    default: {
      default: (encoding: HHEncoding): string => {
        switch (encoding) {
          case 'UTF8':
            return 'UTF-8';
          case 'SJIS':
            return 'Shift_JIS';
          default:
            return 'Not supported';
        }
      },
      [Locale.en_US]: (encoding: HHEncoding): string => {
        switch (encoding) {
          case 'UTF8':
            return 'UTF-8';
          case 'SJIS':
            return 'Shift_JIS';
          default:
            return 'Not supported';
        }
      },
    },
  },
  moreActions: {
    default: {
      default: 'もっと見る',
      [Locale.en_US]: 'More action',
    },
  },
  checkDetail: {
    default: {
      default: '詳細を確認する',
      [Locale.en_US]: 'check Details',
    },
  },
  searchBy: {
    default: {
      default: (attr: string[]) => `${attr.join('、')}で検索`,
      [Locale.en_US]: (attr: string[]) => `Search By ${attr.join(', ')}`,
    },
  },
  submit: {
    default: {
      default: '保存する',
      [Locale.en_US]: 'Submit',
    },
  },
};

export type CommonDict = typeof commonDictDef;

const useCommonDictionary = (): Dict<CommonDict> => {
  const dictionaryCommon = useMemo(() => new DictionaryCommon(commonDictDef), []);
  const dictState: DictionaryState = useSelector<Store, DictionaryState>(state => state.dictionary);
  const [dict, setDict] = useState<CommonDict>(dictionaryCommon.createDict(dictState.businessType, dictState.locale));
  useEffect(() => {
    setDict(dictionaryCommon.createDict(dictState.businessType, dictState.locale));
  }, [dictState.businessType, dictState.locale, dictionaryCommon]);
  return dict;
};

const useDictionary = <T extends DictionaryDefinition>(dictDef: T): Dict<T> => {
  const dictionaryCommon = useMemo(() => new DictionaryCommon(dictDef), [dictDef]);
  const dictState: DictionaryState = useSelector<Store, DictionaryState>(state => state.dictionary);
  const [dict, setDict] = useState<T>(dictionaryCommon.createDict(dictState.businessType, dictState.locale));
  useEffect(() => {
    setDict(dictionaryCommon.createDict(dictState.businessType, dictState.locale));
  }, [dictState.businessType, dictState.locale, dictionaryCommon]);
  return dict;
};

export {useDictionary, useCommonDictionary};
