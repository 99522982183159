import {Snackbar} from '@material-ui/core';
import React, {createContext, FC, useCallback, useState} from 'react';
import {styles} from './styles';
import styled from 'styled-components';

export type DisplayType = 'Warning' | 'Error' | 'Success';

export type SnackBarState = {
  open?: boolean;
  message: string;
  display?: DisplayType;
  customAutoHideDuration?: number;
};

type SnackBarContext = {
  setSnackBar: (state: SnackBarState) => void;
};

export const SnackBarContext = createContext<SnackBarContext>({
  setSnackBar: (_: SnackBarState) => {},
});

const BASE_SNACK_BAR_STATE: SnackBarState = {
  open: false,
  message: '',
  display: 'Success',
  customAutoHideDuration: 6000,
};

const PreWrapSpan = styled.span`
  white-space: pre-wrap;
`;
/**
 * 指定の機能の権限がない場合、childをdisableする
 */
export const SnackbarWrapper: FC = ({children}) => {
  const classes = styles();

  const [snackBarState, setSnackBarState] = useState<SnackBarState>(BASE_SNACK_BAR_STATE);

  const setSnackBar = useCallback((snackBarState: Partial<SnackBarState>) => {
    setSnackBarState(c => ({
      ...c,
      open: true,
      ...snackBarState,
    }));
  }, []);

  const onClose = useCallback(() => {
    setSnackBarState(BASE_SNACK_BAR_STATE);
  }, []);

  return (
    <>
      <SnackBarContext.Provider value={{setSnackBar}}>{children}</SnackBarContext.Provider>
      <Snackbar
        id={`${snackBarState.display}SnackBar`}
        open={snackBarState.open}
        onClose={onClose}
        autoHideDuration={snackBarState.customAutoHideDuration}
        message={<PreWrapSpan>{snackBarState.message}</PreWrapSpan>}
        ContentProps={{
          className:
            snackBarState.display === 'Warning'
              ? classes.warning
              : snackBarState.display === 'Success'
              ? classes.success
              : classes.error,
        }}
      />{' '}
    </>
  );
};
