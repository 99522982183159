import {Overrides} from '@material-ui/core/styles/overrides';
import BFont from './b-font';
import {createTheme} from './styles';
import {HHColor} from './hhColor';
import {ThemeOptions} from '@material-ui/core/styles/createTheme';

// enumにしておく
export enum DefaultThemeColor {
  inherit = 'inherit',
  primary = 'primary',
  secondary = 'secondary',
  default = 'default',
}

export enum ThemeColor {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

const overrides: Overrides = {
  MuiButton: {
    root: {
      textTransform: 'none',
    },
  },
};

/**
 * 何回もtheme作るのコストの無駄なので必要なものは先に作る。
 */
const Themes: {[K in ThemeColor]: any} = {
  success: createTheme({
    palette: {
      primary: {
        main: HHColor.Surface.success,
        light: HHColor.Surface.successLite,
        dark: HHColor.Surface.success,
        contrastText: HHColor.Surface.neutralLow,
      },
    },
    overrides: overrides,
  }),
  info: createTheme({
    palette: {
      primary: {
        main: HHColor.Surface.info,
        light: HHColor.Surface.infoLight,
        dark: HHColor.Surface.info,
        contrastText: HHColor.Surface.neutralLow,
      },
    },
    overrides: overrides,
  }),
  warning: createTheme({
    palette: {
      primary: {
        main: HHColor.Surface.warning,
        light: HHColor.Surface.warningLight,
        dark: HHColor.Surface.warning,
        contrastText: HHColor.Surface.neutralLow,
      },
    },
    overrides: overrides,
  }),
  error: createTheme({
    palette: {
      primary: {
        main: HHColor.Surface.error,
        light: HHColor.Surface.errorLight,
        dark: HHColor.Surface.error,
        contrastText: HHColor.Surface.neutralLow,
      },
    },
    overrides: overrides,
  }),
};

const defaultThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 980,
      md: 1024,
      lg: 1280,
      xl: 1580,
    },
  },
  palette: {
    primary: {
      main: HHColor.Surface.brandPrimary,
      light: HHColor.Surface.brandPrimary,
      dark: HHColor.Surface.brandSecondary,
      contrastText: HHColor.Text.neutralWhitePrimary,
    },
    secondary: {
      main: HHColor.Surface.brandSecondary,
      light: HHColor.Surface.brandSecondary,
      dark: HHColor.Surface.brandPrimary,
      contrastText: HHColor.Text.neutralWhitePrimary,
    },
    text: {
      primary: HHColor.Text.neutralPrimary,
      secondary: HHColor.Text.neutralSecondary,
      hint: HHColor.Text.neutralDisabled,
    },
  },
  typography: {
    fontFamily: BFont.FONT_FAMILY,
    fontWeightBold: 500,
    h1: {
      fontSize: 28,
      fontWeight: 500,
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        height: '1.2em',
        paddingTop: 5,
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):before': {
          borderBottom: `2px solid ${HHColor.Border.onLightStrong}`,
        },
      },
    },
    MuiTypography: {
      root: {
        color: HHColor.Object.neutralPrimary,
        fontSize: '0.875rem',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTableCell: {
      body: {
        position: 'relative',
      },
    },
    MuiLink: {
      root: {
        color: HHColor.Text.linkPrimary,
        '&:hover': {
          color: HHColor.Text.linkPrimaryHover,
        },
      },
    },
  },
};

/**
 * 下記に移行したので使わないこと
 * {@link HHDefaultTheme}
 * @deprecated
 */
export const DefaultTheme = createTheme(defaultThemeOptions);

export default Themes;
