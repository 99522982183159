import type {AlgoliaObject} from '../../algolia';
import {
  BuildingStructureType,
  DepositKeyMoneyUnit,
  SpaceAttribute,
} from '../../../firebase/firestore/references/organizations/spaces/firestore-spaces';
import {EpochMillis} from '../../../common-types/numbers';
import {SpaceStatus} from '../../../firebase/firestore/references/organizations/spaces/status-histories/firestore-organizations-spaces-status-histories';
import {
  FirestorePrivateViewBrokerageType,
  FirestorePrivateViewContactSettings,
  FirestorePrivateViewNotesSettings,
} from '../../../firebase/firestore/references/organizations/common-settings/space-setting/space-private-view-settings/firestore-organization-space-private-view-settings';
import {DayOfWeek} from '../../../business-types/enums/core/common/day-of-week';
import {PaymentMethodOfReservationFee} from '../../../business-types/enums/residence/common/payment-method-of-reservation-fee';
import {FacilityUsageType} from '../../../enums/facilityUsageType';
import {SumamoruProjectType} from '../../../business-types/residence/organizations/add-ons/sumamoru-project-type';
import {SumamoruPlan} from '../../../business-types/residence/organizations/add-ons/SumamoruPlan';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';
import {SumamoruInstallAppType} from '@pages/v1/add-ons/sumamoru/SumamoruLinkableBuildingRegistrationDialog';

export enum AlgoliaSpacesAttributes {
  id = 'id',
  postcode = 'postcode',
  prefecture = 'prefecture',
  city = 'city',
  afterCity = 'afterCity',
  address = 'address',
  code = 'code',
  bitlockIds = 'bitlockIds',
  hasDevices = 'hasDevices',
  name = 'name',
  phoneticName = 'phoneticName',
  parents = 'parents',
  photoUri = 'photoUri',
  // space groupも同じindexにするため。
  isGroup = 'isGroup',
  parentNames = 'parentNames',
  parentId = 'parentId',
  attributes = 'attributes',
  createdAt = 'createdAt',

  status = 'status',
  scheduledStatuses = 'scheduledStatuses',
  equipments = 'equipments',
  memo = 'memo',
  privateReservationSiteRemarks = 'privateReservationSiteRemarks',

  photoUri02 = 'photoUri02',
  photoUri03 = 'photoUri03',
  photoUri04 = 'photoUri04',
  photoUri05 = 'photoUri05',
  photoUri06 = 'photoUri06',
  photoUri07 = 'photoUri07',
  photoUri08 = 'photoUri08',
  photoUri09 = 'photoUri09',
  photoUri10 = 'photoUri10',

  occupantContractIds = 'occupantContractIds',
  occupantRepresentativeNames = 'occupantRepresentativeNames',

  // RESIDENCE property
  charge = 'charge',
  deposit = 'deposit',
  depositUnit = 'depositUnit',
  keyMoney = 'keyMoney',
  keyMoneyUnit = 'keyMoneyUnit',
  managementFee = 'managementFee',
  brokerageFee = 'brokerageFee',
  ownerName = 'ownerName',
  ownerPhoneNumber = 'ownerPhoneNumber',
  layout = 'layout',
  exclusiveArea = 'exclusiveArea',
  floorNumber = 'floorNumber',
  // RESIDENCE building
  constructDate = 'constructDate',
  nearestStation = 'nearestStation',
  minutesByWalkingFromStation = 'minutesByWalkingFromStation',
  minutesByBusFromStation = 'minutesByBusFromStation',
  minutesByCarFromStation = 'minutesByCarFromStation',
  storyNumber = 'storyNumber',
  structure = 'structure',
  totalPropertyCount = 'totalPropertyCount',
  occupiedCount = 'occupiedCount',
  showingPropertyCount = 'showingPropertyCount',
  applicationWithinPropertyCount = 'applicationWithinPropertyCount',
  closedPropertyCount = 'closedPropertyCount',

  occupancyRate = 'occupancyRate',
  vacantStartDate = 'vacantStartDate',

  addOns = 'addOns',
  sumamoruLinkable = 'addOns.sumamoruData.sumamoruLinkable',
  sumamoruProjectTypes = 'addOns.sumamoruData.sumamoruProjectTypes',
  sumamoruCode = 'addOns.sumamoruData.sumamoruCode',
  sumamoruInstallAppTypes = 'addOns.sumamoruData.sumamoruInstallAppTypes',

  settings = 'settings',
  // 内見予約
  brokerageType = 'settings.privateViewSetting.brokerageType',
  exclusiveExternalOrganizationIds = 'settings.privateViewSetting.exclusiveExternalOrganizationIds',
  canSelfView = 'settings.privateViewSetting.canSelfView',
  notesSettings = 'settings.privateViewSetting.notesSettings',
  contactSettings = 'settings.privateViewSetting.contactSettings',
  canReserveNoBitlockSpace = 'settings.privateViewSetting.canReserveNoBitlockSpace',

  // reservation
  reservable = 'reservable',
  reservableTimes = 'reservableTimes',
  reservableDayOfWeeks = 'reservableDayOfWeeks',
  reservableTimesEachDayOfWeek = 'reservableTimesEachDayOfWeek',
  maxCapacity = 'maxCapacity',
  reservableMaxCapacity = 'reservableMaxCapacity',
  linkReception = 'linkReception',
  facilityCount = 'facilityCount', // 保有する施設の件数
  equipmentCount = 'equipmentCount',

  // homehub 空間グループ管理
  homehubTmpSpaceGroupNames = 'homehubTmpSpaceGroupNames',
  homehubTmpSpaceGroupCodes = 'homehubTmpSpaceGroupCodes',
}

export interface AlgoliaScheduledSpaceStatus {
  id: string;
  status: SpaceStatus;
  startAt: EpochMillis;
  endAt: EpochMillis;
  createdAt: EpochMillis;
}

export interface SpaceAddOns {
  sumamoruData?: {
    sumamoruLinkable: boolean; // 標準採用建物かどうか
    sumamoruProjectTypes?: Array<SumamoruProjectType>; // どのスマモルプロジェクトに該当するものか、原則1つ、ただしゆくゆくは複数となる可能性が0でないため配列として保持する
    sumamoruCode: string; // 標準採用建物No.
    sumamoruPlans?: Array<SumamoruPlan>; // 建物にプランが設定されているか
    sumamoruInstallAppTypes?: Array<SumamoruInstallAppType>; // 入居者がインストールするべきアプリの種別（基本、homehub appだが非対応デバイスを扱う場合、bitlock appを使う）
  };
}

interface AlgoliaSpaceSettings {
  privateViewSetting: AlgoliaSpacePrivateViewSetting;
}

interface AlgoliaSpacePrivateViewSetting {
  brokerageType: FirestorePrivateViewBrokerageType;
  exclusiveExternalOrganizationIds: string[];
  canSelfView: boolean;
  notesSettings: FirestorePrivateViewNotesSettings;
  contactSettings?: FirestorePrivateViewContactSettings;
  canReserveNoBitlockSpace?: boolean;
}

export interface AlgoliaSpace extends AlgoliaObject {
  name: string;
  phoneticName?: string;
  code?: string;
  postcode?: string; // 郵便番号
  prefecture?: string; // 都道府県コード
  city?: string; // 市区町村
  afterCity?: string; // 市区町村以降（番地など）
  address: string;
  bitlockIds: string[];
  hasDevices?: boolean;
  photoUri: string;
  parents: string[];
  isGroup: boolean;
  parentNames?: string[];
  parentId: string;
  attributes: (SpaceAttribute | string)[];
  createdAt?: EpochMillis;

  status?: SpaceStatus; // 空間ステータス
  scheduledStatuses?: AlgoliaScheduledSpaceStatus[]; // 未来の空間ステータス一覧
  equipments?: string[]; // 設備情報, RESIDENCEの場合ここにリフォーム物件、バストイレ別など○×で表現できる項目を持つ
  memo?: string; // メモ
  privateReservationSiteRemarks?: string; // 備考

  photoUri02?: string;
  photoUri03?: string;
  photoUri04?: string;
  photoUri05?: string;
  photoUri06?: string;
  photoUri07?: string;
  photoUri08?: string;
  photoUri09?: string;
  photoUri10?: string;

  // 入居者の契約一覧
  occupantContractIds?: string[];
  // 入居者の契約代表者一覧
  occupantCustomerIds?: string[];
  // 入居中の契約代表者の名前一覧（表示用）
  occupantRepresentativeNames?: string[];

  // RESIDENCE property
  charge?: number; // 賃料
  deposit?: number; // 敷金
  depositUnit?: DepositKeyMoneyUnit; // 敷金の単位
  keyMoney?: number; // 礼金
  keyMoneyUnit?: DepositKeyMoneyUnit; // 礼金の単位
  managementFee?: number; // 管理費
  brokerageFee?: number; // 仲介手数料
  ownerName?: string; // オーナー名
  ownerPhoneNumber?: string; // オーナー連絡先電話番号
  layout?: string; // 間取り
  exclusiveArea?: number; // 専有面積
  floorNumber?: number; // 部屋の階
  // RESIDENCE building
  constructDate?: number; // 築年月日
  nearestStation?: string; // 最寄駅
  minutesByWalkingFromStation?: number; // 駅徒歩(分)
  minutesByBusFromStation?: number; // 駅からバスでかかる時間(分)
  minutesByCarFromStation?: number; // 駅から車でかかる時間(分)
  storyNumber?: number; // 建物の階数
  structure?: BuildingStructureType; // 構造
  totalPropertyCount?: number; // 総物件数
  occupiedCount?: number; // 入居者数
  showingPropertyCount?: number; // 公開中物件数
  applicationWithinPropertyCount?: number; // 申し込み中物件数
  closedPropertyCount?: number; // 停止中物件数
  occupancyRate?: number; // 入居率
  privateViewFormat?: string; // 内見フォーマット
  vacantStartDate?: EpochMillis; // 空室期間の開始日
  buildingDeviceCount?: number; // 建物中のデバイス数

  // アドオン用定義
  addOns?: SpaceAddOns;
  // 内見予約定義
  settings?: AlgoliaSpaceSettings;

  // 予約関連 (本当はspace直下でなく、reservationSettingのmap内に要素保持したい)
  hasReservableUserLimitation?: boolean; // 予約を行う入居者の制限があるか否か
  permitUserReserve?: boolean; // 入居者が予約可能か否か
  reservableUserDetectKeys?: Array<{spaceId?: string}>; // 予約可能な入居者を特定するためのキー情報
  permitDuplicatedReservation?: boolean; // 重複予約を許容するか否か
  reserveTimeUnit?: number; // 予約可能な時間単位 (min)

  reservable?: boolean;
  minReservationTime?: number; // 予約可能な最小時間 (min)
  maxReservationTime?: number; // 予約可能な最大時間 (m
  hasReservationFee?: boolean; // 予約料金が発生するか
  paymentMethodOfReservationFee?: Array<PaymentMethodOfReservationFee>; // 予約料金の支払い可能方法

  approvalAccounts?: string[];
  receptionKeyDataType?: number;
  reservableTimeZone?: string;

  reservableTimes?: {
    startTime?: string;
    endTime?: string;
  }[];
  reservableDayOfWeeks?: Array<DayOfWeek>;
  reservableTimesEachDayOfWeek?: {
    [key in DayOfWeek]: Array<{
      startTime?: string;
      endTime?: string;
    }>;
  };
  maxCapacity?: number;
  reservableMaxCapacity?: number;
  linkReception?: boolean;

  usageType?: FacilityUsageType;

  // homehub 空間グループ管理
  homehubTmpSpaceGroupNames?: string[];
  homehubTmpSpaceGroupCodes?: string[];
  numberOfVote?: number;
}

const searchableAttributes = {
  code: AlgoliaSpacesAttributes.code,
  address: AlgoliaSpacesAttributes.address,
  name: AlgoliaSpacesAttributes.name,
  phoneticName: AlgoliaSpacesAttributes.phoneticName,
  parentNames: AlgoliaSpacesAttributes.parentNames,
  occupantRepresentativeNames: AlgoliaSpacesAttributes.occupantRepresentativeNames,
  // homehub 空間グループ管理
  homehubTmpSpaceGroupNames: AlgoliaSpacesAttributes.homehubTmpSpaceGroupNames,
  homehubTmpSpaceGroupCodes: AlgoliaSpacesAttributes.homehubTmpSpaceGroupCodes,
};
export default class AlgoliaSpaces extends LegacyIndexBase<AlgoliaSpace> {
  protected name = LegacyIndexName.spaces;
  protected rawIndexName: string;

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    id: AlgoliaSpacesAttributes.id,
    bitlockIds: AlgoliaSpacesAttributes.bitlockIds,
    hasDevices: AlgoliaSpacesAttributes.hasDevices,
    parents: AlgoliaSpacesAttributes.parents,
    parentNames: AlgoliaSpacesAttributes.parentNames,
    isGroup: AlgoliaSpacesAttributes.isGroup,
    parentId: AlgoliaSpacesAttributes.parentId,
    attributes: AlgoliaSpacesAttributes.attributes,
    status: AlgoliaSpacesAttributes.status,
    code: AlgoliaSpacesAttributes.code,
    sumamoruLinkable: AlgoliaSpacesAttributes.sumamoruLinkable,
    facilityCount: AlgoliaSpacesAttributes.facilityCount,
    equipmentCount: AlgoliaSpacesAttributes.equipmentCount,
    sumamoruProjectTypes: AlgoliaSpacesAttributes.sumamoruProjectTypes,
    homehubTmpSpaceGroupCodes: AlgoliaSpacesAttributes.homehubTmpSpaceGroupCodes,
  });

  public searchableAttributes = AlgoliaSpaces.searchableAttributes;

  public static getIndex = (organizationId: string) => new AlgoliaSpaces(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaSpaces.facets();
}
