import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {
    WifiBleConnection,
    WifiBleConnectionMeta,
    WifiLinkedDevice,
} from '../../../firebase/firestore/references/organizations/wifi-connectors/firestore-organizations-wifi-connectors';
import {
    FirestoreConnectableDeviceData
} from '../../../firebase/firestore/references/organizations/connectable-devices/firestore-connectable-devices';
import AlgoliaBase from '../../algolia-base';
import {Timestamp} from '../../../firebase/firestore/firestore';

export enum AlgoliaWifiConnectorsAttributes {
  id = 'id',
  createdAt = 'createdAt',
  deviceId = 'deviceId',
  linkingDeviceIds = 'linkingDeviceIds',
  firmware = 'firmware',
}

export interface AlgoliaWifiConnectorData extends AlgoliaObject {
  id: string; // uuid(じゃないけど)と同一
  uuid: string; // miraApiからコネクタ登録ごとに付与される、uuidを魔改造した文字列
  wifiConnectorId: string; // コネクタのハード一意なMacAddress
  name: string;
  deleted: boolean;
  organizationId?: string; // owner
  uid?: string; // owner
  firmware?: string; // はじめてsystem_startを受け取った後にnullでなくなる
  linkedDevices: {
    [deviceId: string]: WifiLinkedDevice;
  };
  bleConnection?: WifiBleConnection; // 接続試行中〜切断するまで存在する
  bleReservedConnection?: WifiBleConnectionMeta; // スキャン中にコマンド実行しようとしたときなどに入る
  latestBleConnection?: WifiBleConnection; // 最終実行のステータス等をアプリからとる用

  hasLogDeviceAddresses: string[]; // 中身は、`${deviceId}@${deviceAddress}`
  retryTime?: number; // コネクションに失敗した場合、1回までretryする。
  connectableDevices?: FirestoreConnectableDeviceData[]; // コネクタと接続可能なbitlock情報を保持しておく。
  readLogCount?: number; // 一回のsystem_alive_pongでログ取得ロジックを投げた回数。
  advFiltered?: boolean; // scan対象のfilterがかかっているかどうかをチェックする。falseの場合は、system_alive_pongでfilterをかける。
  linkingDeviceIds?: string[];
  // logTimePeriodFromとlogTimePeriodToが両方nullの場合はログを取らない
  logTimePeriodFrom: string; // ログ取得開始時間
  logTimePeriodTo: string; // ログ取得終了時間
  createdAt?: Timestamp;
}

export default class AlgoliaWifiConnectors extends AlgoliaBase<AlgoliaWifiConnectorData> {
  public static readonly facets = () => ({
    id: AlgoliaWifiConnectorsAttributes.id,
    deviceId: AlgoliaWifiConnectorsAttributes.deviceId,
    createdAt: AlgoliaWifiConnectorsAttributes.createdAt,
    linkingDeviceIds: AlgoliaWifiConnectorsAttributes.linkingDeviceIds,
    firmware: AlgoliaWifiConnectorsAttributes.firmware,
  });
  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaWifiConnectors(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.wifiConnectors;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaWifiConnectors.facets();
}
