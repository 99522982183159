import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {
    AdditionalItem
} from '../../../firebase/firestore/references/organizations/space-attributes/firestore-space-attributes';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaSpaceAttributeAttributes {
  id = 'id',
  code = 'code',
  name = 'name',
  additionalItems = 'additionalItems',
}

export interface AlgoliaSpaceAttributeData extends AlgoliaObject {
  code: string;
  name: string;
  additionalItems: AdditionalItem[];
}

export default class AlgoliaSpaceAttributes extends AlgoliaBase<AlgoliaSpaceAttributeData> {
  public static readonly facets = () => ({
    // for filter
    id: AlgoliaSpaceAttributeAttributes.id,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaSpaceAttributes(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.spaceAttributes;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaSpaceAttributes.facets();
}
