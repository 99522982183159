import {
    OrganizationsTeleconferenceSettings
} from '../firebase/firestore/references-types/organizations/commonSettings/firestore-types-organizations-teleconference-settings';
import {
    FirestorePrivateViewSettingState
} from '../firebase/firestore/references/organizations/common-settings/space-setting/space-private-view-settings/firestore-organization-space-private-view-settings';
import {KeyIssueSettingsData} from '../firebase/firestore/references/organizations/secrets/firestore-keyIssue-settings';
import {
    UsableFunctions
} from '../firebase/firestore/references/organizations/usable-functions/firestore-usableFunctions';
import ReduxUtil from './ReduxUtil';
import {
    FirestoreOrganizationBaseNoticeData,
    FirestoreOrganizationDepartureNoticeData,
} from '../firebase/firestore/references/organizations/secrets/firestore-notification-settings';
import {SumamoruProjectType} from '../business-types/residence/organizations/add-ons/sumamoru-project-type';
import {
    OrgCoworkingSettingResponseData
} from '../firebase/functions/api-types/organizations/common-settings/coworking-settings/api-types-org-coworking-settings';
import {
    FirestoreOrganizationDashboardSettingData
} from '../firebase/firestore/references/organizations/secrets/firestore-dashboard-settings';
import {
    OrgReservationNotificationSettingResponseData
} from '../firebase/functions/api-types/organizations/common-settings/notification-settings/reservation-notification-setting/api-types-org-reservation-notification-setting';
import {SumamoruPlan} from '../business-types/residence/organizations/add-ons/SumamoruPlan';

export interface AddOnSumamoruState {
  sumamoruLinkable: boolean;
  sumamoruProjectTypes?: Array<SumamoruProjectType>;
  sumamoruPlans?: Array<SumamoruPlan>;
  managementCompanyName: string;
  managementCompanyAddress: string;
  managementCompanyPhoneNumber: string;
}

export interface PrivateViewReservationSiteSettingState {
  isUsable: boolean;
  siteName: string;
  // サイト全体の内見予約設定のポリシー
  privateViewSitePolicy?: FirestorePrivateViewSettingState & {settingId: string};
}

export interface SettingsState {
  // 通知先メールアドレス
  notificationEmail: string;
  // バッテリー低下通知先メールアドレス
  // 通知する内容の担当が変わった場合に、それぞれメールアドレスを設定できている必要があるんじゃないかという
  // 意図でわけて定義しています。
  // 本質的には、通知先メールアドレスと通知内容をそれぞれ配列で持たなければ行けないんじゃないかと予想。
  rowBatteryNotificationEmail: string;
  // 退去予定通知設定
  departureNoticeConfig?: FirestoreOrganizationDepartureNoticeData;
  rowBatteryNoticeConfig?: FirestoreOrganizationBaseNoticeData;
  // AddOnの設定
  addOns: {
    sumamoruData?: AddOnSumamoruState;
  };
  // [RESIDENCE]業者に対する自動削除されないカギ発行
  keyIssueSettings?: KeyIssueSettingsData;
  usableFunctions: UsableFunctions[];
  // [RESIDENCE]内見予約サイト利用設定
  privateViewReservationSiteSetting?: PrivateViewReservationSiteSettingState;
  teleconferenceSettings?: OrganizationsTeleconferenceSettings;
  // [COWORKING]支払い方法等のコワーキングの設定
  coworkingSettings?: OrgCoworkingSettingResponseData;
  // 直前の自動ログ取得に失敗したときにbM通理する
  autoLoggingFailureNotificationEmail: string;
  dashboardSettings?: Partial<FirestoreOrganizationDashboardSettingData>;
  reservationNotificationSetting?: OrgReservationNotificationSettingResponseData;

  // NOTE: sublease用、超暫定対応。
  //  管理画面の同一URLに対する画面の切り替えに利用。
  useSpaceUsageForOccupancy?: boolean;

  useAuthDeviceOccupancyMode?: boolean;
}

const getInitialState = (): SettingsState => ({
  notificationEmail: '',
  rowBatteryNotificationEmail: '',
  addOns: {},
  departureNoticeConfig: undefined,
  keyIssueSettings: undefined,
  usableFunctions: [],
  privateViewReservationSiteSetting: undefined,
  coworkingSettings: undefined,
  autoLoggingFailureNotificationEmail: '',
  dashboardSettings: undefined,
  reservationNotificationSetting: undefined,
  useSpaceUsageForOccupancy: false,
  useAuthDeviceOccupancyMode: false,
});

const TYPE_PREFIX = 'SETTINGS';
const type = t => TYPE_PREFIX + t;

export const SettingsActions = {
  clear: () => ({type: type('clear')}),
  setSettings: (settings: Partial<SettingsState>) => ({
    type: type('setSettings'),
    settings: settings,
  }),
  setNotificationEmail: (notificationEmail: string) => ({
    type: type('setNotificationEmail'),
    notificationEmail: notificationEmail,
  }),
  setRowBatteryNotificationEmail: (notificationEmail: string) => ({
    type: type('setRowBatteryNotificationEmail'),
    rowBatteryNotificationEmail: notificationEmail,
  }),
  setDepartureNoticeConfig: (departureNoticeConfig: FirestoreOrganizationDepartureNoticeData) => ({
    type: type('setDepartureNoticeConfig'),
    departureNoticeConfig: departureNoticeConfig,
  }),
  setRowBatteryNoticeConfig: (rowBatteryNoticeConfig: FirestoreOrganizationBaseNoticeData) => ({
    type: type('setRowBatteryNoticeConfig'),
    rowBatteryNoticeConfig: rowBatteryNoticeConfig,
  }),
  setSumamoruData: (sumamoruData: AddOnSumamoruState) => ({
    type: type('setSumamoruData'),
    sumamoruData: sumamoruData,
  }),
  setKeyIssueSettings: (keyIssueSettings: KeyIssueSettingsData) => ({
    type: type('setKeyIssueSettings'),
    keyIssueSettings: keyIssueSettings,
  }),
  setPrivateViewReservationSiteSettings: (privateViewSettings: PrivateViewReservationSiteSettingState) => ({
    type: type('setPrivateViewReservationSiteSettings'),
    privateViewSettings: privateViewSettings,
  }),
  setAutoLoggingFailureEmail: (autoLoggingFailureNotificationEmail: string) => ({
    type: type('setAutoLoggingFailureEmail'),
    autoLoggingFailureNotificationEmail: autoLoggingFailureNotificationEmail,
  }),
  setDashboardSettings: (dashboardSettings: Partial<FirestoreOrganizationDashboardSettingData>) => ({
    type: type('setDashboardSettings'),
    dashboardSettings: dashboardSettings,
  }),
  setReservationNotificationSetting: (
    reservationNotificationSetting: OrgReservationNotificationSettingResponseData,
  ) => ({
    type: type('setReservationNotificationSetting'),
    reservationNotificationSetting: reservationNotificationSetting,
  }),
};

const SettingsReducerDefinitions = {
  clear: () => getInitialState(),
  setSettings: (state, action) => ({
    ...state,
    ...action.settings,
  }),
  setNotificationEmail: (state, action) => ({...state, notificationEmail: action.notificationEmail}),
  setDepartureNoticeConfig: (state, action) => ({...state, departureNoticeConfig: action.departureNoticeConfig}),
  setRowBatteryNoticeConfig: (state, action) => ({...state, rowBatteryNoticeConfig: action.rowBatteryNoticeConfig}),
  setSumamoruData: (state, action) => ({
    ...state,
    addOns: {...state.addOns, sumamoruData: action.sumamoruData},
  }),
  setKeyIssueSettings: (state, action) => ({...state, keyIssueSettings: action.keyIssueSettings}),
  setPrivateViewReservationSiteSettings: (state, action) => ({
    ...state,
    privateViewReservationSiteSetting: action.privateViewSettings,
  }),
  setDashboardSettings: (state, action) => ({...state, dashboardSettings: action.dashboardSettings}),
  setReservationNotificationSetting: (state, action) => ({
    ...state,
    reservationNotificationSetting: action.reservationNotificationSetting,
  }),
};

const settingsReducer = ReduxUtil.createReducer(getInitialState(), SettingsReducerDefinitions, TYPE_PREFIX);

export {settingsReducer};
