/**
 * 例外発生の結果、画面への表示を伴わないもの（実装に問題があるケース）
 */
export default class CommonError extends Error {
  public readonly error: Error | undefined;
  public readonly code: string;

  constructor(code: string, message?: string, error?: Error) {
    super(message ? message : '');
    this.code = code;
    this.error = error;
  }
}
