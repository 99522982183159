import type {AlgoliaObject} from '../../algolia';
import {ContractPlanChargeCycle} from '../../../firebase/firestore/references/organizations/contract-plans/firestore-contract-plans';
import {
  ActualContractData,
  ContractOtherChargeData,
  CustomerContractStatus,
} from '../../../firebase/firestore/references/organizations/customer-contracts/firestore-customer-contracts';
import {EpochMillis} from '../../../common-types/numbers';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

export enum AlgoliaCustomerContractAttributes {
  id = 'id',
  contractNo = 'contractNo',
  customerId = 'customerId',
  applicationId = 'applicationId',
  contractDate = 'contractDate',
  contractStartDate = 'contractStartDate',
  contractEndDate = 'contractEndDate',
  actualContracts = 'actualContracts',
  chargeAmount = 'chargeAmount',
  chargeCycle = 'chargeCycle',
  otherCharges = 'otherCharges',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  occupyScheduledDate = 'occupyScheduledDate',
  leaveFillDate = 'leaveFillDate',
  vacateDate = 'vacateDate',
  expired = 'expired',
  renewed = 'renewed',
  customerName = 'customerName',
  customerEmail = 'customerEmail',
  customerPhoneNumber = 'customerPhoneNumber',
  customerMobilePhoneNumber = 'customerMobilePhoneNumber',
  contractStatus = 'contractStatus',
}

export interface AlgoliaCustomerContractData extends AlgoliaObject {
  contractNo: string;
  customerId: string;
  applicationId?: string;
  contractDate: EpochMillis; // milliseconds
  contractStartDate: EpochMillis; // milliseconds
  contractEndDate: EpochMillis; // milliseconds
  actualContracts: ActualContractData[];
  chargeAmount: number; // 契約に紐づく利用料の総額（otherChargesは含めない）
  chargeCycle: ContractPlanChargeCycle; // 決済サイクル
  otherCharges: ContractOtherChargeData[];
  createdAt?: EpochMillis;
  updatedAt?: EpochMillis;
  // for RESIDENCE
  occupyScheduledDate?: EpochMillis; // 入居予定日
  leaveFillDate?: EpochMillis; // 退去申告日
  vacateDate?: EpochMillis; // 明け渡し(退去)日
  expired: boolean;
  renewed: boolean;
  contractStatus?: CustomerContractStatus; // 契約の状態を表す

  // 表示用
  customerName: string;
  customerEmail: string;
  customerPhoneNumber: string;
  customerMobilePhoneNumber: string;
}

export default class AlgoliaCustomerContracts extends LegacyIndexBase<AlgoliaCustomerContractData> {
  protected name = LegacyIndexName.customerContracts;
  protected rawIndexName: string;

  public static readonly facets = () => ({
    // for facet
    id: AlgoliaCustomerContractAttributes.id,
    customerId: AlgoliaCustomerContractAttributes.customerId,
    // for filter
    expired: AlgoliaCustomerContractAttributes.expired,
    renewed: AlgoliaCustomerContractAttributes.renewed,
    contractStatus: AlgoliaCustomerContractAttributes.contractStatus,
  });

  public static getIndex = (organizationId: string) => new AlgoliaCustomerContracts(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaCustomerContracts.facets();
}
