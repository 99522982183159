import {EnumAlternativeUnion} from '@bitkey-service/functions-common-types/lib/common/typeUtils';

/**
 * 基本的に /_next/model/thing/commonThingModel.ts を利用するようにしてください。
 * 20220912 @shinya.umino
 */

/**
 * @deprecated
 */
export type CommonThing = {
  id: string;
  thingId?: string;
  types: CommonThingType[];
  name?: string;
  nameJp?: string;
  nameEn?: string;
  code: string;
  organizationId?: string;
  hardware?: Hardware;
  features: Features[];
  address?: Address;
  battery?: Battery;
  latestStatus?: {
    status?: BitlinkStatus;
    timestamp?: number;
  };
  spaceId?: string;
  linkDeviceSetting?: LinkDeviceSetting;
  authDeviceSetting?: AuthDeviceSetting;
  lockDeviceSetting?: LockDeviceSetting;
  lastUpdatedBy?: DataOperationLog;
  registeredBy?: DataOperationLog;
};

/**
 * @deprecated
 */
export const CommonThingType = {
  Bitlock: 'bitlock',
  BitlockGate: 'bitlockGate',
  ElectricLock: 'electricLock',
  FlapperGate: 'flapperGate',
  Entrance: 'entrance',
  Bitreader: 'bitreader',
  FaceRecognitionTablet: 'faceRecognitionTablet',
  Reader: 'reader',
  EdgeWorkstation: 'edgeWorkstation',
  Display: 'display',
  Pc: 'pc',
  Car: 'car',
  Bike: 'bike',
  SecurityCamera: 'securityCamera',
  PeopleCountCamera: 'peopleCountCamera',
  Bitreception: 'bitreception',
  Bitlink: 'bitlink',
  WifiConnector: 'wifiConnector',
  Locker: 'locker',
  Cabinet: 'cabinet',
  Bitbutton: 'bitbutton',
  Bitbox: 'bitbox',
  DoorIntercom: 'doorIntercom',
  Opelo: 'opelo',
  OpeloEntrance: 'opeloEntrance',
};
export type CommonThingType = EnumAlternativeUnion<typeof CommonThingType>;

/**
 * @deprecated
 */
export type Hardware = {
  model?: CommonTypesBleDeviceType;
  bitkeyUDID?: string;
  peripheralId?: string;
  serialNo?: string;
  firmware?: string;
  childIndex?: number;
  macAddress?: string;
};

/**
 * @deprecated
 */
const Features = {
  AuthDevice: 'AuthDevice',
  AccessPoint: 'AccessPoint',
  LockDevice: 'LockDevice',
  SensorDevice: 'SensorDevice',
  Equipment: 'Equipment',
  Furniture: 'Furniture',
  Vehicle: 'Vehicle',
  CameraDevice: 'CameraDevice',
  ReceptionDevice: 'ReceptionDevice',
  RoomSupportDevice: 'RoomSupportDevice',
  IoTDevice: 'IoTDevice',
  Key: 'Key',
  StorageDevice: 'StorageDevice',
} as const;
export type Features = EnumAlternativeUnion<typeof Features>;

/**
 * @deprecated
 */
export type Address = {
  county?: string;
  prefecture?: string;
  city?: string;
  sublocality?: string;
  others?: string;
};

/**
 * @deprecated
 */
export type Battery = {
  value: number;
  level?: 0 | 1 | 2 | 3;
};

/**
 * @deprecated
 */
export enum CommonTypesBleDeviceType {
  Bitlock_LITE = 'bitlock LITE',
  Bitlock_Pro = 'bitlock Pro',
  Bitlock_PRO2 = 'bitlock PRO2',
  Bitlock_GATE = 'bitlock GATE',
  Bitreader = 'bitreader',
  Bitreader_G2 = 'bitreader G2',
  BitreaderPlus = 'bitreader+',
  Bit_readerPlus_WiFi = 'bit reader+ Wi-Fi',
  Bit_readerPlus_LTE = 'bit reader+ LTE',
  Bitbutton_KIDS = 'bitbutton KIDS',
  Bitbutton_ADULT = 'bitbutton ADULT',
  Bitbutton_Card = 'bitbutton Card',
  Bitlocker_for_Okamura = 'bitlocker for Okamura',
  Bitlocker_model2 = 'bitlocker model2',
  Bitlink_WiFi = 'bitlink Wi-Fi',
  Bitlink_LTE = 'bitlink LTE',
  WiFi_Connector = 'Wi-Fi Connector',
  Bitlock_MINI = 'bitlock MINI',
  Fuki_lock = 'fuki lock',
  Alpha_ePPH_lock = 'alpha ePPH lock',
  Alpha_ed_lock = 'alpha ed lock',
  Bitlock_LITE_ec = 'bitlock LITE(ec)',
  BITLOCKGATEFORELEVATOR = 'BITLOCK_GATE_FOR_ELEVATOR',
  Bitbox = 'bitbox',
  UNKNOWN = 'UNKNOWN',
}

/**
 * @deprecated
 */
export type DataOperationLog = {
  personaId?: string;
  organizationId?: string;
  memo?: string;
  systemMemo?: string;
  timestamp?: number;
};

/**
 * @deprecated
 */
export enum BitlinkModel {
  Bitlink_LTE = 'bitlink LTE',
  Bitlink_WiFi = 'bitlink Wi-Fi',
  WiFi_Connector = 'Wi-Fi Connector',
}

export enum BitlinkStatus {
  Alive = 'alive',
  Dead = 'dead',
}

/**
 * @deprecated
 */
export type LinkDeviceSetting = {
  targets?: string[];
  things?: {
    id?: string;
    type?: CommonThingType;
  }[];
  hardwareInformation?: {
    model?: BitlinkModel;
    firmwareVersion?: string;
    macAddress?: string;
  };
  hardwareSetting?: {
    ledOn?: boolean;
  };
  bleSetting?: {
    scanInterval?: number;
    scanWindow?: number;
    advertiseInterval?: number;
    txPower?: number;
    maxConnectionNumber?: number;
  };
  advSetting?: {
    batteryValue?: boolean;
    log?: boolean;
    doorStatus?: boolean;
    lockStatus?: boolean;
    batteryExpired?: boolean;
    registration?: boolean;
  };
  logTimePeriodFrom?: string;
  logTimePeriodTo?: string;
  wifi?: {
    ssid?: string;
    wifiFrequency?: number;
  };
  linkDevices?: LinkDevice[];
};

/**
 * @deprecated
 */
export type LinkDevice = {
  thingId?: string;
  bitkeyUDID?: string;
  type?: CommonThingType;
};

/**
 * @deprecated
 */
export type LockDeviceSetting = {
  gateId?: string;
  bkp?: BkpObjectEntity;
  effectiveKeyTypes: EffectiveKeyType[];
  firmware?: string;
  lockDeviceIds?: string[];
  model?: string;
  serialNo?: string;
  side?: SideType;
  childIndex?: number;
  controlledBy?: ControlledBy;
  things?: CommonRelatedThing[];
  uuid?: string;
  udid?: string;
};

/**
 * @deprecated
 */
export const CommonTypeLockDeviceController = {
  BK_WifiConnector: 'BK_WifiConnector', // wifiコネクタ
  BK_Bitlink: 'BK_Bitlink', // bitlink
  EdgeWorkStation: 'EdgeWorkStation',
  ParentLockerDevice: 'ParentLockerDevice',
} as const;
export type CommonTypeLockDeviceController = EnumAlternativeUnion<typeof CommonTypeLockDeviceController>;

/**
 * @deprecated
 */
type ControlledBy = {
  thingId: string;
  mappingCode?: string; // 制御先でマッピングするためのCodeがあれば
  type: CommonTypeLockDeviceController;
};

/**
 * @deprecated
 */
type BkpObjectEntity = {
  type: string;
  ownerUid: string;
  objectId: string;
  bkpHoles: BkpHole[];
};

/**
 * @deprecated
 */
type BkpHole = {
  holeId: string;
  holeName: string;
};

/**
 * @deprecated
 */
export type AuthDeviceSetting = {
  bkp?: BkpTreasureBoxEntity | BkpMemberPropetyEntity | BkpArbitraryPropetyEntity;
  effectiveKeyTypes: EffectiveKeyType[];
  firmware?: string;
  lockDeviceIds?: string[];
  model?: string;
  serialNo?: string;
  side?: SideType;
  childIndex?: number;
  things?: CommonRelatedThing[];
  uuid?: string;
  udid?: string;
};

/**
 * @deprecated
 */
type BkpTreasureBoxEntity = {
  type: string;
  treasureBoxId: string;
};

/**
 * @deprecated
 */
type BkpMemberPropetyEntity = {
  type: string;
  propertyName: string;
  propertyValue: string;
  memberPropertyLabelId?: string;
};

/**
 * @deprecated
 */
type BkpArbitraryPropetyEntity = {
  type: string;
  propertyName: string;
  propertyValue: string;
  arbitraryPropertyId: string;
  arbitraryPropertyLabelId: string;
};

/**
 * @deprecated
 */
type CommonRelatedThing = {
  id: string;
  type: CommonThingType;
};

/**
 * @deprecated
 */
const SideType = {
  Outside: 'outside',
  Inside: 'inside',
} as const;
type SideType = EnumAlternativeUnion<typeof SideType>;

/**
 * @deprecated
 */
const EffectiveKeyType = {
  Smartphone: 'smartphone',
  NfcCard: 'nfcCard',
  QrCode: 'qrCode',
  FaceRecognition: 'faceRecognition',
  Reception: 'reception',
  Wifi: 'wifi',
} as const;
type EffectiveKeyType = EnumAlternativeUnion<typeof EffectiveKeyType>;
