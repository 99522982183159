import {Client} from 'algoliasearch';
import type {AlgoliaObject} from '../../algolia';
import {PassportAuthority} from '../../../firebase/firestore/references/users/receivedPassports/firestore-users-receivedPassports';
import {PersonType} from '../../../firebase/firestore/references/organizations/space-types/firestore-spaceTypes';
import {
  FirestoreOrganizationKeyAuthorityGroupName,
  FirestoreOrganizationKeyType,
} from '../../../firebase/firestore/references/organizations/keys/firestore-keys';
import {EpochMillis} from '../../../common-types/numbers';
import {Operator} from '../../../firebase/firestore/firestore';
import {Days, RepeatTypes} from '@bitkey-service/bkp-sdk-types';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

export enum AlgoliaKeyAttributes {
  id = 'id',
  spaceId = 'spaceId',
  deviceId = 'deviceId',
  holeId = 'holeId',
  contractId = 'contractId',
  additionalHoles = 'additionalHoles',
  keyType = 'keyType',
  authorities = 'authorities',
  authorityGroups = 'authorityGroups',
  organizationId = 'organizationId',
  organizationPersonaId = 'organizationPersonaId',
  personaId = 'personaId',
  externalId = 'externalId',
  personType = 'personType',
  customerId = 'customerId',
  usageStart = 'usageStart',
  usageEnd = 'usageEnd',
  publishStart = 'publishStart',
  publishEnd = 'publishEnd',
  usableTimes = 'usableTimes',
  message = 'message',
  useCount = 'useCount',
  remainingUsableCount = 'remainingUsableCount',
  email = 'email',
  createdAt = 'createdAt',
  createOperator = 'createOperator',
  repeatFirstDayOfWeek = 'repeatFirstDayOfWeek',
  repeatInterval = 'repeatInterval',
  repeatStartDate = 'repeatStartDate',
  repeatType = 'repeatType',
  repeatUseMinutes = 'repeatUseMinutes',
  repeatUseStartAt = 'repeatUseStartAt',
  repeatWeekOfMonth = 'repeatWeekOfMonth',
  repeatWeeklyDays = 'repeatWeeklyDays',
  repeatStart = 'repeatStart',
  repeatEnd = 'repeatEnd',
  standardTime = 'standardTime',
  authDeviceContractIds = 'authDeviceContractIds',
}

export interface AlgoliaKeyData extends AlgoliaObject {
  spaceId?: string;
  deviceId: string;
  holeId: string;
  contractId?: string;
  additionalHoles?: {
    holeId: string;
    contractId: string;
  }[];
  keyType: FirestoreOrganizationKeyType;
  authorities?: PassportAuthority[];
  authorityGroups?: FirestoreOrganizationKeyAuthorityGroupName[];

  authDeviceContractIds?: string[];

  organizationId?: string;
  organizationPersonaId?: string;
  personaId: string;
  externalId?: string;
  personType: PersonType;
  customerId?: string;
  usageStart?: EpochMillis;
  usageEnd?: EpochMillis;
  publishStart?: EpochMillis;
  publishEnd?: EpochMillis;
  usableTimes?: number;
  message?: string;
  useCount: number;
  remainingUsableCount: number;
  email?: string;
  createdAt: EpochMillis;
  createOperator?: Operator;
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: RepeatTypes;
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];
  repeatStart?: string; // ex) HH:mm:ss
  repeatEnd?: string; // ex) HH:mm:ss
  standardTime?: number; // ex) 9 <- JST
}

export default class AlgoliaKeys extends LegacyIndexBase<AlgoliaKeyData> {
  protected name = LegacyIndexName.keys;
  protected rawIndexName: string;

  public static readonly facets = () => ({
    keyType: AlgoliaKeyAttributes.keyType,
    authorityGroups: AlgoliaKeyAttributes.authorityGroups,
    personType: AlgoliaKeyAttributes.personType,
    usageStart: AlgoliaKeyAttributes.usageStart,
    usageEnd: AlgoliaKeyAttributes.usageEnd,
    publishStart: AlgoliaKeyAttributes.publishStart,
    publishEnd: AlgoliaKeyAttributes.publishEnd,
    usableTimes: AlgoliaKeyAttributes.usableTimes,
    useCount: AlgoliaKeyAttributes.useCount,
    remainingUsableCount: AlgoliaKeyAttributes.remainingUsableCount,
    // relation用
    id: AlgoliaKeyAttributes.id,
    spaceId: AlgoliaKeyAttributes.spaceId,
    deviceId: AlgoliaKeyAttributes.deviceId,
    holeId: AlgoliaKeyAttributes.holeId,
    contractId: AlgoliaKeyAttributes.contractId,
    additionalHoles: AlgoliaKeyAttributes.additionalHoles,
    authorities: AlgoliaKeyAttributes.authorities,
    organizationId: AlgoliaKeyAttributes.organizationId,
    organizationPersonaId: AlgoliaKeyAttributes.organizationPersonaId,
    personaId: AlgoliaKeyAttributes.personaId,
    externalId: AlgoliaKeyAttributes.externalId,
    customerId: AlgoliaKeyAttributes.customerId,
    authDeviceContractIds: AlgoliaKeyAttributes.authDeviceContractIds,
  });

  public static getIndex = (organizationId: string) => new AlgoliaKeys(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaKeys.facets();
}
