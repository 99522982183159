import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaRoutesAttributes {
  id = 'id',
  name = 'name',
  goalDeviceId = 'goalDeviceId',
  goalDeviceName = 'goalDeviceName',
  viaDeviceIds = 'viaDeviceIds',
  memo = 'memo',
  activated = 'activated',
  deleted = 'deleted',
}

export interface AlgoliaRoute extends AlgoliaObject {
  id: string;
  name: string;
  goalDeviceId: string;
  goalDeviceName: string;
  viaDeviceIds?: string[];
  memo?: string;
  activated: boolean;
  deleted: boolean;
}

export default class AlgoliaRoutes extends AlgoliaBase<AlgoliaRoute> {
  public static readonly facets = () => ({
    goal: AlgoliaRoutesAttributes.goalDeviceName,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaRoutes(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.routes;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => this.facets();
}
