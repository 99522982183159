import {AlgoliaIndexName} from '../../algolia/algolia';
import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';
import {V2FirebaseHomehubBackendFunctions} from '../../firebase/functions/v2-firebase-homehub-backend-functions';

export default class AlgoliaSettingsService {
  public static updateSetting = (indexName: AlgoliaIndexName) =>
    V2FirebaseHomehubOldApiFunctions.call('organizations-algolia-updateIndexSetting', indexName);

  public static updateData = (indexName: AlgoliaIndexName) =>
    V2FirebaseHomehubOldApiFunctions.call('organizations-algolia-updateIndexData', indexName);

  public static updateAlgoliaIndexSettings = async (): Promise<void> => {
    await V2FirebaseHomehubBackendFunctions.put(
      `homehubAccount/${V2FirebaseHomehubBackendFunctions.getVersion()}/organizations/algoliaIndexSettings`,
    );
    return;
  };
}
