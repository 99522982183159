import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import FunctionsErrorCodeEnum, {ServiceError} from '../../errors/service-error';
import axios, {AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';

import AuthService from '../../services/auth/auth-service';
import CommonError from '../../errors/common-error';
import HttpStatus from 'http-status-codes';
import {SystemAuditLogObserveService} from '../../services/system-audit-logs/system-audit-log-observe-service';
import {incrInitOldAPI} from '../../redux/api';
import {store} from '../../redux/store';

const _authErrorWrapper = <T>(fn: () => AxiosPromise<T>) =>
  fn().catch(e => {
    if (e.response && e.response.status === HttpStatus.UNAUTHORIZED) {
      AuthService.logout({sessionError: true});
    }
    throw e;
  });

enum HomehubOldApiVersions {
  DefaultApiVersion = 'v1',
  V2DataModelVersion = 'v2',
}

const HOMEHUB_V2_OLD_API_PREFIX = 'homehubOld/v2/';

/**
 * 旧bitlock-serviceに該当するCloud Functionsを使う場合は全てこれを経由する。
 * 実際の呼び出しはfunctionsディレクトリ以下の個別のファイルで実装。
 * APIの接続先をlocalhost等に変更する場合はfirebase.tsを参照。
 *
 * デフォルトはbitlock-serviceのソースがdeployされているv1に向いているが、
 * changeToV2()メソッド実行後は homehub-old-apiのソースがdeployされているv2に向き先を変更する
 */
export class V2FirebaseHomehubOldApiFunctions {
  private static version: HomehubOldApiVersions;
  private static axios: AxiosInstance;

  /**
   * 旧bitlock-serviceへのAPI処理をv1で初期化する
   * 環境初期化時とログアウト時に実施すること
   */
  public static init = (baseURL: string) => {
    V2FirebaseHomehubOldApiFunctions.version = HomehubOldApiVersions.DefaultApiVersion;
    V2FirebaseHomehubOldApiFunctions.axios = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    store.dispatch(incrInitOldAPI());
  };

  /**
   * 現在のversionを取得
   * v1 = 旧bitlock-service
   * v2 = homehub-old-api
   */
  public static getVersion = () => V2FirebaseHomehubOldApiFunctions.version;

  /**
   * APIの向き先をv2に変更する処理
   */
  public static changeToV2 = () => {
    // NOTE: setしたtokenが消えるので逃がしておく
    const preHeaders = V2FirebaseHomehubOldApiFunctions.axios.defaults.headers.common;
    V2FirebaseHomehubOldApiFunctions.version = HomehubOldApiVersions.V2DataModelVersion;
    const v2OldApiHost = process.env.REACT_APP_V2_HOMEHUB_API_DOMAIN;
    V2FirebaseHomehubOldApiFunctions.axios = axios.create({
      baseURL: v2OldApiHost,
      headers: preHeaders,
    });
    store.dispatch(incrInitOldAPI());
  };

  private static setSystemAuditLog = (requestMethod: string, requestUrl: string, requestBody?: any) => {
    if (!SystemAuditLogObserveService.getInstance().isObserving()) {
      return;
    }
    SystemAuditLogObserveService.getInstance().setRequest({
      method: requestMethod,
      url: requestUrl,
      body: requestBody,
    });
  };

  public static post = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> =>
    _authErrorWrapper(() =>
      V2FirebaseHomehubOldApiFunctions.axios.post<T>(
        V2FirebaseHomehubOldApiFunctions.addPrefixApiPathForV2(url),
        data,
        config,
      ),
    );
  public static patch = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> => {
    const mergedUrl = V2FirebaseHomehubOldApiFunctions.addPrefixApiPathForV2(url);
    V2FirebaseHomehubOldApiFunctions.setSystemAuditLog('PATCH', mergedUrl, data);
    return _authErrorWrapper(() => V2FirebaseHomehubOldApiFunctions.axios.patch(mergedUrl, data, config));
  };
  public static get = <T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> => {
    const mergedUrl = V2FirebaseHomehubOldApiFunctions.addPrefixApiPathForV2(url);
    V2FirebaseHomehubOldApiFunctions.setSystemAuditLog('GET', mergedUrl);
    return _authErrorWrapper(() => V2FirebaseHomehubOldApiFunctions.axios.get(mergedUrl, config));
  };
  public static put = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> =>
    _authErrorWrapper(() =>
      V2FirebaseHomehubOldApiFunctions.axios.put<T>(
        V2FirebaseHomehubOldApiFunctions.addPrefixApiPathForV2(url),
        data,
        config,
      ),
    );
  public static delete = (url: string, config?: AxiosRequestConfig): AxiosPromise =>
    _authErrorWrapper(() =>
      V2FirebaseHomehubOldApiFunctions.axios.delete(
        V2FirebaseHomehubOldApiFunctions.addPrefixApiPathForV2(url),
        config,
      ),
    );

  public static setToken = token => {
    V2FirebaseHomehubOldApiFunctions.axios.defaults.headers.common['X-Api-Key'] = token;
  };

  private static checkLogin = () => {
    let unsubscribe;
    return new Promise((resolve, reject) => {
      const waitTimer = setTimeout(() => {
        if (unsubscribe) {
          unsubscribe();
        }
        reject('timeout');
      }, 10000);
      unsubscribe = firebase.auth().onAuthStateChanged(user => {
        if (user && user.uid) {
          if (unsubscribe) {
            unsubscribe();
          }
          clearTimeout(waitTimer);
          resolve('');
        }
      });
    });
  };
  /**
   * 以下の目的で勝手にapiNameにprefixをつけるようにします
   * ・callableとかの打ち間違いによるリトライ工数をなくす
   * ・APIバージョン一括変更を容易にする
   * ただ、特定のAPIだけ別バージョンを利用する可能性（やりたくない）があるので、以下のようにします
   * ・v1とかv2とかをつけた場合はそのバージョンを使う
   * ・つけなかったらここで指定しているバージョンを勝手に指定する
   * ・callableは常に先頭につける
   * ・callableから始まるapiNameは即エラー。理由はここの仕様に準拠していない実装にすぐ気づけるようにするため。
   *
   * @param apiName
   * @param data
   * @param options
   */
  public static call = async (apiName: string, data?, options?) => {
    // v2だったらexpress postに置き換え
    if (V2FirebaseHomehubOldApiFunctions.version === HomehubOldApiVersions.V2DataModelVersion) {
      if (options) {
        console.warn('not implemented options param for v2 Functions.call');
      }
      return await V2FirebaseHomehubOldApiFunctions.routingToExpress(apiName, data);
    }
    // ログインを非同期にしたのでちゃんとここで確認する。
    await V2FirebaseHomehubOldApiFunctions.checkLogin().catch(_ => {
      throw new Error('unauthorized.');
    });
    if (apiName.startsWith('callable-')) {
      throw new Error('apiName must not starts with "callable-"');
    }
    if (!/^v[0-9]+-/.exec(apiName)) {
      apiName = V2FirebaseHomehubOldApiFunctions.version + '-' + apiName;
    }
    apiName = 'callable-' + apiName;
    if (!options) {
      options = {};
    }
    if (!options.timeout) {
      // ここのtimeoutはミリ秒らしいです。
      // 540秒だとエラー発生しまくったので下記のようにします。
      options.timeout = 540000;
    }
    const res = await firebase
      .functions()
      .httpsCallable(
        apiName,
        options,
      )(data)
      .catch(e => {
        if (e.code === 'unauthenticated') {
          AuthService.logout({sessionError: true});
        }
        throw e;
      });
    if (!res) throw new CommonError('', 'Empty response.');
    return res.data;
  };

  /**
   * v2の場合にcallable functionsをexpressのpostに置換する処理
   * @param apiName
   * @param data
   */
  private static routingToExpress = async (apiName: string, data?: any) => {
    if (apiName.startsWith('callable-')) {
      throw new Error('apiName must not starts with "callable-"');
    }
    if (!/^v[0-9]+-/.exec(apiName)) {
      apiName = V2FirebaseHomehubOldApiFunctions.version + '-' + apiName;
    }
    // NOTE: v2 homehub-old-apiでは /callable-v2-spaces-register は /callable-v2/spaces/register に置き変わる
    apiName = 'callable-' + apiName.replaceAll('-', '/');
    const response = await V2FirebaseHomehubOldApiFunctions.post(apiName, data, {
      timeout: 540000,
    }).catch(e => {
      // API POSTの結果をhttpsCallableのServiceError型に変換して処理
      throw new ServiceError(
        e.response?.data?.code || FunctionsErrorCodeEnum.INTERNAL,
        e.response?.data.message || 'some error occurred.',
      );
    });
    return response.data;
  };

  /**
   * v2の場合はv2のAPI用prefixをpathに付与する
   * @param apiName
   */
  private static addPrefixApiPathForV2 = (apiName: string): string => {
    if (V2FirebaseHomehubOldApiFunctions.version === HomehubOldApiVersions.V2DataModelVersion) {
      return HOMEHUB_V2_OLD_API_PREFIX + apiName;
    }
    return apiName;
  };

  public static addInterceptors(beforeRequest, errorBeforeRequest, handleResponse, handleErrorResponse) {
    this.axios.interceptors.request.use(beforeRequest, errorBeforeRequest);
    this.axios.interceptors.response.use(handleResponse, handleErrorResponse);
  }
}
