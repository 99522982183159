import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';
import {
    UsableFunctions
} from '../../firebase/firestore/references/organizations/usable-functions/firestore-usableFunctions';
import Redux from '../../redux/ReduxConnector';

export default class UsableFunctionsServices {
  public static loadUsableFunctionsServices = async () => {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-loadUsableFunctions').catch(e => {
      console.log(e);
      // ここでエラーで落とされても困るので握りつぶす。
      // throw new CommonError('利用できる機能のロードに失敗しました。');
    });
  };
  /**
   * 特定のfunctionが利用可能かどうかを返却する共通ロジック
   * usableFunctionsが渡されなかった場合はReduxから最新の値を読み込む
   * @param functionKey
   * @param usableFunctions
   */
  public static isUsableFunctions = (functionKey: UsableFunctions, usableFunctions?: UsableFunctions[]): boolean => {
    if (usableFunctions && usableFunctions[0]) {
      return usableFunctions.some(f => f === functionKey);
    }
    if (!Redux || !Redux.getStore()) {
      return false;
    }
    const usableFunctionsFromRedux = Redux.getStore().settings.usableFunctions;
    return usableFunctionsFromRedux.some(f => f === functionKey);
  };
}
