import ReduxUtil from './ReduxUtil';
import {MergedBaseUserAuthority} from '../business-types/types/core/common/merged-base-user-authority';

export interface UserState {
  organizationId: string;
  personaId: string;
  userId: string;
  email: string;
  name: string;
  organizationName: string;
  photoUri: string;
  /*
   * 担当しているスペース情報をフラット化したデータを保持
   * 現状支店グループのみ配下の全ての支店に変換している(支店グループは含まない)
   * inChargeOfSpacesを利用している箇所が多かったためこの形を選択したが
   * 支店グループ配下の支店全てを管理する専用のプロパティ(allInChargeOfAreaIds)を利用した方がよいかも
   */
  inChargeOfSpaces: {
    id: string;
    name: string;
  }[];
  /*
   * firestore上に保持しているinChargeOfSpaceIdsに関連するspaceデータを保持
   * 担当支店グループ等、データベースで管理しているspaceデータをそのまま利用したい箇所があったため用意
   */
  originalInChargeOfSpaces: {
    id: string;
    name: string;
  }[];
  useReception?: boolean;
  userAuthority?: MergedBaseUserAuthority;
  departmentName?: string;
  // TODO: アナウンスメント・議事録・広報誌の方が、支店IDを保持するように変更したら、いらなくなる。
  inChargeOfBuildingIds?: string[];
  spaceGroup?: {
    id: string;
    name: string;
    building: string;
  };
}

const getInitialState = (): UserState => ({
  organizationId: '',
  personaId: '',
  userId: '',
  email: '',
  name: '',
  organizationName: '',
  photoUri: '',
  inChargeOfSpaces: [],
  originalInChargeOfSpaces: [],
  userAuthority: undefined,
  spaceGroup: undefined,
});

const TYPE_PREFIX = 'USER';
const type = t => TYPE_PREFIX + t;

export const UserActions = {
  clear: () => ({type: type('clear')}),
  setUser: (user: Partial<UserState>) => ({
    type: type('setUser'),
    user: user,
  }),
  setInChargeOfSpaces: (
    inChargeOfSpaces: {
      id: string;
      name: string;
    }[],
  ) => ({
    type: type('setInChargeOfSpaces'),
    inChargeOfSpaces: inChargeOfSpaces,
  }),
  setOriginalInChargeOfSpaces: (
    originalInChargeOfSpaces: {
      id: string;
      name: string;
    }[],
  ) => ({
    type: type('setOriginalInChargeOfSpaces'),
    originalInChargeOfSpaces,
  }),
  setInChargeOfBuildingIds: (inChargeOfBuildingIds: string[]) => ({
    type: type('setInChargeOfBuildingIds'),
    inChargeOfBuildingIds,
  }),
  setSpaceGroup: (spaceGroup: any) => ({
    type: type('setSpaceGroup'),
    spaceGroup,
  }),
  setUserName: (userName: string) => ({
    type: type('setUserName'),
    userName: userName,
  }),
};

const UserReducerDefinitions = {
  clear: () => getInitialState(),
  setUser: (state, action) => action.user,
  setInChargeOfSpaces: (state, action) => ({...state, inChargeOfSpaces: action.inChargeOfSpaces}),
  setOriginalInChargeOfSpaces: (state, action) => ({
    ...state,
    originalInChargeOfSpaces: action.originalInChargeOfSpaces,
  }),
  setInChargeOfBuildingIds: (state, action) => ({...state, inChargeOfBuildingIds: action.inChargeOfBuildingIds}),
  setSpaceGroup: (state, action) => ({...state, spaceGroup: action.spaceGroup}),
  setUserName: (state, action) => ({...state, name: action.userName}),
};

const userReducer = ReduxUtil.createReducer(getInitialState(), UserReducerDefinitions, TYPE_PREFIX);

export {userReducer};
