import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';
import CommonError from '../../errors/common-error';
import LocalStorage, {LocalStorageKey} from '../../local-storage/LocalStorage';
import {V2FirebaseHomehubBackendFunctions} from '../../firebase/functions/v2-firebase-homehub-backend-functions';
import {AlgoliaCredentials} from '../../algolia/algolia';

export default class OrganizationService {
  public static generateAlgoliaSearchApiKey = async (indexNameSuffixes: string[]): Promise<AlgoliaCredentials[]> => {
    const BASE_PATH = 'homehubAccount/' + V2FirebaseHomehubBackendFunctions.getVersion();

    return await V2FirebaseHomehubBackendFunctions.put(`${BASE_PATH}/organizations/algoliaSearchApiKey/generate`, {
      indexNameSuffixes,
    })
      .then(res => {
        return res.data;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  };

  public static updateOrganizationName = async (data: {updatedName}) => {
    try {
      const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
      await V2FirebaseHomehubOldApiFunctions.call('organizations-updateName', {
        accessToken: accessToken,
        name: data.updatedName,
      });
    } catch (e) {
      throw new CommonError(e.code, e.message, e);
    }
  };
}
