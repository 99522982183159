import type {AlgoliaObject} from '../../algolia';
import {
  FirestoreOrganizationMemberStatus,
  MemberRole,
} from '../../../firebase/firestore/references/organizations/members/firestore-members';
import {PersonType} from '../../../firebase/firestore/references/organizations/space-types/firestore-spaceTypes';
import {EpochMillis} from '../../../common-types/numbers';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

export enum AlgoliaMembersAttributes {
  id = 'id',
  personaId = 'personaId',
  defaultPersonaId = 'defaultPersonaId',
  externalId = 'externalId',
  name = 'name',
  email = 'email',
  roleIds = 'roleIds',
  hide = 'hide',
  spaceIds = 'spaceIds',
  spaceId = 'devices.spaceId',
  deviceId = 'devices.deviceId',
  status = 'status',
  personType = 'personType',
  inChargeOfSpaceIds = 'inChargeOfSpaceIds',
  userAuthorityIds = 'userAuthorityIds',
  roles = 'roles',
  departmentName = 'departmentName',
}

export enum FilterAttributes {
  id = 'id',
}

export enum FacetAttributes {
  registrationStatus = 'registrationStatus',
}

export interface AlgoliaMember extends AlgoliaObject {
  // TODO: TriggerでAlgoliaに反映させる
  admin?: boolean;
  organizationId?: string;
  personaId: string;
  defaultPersonaId: string;
  externalId: string;
  name: string;
  email: string;
  fcmToken: string;
  photoUri: string;
  power: string[];
  roleIds: string[];
  hide: boolean;
  spaceIds: string[];
  devices: {spaceId: string; deviceId: string}[];
  status: FirestoreOrganizationMemberStatus;
  personType: PersonType;
  inChargeOfSpaceIds: string[];
  themeColor?: string;
  roles?: MemberRole[];
  departmentName?: string;
  userAuthorityIds?: string[];
  createdAt: EpochMillis;
  updatedAt: EpochMillis;
  facetimeId?: string;
  invitedAt?: EpochMillis;
}

const searchableAttributes = {
  name: AlgoliaMembersAttributes.name,
  email: AlgoliaMembersAttributes.email,
  personaId: AlgoliaMembersAttributes.personaId,
  externalId: AlgoliaMembersAttributes.externalId,
};

export default class AlgoliaMembers extends LegacyIndexBase<AlgoliaMember> {
  protected name = LegacyIndexName.members;
  protected rawIndexName: string;

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    // for facet
    status: AlgoliaMembersAttributes.status,
    // for filter
    id: AlgoliaMembersAttributes.id,
    email: AlgoliaMembersAttributes.email,
    spaceIds: AlgoliaMembersAttributes.spaceIds,
    spaceId: AlgoliaMembersAttributes.spaceId,
    deviceId: AlgoliaMembersAttributes.deviceId,
    defaultPersonaId: AlgoliaMembersAttributes.defaultPersonaId,
    inChargeOfSpaceIds: AlgoliaMembersAttributes.inChargeOfSpaceIds,
    roles: AlgoliaMembersAttributes.roles,
    userAuthorityIds: AlgoliaMembersAttributes.userAuthorityIds,
  });

  public static getIndex = (organizationId: string) => new AlgoliaMembers(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaMembers.facets();
  public readonly searchableAttributes = AlgoliaMembers.searchableAttributes;
}
