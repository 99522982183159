// この書き方をしないと実行時エラーになる。firebaseを丸ごとimportするとこうやって書けって警告出る。
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default class FirebaseAuth {
  public static signInWithCustomToken = customToken => {
    return firebase.auth().signInWithCustomToken(customToken);
  };

  public static signOut = () => {
    return firebase.auth().signOut();
  };
}
