import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaMemberPropertyLabelsAttributes {
  id = 'id',
  name = 'name',
  lendable = 'lendable',
  bitlocks = 'bitlocks',
  numberOfNfcCard = 'numberOfNfcCard',
}

export interface AlgoliaMemberPropertyLabelData extends AlgoliaObject {
  name: string;
  lendable: boolean;
  bitlockIds: string[];
  readerIds: string[];
  numberOfNfcCard: number;
}

export default class AlgoliaMemberPropertyLabels extends AlgoliaBase<AlgoliaMemberPropertyLabelData> {
  public static readonly facets = () => ({
    // search
    name: AlgoliaMemberPropertyLabelsAttributes.name,
    // relation
    id: AlgoliaMemberPropertyLabelsAttributes.id,
    bitlockIds: AlgoliaMemberPropertyLabelsAttributes.bitlocks,
    numberOfNfcCard: AlgoliaMemberPropertyLabelsAttributes.numberOfNfcCard,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaMemberPropertyLabels(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.memberPropertyLabels;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaMemberPropertyLabels.facets();
}
