import {ThemeOptions, createTheme} from '@mui/material/styles/';

import {HHColor} from '@styles/v2/hhColor';
import HHFont from '@styles/v2/hhFont';

// enumにしておく
export enum DefaultThemeColor {
  inherit = 'inherit',
  primary = 'primary',
  secondary = 'secondary',
  default = 'default',
}

export enum ThemeColor {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

// const overrides: styleOverrides = {
//   MuiButton: {
//     root: {
//       textTransform: 'none',
//     },
//   },
// };

/**
 * 何回もtheme作るのコストの無駄なので必要なものは先に作る。
 */
const HHThemes: {[K in ThemeColor]: any} = {
  success: createTheme({
    palette: {
      primary: HHColor.Success,
    },
    // overrides: overrides,
  }),
  info: createTheme({
    palette: {
      primary: HHColor.Info,
    },
    // overrides: overrides,
  }),
  warning: createTheme({
    palette: {
      primary: HHColor.Warning,
    },
    // overrides: overrides,
  }),
  error: createTheme({
    palette: {
      primary: HHColor.Error,
    },
    // overrides: overrides,
  }),
};

const defaultThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 980,
      md: 1024,
      lg: 1280,
      xl: 1580,
    },
  },
  palette: {
    primary: HHColor.Primary,
    secondary: HHColor.Secondary,
    text: HHColor.Text,
    success: HHColor.Success,
    info: HHColor.Info,
    error: HHColor.Error,
    warning: HHColor.Warning,
  },
  typography: {
    fontFamily: HHFont.FONT_FAMILY,
    fontSize: 16,
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: '175%',
      letterSpacing: '-0.04em',
    },
    h2: {
      fontSize: '1.65rem',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '-0.03em',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '-0.03em',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '-0.02em',
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '-0.02em',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '160%',
      letterSpacing: '-0.02em',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '160%',
      letterSpacing: '-0.02em',
    },

    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '180%',
      letterSpacing: '0.05em',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '170%',
      letterSpacing: '0.05em',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '260%',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '170%',
      letterSpacing: '0.075em',
    },
    button: {
      fontWeight: 500,
      lineHeight: '180%',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',
        },
      },
    },
  },
  // mui v5からoverridesの書き方が変わっている、これらのOverridesが効いてそうなv2コンポーネントもなさそう、なので一旦コメントアウト
  // overrides: {
  //   MuiInputBase: {
  //     input: {
  //       height: '1.2em',
  //       paddingTop: 5,
  //     },
  //   },
  //   MuiInput: {
  //     underline: {
  //       '&:hover:not($disabled):before': {
  //         borderBottom: `2px solid ${HHColor.Other.divider}`,
  //       },
  //     },
  //   },
  //   MuiTypography: {
  //     root: {
  //       color: HHColor.Text.primary,
  //       fontSize: '0.875rem',
  //     },
  //   },
  //   MuiButton: {
  //     root: {
  //       textTransform: 'none',
  //     },
  //   },
  //   MuiOutlinedInput: {
  //     inputMarginDense: {
  //       paddingTop: 10.5,
  //       paddingBottom: 10.5,
  //     },
  //   },
  //   MuiTab: {
  //     root: {
  //       textTransform: 'none',
  //     },
  //   },
  //   MuiTableCell: {
  //     body: {
  //       position: 'relative',
  //     },
  //   },
  // },
};

export const HHDefaultTheme = createTheme(defaultThemeOptions);

/**
 * Buttonについて色味を変更したいため
 */
export const HHButtonTheme = createTheme({
  ...defaultThemeOptions,
  palette: {
    ...defaultThemeOptions.palette,
    secondary: {
      main: HHColor.Primary.contrast,
      light: HHColor.Secondary.light,
      dark: HHColor.Other.buttonSecondaryHover,
      contrastText: HHColor.Text.primary,
    },
  },
});

export default HHThemes;
