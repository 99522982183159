import {Store} from './store';

export const selectUser = (state: Store) => state.user;
export const selectUserOrganizationId = (state: Store) => state.user.organizationId;
export const selectDictionary = (state: Store) => state.dictionary;
export const selectIsLoading = (state: Store): boolean => state.api.loadingCount === 0;
export const selectAppAPIInitCount = (state: Store): number => state.api.appAPIInitCount;
export const selectAppOldAPIInitCount = (state: Store): number => state.api.appOldAPIInitCount;

// settings
export const selectSettings = (state: Store) => state.settings;
export const selectUsableFunctions = (state: Store) => state.settings.usableFunctions;
export const selectDashboardSettings = (state: Store) => state.settings.dashboardSettings;
export const selectAddOnSumamoru = (state: Store) => state.settings.addOns.sumamoruData;
export const selectTeleConferenceSettings = (state: Store) => state.settings.teleconferenceSettings;
export const selectKeyIssueSettings = (state: Store) => state.settings.keyIssueSettings;
