import {V2FirebaseHomehubOldApiFunctions} from '../../../firebase/functions/v2-firebase-homehub-old-api-functions';
import CommonError from '../../../errors/common-error';
import {
    OrganizationsReservationNotificationSettingDetectUnitKey
} from '../../../firebase/firestore/references/organizations/common-settings/notification-setting/reservation-notification-setting/firestore-organizations-reservation-notification-setting';
import {
    ApiTypesOrgReservationNotificationSettingGetResponse,
    ApiTypesOrgReservationNotificationSettingPostResponse,
    OrgReservationNotificationSettingResponseData,
} from '../../../firebase/functions/api-types/organizations/common-settings/notification-settings/reservation-notification-setting/api-types-org-reservation-notification-setting';
import {ReservationServiceType} from '../../../business-types/enums/core/common/reservation-type';

export default class ReservationNotificationSettingService {
  public static registerReservationNotificationSetting = async (params: {
    detectId: string;
    detectUnit: OrganizationsReservationNotificationSettingDetectUnitKey;
    reservationServiceType: ReservationServiceType;
    notifyOrganization?: boolean;
    notifyBranch?: boolean;
  }): Promise<OrgReservationNotificationSettingResponseData | undefined> => {
    const res = await V2FirebaseHomehubOldApiFunctions.post<ApiTypesOrgReservationNotificationSettingPostResponse>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/reservation-notification-setting/`,
      {
        detectId: params.detectId,
        detectUnit: params.detectUnit,
        reservationServiceType: params.reservationServiceType,
        notifyOrganization: params.notifyOrganization || undefined,
        notifyBranch: params.notifyBranch || undefined,
      },
    ).catch(e => {
      throw new CommonError(e);
    });
    if (!res || !res.data) {
      return;
    }
    const data = res.data as {reservationNotificationSetting: OrgReservationNotificationSettingResponseData};
    return data.reservationNotificationSetting;
  };

  public static updateReservationNotificationSettings = async (
    existingData: {
      reservationServiceType: ReservationServiceType;
      detectUnit: OrganizationsReservationNotificationSettingDetectUnitKey;
      detectId: string;
    },
    params: {
      detectId?: string;
      detectUnit?: OrganizationsReservationNotificationSettingDetectUnitKey;
      reservationServiceType?: ReservationServiceType;
      notifyOrganization?: boolean;
      notifyBranch?: boolean;
    },
  ) => {
    const res = await V2FirebaseHomehubOldApiFunctions.patch<ApiTypesOrgReservationNotificationSettingPostResponse>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/reservation-notification-setting/${
        existingData.reservationServiceType
      }/${existingData.detectUnit}/${existingData.detectId}`,
      {
        detectId: params.detectId || undefined,
        detectUnit: params.detectUnit || undefined,
        reservationServiceType: params.reservationServiceType || undefined,
        notifyOrganization: !!params.notifyOrganization,
        notifyBranch: !!params.notifyBranch,
      },
    ).catch(e => {
      throw new CommonError(e);
    });
    if (!res || !res.data) {
      return;
    }
    const data = res.data as {reservationNotificationSetting: OrgReservationNotificationSettingResponseData};
    return data.reservationNotificationSetting;
  };

  public static deleteReservationNotificationSettings = async (existingData: {
    reservationServiceType: ReservationServiceType;
    detectUnit: OrganizationsReservationNotificationSettingDetectUnitKey;
    detectId: string;
  }) => {
    await V2FirebaseHomehubOldApiFunctions.delete(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/reservation-notification-setting/${
        existingData.reservationServiceType
      }/${existingData.detectUnit}/${existingData.detectId}`,
    ).catch(e => {
      throw new CommonError(e);
    });
  };

  public static getReservationNotificationSetting = async (existingData: {
    reservationServiceType: ReservationServiceType;
    detectUnit: OrganizationsReservationNotificationSettingDetectUnitKey;
    detectId: string;
  }): Promise<OrgReservationNotificationSettingResponseData | undefined> => {
    const res = await V2FirebaseHomehubOldApiFunctions.get<ApiTypesOrgReservationNotificationSettingGetResponse>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/common-settings/reservation-notification-setting/${
        existingData.reservationServiceType
      }/${existingData.detectUnit}/${existingData.detectId}`,
    ).catch(e => {
      throw new CommonError(e);
    });
    if (!res || !res.data) {
      return;
    }
    const data = res.data as {reservationNotificationSettings: OrgReservationNotificationSettingResponseData[]};
    return data.reservationNotificationSettings[0];
  };
}
