import {FirestoreDocumentData, Timestamp} from '../../../firestore';
import {PassportAuthority} from '../../users/receivedPassports/firestore-users-receivedPassports';
import {PersonType} from '../space-types/firestore-spaceTypes';
import {AlgoliaDevice} from '../../../../../algolia/references/devices/algolia-devices';
import {FirestoreOrganizationMemberStatus} from '../members/firestore-members';
import {FirestoreOrganizationGuestStatus} from '../guests/firestore-guests';
import {FunctionsErrorCodeEnum} from '../../../../../errors/service-error';
import {Days, HoleLendContractStatus, RepeatTypes} from '@bitkey-service/bkp-sdk-types';

export enum FirestoreOrganizationKeyType {
  PRINCIPAL = 'PRINCIPAL',
  ONETIME = 'ONETIME',
}

export enum HoleType {
  MASTER = 'MASTER',
  SPARE = 'SPARE',
  ONETIME = 'ONETIME',
  SETTING = 'SETTING',
  BUTTON = 'BUTTON',
}

export enum FirestoreOrganizationKeyAuthorityGroupName {
  deviceSetting = 'deviceSetting',
  createSpareKey = 'createSpareKey',
  shareTickets = 'shareTickets',
  viewLogAndReceivers = 'viewLogAndReceivers',
  registerSmartKeys = 'registerSmartKeys',
  useWifiConnector = 'useWifiConnector',
}

export enum FirestoreOrganizationDealType {
  DEAL = 'DEAL',
  DEAL_OFFER = 'DEAL_OFFER',
}

export enum AuthorityForCSV {
  ALLOW = '0',
  DISALLOW = '1',
}

export const FirestoreOrganizationKeyAuthorityGroupMapping: {
  [K in FirestoreOrganizationKeyAuthorityGroupName]: PassportAuthority[];
} = {
  deviceSetting: [
    PassportAuthority.ADJUST_THUMBTURN,
    PassportAuthority.CHANGE_AUTOLOCK,
    PassportAuthority.CHANGE_DEVICE_ADDRESS,
    PassportAuthority.CHANGE_DEVICE_NAME,
    PassportAuthority.UPDATE_FIRMWARE,
  ],
  viewLogAndReceivers: [PassportAuthority.SHOW_DEVICE_LOG, PassportAuthority.SHOW_KEY_RECEIVERS],
  shareTickets: [PassportAuthority.SHARE_TICKETS],
  registerSmartKeys: [PassportAuthority.REGISTER_BUTTONS],
  useWifiConnector: [PassportAuthority.USE_WIFI_CONNECTOR],
  createSpareKey: [PassportAuthority.CREATE_SPARE_KEY],
};

export interface FirestoreOrganizationKeyInfo {
  spaceId: string;
  deviceId: string;
  holeId: string;
  additionalHoleIds?: string[];
  keyType: FirestoreOrganizationKeyType;
  authorities?: PassportAuthority[];
  usageStart?: Timestamp;
  usageEnd?: Timestamp;
  publishStart?: Timestamp;
  publishEnd?: Timestamp;
  usableTimes?: number;
  authorityGroups?: FirestoreOrganizationKeyAuthorityGroupName[];

  authDeviceContractIds?: string[];

  // 表示用
  spaceName: string;
  deviceName: string;

  // additionalHole用
  device: AlgoliaDevice;

  // 繰り返し用
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: RepeatTypes;
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];

  // 現時点でアプリの表示上のみ
  // 同期させるために組織でも持っておく。
  // 組織でも表示させる時に使うと思う。
  repeatStart?: string; // ex) HH:mm:ss
  repeatEnd?: string; // ex) HH:mm:ss
  standardTime?: number; // ex) 9 <- JST

  // CSV鍵発行用
  // リクエストが一意に定まらない場合に使う
  index?: number;
}
export interface FirestoreOrganizationKeyInfoResponse {
  spaceId: string;
  deviceId: string;
  holeId: string;
  additionalHoleIds?: string[];
  keyType: FirestoreOrganizationKeyType;
  authorities?: PassportAuthority[];
  usageStart?: Timestamp;
  usageEnd?: Timestamp;
  publishStart?: Timestamp;
  publishEnd?: Timestamp;
  usableTimes?: number;
  authorityGroups?: FirestoreOrganizationKeyAuthorityGroupName[];

  // 表示用
  spaceName: string;
  deviceName: string;

  // 繰り返し用
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: RepeatTypes;
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];

  // 現時点でアプリの表示上のみ
  // 同期させるために組織でも持っておく。
  // 組織でも表示させる時に使うと思う。
  repeatStart?: string; // ex) HH:mm:ss
  repeatEnd?: string; // ex) HH:mm:ss
  standardTime?: number; // ex) 9 <- JST
  // CSV鍵発行用
  // リクエストが一意に定まらない場合に使う
  index?: number;
}

export interface FirestoreOrganizationKeyReceiver {
  organizationId?: string;
  personaId: string;
  // 組織所属のpersonaに送る場合にアプリの都合で必要。
  externalId?: string;
  personType: PersonType;
  customerId?: string;

  // 表示用
  name: string;
  email: string;
  photoUri: string;
  phoneNumber: string;

  // 招待状況によって鍵送信ロジックを分ける。
  // TODO: バックエンドの型定義にstatusは存在しない。
  inviteStatus?: FirestoreOrganizationMemberStatus | FirestoreOrganizationGuestStatus;
  // レジデンス用：自動削除しない設定
  disableAutoDelete?: boolean;
}

export interface FirestoreOrganizationKeyData extends FirestoreDocumentData {
  spaceId?: string;
  deviceId: string;
  holeId: string;
  keyType: FirestoreOrganizationKeyType;
  // チケットの時にはいれないとserviceからエラーが返ってくる
  dealType?: FirestoreOrganizationDealType;
  authorities?: PassportAuthority[];
  additionalHoles?: {
    holeId: string;
    contractId: string;
    contractStatus?: HoleLendContractStatus;
  }[];
  // senderはこのドキュメントのあるorganizationに決まってるので不要なので、receiverとかつける必要ないから省略
  organizationId?: string;
  organizationPersonaId?: string;
  personaId: string;
  externalId?: string;
  personType: PersonType;
  customerId?: string;
  usageStart?: Timestamp;
  usageEnd?: Timestamp;
  publishStart?: Timestamp;
  publishEnd?: Timestamp;
  usableTimes?: number;
  message?: string;
  // 多分必要
  useCount: number;
  // dynamicLink経由で受け取る場合の送信時email
  email?: string;
  // CSVカギ削除で使う。Serviceから返ってくるエラーが一意に識別場合に使う
  index?: number;
}

export interface FirestoreSendKeySuccessResponse {
  id: string;
  receiver: FirestoreOrganizationKeyReceiver;
  key: FirestoreOrganizationKeyInfoResponse;
}

export interface FirestoreSendKeyErrorResponse extends FirestoreSendKeySuccessResponse {
  code: FunctionsErrorCodeEnum;
  message: string;
}

export interface FirestoreDeleteKeySuccessResponse {
  key: FirestoreOrganizationKeyData;
}

export interface FirestoreDeleteKeyErrorResponse extends FirestoreDeleteKeySuccessResponse {
  code: FunctionsErrorCodeEnum;
  message: string;
}
