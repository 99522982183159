import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useCsvDownloadContext} from '../../providers/csv/CsvDownloadProvider';

const CsvDownloadIndicator: React.FC = () => {
  const {totalCount, progressCount, isProcessing, isFinished} = useCsvDownloadContext();
  const classes = useStyles();

  const progress = useMemo(() => {
    return (progressCount / totalCount) * 100;
  }, [progressCount, totalCount]);

  const progressMessage = useMemo(() => {
    return `${progressCount} / ${totalCount}`;
  }, [progressCount, totalCount]);

  const message = useMemo(() => {
    if (isFinished) {
      return 'ダウンロードが完了しました。';
    }
    return 'CSVファイルをダウンロード中...';
  }, [isFinished]);

  return isProcessing ? (
    <div className={classes.root}>
      <Box display='flex' alignItems='center'>
        <Box width='100%' mr={1}>
          <LinearProgress variant='determinate' value={progress} />
        </Box>
        <Box minWidth={60}>
          <Typography variant='body2' color='textSecondary'>
            {progressMessage}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography color='textPrimary'>{message}</Typography>
      </Box>
    </div>
  ) : (
    <></>
  );
};

const useStyles = makeStyles({
  root: {
    width: '30%',
    padding: 32,
    position: 'fixed',
    bottom: 24,
    right: 32,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
  },
});

export default CsvDownloadIndicator;
