import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import {
    DeviceLogActionSource,
    DeviceLogActionType,
} from '../../../firebase/firestore/references/devices/logs/firestore-device-logs';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaReaderLogAttributes {
  id = 'id',
  deviceId = 'deviceId',
  actionType = 'actionType',
  logIndex = 'logIndex',
  recordedAt = 'recordedAt',
  actionSource = 'actionSource',
  deviceOrganizationId = 'deviceOrganizationId',
  deviceName = 'deviceName',
  operatorName = 'operatorName',
  cardId = 'cardId',
}

export interface AlgoliaReaderLogData extends AlgoliaObject {
  deviceId: string;
  deviceName: string;
  actionType: DeviceLogActionType;
  logIndex: number;
  recordedAt: EpochMillis;
  actionSource: DeviceLogActionSource;
  deviceOrganizationId?: string;
  cardId: string;
}

export default class AlgoliaReaderLogs extends AlgoliaBase<AlgoliaReaderLogData> {
  public static readonly facets = () => ({
    // for relation
    actionType: AlgoliaReaderLogAttributes.actionType,
    actionSource: AlgoliaReaderLogAttributes.actionSource,
    // for relation
    id: AlgoliaReaderLogAttributes.id,
    deviceId: AlgoliaReaderLogAttributes.deviceId,
    logIndex: AlgoliaReaderLogAttributes.logIndex,
    recordedAt: AlgoliaReaderLogAttributes.recordedAt,
    deviceOrganizationId: AlgoliaReaderLogAttributes.deviceOrganizationId,
    cardId: AlgoliaReaderLogAttributes.cardId,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaReaderLogs(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.readerLogs;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaReaderLogs.facets();
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaReaderLogData>(this.index, condition);
  public browse = async condition => Algolia.browse<AlgoliaReaderLogData>(this.index, condition);
}
