import {AlgoliaSpace} from './algolia-spaces';
import ArrayUtil from '../../../basic-utils/array-util';

export default class SpaceUtils {
  public static generatePhotoUriKeyString = (photoIndex: number): string => {
    if (photoIndex >= 9) {
      return `photoUri${photoIndex + 1}`;
    } else if (photoIndex > 0) {
      return `photoUri0${photoIndex + 1}`;
    } else {
      return 'photoUri';
    }
  };

  private static extractPhotoUriKeyNames = (space: AlgoliaSpace | Partial<AlgoliaSpace>) => {
    const spaceKeys = Object.keys(space);
    return spaceKeys.filter(key => key.match(/photoUri(\d\d)?/)).sort();
  };

  public static extractAllPhotoUri(space: AlgoliaSpace): Partial<AlgoliaSpace> {
    const photoUriKeys = this.extractPhotoUriKeyNames(space);
    const photoUris = {};
    photoUriKeys.forEach(key => {
      photoUris[key] = space[key];
    });
    return photoUris;
  }

  public static extractPhotoUris(space: AlgoliaSpace | Partial<AlgoliaSpace>): string[] {
    const photoUriKeys = this.extractPhotoUriKeyNames(space);
    return photoUriKeys.map(key => space[key] as string).filter(Boolean);
  }

  public static mapPhotoUrisToArray(space: AlgoliaSpace): string[] {
    const photoUriKeys = this.extractPhotoUriKeyNames(space);
    return photoUriKeys.map(key => space[key]).filter(photoUri => !!photoUri);
  }

  public static getPhotoUriByIndex(space: AlgoliaSpace, photoIndex: number): string | undefined {
    const photoUri = space[this.generatePhotoUriKeyString(photoIndex)];
    return photoUri || undefined;
  }

  public static generateNewPhotoUrisOnDelete(space: AlgoliaSpace, photoIndexToDelete: number): Partial<AlgoliaSpace> {
    const photoUriKeys = this.extractPhotoUriKeyNames(space);
    if (photoIndexToDelete < 0 || photoIndexToDelete >= photoUriKeys.length) {
      throw Error('Invalid photoIndexToDelete');
    }
    const uris = photoUriKeys.map(key => space[key]);
    uris[photoIndexToDelete] = undefined;
    const justifiedUris = ArrayUtil.justifyLeft(uris);
    const newPhotoUrisOnDelete = {};
    photoUriKeys.forEach((key, i) => {
      newPhotoUrisOnDelete[key] = justifiedUris[i];
    });
    return newPhotoUrisOnDelete;
  }
}
