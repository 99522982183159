import {AlgoliaSearchCondition} from '@algolia/v1/algolia';

export const toAlgoliaResponseFromElasticSearch = (response: any, condition: AlgoliaSearchCondition) => ({
  hits: response.hits,
  page: condition.offset ?? 0,
  nbHits: response.total,
  nbPages: Math.ceil(response.total / (condition.offset ?? 100)),
  hitsPerPage: condition.offset ?? 100,
  processingTimeMS: 0,
  query: '',
  params: '',
  facets: response.facets,
  exhaustiveNbHits: false,
  exhaustiveFacetsCount: false,
});
