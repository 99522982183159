import type {Timestamp} from '../../../firebase/firestore/firestore';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {AlgoliaObject} from '@algolia/v1/legacy-index-type';

export enum AlgoliaOrganizationNfcCardAttributes {
  id = 'id',
  propertyValue = 'propertyValue',
  lowerPropertyValue = 'lowerPropertyValue',
  memberPropertyId = 'memberPropertyId',
  nfcGroupId = 'nfcGroupId',
  memberPropertyLabelId = 'memberPropertyLabelId',
  name = 'name',
  code = 'code',
  readerIds = 'readerIds',
  failedReaderIds = 'failedReaderIds',
  status = 'status',
  isLost = 'isLost',
  isLending = 'isLending',
  lendingPersonaId = 'lendingPersonaId',
  borrowerPeopleId = 'borrowerPeopleId',
  lendAt = 'lendAt',
  lendLimitPeriod = 'lendLimitPeriod',
  lendRemarks = 'lendRemarks',
}

export interface AlgoliaOrganizationNfcCard extends AlgoliaObject {
  propertyValue: string;
  memberPropertyId: string;

  nfcGroupId: string;
  memberPropertyLabelId: string;

  name?: string;
  code?: string;

  readerIds: string[];
  failedReaderIds: string[];

  status?: 'Available' | 'Stop';

  isLost: boolean;
  isLending: boolean;
  lendingPersonaId?: string;
  borrowerPeopleId?: string;
  lendAt?: Timestamp;
  lendLimitPeriod?: Timestamp;
  lendRemarks?: string;

  createdAt: Timestamp;
  updatedAt: Timestamp;
}

const searchableAttributes = {
  name: AlgoliaOrganizationNfcCardAttributes.name,
  propertyValue: AlgoliaOrganizationNfcCardAttributes.propertyValue,
  code: AlgoliaOrganizationNfcCardAttributes.code,
};

export default class AlgoliaOrganizationNfcCards extends LegacyIndexBase<AlgoliaOrganizationNfcCard> {
  protected name = LegacyIndexName.organizationNfcCards;
  protected rawIndexName: string;

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    code: AlgoliaOrganizationNfcCardAttributes.code,
    isLending: AlgoliaOrganizationNfcCardAttributes.isLending,
    isLost: AlgoliaOrganizationNfcCardAttributes.isLost,
    propertyValue: AlgoliaOrganizationNfcCardAttributes.propertyValue,
  });

  public static getIndex = (organizationId: string) => new AlgoliaOrganizationNfcCards(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public searchableAttributes = AlgoliaOrganizationNfcCards.searchableAttributes;
  public facets = () => AlgoliaOrganizationNfcCards.facets();
}
