import {alpha, lighten} from '@material-ui/core/styles/colorManipulator';

const colors = {
  White: '#ffffff',
  Black: '#000000',
  Black87per: '#212121',
  Black60per: '#666666',
  Black54per: '#757575',
  Black50per: '#808080',
  Black38per: '#9e9e9e',
  Black26per: '#BDBDBD',
  Black12per: '#E0E0E0',
  Black8per: '#EBEBEB',
  Black4per: '#F5F5F5',
  // 名前適当なので他に良いのあれば
  WarmGray: '#F7F6F3',
  // 名前適当なので他に良いのあれば
  WhiteGray: '#F7F7F7',
  Gray900: '#131313',
  BrandOrange: '#F06E32',
  BrandDarkOrange: '#A84D23',
  BrandLightOrange: '#F38B5B',
  BrandBackGroundOrange: '#FDEDE6',
  Yellow: '#FFB400',
  Grey800: '#333333',
  DarkGrey: '#232323',
  LightGrey: '#5B5B5B',
  ErrorRed: '#D63D2F',
  ErrorDarkRed: '#952A20',
  ErrorLightRed: '#DE6358',
  WarningOrange: '#ED6C02',
  WarningDarkOrange: '#E65100',
  WarningLightOrange: '#FF9800',
  InfoBlue: '#676FBF',
  InfoDarkBlue: '#484D85',
  InfoLightBlue: '#858BCB',
  SuccessGreen: '#2E7D32',
  SuccessDarkGreen: '#1B5E20',
  SuccessLightGreen: '#4CAF50',
  // #Other figmaにあるけど色定義されていないもの
  OutlinedGray: '#8C8C8C',
  OutlinedOrange: '#EBA585',
  BackgroundRadioOrange: '#E7DDD8',
  RadioAreaLabelGray: '#424242',

  // workhubの予約から持ってきたもの。適切に名前変更してあげたい
  Grey150: '#DFE1E6',
  Grey590: '#8a8a8d',
  Shade2_100: '#cfd8dc',
  Shade2_50: '#ECEFF1',
  // Calendar
  CalendarKey: '#0091F1',
  // CalendarLight: '#60B7F1',
  CalendarSelection: '#DEF0FC',
  CalendarDialogSelectionBackground: '#FAFBFC',
};

/**
 * Figmaからコード化したカラー定義
 * refs: https://www.figma.com/file/rL1bsArTOV5M9D5UbVjgiZ/%5BWIP%5D-MUI-for-homehub?node-id=11146%3A85452&t=JLqqFkfhbpEUZhU9-0
 */
export const HHColor = {
  Text: {
    primary: colors.Black87per,
    secondary: colors.Black60per,
    disabled: colors.Black38per,
    lighten87: alpha(colors.Black, 0.87),
    lighten38: alpha(colors.Black, 0.38),
    lighten60: alpha(colors.Black, 0.6),
  },
  Primary: {
    main: colors.BrandOrange,
    dark: colors.BrandDarkOrange,
    light: colors.BrandLightOrange,
    contrast: colors.White,
  },
  Secondary: {
    main: colors.Grey800,
    dark: colors.DarkGrey,
    light: colors.LightGrey,
    contrast: colors.White,
  },
  Error: {
    main: colors.ErrorRed,
    dark: colors.ErrorDarkRed,
    light: colors.ErrorLightRed,
    contrast: colors.White,
    opacity12: alpha(colors.ErrorRed, 0.12),
    lighten90: lighten(colors.ErrorRed, 0.9),
    lightAlpha30: alpha(colors.ErrorLightRed, 0.3),
    cardDefault: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D63D2F',
    cardHover: 'rgba(244, 67, 54, 0.08)',
  },
  Warning: {
    main: colors.WarningOrange,
    dark: colors.WarningDarkOrange,
    light: colors.WarningLightOrange,
    contrast: colors.White,
  },
  Info: {
    main: colors.InfoBlue,
    dark: colors.InfoDarkBlue,
    light: colors.InfoLightBlue,
    contrast: colors.White,
  },
  Success: {
    main: colors.SuccessGreen,
    dark: colors.SuccessDarkGreen,
    light: colors.SuccessLightGreen,
    contrast: colors.White,
    lightAlpha30: alpha(colors.SuccessLightGreen, 0.3),
  },
  Action: {
    active: colors.Black54per,
    hover: colors.Black4per,
    selected: colors.Black8per,
    disabled: colors.Black26per,
    disabledBackground: colors.Black12per,
    focus: colors.Black12per,
  },
  Background: {
    paper: colors.White,
    default: colors.WarmGray,
    brand: colors.BrandBackGroundOrange,
    cardDefault: alpha(colors.Gray900, 0.1),
  },
  // NOTE: ここに定義されているカラーコードは然るべき名前をつけるべきモノたち
  Other: {
    divider: colors.Black12per,
    outlineBorder: colors.White,
    outlineBorderGray: colors.OutlinedGray,
    outlineBorderOrange: colors.OutlinedOrange,
    standardInputLine: colors.White,
    backdropOverlay: colors.Black50per,
    ratingActive: colors.Yellow,
    snackbarBackground: colors.Grey800,
    buttonSecondaryHover: colors.WhiteGray,
    selectedBackGroundOrange: colors.BrandBackGroundOrange,
    radioAreaLabelGray: colors.RadioAreaLabelGray,
  },
  // 予約モジュールで利用している色、あとで整理したい。
  Reservation: {
    grey150: colors.Grey150,
    grey300: colors.Black12per,
    grey500: colors.Black38per,
    grey590: colors.Grey590,
    shade2_50: colors.Shade2_50,
    shade2_100: colors.Shade2_100,
    calendarKey: colors.CalendarKey,
    calendarSelection: colors.CalendarSelection,
    calendarDialogSelection: colors.CalendarDialogSelectionBackground,
    ReservedCell: {
      Background: {
        reservation: colors.BrandLightOrange,
        maintenance: colors.LightGrey,
        interval: colors.Shade2_50,
      },
      Icon: colors.White,
      Border: colors.White,
    },
    Dialog: {
      Icon: colors.Black60per,
    },
  },
};
