import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaRegionsAttributes {
  id = 'id',
  code = 'code',
  name = 'name',
  parents = 'parents',
  parentId = 'parentId',
  memo = 'description',
  organizationId = 'organizationId',
}

export interface AlgoliaRegion extends AlgoliaObject {
  id: string;
  name: string;
  code: string;
  parents: string[];
  parentId: string;
  memo: string;
  organizationId: string;
}

const searchableAttributes = {
  code: AlgoliaRegionsAttributes.code,
  name: AlgoliaRegionsAttributes.name,
};

export default class AlgoliaRegions extends AlgoliaBase<AlgoliaRegion> {
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;
  public static readonly facets = () => ({
    id: AlgoliaRegionsAttributes.id,
  });
  public static getIndex = (client: Client, organizationId: string) => new AlgoliaRegions(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.regions;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaRegion>(this.index, condition);
  public searchableAttributes = AlgoliaRegions.searchableAttributes;
  public facets = () => AlgoliaRegions.facets();
}
