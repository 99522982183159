import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {Client} from 'algoliasearch';
import {EpochMillis} from '../../../common-types/numbers';

export declare type StoreBasicType = {
  id: string;
  createdAt?: EpochMillis;
  updatedAt?: EpochMillis;
  deleted?: boolean;
  deletedAt?: EpochMillis;
  migrate?: number;
  // createOperator?: Operator;
  // lastUpdateOperator?: Operator;
  // deleteOperator?: Operator;
};

/**
 * 注文のステータス
 * homehubの性質上、サービスとの連携のステータスが大事になりそう
 * この辺のステータスは連携が増えると増えるイメージ
 * キャンセルは性質が異なるかなと思ったので、booleanで持たせてます
 */
export enum OrderStatus {
  // サービサーに注文が連携できたかどうか
  // 常にリアルタイムで連携できるわけではないのではないか・・・
  BEFORE_COORDINATION = 'BEFORE_COORDINATION', // 連携前
  COORDINATION_COMPLETED = 'COORDINATION_COMPLETED', // 連携完了

  // 配送系
  BEFORE_SHIPMENT = 'BEFORE_SHIPMENT',
  ALL_SHIPPED = 'ALL_SHIPPED',
}

export enum OrderSettlementStatus {
  AUTHORIZE = 'AUTHORIZE', // 与信をとった状態
  SETTLED = 'SETTLED', // 支払い済み

  NONE = 'NONE', // 決済を実施しない場合
  ERROR = 'ERROR', // エラー

  AUTHORIZE_CANCELED = 'CANCELED', // 与信の取り消し
  REFUNDED = 'REFUNDED', // 返金済み
}

// 指定された配送の時間帯（業者によって変わりうるかも）
export enum OrderSelectTimeframe {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
  NIGHT = 'NIGHT',
  ANY = 'ANY',
}

export type CommonTypesOrederShipmentInfo = {
  // 配送先、どこ・だれへ
  address: {
    prefecture: string;
    state: string;
    street: string;
    building?: string;
    other?: string;
  };
  postal: string;
  tel?: string; // requireにしなくてもいい気はする
  // 読み仮名
  kana: {
    family: string;
    given: string;
  };

  // 漢字、アルファベット
  name: {
    family: string;
    given: string;
  };

  // TODO: 配送設定について
  // 注文の詳細（配送先・決済）連携するか、配送までも連携するかでここはめちゃくちゃ変わりそう
  // どうやって届けるか（贈り物・ラッピング・配送方法）
  shippingCarrier?: string; // 配送業者
  shippingOption?: string;

  // いつ届けるか（日付・時間指定）
  selectTimestamp?: number; // 指定された配送日
  selectTimeframe?: OrderSelectTimeframe;
};

export declare type StoreTypesSellingSalesUnits = StoreBasicType & {
  name: string;
  salesUnitCode: string;
  memo?: string;
  status: any;
  price: any;
  salesSettings: {
    shipmentFee: number;
    shipmentLeadDays?: number;
    taxRate: number;
  };
  itemIds: string[];
  detectableCondition?: any;
  salesUnitCategory: any[];
  salesUnitArticles: any[];
  publishStartDate?: EpochMillis;
  publishEndDate?: EpochMillis;
  isRequireStock: boolean;
  attributes?: {
    [key: string]: string;
  }[];
  searchKeywords?: string[];
  serviceProviderId: string;
  sellerType: 'User' | 'Organization' | 'Shop';
  sellerName: string;
  organizationId?: string;
};

export enum AlgoliaOrdersAttributes {
  id = 'id',
  settlementStatus = 'settlementStatus',
  // defaultPersonaId = 'defaultPersonaId',
  // externalId = 'externalId',
  // name = 'name',
  // email = 'email',
  // roleIds = 'roleIds',
  // hide = 'hide',
  // spaceIds = 'spaceIds',
  // spaceId = 'devices.spaceId',
  // deviceId = 'devices.deviceId',
  // status = 'status',
  // personType = 'personType',
  // inChargeOfSpaceIds = 'inChargeOfSpaceIds',
  // userAuthorityIds = 'userAuthorityIds',
  // roles = 'roles',
  // departmentName = 'departmentName',
}

export enum FilterAttributes {
  id = 'id',
}

export enum FacetAttributes {
  settlementStatus = 'settlementStatus',
}

export type AlgoliaOrder = AlgoliaObject &
  StoreBasicType & {
    personaId: string; // 誰が
    deviceType: 'SP' | 'Tablet' | 'TV' | 'Web'; // どのhomehubで購入したか
    isCancel: boolean; // キャンセル有無

    // 同時に他のサービサーの注文もあった場合ここにグルーピングされるLinkIdを入れる
    orderLinkId?: string;
    // ユーザが認識する注文の単位
    // メールとかに使う
    userOrderNumber: string;

    orderStatus: OrderStatus; // サービサーへの連携が完了したかどうか
    // 与信とりました、決済まで終わりました、みたいな
    settlementStatus: OrderSettlementStatus;

    settlements: {
      settlementId?: string; // settlementDocumentのIDがあれば
      method?: string; // 決済方法

      // core-settlementにわたすパラメータをそのまま保存
      settlementParams?: {
        customerId: string;
        orderId: string;
        itemId: string;
        amount: number;
        tax: number;
        creditCard?: {
          cardKey?: string;
        };
      };

      transactionId?: string;
      trackingId?: string;

      authorizedAt?: number; // 与信をとった日
      executedAt?: number; // 決済を行なった日
    };

    salesUnits: StoreTypesSellingSalesUnits[]; // 何を

    quantity: {salesUnitId: string; quantity: number}[]; // 数量

    // ユーザが設定した配送情報を抱える
    defaultShipment: CommonTypesOrederShipmentInfo;

    // いくらで
    // 値引き・税込・送料込み価格
    // 基本的にこれがそのまま決済に使われる
    totalAmount: number; // 合計額（税抜き、値引き済み）
    totalAdjustAmount: number; // 値引き（あるいは増額）
    totalTaxAmount: number; // 税額

    shipmentFeeTotal: number; // 送料（税抜き、値引き済み）
    shipmentFeeAdjust: number; // 送料の値引き額
    shipmentTax: number; // 送料の税額

    settlementFeeTotal?: number; // 決済手数料

    // 適用キャンペーン
    // TODO: 型定義
    applicableCampaigns?: any[];

    // 価格詳細
    amountDetails: {
      salesUnitId: string;
      total: number; // 合計額（税込、値引き済み）
      baseAmount: number; // 額（税別）
      discount: number; // 値引き額
      tax: number; // 税額
    }[];

    serviceProviderId: string;
    sellerType: 'User' | 'Organization' | 'Shop';
    sellerName: string;
    organizationId?: string;

    orderShipments?: Array<OrderShipment>;
  };

type OrderShipment = {
  orderId: string;
  personaId: string;

  shipmentSalesUnits: StoreTypesSellingSalesUnits[];

  shipment: CommonTypesOrederShipmentInfo;

  quantity: {salesUnitId: string; quantity: number}[];

  // どこから購入したか
  // TODO: サービスプロバイダに合わせる
  serviceProviderId: string;
  sellerType: 'User' | 'Organization' | 'Shop';
  sellerName: string;
  organizationId?: string;

  // MEMO: 一応キャンセルフラグはDetailでも持つが、部分キャンセルは基本やりたくない
  isCancel: boolean; // キャンセル有無
};

const searchableAttributes = {
  // name: AlgoliaMembersAttributes.name,
  // email: AlgoliaMembersAttributes.email,
  // personaId: AlgoliaMembersAttributes.personaId,
  // externalId: AlgoliaMembersAttributes.externalId,
};

export default class AlgoliaOrders extends AlgoliaBase<AlgoliaOrder> {
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    // for facet
    settlementStatus: AlgoliaOrdersAttributes.settlementStatus,

    // for filter
    id: AlgoliaOrdersAttributes.id,
    // email: AlgoliaMembersAttributes.email,
    // spaceIds: AlgoliaMembersAttributes.spaceIds,
    // spaceId: AlgoliaMembersAttributes.spaceId,
    // deviceId: AlgoliaMembersAttributes.deviceId,
    // defaultPersonaId: AlgoliaMembersAttributes.defaultPersonaId,
    // inChargeOfSpaceIds: AlgoliaMembersAttributes.inChargeOfSpaceIds,
    // roles: AlgoliaMembersAttributes.roles,
    // userAuthorityIds: AlgoliaMembersAttributes.userAuthorityIds,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaOrders(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.orders;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaOrders.facets();
  public readonly searchableAttributes = AlgoliaOrders.searchableAttributes;
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaOrder>(this.index, condition);
}
