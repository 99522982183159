import {
    FirestoreTypesOrganizationSystemAuditLogs
} from '../../firebase/firestore/references-types/organizations/system-audit-logs/firestore-types-organizations-system-audit-logs';
import {BrowseParameters, QueryParameters} from 'algoliasearch';
import Redux from '../../redux/ReduxConnector';
import {V2FirebaseHomehubBackendFunctions} from '../../firebase/functions/v2-firebase-homehub-backend-functions';

export class SystemAuditLogsService {
  public static addSystemAuditLog = async (data: {
    requests?: {
      method: string;
      url: string;
      body?: any;
    }[];
    requestsAlgolia?: {
      indexName: string;
      searchParameters?: QueryParameters;
      browseConditions?: {
        query: string;
        browseParameters: BrowseParameters;
      };
    }[];
  }): Promise<FirestoreTypesOrganizationSystemAuditLogs> => {
    const organizationId = Redux.getStore().user.organizationId;
    const res = await V2FirebaseHomehubBackendFunctions.post(
      `homehubAnalysis/${V2FirebaseHomehubBackendFunctions.getVersion()}/organizations/${organizationId}/systemAuditLogs`,
      data,
    );
    return res.data;
  };
}
