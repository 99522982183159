import {Index} from 'algoliasearch';
import Algolia, {AlgoliaFilters, AlgoliaIndex, AlgoliaObject, AlgoliaSearchCondition, BrowseCondition} from './algolia';

export default abstract class AlgoliaBase<T extends AlgoliaObject> implements AlgoliaIndex<T> {
  // spaceでやってる、typeがからっぽというフィルターをかけたいときに、フィルターなしになってしまう問題の対策
  // ここで共通化していいかどうかが微妙なので一旦コメントアウト
  // private static FORCE_EMPTY_FILTER = ['xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'];

  protected index: Index;
  protected name: string;
  protected rawIndexName: string;
  public browse = async (condition: BrowseCondition) => Algolia.browse<T>(this.index, condition);
  public browseNext = async (cursor: string) => Algolia.browseNext<T>(this.index, cursor);
  public clearCache = () => Algolia.clearCache(this.index);
  public abstract facets: () => {[p: string]: string};
  public getName = () => this.name;
  public getRawIndexName = () => this.rawIndexName;
  public search = (condition: AlgoliaSearchCondition) => Algolia.search<T>(this.index, condition);
  public relation = (filters: AlgoliaFilters, length?: number) => Algolia.relation<T>(this.index, filters, length);
}
