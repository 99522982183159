import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {EpochMillis} from '../../../common-types/numbers';
import {
    AnnouncementStatus
} from '../../../firebase/functions/api-types/organizations/announcements/api-types-organizations-announcements';
import {ContentOpenType} from '../../../enums/contentOpenType';
import {Client} from 'algoliasearch';

export enum AlgoliaAnnouncementsAttributes {
  id = 'id',
  updatedAt = 'updatedAt',
  title = 'title',
  content = 'content',
  categoryId = 'categoryId',
  mainImages = 'mainImages',
  documents = 'documents',
  status = 'status',
}

export enum FilterAttributes {
  title = 'title',
}

export enum FacetAttributes {
  categoryId = 'categoryId',
  status = 'status',
}

export interface AlgoliaAnnouncementData extends AlgoliaObject {
  id: string;
  /** メインの画像、firestoreのuriが記載される */
  mainImages: Array<string>;
  /** 組織ID */
  organizationId: string;
  /** 投稿時間 */
  createdAt: EpochMillis;
  /** 更新時間 */
  updatedAt: EpochMillis;

  /** タイトル */
  title: string;

  /** 概要 */
  description?: string;

  /** マークダウン or HTML方式で保持 */
  content: string;

  /** コンテンツ読み込み権限を持つUserAuthorityの一覧 */
  readableUserAuthorityIds: string[];
  /** カテゴリID */
  categoryId: string;

  /** 公開範囲設定(default: Public) */
  openType?: ContentOpenType;

  /** 配信日時 */
  publishedAt?: number;

  /** 配信予定日 */
  publishScheduledAt?: number;

  /** 配信終了予定日 */
  closedScheduledAt?: number;

  /** アナウンスメント種別 */
  // announcementType: AnnouncementsType;

  /**  */
  status: AnnouncementStatus;
  /**  */
  isEmergency: boolean;
  /**  */
  isImportant: boolean;
}

const searchableAttributes = {
  title: AlgoliaAnnouncementsAttributes.title,
};

export default class AlgoliaAnnouncements extends AlgoliaBase<AlgoliaAnnouncementData> {
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    categoryId: AlgoliaAnnouncementsAttributes.categoryId,
    title: AlgoliaAnnouncementsAttributes.title,
    status: AlgoliaAnnouncementsAttributes.status,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaAnnouncements(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.announcements;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaAnnouncements.facets();
  public readonly searchableAttributes = AlgoliaAnnouncements.searchableAttributes;
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaAnnouncementData>(this.index, condition);
}
