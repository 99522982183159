import {FirestoreUser} from '../../../firestore/references/users/firestore-users';
import {V2FirebaseHomehubOldApiFunctions} from '../../v2-firebase-homehub-old-api-functions';

export default class ApiUsers {
  public static getSelf = async () => {
    const res = await V2FirebaseHomehubOldApiFunctions.get<FirestoreUser>(
      `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-users/getSelf`,
    );
    return res ? res.data : undefined;
  };
}
