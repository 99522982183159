import type {EpochMillis} from '../../../common-types/numbers';
import type {Operator} from '../../../firebase/firestore/firestore';
import type {AlgoliaObject} from '@algolia/v1/legacy-index-type';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';

export enum AlgoliaFaqTagAttributes {
  id = 'id',
  title = 'title',
  createdAt = 'createdAt',
  createOperator = 'createOperator',
  updatedAt = 'updatedAt',
  lastUpdateOperator = 'lastUpdateOperator',
  deleted = 'deleted',
  deletedAt = 'deletedAt',
  deletedOperator = 'deletedOperator',
}

export interface AlgoliaFaqTagData extends AlgoliaObject {
  title?: string;
  createdAt?: EpochMillis;
  createOperator?: Operator;
  updatedAt?: EpochMillis;
  lastUpdateOperator?: Operator;
  deleted: boolean;
  deletedAt?: EpochMillis;
  deletedOperator?: Operator;
}

export default class AlgoliaFaqTags extends LegacyIndexBase<AlgoliaFaqTagData> {
  protected name = LegacyIndexName.faqTags;
  protected rawIndexName: string;

  public static readonly facets = () => ({
    id: AlgoliaFaqTagAttributes.id,
    title: AlgoliaFaqTagAttributes.title,
  });

  public static getIndex = (organizationId: string) => new AlgoliaFaqTags(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaFaqTags.facets();
}
