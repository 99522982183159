import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

import {
  BoardMeetingParticipantType,
  BoardMeetingStatus,
  BoardMeetingType,
} from '../../../firebase/firestore/references-types/organizations/board-meetings/firestore-types-organizations-board-meetings';
import type {AlgoliaObject} from '../../algolia';

export enum AlgoliaBoardMeetingsAttributes {
  title = 'title',
  type = 'type',
  branchId = 'branchId',
  status = 'status',
  buildingName = 'buildingName',
  buildingSpaceId = 'buildingSpaceId',
  spaceGroupId = 'spaceGroupId',
  startAt = 'startAt',
  endAt = 'endAt',
  participantsDefaultPersonaId = 'participants.defaultPersonaId',
  participantsEmail = 'participants.email',
  participantsPhoneNumber = 'participants.phoneNumber',
  participantsParticipantType = 'participants.participantType',
}

export interface AlgoliaBoardMeetingParticipant {
  participantType: BoardMeetingParticipantType;
  host: boolean;
}

export interface AlgoliaBoardMeetingData extends AlgoliaObject {
  type: BoardMeetingType;
  title: string;
  startAt: number;
  endAt: number;
  buildingSpaceId?: string; // 理事会, 総会対象の建物spaceId
  spaceGroupId?: string; // 理事会, 総会対象の建物spaceId
  status: BoardMeetingStatus;
  roomId: string;
  recorded?: boolean; // 録音されたかどうか
  branchId?: string;
}

const searchableAttributes = {
  title: AlgoliaBoardMeetingsAttributes.title,
  buildingName: AlgoliaBoardMeetingsAttributes.buildingName,
};

export default class AlgoliaBoardMeetings extends LegacyIndexBase<AlgoliaBoardMeetingData> {
  protected name = LegacyIndexName.boardMeetings;
  protected rawIndexName: string;

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;
  public static readonly facets = () => ({
    type: AlgoliaBoardMeetingsAttributes.type,
    status: AlgoliaBoardMeetingsAttributes.status,
    branchId: AlgoliaBoardMeetingsAttributes.branchId,
    buildingSpaceId: AlgoliaBoardMeetingsAttributes.buildingSpaceId,
    spaceGroupId: AlgoliaBoardMeetingsAttributes.spaceGroupId,
    buildingName: AlgoliaBoardMeetingsAttributes.buildingName,
    startAt: AlgoliaBoardMeetingsAttributes.startAt,
    endAt: AlgoliaBoardMeetingsAttributes.endAt,
    participantsDefaultPersonaId: AlgoliaBoardMeetingsAttributes.participantsDefaultPersonaId,
    participantsEmail: AlgoliaBoardMeetingsAttributes.participantsEmail,
    participantsPhoneNumber: AlgoliaBoardMeetingsAttributes.participantsPhoneNumber,
    participantsParticipantType: AlgoliaBoardMeetingsAttributes.participantsParticipantType,
  });
  public static getIndex = (organizationId: string) => new AlgoliaBoardMeetings(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public searchableAttributes = AlgoliaBoardMeetings.searchableAttributes;
  public facets = () => AlgoliaBoardMeetings.facets();
}
