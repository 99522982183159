import type {AlgoliaObject} from '../../algolia';
import {
  FirestoreOrganizationGuestStatus,
  FirestoreOrganizationGuestType,
} from '../../../firebase/firestore/references/organizations/guests/firestore-guests';
import {PersonType} from '../../../firebase/firestore/references/organizations/space-types/firestore-spaceTypes';
import {AlgoliaOrganizationsFaceImageSummaryStatus} from '../face-image-auth-contracts-summaries/algolia-face-image-auth-contracts-summaries';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

export enum AlgoliaGuestAttributes {
  id = 'id',
  personaId = 'personaId',
  externalId = 'externalId',
  name = 'name',
  email = 'email',
  phoneNumber = 'phoneNumber',
  fcmToken = 'fcmToken',
  photoUri = 'photoUri',
  guestType = 'guestType',
  personType = 'personType',
  status = 'status',
  customerIds = 'customerIds',
  // 今までの空間単位で管理されてるやつ
  spaceIds = 'spaceIds',
  // デバイス単位で管理してるやつ
  spaceId = 'device.spaceId',
  deviceId = 'device.deviceId',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  inChargeOfSpaceIds = 'inChargeOfSpaceIds',
}

export interface AlgoliaGuestData extends AlgoliaObject {
  organizationId?: string;
  personaId: string;
  externalId: string;
  name: string;
  email: string;
  telephone?: string;
  fcmToken: string;
  photoUri: string;
  guestType: FirestoreOrganizationGuestType;
  personType: PersonType;
  status: FirestoreOrganizationGuestStatus;
  customerIds?: string[];
  spaceIds?: string[];
  devices: {spaceId: string; deviceId: string}[];
  themeColor?: string;
  createdAt?: number;
  updatedAt?: number;

  inChargeOfSpaceIds: string[];

  // 以下賃貸管理用
  birthday?: string;
  phoneNumber: string;
  // 携帯電話番号
  mobilePhoneNumber?: string;
  address?: string;
  // 勤めている企業
  companyName?: string;
  // 企業の電話番号
  companyPhoneNumber?: string;
  businessAddress?: string;
  // 年収
  annualIncome?: string;
  faceImageRegisteredStatus?: AlgoliaOrganizationsFaceImageSummaryStatus;
}

export default class AlgoliaGuests extends LegacyIndexBase<AlgoliaGuestData> {
  protected name = LegacyIndexName.guests;
  protected rawIndexName: string;

  public static readonly facets = () => ({
    // for facet
    personType: AlgoliaGuestAttributes.personType,
    guestType: AlgoliaGuestAttributes.guestType,
    status: AlgoliaGuestAttributes.status,
    // for filter
    id: AlgoliaGuestAttributes.id,
    email: AlgoliaGuestAttributes.email,
    phoneNumber: AlgoliaGuestAttributes.phoneNumber,
    externalId: AlgoliaGuestAttributes.externalId,
    customerIds: AlgoliaGuestAttributes.customerIds,
    spaceIds: AlgoliaGuestAttributes.spaceIds,
    spaceId: AlgoliaGuestAttributes.spaceId,
    deviceId: AlgoliaGuestAttributes.deviceId,
    inChargeOfSpaceIds: AlgoliaGuestAttributes.inChargeOfSpaceIds,
  });

  public static getIndex = (organizationId: string) => new AlgoliaGuests(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaGuests.facets();
}
