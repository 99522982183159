import {FirestoreDocumentData} from '../../../firestore';

export enum PersonType {
  member = 'member',
  guest_temporary = 'guest_temporary',
  guest_customer_representative = 'guest_customer_representative',
  guest_customer_member = 'guest_customer_member',
  cast = 'cast',
  guest_reception = 'guest_reception',
  guest_customer = 'guest_customer',
}

// この情報が空間登録時のデフォルト値になるという使い方がいいのでは
// 逆に空間登録時にそれをコピーしてタイプとして追加することもできるとか。
// 複数のタイプを選択して和集合にするのもあり
export interface FirestoreOrganizationSpaceTypeData extends FirestoreDocumentData {
  name: string;
  defaultTicketAccessibility: PersonType[];
  defaultKeyAccessibility: PersonType[];
  // 一つの空間でたくさん契約できるものもあるはず。フリーランスの人たちがいるところとか
  maximumContracts: number;

  // 他に基本的な備品とかクレジット利用とか予約可能とかの情報を持つイメージ。
}
