import {Client, Response} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject, AlgoliaSearchCondition} from '../../algolia';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaCondominiumAssociationsAttributes {
  name = 'name',
  code = 'code',
  allowVoting = 'allowVoting',
  inChargeOfOldSpaceIds = 'inChargeOfOldSpaceIds',
  oldSpaceIds = 'oldSpaceIds',
  v2SpaceIds = 'v2SpaceIds',
}

export interface AlgoliaCondominiumAssociationsData extends AlgoliaObject {
  name: string;
  code: string;
  allowVoting: boolean;
  inChargeOfOldSpaceIds: string[];
  oldSpaceIds: string[];
  v2SpaceIds: string[];
  buildings?: string[];
}

const searchableAttributes = {
  name: AlgoliaCondominiumAssociationsAttributes.name,
  code: AlgoliaCondominiumAssociationsAttributes.code,
  allowVoting: AlgoliaCondominiumAssociationsAttributes.allowVoting,
  inChargeOfOldSpaceIds: AlgoliaCondominiumAssociationsAttributes.inChargeOfOldSpaceIds,
  oldSpaceIds: AlgoliaCondominiumAssociationsAttributes.oldSpaceIds,
  v2SpaceIds: AlgoliaCondominiumAssociationsAttributes.v2SpaceIds,
};

export default class AlgoliaCondominiumAssociations extends AlgoliaBase<AlgoliaCondominiumAssociationsData> {
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;
  public static readonly facets = () => ({
    name: AlgoliaCondominiumAssociationsAttributes.name,
    code: AlgoliaCondominiumAssociationsAttributes.code,
    allowVoting: AlgoliaCondominiumAssociationsAttributes.allowVoting,
    inChargeOfOldSpaceIds: AlgoliaCondominiumAssociationsAttributes.inChargeOfOldSpaceIds,
    oldSpaceIds: AlgoliaCondominiumAssociationsAttributes.oldSpaceIds,
    v2SpaceIds: AlgoliaCondominiumAssociationsAttributes.v2SpaceIds,
  });
  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaCondominiumAssociations(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.condominiumAssociations;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaCondominiumAssociationsData>(this.index, condition);
  public searchableAttributes = AlgoliaCondominiumAssociations.searchableAttributes;
  public facets = () => AlgoliaCondominiumAssociations.facets();
  public search = async (condition: AlgoliaSearchCondition) => {
    const res: Response<AlgoliaCondominiumAssociationsData> = await Algolia.search<AlgoliaCondominiumAssociationsData>(
      this.index,
      condition,
    );
    if (!res || !res.hits) {
      return res;
    }
    return res;
  };
}
