export enum BusinessType {
  CORE = 'CORE',
  CO_WORKING = 'CO_WORKING',
  RESIDENCE = 'RESIDENCE',

  // WorkHub遷移用
  Office = 'office',
}

export const isResidence = (businessType: BusinessType): boolean => businessType === BusinessType.RESIDENCE;
