/**
 * TakuTyのプラン名
 */
export enum SumamoruPlan {
  OG_Default = 'OG_Default',
  OG_WLock = 'OG_WLock',
  OG_Viewn = 'OG_Viewn',
}

export const SumamoruPlanMap: {[K in SumamoruPlan]: string} = {
  OG_Default: 'デフォルト',
  OG_WLock: 'ダブルロック',
  OG_Viewn: 'ビューン',
};

export const SumamoruPlanMapEn: {[K in SumamoruPlan]: string} = {
  OG_Default: 'Default',
  OG_WLock: 'WLock',
  OG_Viewn: 'OG_Viewn',
};
