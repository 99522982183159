import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {EpochMillis} from '../../../common-types/numbers';
import {AlgoliaReader} from './algolia-readers';

export interface AlgoliaReaderDeletedData extends AlgoliaObject, AlgoliaReader {
  deletedAt: EpochMillis;
  hideLogs: boolean;
}

export enum AlgoliaReadersDeletedAttributes {
  id = 'id',
  bkpTreasureBoxId = 'bkpTreasureBoxId',
  name = 'name',
  bitlockIds = 'bitlockIds',
  battery = 'battery',
  bitkeyUDID = 'bitkeyUDID',
  firmware = 'firmware',
  model = 'model',
  serialNo = 'serialNo',
  createdAt = 'createdAt',
  deviceAddress = 'deviceAddress',
  connectorId = 'connectorId',
}

export default class AlgoliaReadersDeleted extends AlgoliaBase<AlgoliaReaderDeletedData> {
  public static readonly facets = () => ({
    name: AlgoliaReadersDeletedAttributes.name,
    serialNo: AlgoliaReadersDeletedAttributes.serialNo,
    // relation
    id: AlgoliaReadersDeletedAttributes.id,
    deviceAddress: AlgoliaReadersDeletedAttributes.deviceAddress,
    connectorId: AlgoliaReadersDeletedAttributes.connectorId,
    model: AlgoliaReadersDeletedAttributes.model,
    firmware: AlgoliaReadersDeletedAttributes.firmware,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaReadersDeleted(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.readersDeleted;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaReadersDeleted.facets();
}
