import type {EpochMillis} from '../../../common-types/numbers';
import type {Operator} from '../../../firebase/firestore/firestore';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';
import type {AlgoliaObject} from '@algolia/v1/legacy-index-type';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';

export enum AlgoliaFaqContactAttributes {
  id = 'id',
  name = 'name',
  emailId = 'emailId',
  phoneNumber = 'phoneNumber',
  summary = 'summary',
  createdAt = 'createdAt',
  createOperator = 'createOperator',
  updatedAt = 'updatedAt',
  lastUpdateOperator = 'lastUpdateOperator',
  deleted = 'deleted',
  deletedAt = 'deletedAt',
  deletedOperator = 'deletedOperator',
}

export interface AlgoliaFaqContactData extends AlgoliaObject {
  name: string;
  emailId?: string;
  phoneNumber?: string;
  summary: string;
  createdAt?: EpochMillis;
  createOperator?: Operator;
  updatedAt?: EpochMillis;
  lastUpdateOperator?: Operator;
  deleted: boolean;
  deletedAt?: EpochMillis;
  deletedOperator?: Operator;
}

export default class AlgoliaFaqContacts extends LegacyIndexBase<AlgoliaFaqContactData> {
  protected name = LegacyIndexName.faqContacts;
  protected rawIndexName: string;

  public static readonly facets = () => ({
    id: AlgoliaFaqContactAttributes.id,
    name: AlgoliaFaqContactAttributes.name,
    emailId: AlgoliaFaqContactAttributes.emailId,
    phoneNumber: AlgoliaFaqContactAttributes.phoneNumber,
    summary: AlgoliaFaqContactAttributes.summary,
  });

  public static getIndex = (organizationId: string) => new AlgoliaFaqContacts(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaFaqContacts.facets();
}
