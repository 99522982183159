import {
    FirestoreDeleteKeyErrorResponse,
    FirestoreDeleteKeySuccessResponse,
    FirestoreOrganizationKeyData,
    FirestoreOrganizationKeyInfo,
    FirestoreOrganizationKeyReceiver,
    FirestoreOrganizationKeyType,
    FirestoreSendKeyErrorResponse,
    FirestoreSendKeySuccessResponse,
} from '../../firebase/firestore/references/organizations/keys/firestore-keys';
import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';
import DictError from '../../errors/dict-error';
import {ErrorDictKey} from '../../dictionary/error-dictionary';
import {AlgoliaKeyData} from '../../algolia/references/keys/algolia-keys';
import Redux from '../../redux/ReduxConnector';
import {FirestoreResponseData} from '../../firebase/firestore/firestore';
import {
    FirestoreOrganizationKeyIssueSettingsData
} from '../../firebase/firestore/references/organizations/secrets/firestore-keyIssue-settings';
import Algolia from '../../algolia/algolia';
import {PersonType} from '../../firebase/firestore/references/organizations/space-types/firestore-spaceTypes';
import FunctionsErrorCodeEnum from '../../errors/service-error';
import LocalStorage, {LocalStorageKey} from '../../local-storage/LocalStorage';

export default class KeysService {
  public static sendKey = async (data: {
    keys: FirestoreOrganizationKeyInfo[];
    receivers: FirestoreOrganizationKeyReceiver[];
    message: string;
  }): Promise<{successList: FirestoreSendKeySuccessResponse[]; errorList: FirestoreSendKeyErrorResponse[]}> => {
    for (const keyData of data.keys) {
      // FIXME typescriptのversion上げたら警告増えたので、いったんts-ignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete keyData.device;
    }
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    const sendKeyResult = await V2FirebaseHomehubOldApiFunctions.call('organizations-keys-send', {
      accessToken,
      keys: data.keys.map(k => ({
        ...k,
        usageStart: k.usageStart ? k.usageStart.toMillis() : undefined,
        usageEnd: k.usageEnd ? k.usageEnd.toMillis() : undefined,
        publishStart: k.publishStart ? k.publishStart.toMillis() : undefined,
        publishEnd: k.publishEnd ? k.publishEnd.toMillis() : undefined,
        repeatType: k.repeatType,
      })),
      receivers: data.receivers,
      message: data.message,
    }).catch(e => {
      throw new DictError(ErrorDictKey.failedToSend, e);
    });

    // tobilife crewでかつmemberへのチケット送信であればデフォルトペルソナと組織ペルソナにチケットを発行する。
    // tobilife crewが組織ペルソナ前提かつ自組織にチケット＋TODOで管理したい人員がいるため。
    if (
      Redux.getStore().dictionary.useTobilifeCrew &&
      data.keys.find(key => key.keyType === FirestoreOrganizationKeyType.ONETIME) &&
      !!data.receivers.find(receiver => receiver.personType === PersonType.member)
    ) {
      const tickets = data.keys.filter(key => key.keyType === FirestoreOrganizationKeyType.ONETIME);
      const receivers = data.receivers.filter(receiver => receiver.personType === PersonType.member);

      await V2FirebaseHomehubOldApiFunctions.call('organizations-tickets-send', {
        accessToken,
        keys: tickets.map(k => ({
          ...k,
          usageStart: k.usageStart ? k.usageStart.toMillis() : undefined,
          usageEnd: k.usageEnd ? k.usageEnd.toMillis() : undefined,
          publishStart: k.publishStart ? k.publishStart.toMillis() : undefined,
          publishEnd: k.publishEnd ? k.publishEnd.toMillis() : undefined,
          repeatType: k.repeatType,
        })),
        receivers: receivers,
        message: data.message,
      }).catch(e => {
        throw new DictError(ErrorDictKey.failedToSend, e);
      });
    }

    return sendKeyResult;
  };

  public static sendKeys = async (
    data: {
      keys: FirestoreOrganizationKeyInfo[];
      receivers: FirestoreOrganizationKeyReceiver[];
      message: string;
    }[],
  ): Promise<{successList: FirestoreSendKeySuccessResponse[]; errorList: FirestoreSendKeyErrorResponse[]}> => {
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    // GCFのタイムアウトを15行ごとにリクエストして回避（1行の処理は２秒ちょっとなので１リクエスト30秒ほどで終わる計算）
    const requestDivideSize = 15;
    let tmpDivideRequest: {
      keys: FirestoreOrganizationKeyInfo[];
      receivers: FirestoreOrganizationKeyReceiver[];
      message: string;
    }[] = [];
    const divideRequest: {
      keys: FirestoreOrganizationKeyInfo[];
      receivers: FirestoreOrganizationKeyReceiver[];
      message: string;
    }[][] = [];
    for (let i = 0; i < data.length; i++) {
      for (const keyData of data[i].keys) {
        // FIXME typescriptのversion上げたら警告増えたので、いったんts-ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete keyData.device;
      }
      tmpDivideRequest.push({
        keys: data[i].keys,
        receivers: data[i].receivers,
        message: data[i].message,
      });
      if ((i % requestDivideSize === 0 && i !== 0) || i === data.length - 1) {
        divideRequest.push(tmpDivideRequest);
        tmpDivideRequest = [];
      }
    }

    const successList: FirestoreSendKeySuccessResponse[] = [];
    const errorList: FirestoreSendKeyErrorResponse[] = [];
    for (const request of divideRequest) {
      const result = await V2FirebaseHomehubOldApiFunctions.call('organizations-keys-multipleSend', {
        accessToken,
        requests: request.map(r => ({
          ...r,
          keys: r.keys.map(k => ({
            ...k,
            usageStart: k.usageStart ? k.usageStart.toMillis() : undefined,
            usageEnd: k.usageEnd ? k.usageEnd.toMillis() : undefined,
            publishStart: k.publishStart ? k.publishStart.toMillis() : undefined,
            publishEnd: k.publishEnd ? k.publishEnd.toMillis() : undefined,
          })),
        })),
      }).catch(e => {
        for (const d of data) {
          for (const key of d.keys) {
            for (const receiver of d.receivers) {
              errorList.push({
                code: FunctionsErrorCodeEnum.ABORTED,
                id: '',
                message: e.message,
                key: key,
                receiver: receiver,
              });
            }
          }
        }
      });
      if (!!result && !!result.successList) {
        successList.push(...result.successList);
      }
      if (!!result && !!result.errorList) {
        errorList.push(...result.errorList);
      }
    }
    return {
      successList,
      errorList,
    };
  };

  // buttonに紐づいているカギのid
  public static getKeyIdsRelatedButton = async (data: {keys: AlgoliaKeyData[]}): Promise<string[]> => {
    try {
      const deviceButtonRelationIndex = Algolia.indices().deviceButtonRelations();
      const keyIds = data.keys.map(key => key.id);
      const keyIdsRelatedButton = (
        await deviceButtonRelationIndex.relation({
          [deviceButtonRelationIndex.facets().passportId]: keyIds,
        })
      ).hits.map(deviceButtonRelation => deviceButtonRelation.passportId);
      return keyIdsRelatedButton;
    } catch (e) {
      console.log(e);
      throw new Error(e);
    }
  };

  public static deleteKey = async (data: {keys: AlgoliaKeyData[]}) => {
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    await V2FirebaseHomehubOldApiFunctions.call('organizations-keys-delete', {
      accessToken,
      keys: data.keys,
    }).catch(e => {
      console.log(e);
      throw new DictError(ErrorDictKey.failedToDeleteKeyAndTicket, e);
    });
  };

  public static deleteKeys = async (data: {
    keys: FirestoreOrganizationKeyData[];
  }): Promise<{successList: FirestoreDeleteKeySuccessResponse[]; errorList: FirestoreDeleteKeyErrorResponse[]}> => {
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    const requestDivideSize = 15;
    const dividedKeyData: {keys: FirestoreOrganizationKeyData[]}[] = [];
    let tmpKeyData: FirestoreOrganizationKeyData[] = [];
    for (let i = 0; i < data.keys.length; i++) {
      tmpKeyData.push(data.keys[i]);
      if (i % requestDivideSize === 0 || i === data.keys.length - 1) {
        dividedKeyData.push({keys: tmpKeyData});
        tmpKeyData = [];
      }
    }
    const allSuccessList: FirestoreDeleteKeySuccessResponse[] = [];
    const allErrorList: FirestoreDeleteKeyErrorResponse[] = [];
    for (const d of dividedKeyData) {
      const {
        successList,
        errorList,
      }: {
        successList: FirestoreDeleteKeySuccessResponse[];
        errorList: FirestoreDeleteKeyErrorResponse[];
      } = await V2FirebaseHomehubOldApiFunctions.call('organizations-keys-multipleDelete', {
        accessToken,
        keys: d.keys.map(k => ({
          ...k,
          usageStart: k.usageStart ? k.usageStart.toMillis() : undefined,
          usageEnd: k.usageEnd ? k.usageEnd.toMillis() : undefined,
          publishStart: k.publishStart ? k.publishStart.toMillis() : undefined,
          publishEnd: k.publishEnd ? k.publishEnd.toMillis() : undefined,
        })),
      }).catch(e => {
        for (const k of d.keys) {
          errorList.push({
            code: FunctionsErrorCodeEnum.ABORTED,
            message: e.message,
            key: k,
          });
        }
      });
      if (successList) {
        allSuccessList.push(...successList);
      }
      if (errorList) {
        allErrorList.push(...errorList);
      }
    }
    return {
      successList: allSuccessList,
      errorList: allErrorList,
    };
  };

  // カギ発行設定のための関数
  public static registerKeyIssueSettings = async (data: {
    isActiveDisableAutoDelete: boolean;
    disableAutoDeleteAppliedPersonType: string[];
  }) => {
    await V2FirebaseHomehubOldApiFunctions.call('organizations-keys-registerKeyIssueSettings', {
      isActiveDisableAutoDelete: data.isActiveDisableAutoDelete,
      disableAutoDeleteAppliedPersonType: data.disableAutoDeleteAppliedPersonType,
    })
      .then(() => {
        // 成功したらReduxを変更する
        Redux.actions.settings.setKeyIssueSettings(data);
      })
      .catch(e => {
        throw new DictError(ErrorDictKey.failedToRegisterKeyIssueSettings, e);
      });
  };

  public static loadKeyIssueSettings = async (): Promise<
    FirestoreResponseData<FirestoreOrganizationKeyIssueSettingsData> | undefined
  > => {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-keys-getKeyIssueSettings').catch(e => {
      throw new DictError(ErrorDictKey.failedToGetKeyIssueSettings, e);
    });
  };
}
