import {FirestoreDocumentData} from '../../../../../firestore';
import {ReservationServiceType} from '../../../../../../../business-types/enums/core/common/reservation-type';

export enum OrganizationsReservationNotificationSettingDetectUnitKey {
  Organization = 'Organization',
  Branch = 'Branch',
}

export interface FirestoreOrganizationsReservationNotificationSettingData extends FirestoreDocumentData {
  detectId: string;
  detectUnit: OrganizationsReservationNotificationSettingDetectUnitKey;
  reservationServiceType: ReservationServiceType;
  notifyOrganization?: boolean;
  notifyBranch?: boolean;
}
