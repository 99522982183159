import type {AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';
import {StoreTypesOrgPeople} from '@repository/v2/firestore/organizations/people/firestoreOrgPeople';

export enum AlgoliaResidentAttributes {
  email = 'email',
  code = 'peopleCode',
  name = 'peopleName',
  peopleId = 'peopleId',
  phoneNumber = 'phoneNumber',
  peopleContactEmail = 'peopleContactEmail',
  peopleContactPhoneNumber = 'peopleContactPhoneNumber',
  bkpDefaultPersonaId = 'bkpDefaultPersonaId',
  bkpUserId = 'bkpUserId',
  invitationStatus = 'invitationStatus',
  hasInvitingUsages = 'hasInvitingUsages',
  nearestInvitingUsageStartAt = 'nearestInvitingUsageStartAt',
}

/**
 * backend側の型定義 `AlgoliaTypesOrganizationResident`
 */
export interface AlgoliaResidentData extends AlgoliaObject {
  id: string;
  organizationId: string;
  peopleId: string;
  email?: string;
  phoneNumber?: string;
  bkpDefaultPersonaId?: string;
  bkpUserId?: string;
  invitationStatus: StoreTypesOrgPeople['invitationStatus'];
  updatedAt: EpochMillis;
  peopleName?: string;
  peopleCode?: string;
  // ES経由で返却されるデータに ISOString形式で格納されている
  created_at?: string;

  peopleContactEmail?: string;
  peopleContactPhoneNumber?: string;

  hasInvitingUsages: boolean;
  nearestInvitingUsageStartAt?: EpochMillis;
  nearestInvitingUsageLastInvitationSentAt?: EpochMillis;
  hasResentInvitationToNearestInvitingUsage?: boolean;
}

const searchableAttributes = {
  email: AlgoliaResidentAttributes.email,
  phoneNumber: AlgoliaResidentAttributes.email,
  contactEmail: AlgoliaResidentAttributes.peopleContactEmail,
  contactPhoneNumber: AlgoliaResidentAttributes.peopleContactPhoneNumber,
  name: AlgoliaResidentAttributes.name,
  code: AlgoliaResidentAttributes.code,
};

export default class AlgoliaResidents extends LegacyIndexBase<AlgoliaResidentData> {
  protected name = LegacyIndexName.residents;
  protected rawIndexName: string;

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }
  public static getIndex = (organizationId: string) => new AlgoliaResidents(organizationId);

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    peopleId: AlgoliaResidentAttributes.peopleId,
    email: AlgoliaResidentAttributes.email,
    phoneNumber: AlgoliaResidentAttributes.phoneNumber,
    bkpDefaultPersonaId: AlgoliaResidentAttributes.bkpDefaultPersonaId,
    bkpUserId: AlgoliaResidentAttributes.bkpUserId,
    invitationStatus: AlgoliaResidentAttributes.invitationStatus,
    hasInvitingUsages: AlgoliaResidentAttributes.hasInvitingUsages,
  });
  public facets = () => AlgoliaResidents.facets();
}
