import {AlgoliaIndexName} from '../../algolia/algolia';
import {BrowseParameters, QueryParameters} from 'algoliasearch';
import {SystemAuditLogsService} from './system-audit-logs-service';

export class SystemAuditLogObserveService {
  private static instance: SystemAuditLogObserveService | null;
  private observing;
  private requests: {
    method: string;
    url: string;
    body?: any;
  }[];
  private algoliaRequests: {
    indexName: string;
    searchParameters?: QueryParameters;
    browseConditions?: {
      query: string;
      browseParameters: BrowseParameters;
    };
  }[];

  constructor() {
    SystemAuditLogObserveService.instance = null;
    this.observing = false;
    this.requests = [];
    this.algoliaRequests = [];
  }

  public static getInstance = () => {
    if (!SystemAuditLogObserveService.instance) {
      SystemAuditLogObserveService.instance = new SystemAuditLogObserveService();
      return SystemAuditLogObserveService.instance;
    }
    return SystemAuditLogObserveService.instance;
  };

  public isObserving = () => {
    return this.observing;
  };

  public startObserve = () => {
    this.observing = true;
  };

  public setAlgoliaRequests = (data: {
    indexName: AlgoliaIndexName;
    searchParameters?: QueryParameters;
    browseConditions?: {
      query: string;
      browseParameters: BrowseParameters;
    };
  }) => {
    const alreadyExistIndex = this.algoliaRequests.findIndex(request => request.indexName === data.indexName);
    if (alreadyExistIndex === -1) {
      this.algoliaRequests.push(data);
      return;
    }
    // 一連の処理で同一Indexに別々のparamsを渡すことが想定できなかったので上書き
    if (data.searchParameters) {
      this.algoliaRequests[alreadyExistIndex].searchParameters = data.searchParameters;
    }
    if (data.browseConditions) {
      this.algoliaRequests[alreadyExistIndex].browseConditions = data.browseConditions;
    }
  };

  public setRequest = (data: {method: string; url: string; body?: any}) => {
    const hasSamePathRequests = this.requests.some(request => request.url === data.url);
    if (hasSamePathRequests) {
      return;
    }
    this.requests.push(data);
  };

  public postRequests = async () => {
    await SystemAuditLogsService.addSystemAuditLog({
      requests: this.requests,
      requestsAlgolia: this.algoliaRequests,
      // SystemAuditLogの登録に失敗しても元の処理は継続して欲しいので
    }).catch(e => console.error(e));
  };

  public releaseObserve = () => {
    this.observing = false;
    this.requests = [];
    this.algoliaRequests = [];
  };
}
