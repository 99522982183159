import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useCommonDictionary} from '../../dictionary/Dictionary';
import SimpleSnackbar, {SnackbarState} from '../snackbars/SimpleSnackbar';

const NetWorkWatcher: React.FC = () => {
  const commonDict = useCommonDictionary();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    message: '',
    success: false,
  });

  const [onlineTimeout, setOnlineTimeout] = useState<NodeJS.Timeout>();
  const [offlineTimeout, setOfflineTimeout] = useState<NodeJS.Timeout>();

  const offlineListener = useCallback(() => {
    if (onlineTimeout) {
      clearTimeout(onlineTimeout);
      setOnlineTimeout(undefined);
    }
    setOfflineTimeout(
      setTimeout(() => {
        setSnackbarState({
          open: true,
          message: commonDict.networkConnectionError,
          success: false,
          customAutoHideDuration: 600000,
          onExited: offlineListener,
        });
      }, 5000),
    );
  }, [commonDict.networkConnectionError, onlineTimeout]);

  const onlineListener = useCallback(() => {
    if (offlineTimeout) {
      clearTimeout(offlineTimeout);
      setOfflineTimeout(undefined);
      setOnlineTimeout(
        setTimeout(() => {
          setSnackbarState({
            open: true,
            message: commonDict.networkConnectionSuccess,
            success: true,
          });
        }, 1000),
      );
    }
  }, [commonDict.networkConnectionSuccess, offlineTimeout]);

  useEffect(() => {
    window.addEventListener('offline', offlineListener);
    window.addEventListener('online', onlineListener);
    return () => {
      window.removeEventListener('offline', offlineListener);
      window.removeEventListener('online', onlineListener);
    };
  }, [commonDict.networkConnectionError, commonDict.networkConnectionSuccess, offlineListener, onlineListener]);

  return <SimpleSnackbar snackbarState={snackbarState} setSnackbarState={setSnackbarState} />;
};

export default NetWorkWatcher;
