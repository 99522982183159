import 'firebase/compat/functions';

import axios, {AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';

import {incrInitAPI} from '../../redux/api';
import {store} from '../../redux/store';

enum HomehubOldApiVersions {
  DefaultApiVersion = 'v1',
  V2DataModelVersion = 'v2',
}

/**
 * Cloud Functionsを使う場合は全てこれを経由する。
 * 実際の呼び出しはfunctionsディレクトリ以下の個別のファイルで実装。
 * APIの接続先をlocalhost等に変更する場合はfirebase.tsを参照。
 *
 * デフォルトはhomehub系のソースがdeployされているv1に向いているが、
 * changeToV2()メソッド実行後は v2のhomehubXXXのソースがdeployされているv2に向き先を変更する
 */
export class V2FirebaseHomehubBackendFunctions {
  private static version: HomehubOldApiVersions;
  private static axios: AxiosInstance;

  public static init = (baseURL: string) => {
    V2FirebaseHomehubBackendFunctions.version = HomehubOldApiVersions.DefaultApiVersion;
    V2FirebaseHomehubBackendFunctions.axios = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    store.dispatch(incrInitAPI());
  };

  /**
   * 現在のversionを取得
   * v1 = 旧bitlock-service
   * v2 = homehub-old-api
   */
  public static getVersion = () => V2FirebaseHomehubBackendFunctions.version;

  /**
   * homehubBoardMeetingApiのversionを取得
   * v1 = 旧bitlock-service
   * v2 = 新Api(2022/09 external_member等に対応したApi)
   */
  public static getHomehubBoardMeetingApiVersion = () => {
    const conferenceVersion = store.getState().settings.teleconferenceSettings?.conferenceVersion || 1;
    return conferenceVersion === 1 ? 'v1' : 'v2';
  };

  /**
   * APIの向き先をv2に変更する処理
   */
  public static changeToV2 = () => {
    V2FirebaseHomehubBackendFunctions.version = HomehubOldApiVersions.V2DataModelVersion;
    // NOTE: setしたtokenが消えるので逃がしておく
    const preHeaders = V2FirebaseHomehubBackendFunctions.axios.defaults.headers.common;
    const v2HubApiHost = process.env.REACT_APP_V2_HOMEHUB_API_DOMAIN;
    V2FirebaseHomehubBackendFunctions.axios = axios.create({
      baseURL: v2HubApiHost,
      headers: preHeaders,
    });
    store.dispatch(incrInitAPI());
  };

  public static post = <T = any, Q = any>(url: string, data?: Q, config?: AxiosRequestConfig): AxiosPromise<T> =>
    V2FirebaseHomehubBackendFunctions.axios.post<T>(url, data, config);
  public static patch = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> =>
    V2FirebaseHomehubBackendFunctions.axios.patch(url, data, config);
  public static get = <T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> =>
    V2FirebaseHomehubBackendFunctions.axios.get(url, config);
  public static put = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> =>
    V2FirebaseHomehubBackendFunctions.axios.put<T>(url, data, config);
  public static delete = (url: string, config?: AxiosRequestConfig): AxiosPromise =>
    V2FirebaseHomehubBackendFunctions.axios.delete(url, config);

  public static setToken = token => {
    V2FirebaseHomehubBackendFunctions.axios.defaults.headers.common['X-Api-Key'] = token;
  };

  public static addInterceptors(beforeRequest, errorBeforeRequest, handleResponse, handleErrorResponse) {
    this.axios.interceptors.response.use(beforeRequest, errorBeforeRequest);
    this.axios.interceptors.request.use(handleResponse, handleErrorResponse);
  }
}
