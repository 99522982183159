import type {AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import {StatusChangeLog} from '../../../business-types/things/storeTypesOrgThing';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

export enum AlgoliaThingStatusAttributes {
  id = 'id',
  thingId = 'thingId',
  batteryLevel = 'battery.level',
  latestStatus = 'latestStatus.status',
  organizationId = 'organizationId',
  updatedAt = 'updatedAt',
}
export type StoreTypesOrgLockDeviceStatus = {
  locked?: StatusChangeLog<boolean>;
};
export interface AlgoliaThingStatusData extends AlgoliaObject {
  thingId: string;
  battery?: StatusChangeLog<number> & {
    level?: 0 | 1 | 2 | 3;
  };
  latestStatus?: {
    status: 'alive' | 'dead';
    timestamp: number;
  };
  organizationId: string;
  updatedAt: EpochMillis;
}

const searchableAttributes = {
  id: AlgoliaThingStatusAttributes.id,
};

export default class AlgoliaThingStatus extends LegacyIndexBase<AlgoliaThingStatusData> {
  protected name = LegacyIndexName.thingStatuses;
  protected rawIndexName: string;

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;
  public static readonly facets = () => ({
    id: AlgoliaThingStatusAttributes.id,
    thingId: AlgoliaThingStatusAttributes.thingId,
    batteryLevel: AlgoliaThingStatusAttributes.batteryLevel,
    latestStatus: AlgoliaThingStatusAttributes.latestStatus,
  });

  public static getIndex = (organizationId: string) => new AlgoliaThingStatus(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaThingStatus.facets();
}
