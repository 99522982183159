import {ApiState} from './api';
import {CommonDictionaryState} from './action-reducers/common-dictionary';
import {DictionaryState} from './dictionary';
import {SettingsState} from './organization-settings';
import {UserState} from './user';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createStore} from 'redux';
import {rootReducer} from './rootReducer';

export interface Store {
  user: UserState;
  dictionary: DictionaryState;
  commonDictionary: CommonDictionaryState;
  settings: SettingsState;
  api: ApiState;
}

const store = createStore(rootReducer, composeWithDevTools());
export {store};
