import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaReaderAttributes {
  id = 'id',
  bkpTreasureBoxId = 'bkpTreasureBoxId',
  name = 'name',
  bitlockIds = 'bitlockIds',
  battery = 'battery',
  bitkeyUDID = 'bitkeyUDID',
  firmware = 'firmware',
  model = 'model',
  serialNo = 'serialNo',
  createdAt = 'createdAt',
  deviceAddress = 'deviceAddress',
  connectorId = 'connectorId',
  bitlinkId = 'bitlinkId',
}

export enum ReaderSide {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE',
}

export interface AlgoliaReader extends AlgoliaObject {
  bkpTreasureBoxId: string;
  name: string;
  bitlockIds: string[];
  battery: number;
  bitkeyUDID: string;
  firmware: string;
  model: string;
  serialNo: string;
  createdAt: EpochMillis;
  deviceAddress: string;
  connectorId: string;
  side?: ReaderSide;
  bitlinkId?: string;
}

export default class AlgoliaReaders extends AlgoliaBase<AlgoliaReader> {
  public static readonly facets = () => ({
    // search
    name: AlgoliaReaderAttributes.name,
    serialNo: AlgoliaReaderAttributes.serialNo,
    bitlinkId: AlgoliaReaderAttributes.bitlinkId,
    // relation
    id: AlgoliaReaderAttributes.id,
    deviceAddress: AlgoliaReaderAttributes.deviceAddress,
    connectorId: AlgoliaReaderAttributes.connectorId,
    model: AlgoliaReaderAttributes.model,
    firmware: AlgoliaReaderAttributes.firmware,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaReaders(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.readers;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaReaders.facets();
}
