import {Moment} from 'moment';
import {EpochMillis} from '../../../../common-types/numbers';
import {ReplaceType} from '../../../../common-utils/type-utils';
import {FirestoreDocumentData, Timestamp} from '../../firestore';
import {Address} from '../organizations/devices/firestore-organization-devices';
import {HoleType} from '../organizations/keys/firestore-keys';

// TODO アプリの型参照系は一箇所にまとめたい。常に連動させるべきなので。
// ここから
export enum BleDeviceType {
  BITLOCK_LITE = 'bitlock LITE',
  BITLOCK_LITE_E_COMPASS = 'bitlock LITE(ec)',
  BITLOCK_PRO = 'bitlock Pro',
  BITLOCK_MINI = 'bitlock MINI',
  BITLOCK_GATE = 'bitlock GATE',
  BITLOCK_GATE_FOR_ELEVATOR = 'BITLOCK_GATE_FOR_ELEVATOR',
  BITREADER = 'BITREADER',
  BITREADER_G2 = 'BITREADER_G2',
  BITREADER_PLUS_LTE = 'bit reader+ LTE',
  BITREADER_PLUS_WIFI = 'bit reader+ Wi-Fi',
  BITBUTTON_KIDS = 'bitbutton_KIDS',
  BITBUTTON_ADULT = 'BITBUTTON_ADULT',
  BITLINK_WIFI = 'BITLINK wifi',
  BITLINK_LTE = 'BITLINK LTE',
  WIFI_CONNECTOR = 'wifiConnector',
  /**
   * hardware.modelのDB的にはfuki_lockだが、
   * 正式名称は MULTILOCK Bitkey Edition なので、
   * DBが改修されるまでは fuki_lock からの変換を行う
   * そのため、FUKI_LOCK 利用禁止
   */
  FUKI_LOCK = 'fuki_lock',
  /**
   * @deprecated
   * Enum値で管理するべきではない、
   * 表示名は表示名への変換処理で管理するようにする
   */
  MULTILOCK_BITKEY_EDITION = 'MULTILOCK Bitkey Edition',

  /**
   * アルファ電子錠対応
   * hardware.modelのDB的には alpha ed lock, alpha ePPH lock だが、
   * 正式名称は ed ロック PLUS Bitkey Edition, ePPH Bitkey Edition なので、
   * DBが改修されるまでは alpha ~ からの変換を行う
   * そのため、ALPHA_XXX は 利用禁止
   */
  ALPHA_ED_LOCK = 'alpha ed lock',
  ALPHA_EPPH_LOCK = 'alpha ePPH lock',
  TEBRA_X = 'Tebra X Bitkey Edition',
  PIACK_3_PG = 'PiACK III PG Bitkey Edition',
  PIACK_3_SMART = 'PiACK III smart Bitkey Edition',

  /**
   * パナソニックの集合玄関機
   */
  Clouge = 'Clouge',
  Windea = 'Windea',
}

/**
 *  アルファ電子錠対応 Firmware version のアルファPrefix
 */
export const ALPHA_LOCK_FW_VERSION_PREFIX = 'alpha_lock_';
export const ED_LOCK_PLUS_FW_VERSION_PREFIX = 'ed_lock_PLUS_bitkey_edition_';
export const EPPH_FW_VERSION_PREFIX = 'ePPH_bitkey_edition_';

/**
 *  アルファ電子錠対応 表示用に変換する名称
 */
export const ED_LOCK_BITKEY_EDITION = 'edロックPLUS Bitkey Edition'; //hardware.model = "alpha ed lock"
export const EPPH_BITKEY_EDITION = 'ePPH Bitkey Edition'; //hardware.model = "alpha ePPH lock"
/**
 * アルファ電子錠対応ここまで
 */

/**
 * fuki_lock > MULTILOCK Bitkey Edition への置換暫定対応
 * TODO DBちゃんと直ったら下記メソッド群抹殺する
 */
export const FUKI_LOCK_PREFIX = 'fuki_lock_';
export const MULTILOCK_BITKEY_EDITION_PREFIX = 'MULTILOCK_bitkey_edition_';

// devices以下のサブコレクションが以下の構成になる
export enum HoleTypeGateForElevator {
  SETTING_HOLE = 'SETTING_HOLE',
  MASTER_HOLE = 'MASTER_HOLE',
  ROOM_HOLE = 'ROOM_HOLE', // ROOM_HOLEコレクション以下に各部屋のhole、BUTTON, ONETIME, SPAREが登録される
}

export const HoleTypeAll = [HoleType.MASTER, HoleType.SPARE, HoleType.ONETIME, HoleType.SETTING, HoleType.BUTTON];
export const RoomHoleTypeAll = [HoleType.SPARE, HoleType.ONETIME, HoleType.BUTTON];

export interface Hardware {
  model: BleDeviceType; // BleDeviceType,
  bitkeyUDID: string;
  serialNo: string;
  firmware: string;
  battery: number;
}

export interface Coordinate {
  latitude: number;
  longitude: number;
}

interface DeviceDoc {
  hardware: Hardware;
  bkpObjectId: string;
  bkpHoles: {[holeType: string]: string}; // { HoleType.MASTER: 'bkpHoleId' }

  ownerUid: string;
  name: string;
  address: Address;
  coordinate?: Coordinate;

  autolockSeconds: number;
  lastCurrentTimeSetAt: Moment;
  linkId?: string;

  createdAt: Moment;
  deleted: false; // always false in app
  deletedAt?: Moment; // always undefined in app
  connectorId: string;
  insideReaders?: LogTargetReader[];
  outsideReaders?: LogTargetReader[];
  autoLockOffCondition?: {
    weekly: number[];
    times: {
      start: EpochMillis;
      end: EpochMillis;
    };
  };
}

export interface LogTargetReader {
  name: string;
  id: string;
  deleted?: boolean;
  hide?: boolean;
}

export type FirestoreDeviceData = ReplaceType<
  DeviceDoc,
  {
    createdAt;
    deletedAt;
  },
  {
    lastCurrentTimeSetAt: Timestamp;
    isOrganization?: boolean;
  },
  FirestoreDocumentData
>;
