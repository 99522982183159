import StorageTemporary from '../../firebase-storage-temporary';
import Redux from '../../../../redux/ReduxConnector';
import FirebaseStorage from '../../firebase-storage';

const ref = (organizationId: string) => StorageTemporary.getStorage().ref('users').child(organizationId);

/**
 * storage temporaryは一時的な格納先。
 * 最終的にはAPI実行して登録するタイミングで削除され、
 * フロント露出しないstorageに格納するためのもの
 * そのため、一時的にという意味でTemporaryに格納するが、
 * 「一時的なファイル」専用の格納先ではない.
 */
export default class StorageTemporaryUsers {
  public static getRef = () => ref(Redux.getStore().user.personaId);

  public static uploadPhoto = (fileName: string, data: File) => {
    const ref = dataId => StorageTemporaryUsers.getRef().child('images').child(dataId);
    return FirebaseStorage.upload(ref(fileName), data);
  };
}
