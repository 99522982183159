import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {Client} from 'algoliasearch';
import {EpochMillis} from '../../../common-types/numbers';

export enum AlgoliaEquipmentsAttributes {
  id = 'id',
  spaceId = 'spaceId',
  name = 'name',
  code = 'code',
  buildingId = 'buildingId',
  buildingName = 'buildingName',
  buildingCode = 'buildingCode',
  storehouseSpaceId = 'storehouseSpaceId',
  storehouseSpaceName = 'storehouseSpaceName',
  storehouseSpaceCode = 'storehouseSpaceCode',
  placeDescription = 'placeDescription',
  emergencyContactPhoneNumber = 'emergencyContactPhoneNumber',
  emergencyContactEmail = 'emergencyContactEmail',
  fixedAmount = 'fixedAmount',
  fixedTaxAmount = 'fixedTaxAmount',
  hasFeeForUse = 'hasFeeForUse',
  amountUnit = 'amountUnit',
  timeUnitInMinutes = 'timeUnitInMinutes',
  variableMaximumAomunt = 'variableMaximumAomunt',
  cumulativeMinutesFrom = 'cumulativeMinutesFrom',
  cumulativeMinutesTo = 'cumulativeMinutesTo',
  organizationId = 'organizationId',
  createdPersonaId = 'createdPersonaId',
  createdAt = 'createdAt',
  updatedPersonaId = 'updatedPersonaId',
  updatedAt = 'updatedAt',
}

export enum FacetAttributes {
  spaceId = 'spaceId',
  code = 'code',
  name = 'name',
  buildingCode = 'buildingCode',
  buildingName = 'buildingName',
}

export interface AlgoliaEquipment extends AlgoliaObject {
  id: string;
  name: string;
  equipmentId?: string;
  code: string;
  description?: string;
  mainImages?: string[];
  spaceId?: string;
  buildingId: string;
  buildingName?: string;
  buildingCode?: string;
  storehouseSpaceId?: string;
  storehouseSpaceName?: string;
  storehouseSpaceCode?: string;
  placeDescription?: string;
  emergencyContactPhoneNumber?: string;
  emergencyContactEmail?: string;
  fixedAmount: number;
  fixedTaxAmount?: number;
  hasFeeForUse: boolean;
  amountUnit?: number;
  timeUnitInMinutes?: number;
  variableMaximumAomunt?: number;
  cumulativeMinutesFrom?: number;
  cumulativeMinutesTo?: number;
  organizationId: string;
  createdPersonaId: string;
  updatedPersonaId: string;
  createdAt: EpochMillis;
  updatedAt: EpochMillis;
  relatedBuildingCode?: string;
}

const searchableAttributes = {
  code: AlgoliaEquipmentsAttributes.code,
  name: AlgoliaEquipmentsAttributes.name,
  buildingCode: AlgoliaEquipmentsAttributes.buildingCode,
  buildingName: AlgoliaEquipmentsAttributes.buildingName,
};

export default class AlgoliaEquipments extends AlgoliaBase<AlgoliaEquipment> {
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    id: AlgoliaEquipmentsAttributes.id,
    spaceId: AlgoliaEquipmentsAttributes.spaceId,
    name: AlgoliaEquipmentsAttributes.name,
    code: AlgoliaEquipmentsAttributes.code,
    buildingId: AlgoliaEquipmentsAttributes.buildingId,
    buildingCode: AlgoliaEquipmentsAttributes.buildingCode,
    buildingName: AlgoliaEquipmentsAttributes.buildingName,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaEquipments(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.equipments;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaEquipments.facets();
  public readonly searchableAttributes = AlgoliaEquipments.searchableAttributes;
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaEquipment>(this.index, condition);
}
