import type {AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import {Operator} from '../../../firebase/firestore/firestore';
import {
  Participants,
  ReservationType,
  StoreOrganizationReservationData,
} from '../../../firebase/firestore/references/organizations/reservations/firestore-organizations-reservations';
import {
  ReservationClientType,
  ReservationServiceType,
} from '../../../business-types/enums/core/common/reservation-type';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';

export enum AlgoliaReservationAttributes {
  id = 'id',
  title = 'title',
  usageStart = 'usageStart',
  usageEnd = 'usageEnd',
  targetSpaceId = 'targetSpaceId',
  targetSpaceName = 'targetSpaceName',
  targetSpaceParentId = 'targetSpaceParentId',
  targetSpaceParentName = 'targetSpaceParentName',
  targetSpaceParentAddress = 'targetSpaceParentAddress',
  participants = 'participants',
  memo = 'memo',
  private = 'private',
  repeatId = 'repeatId',
  receptionId = 'receptionId',
  message = 'message',
  status = 'status',
  approvedOperator = 'approvedOperator',
  rejectedOperator = 'rejectedOperator',
  createdAt = 'createdAt',
  createOperator = 'createOperator',
  updatedAt = 'updatedAt',
  lastUpdateOperator = 'lastUpdateOperator',
  deleted = 'deleted',
  deletedAt = 'deletedAt',
  deletedOperator = 'deletedOperator',
  participantsName = 'participants.name',
  participantsEmail = 'participants.emailId',
  participantsCompanyName = 'participants.companyName',
  participantsPhoneNumber = 'participants.telephone',
  targetSpaceCode = 'targetSpaceCode',
  targetSpaceParentCode = 'targetSpaceParentCode',
  approvedMessage = 'approvedMessage',
  rejectedMessage = 'rejectedMessage',
  canceledMessage = 'canceledMessage',
  externalOrganizationId = 'externalOrganizationId',
}

export enum AlgoliaReservationStatus {
  UnApproved = 'UnApproved',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
}

export interface AlgoliaReservationData extends AlgoliaObject {
  title?: string;
  usageStart?: EpochMillis;
  usageEnd?: EpochMillis;
  reservationServiceType?: ReservationServiceType;
  reservationClientType?: ReservationClientType;
  targetSpaceId: string;
  targetSpaceName: string;
  targetSpaceCode?: string;
  targetSpaceParentId?: string;
  targetSpaceParentName?: string;
  targetSpaceParentAddress?: string;
  targetSpaceParentCode?: string;
  participants?: Participants[];
  memo?: string;
  private?: boolean;
  repeatId?: string;
  receptionId?: string;
  message?: string;
  status: AlgoliaReservationStatus;
  approvedOperator?: Operator;
  rejectedOperator?: Operator;
  createdAt?: EpochMillis;
  createOperator?: Operator;
  updatedAt?: EpochMillis;
  lastUpdateOperator?: Operator;
  deleted: boolean;
  deletedAt?: EpochMillis;
  deletedOperator?: Operator;
  reservationType?: ReservationType;
  isNotReservable?: boolean;
  approvedMessage?: string;
  rejectedMessage?: string;
  canceledMessage?: string;
  externalOrganizationId?: string;
}

export default class AlgoliaReservations extends LegacyIndexBase<AlgoliaReservationData> {
  protected name = LegacyIndexName.reservations;
  protected rawIndexName: string;

  public static readonly facets = () => ({
    id: AlgoliaReservationAttributes.id,
    spaceId: AlgoliaReservationAttributes.targetSpaceId,
    parentSpaceId: AlgoliaReservationAttributes.targetSpaceParentId,
    status: AlgoliaReservationAttributes.status,
  });

  public static getIndex = (organizationId: string) => new AlgoliaReservations(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaReservations.facets();

  public static convertFromFirestore = (
    data: StoreOrganizationReservationData,
    space: {id: string; name: string; code?: string},
    parentSpace: {id: string; name: string; address: string; code?: string} | undefined,
  ): AlgoliaReservationData => ({
    id: data.id,
    title: data.title,
    usageStart: data.condition.startTime,
    usageEnd: data.condition.endTime,
    targetSpaceId: data.targetSpaceId,
    targetSpaceName: space.name,
    targetSpaceParentId: parentSpace?.id,
    targetSpaceParentName: parentSpace?.name,
    targetSpaceParentAddress: parentSpace?.address,
    targetSpaceCode: space.code,
    targetSpaceParentCode: parentSpace?.code,
    participants: data.participants,
    memo: data.memo || undefined,
    private: data.private,
    repeatId: data.repeatId || undefined,
    receptionId: data.receptionId || undefined,
    message: data.message || undefined,
    status: data.rejected
      ? AlgoliaReservationStatus.Rejected
      : data.canceled
      ? AlgoliaReservationStatus.Canceled
      : data.approved
      ? AlgoliaReservationStatus.Approved
      : AlgoliaReservationStatus.UnApproved,
    reservationType: data.reservationType,
    isNotReservable: data.isNotReservable,
    approvedOperator: data.approvedOperator || undefined,
    rejectedOperator: data.rejectedOperator || undefined,
    createdAt: data.createdAt,
    createOperator: data.createOperator || undefined,
    updatedAt: data.updatedAt,
    lastUpdateOperator: data.lastUpdateOperator || undefined,
    deleted: data.deleted,
    deletedAt: data.deletedAt,
    deletedOperator: data.deletedOperator || undefined,
    externalOrganizationId: data.externalOrganizationId || undefined,
    approvedMessage: data.approvedMessage || undefined,
    rejectedMessage: data.rejectedMessage || undefined,
    canceledMessage: data.canceledMessage || undefined,
    reservationServiceType: data.reservationServiceType,
    reservationClientType: data.reservationClientType,
  });
}
