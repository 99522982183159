import {FirestoreDocumentData, Timestamp} from '../../../../firestore';
import {ReplaceType} from '../../../../../../common-utils/type-utils';
import {EpochMillis} from '../../../../../../common-types/numbers';

export enum SpaceStatus {
  vacant = 'vacant', // 空室(公開中)
  applied = 'applied', // 申し込み有
  occupyScheduled = 'occupyScheduled', // 入居予定
  occupied = 'occupied', // 入居中
  leaveScheduled = 'leaveScheduled', // 退去予定
  leaveProcessing = 'leaveProcessing', // 退去処理中
  closed = 'closed', // 停止中
  none = 'none',
}

export interface FirestoreOrganizationsSpaceStatusData extends FirestoreDocumentData {
  spaceId: string;
  status: SpaceStatus;
  startAt: Timestamp;
  endAt: Timestamp;
  expired: boolean; // 期限が切れた過去の履歴かどうか deletedとの棲み分けは、履歴自体のアーカイブでないかどうか
  occupantContractId?: string; // 入居中〜退去処理中まで専有していた契約のid,契約から入居者の情報を引くために持つ
}

export type OrganizationsSpaceStatusHistoryResponse = ReplaceType<
  FirestoreOrganizationsSpaceStatusData,
  {}, // eslint-disable-line
  {
    startAt: EpochMillis;
    endAt: EpochMillis;
    createdAt?: EpochMillis;
    updatedAt?: EpochMillis;
    deletedAt?: EpochMillis;
  },
  {} // eslint-disable-line
>;
