import {FirestoreDocumentData, Timestamp} from '../../../firestore';

import {DayOfWeek} from '../../../../../business-types/enums/core/common/day-of-week';
import {EpochMillis} from '../../../../../common-types/numbers';
import {FacilityUsageType} from '../../../../../enums/facilityUsageType';
import {
    PaymentMethodOfReservationFee
} from '../../../../../business-types/enums/residence/common/payment-method-of-reservation-fee';
import {ReplaceType} from '../../../../../common-utils/type-utils';
import {SpaceStatus} from './status-histories/firestore-organizations-spaces-status-histories';

export enum SpaceAttribute {
  building = 'building',
  homehubTmpSpaceGroup = 'homehubTmpSpaceGroup', // 空間グループ
  property = 'property', // 物件
  publicSpace = 'publicSpace', // 共有部
  parkingSpace = 'parkingSpace', // 駐輪場・駐車場
  facility = 'facility', // 施設
  privateSpace = 'privateSpace', // 専有部
  area = 'area',
  areaGroup = 'areaGroup',
  meetingSpace = 'meetingSpace',
  backyard = 'backyard',
  entrance = 'entrance',
  tmpEquipments = 'tmpEquipments',
}

export const FloorPlans = [
  '未選択',
  '1R',
  '1R+S',
  '1R+ロフト',
  '1K',
  '1K+BR',
  '1K+ロフト',
  '1SK',
  '1DK',
  '1SDK',
  '1LDK',
  '1SLDK',
  '2K',
  '2SK',
  '2DK',
  '2DK+ロフト',
  '2SDK',
  '2LDK',
  '2SLDK',
  '2LDK+S',
  '3K',
  '3SK',
  '3DK',
  '3SDK',
  '3LDK',
  '3LDK+S',
  '3SLDK',
  '4K',
  '4SK',
  '4DK',
  '4SDK',
  '4LDK',
  '4SLDK',
  '5K',
  '5SK',
  '5DK',
  '5SDK',
  '5LDK',
  '5SLDK',
  '6DK',
  '6LDK',
  '6K+',
];

export enum BuildingStructureType {
  UNSELECTED = 'UNSELECTED', // 未選択
  RC = 'RC', // 鉄筋コンクリート造
  H_S = 'H_S', // 重量鉄骨造
  L_S = 'L_S', // 軽量鉄骨造
  W = 'W', // 木造
  SRC = 'SRC', // 鉄骨鉄筋コンクリート造
  CFT = 'CFT', // コンクリート充填鋼管構造
  AL = 'AL', // アルミ造
  CB = 'CB', // コンクリートブロック造
}

export enum DepositKeyMoneyUnit {
  month = 'month',
  yen = 'yen',
}

export enum SendKeyStatus {
  can = 'can',
  none = 'none',
}

export const CsvToDepositKeyMoneyUnit = (val?: number): DepositKeyMoneyUnit => {
  if (val === 1) return DepositKeyMoneyUnit.month;
  if (val === 2) return DepositKeyMoneyUnit.yen;
  return DepositKeyMoneyUnit.month;
};

export const DepositKeyMoneyUnitToCsvView = (val?: DepositKeyMoneyUnit) => {
  if (val === DepositKeyMoneyUnit.month) return 1;
  if (val === DepositKeyMoneyUnit.yen) return 2;
  return 1;
};

export interface FirestoreOrganizationPropertyUpdateData extends FirestoreDocumentData {
  propertyName?: string; // 物件名
  propertyCode: string; // 物件コード
  buildingCode?: string; // 建物コード
  propertyStatus?: SpaceStatus; // 空間ステータス
  userEmail?: string; // メールアドレス
  userPhoneNumber?: string; // 電話番号
  userName?: string; // 入居者名
  userKanaName?: string; // 入居者名（ふりがな）
  canSendKey?: SendKeyStatus; // 鍵の発行有無
  contractStartDate?: EpochMillis; // 入居契約開始日
  contractEndDate?: EpochMillis; // 入居契約終了日
  moveInDate?: EpochMillis; // 入居予定日
  vacateDate?: EpochMillis; // 明け渡し(退去)日
}

export interface FirestoreOrganizationSpaceData extends FirestoreDocumentData {
  name: string;
  code?: string;
  phoneticName?: string;
  name_en?: string;
  postcode?: string; // 郵便番号（i様でも利用）
  prefecture?: string; // 都道府県コード「01」「13」など（i様でも利用、i様の場合は都道府県そのまま「東京都」など）
  city?: string; // 市区町村（i様でも利用）
  afterCity?: string; // 市区町村以降（番地など）
  address: string; // 表示用のフルアドレス（Basicはこれのみ）
  isGroup: boolean;
  photoUri: string;
  bitlockIds?: string[];
  parents: string[];
  parentId: string;
  parentNames?: string[];
  deleted: boolean;
  deletedAt?: Timestamp;
  attributes: (SpaceAttribute | string)[];
  createdAt?: Timestamp;

  status?: SpaceStatus | string; // 空間ステータス
  equipments?: string[]; // 設備情報, RESIDENCEの場合ここにリフォーム物件、バストイレ別など○×で表現できる項目を持つ
  memo?: string; //メモ
  privateReservationSiteRemarks?: string; // 備考

  // いったん横持ち、本来はspaces直下にphotosなどでサブコレクション管理が良いかも
  photoUri02?: string;
  photoUri03?: string;
  photoUri04?: string;
  photoUri05?: string;
  photoUri06?: string;
  photoUri07?: string;
  photoUri08?: string;
  photoUri09?: string;
  photoUri10?: string;

  // 入居者の契約一覧
  occupantContractIds?: string[];

  // RESIDENCE property
  charge?: number; // 賃料
  deposit?: number; // 敷金
  depositUnit?: DepositKeyMoneyUnit; // 敷金の単位
  keyMoney?: number; // 礼金
  keyMoneyUnit?: DepositKeyMoneyUnit; // 礼金の単位
  managementFee?: number; // 管理費
  brokerageFee?: number; // 仲介手数料
  ownerName?: string; // オーナー名
  ownerPhoneNumber?: string; // オーナー連絡先電話番号
  layout?: string; // 間取り
  exclusiveArea?: number; // 専有面積
  floorNumber?: number; // 部屋の階
  // RESIDENCE building
  constructDate?: Timestamp; // 築年月日
  nearestStation?: string; // 最寄駅
  minutesByWalkingFromStation?: number; // 駅徒歩(分)
  minutesByBusFromStation?: number; // 駅からバスでかかる時間(分)
  minutesByCarFromStation?: number; // 駅から車でかかる時間(分)
  structure?: BuildingStructureType; // 構造
  storyNumber?: number; // 建物の階数
  privateViewFormat?: string; // 内見フォーマット

  // Reception system
  tabletIds?: string[]; // 空間を扱うタブレットを特定するId
  receptionPersonaId?: string; // 空間にあるタブレットにログインしている人のpersonaId

  // Reservation Management
  reservableTimes?: {
    startTime?: string;
    endTime?: string;
  }[];
  // 予約関連 (本当はspace直下でなく、reservationSettingのmap内に要素保持したい)
  hasReservableUserLimitation?: boolean; // 予約を行う入居者の制限があるか否か
  permitUserReserve?: boolean; // 入居者が予約可能か否か
  reservableUserDetectKeys?: Array<{spaceId?: string}>; // 予約可能な入居者を特定するためのキー情報
  reservableDayOfWeeks?: Array<DayOfWeek>; // 予約可能な曜日
  reservableTimesEachDayOfWeek?: {
    [key in DayOfWeek]: Array<{
      startTime?: string; // HH:mm:ss
      endTime?: string; // HH:mm:ss
    }>;
  }; // 曜日ごとの予約可能時間帯
  permitDuplicatedReservation?: boolean; // 重複予約を許容するか否か
  reserveTimeUnit?: number; // 予約可能な時間単位 (min)

  reservable?: boolean;
  maxCapacity?: number; // 重複予約可能な件数
  minReservationTime?: number; // 予約可能な最小時間 (min)
  maxReservationTime?: number; // 予約可能な最大時間 (min)
  reservableMaxCapacity?: number;
  hasReservationFee?: boolean; // 予約料金が発生するか
  paymentMethodOfReservationFee?: Array<PaymentMethodOfReservationFee>; // 予約料金の支払い可能方法

  approvalAccounts?: string[];
  linkReception?: boolean;
  receptionKeyDataType?: number;
  reservableTimeZone?: string;

  usageType?: FacilityUsageType;
  numberOfVote?: number;
}

export type OrganizationSpaceRequestData = ReplaceType<
  FirestoreOrganizationSpaceData,
  {
    id;
    createdAt;
    updatedAt;
    deletedAt;
    deleted;
  },
  {
    constructDate?: EpochMillis;
  },
  {
    parentCode?: string;
  }
>;

export type OrganizationPropertyRequestData = ReplaceType<
  FirestoreOrganizationPropertyUpdateData,
  {
    id;
    createdAt;
    updatedAt;
    deletedAt;
    deleted;
  },
  {
    contractStartDate?: EpochMillis;
    contractEndDate?: EpochMillis;
    moveInDate?: EpochMillis;
    vacateDate?: EpochMillis;
  },
  {
    code?: string;
  }
>;
