import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {EpochMillis} from '../../../common-types/numbers';
import AlgoliaBase from '../../algolia-base';
import {PersonType} from '../../../firebase/firestore/references/organizations/space-types/firestore-spaceTypes';
import {RepeatCondition} from '../../../firebase/firestore/references/nfcCards/firestore-nfc-cards';
import {searchElasticSearchNfcCardIndex} from '@repository/v2/indexes/elastic/nfc-cards/elastic-search-index-search-nfc-cards';

export enum AlgoliaNfcCardAttributes {
  id = 'id',
  name = 'name',
  propertyValue = 'propertyValue',
  personaId = 'personaId',
  personaName = 'personaName',
  personaEmail = 'personaEmail',
  personType = 'personType',
  lendable = 'lendable',
  lendingPersonaId = 'lendingPersonaId',
  isLending = 'isLending',
  readerIds = 'readerIds',
  usageRegistered = 'usageRegistered',
  memberPropertyLabelId = 'memberPropertyLabelId',
  lendName = 'lendName',
  lendPhoneNumber = 'lendPhoneNumber',
  lendAt = 'lendAt',
  lendLimitPeriod = 'lendLimitPeriod',
  lendRemarks = 'lendRemarks',
  organizationId = 'organizationId',
  memberPropertyLabelName = 'memberPropertyLabelName',
  nfcConditions = 'nfcConditions',
  forAccessTo = 'forAccessTo',
  purpose = 'purpose',
}

export enum NfcCardPurpose {
  OccupantLending = 'OccupantLending',
  ManagementUse = 'ManagementUse',
}

export interface AlgoliaNfcCard extends AlgoliaObject {
  propertyValue: string;
  memberPropertyId: string;
  personaId?: string;
  personaIds?: string[];
  personaName?: string;
  personaEmail?: string;
  personType?: PersonType;
  isOrganization: boolean;
  organizationId: string;
  name: string;
  memberPropertyLabelId: string;
  memberPropertyLabelName: string;
  propertyName: string;
  multipleAssignees: boolean;
  createdAt: EpochMillis;
  updatedAt: EpochMillis;
  lendable: boolean;
  bitlockIds: string[];
  readerIds: string[];
  lendingPersonaId: string;
  isLending: boolean;
  // personaIdがnullまたはlendableがfalse（どう使うの状態のやつ）というフィルタ用。facetまたいだor検索ができないので。
  usageRegistered: boolean;
  // 貸し出し情報
  lendName?: string;
  lendPhoneNumber?: string;
  lendAt?: EpochMillis;
  lendLimitPeriod?: EpochMillis;
  lendRemarks?: string;
  nfcConditions?: {readerId: string; condition: RepeatCondition}[];
  forAccessTo?: {
    spaceIds?: string[];
    parentSpaceIds?: string[];
  };
  purpose: NfcCardPurpose;
}

const searchableAttributes = {
  name: AlgoliaNfcCardAttributes.name,
  propertyValue: AlgoliaNfcCardAttributes.propertyValue,
  personaName: AlgoliaNfcCardAttributes.personaName,
  personaEmail: AlgoliaNfcCardAttributes.personaEmail,
  lendName: AlgoliaNfcCardAttributes.lendName,
};

export default class AlgoliaNfcCards extends AlgoliaBase<AlgoliaNfcCard> {
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public search = searchElasticSearchNfcCardIndex;

  public static readonly facets = () => ({
    id: AlgoliaNfcCardAttributes.id,
    propertyValue: AlgoliaNfcCardAttributes.propertyValue,
    personaId: AlgoliaNfcCardAttributes.personaId,
    personType: AlgoliaNfcCardAttributes.personType,
    lendable: AlgoliaNfcCardAttributes.lendable,
    lendingPersonaId: AlgoliaNfcCardAttributes.lendingPersonaId,
    isLending: AlgoliaNfcCardAttributes.isLending,
    readerIds: AlgoliaNfcCardAttributes.readerIds,
    usageRegistered: AlgoliaNfcCardAttributes.usageRegistered,
    memberPropertyLabelId: AlgoliaNfcCardAttributes.memberPropertyLabelId,
    organizationId: AlgoliaNfcCardAttributes.organizationId,
    memberPropertyLabelName: AlgoliaNfcCardAttributes.memberPropertyLabelName,
    lendLimitPeriod: AlgoliaNfcCardAttributes.lendLimitPeriod,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaNfcCards(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.nfcCards;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public searchableAttributes = AlgoliaNfcCards.searchableAttributes;
  public facets = () => AlgoliaNfcCards.facets();
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaNfcCard>(this.index, condition);
}
