import BitkeyPlatformSDK from '@bitkey-service/bkp-sdk';
import Firebase from './firebase/firebase';

/**
 * 環境情報を管理するファイル
 * サーバー側とは情報が異なるためserviceのenvを持ってくるのは禁止
 * 各クライアント間では共有してOK
 */
export enum EnvDef {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  LOCAL_STAGING = 'local_staging',
  PRODUCTION = 'production',
  LOCAL_PRODUCTION = 'local_production',
  MIRROR = 'mirror', // BKPの向き先はedgeの本番と同等ver
  LOCAL_MIRROR = 'local_mirror', // BKPの向き先はedgeの本番と同等ver
  DEV_HUB = 'dev_hub',
}

enum BkpUrl {
  production = 'https://bitlock.bitkey.cloud/v1',
  staging = 'https://staging.bitkey.tokyo/v1',
  edge = 'https://edge.bitkey.tokyo/v1',
  demo = 'https://demo.bitkey.tokyo/v1',
}

export enum FirebaseDef {
  dev = 'dev',
  local = 'local',
  ca838 = 'ca838',
  staging = 'staging',
  local_staging = 'local_staging',
  production = 'production',
  local_production = 'local_production',
  mirror = 'mirror',
  local_mirror = 'local_mirror',
  dev_hub = 'dev_hub',
}

// serve、deployの場合は不要な情報だが、これがないとnpm startが使えなくて効率が非常に落ちるため定義。（あとで）
// ここもdemoとかtrialは必要になったら。
type FirebaseEnv = {
  serviceAccount: any;
  storageTemporary: string;
};
const firebase: {
  [K in FirebaseDef]: FirebaseEnv;
} = {
  ca838: {
    storageTemporary: 'bitlock-ca838-temporary', // Not defined.
    serviceAccount: {
      apiKey: 'AIzaSyCABdQ75LlrPibSbQCwUqyYKo2vpZ7LDFU',
      databaseURL: 'https://bitlock-ca838.firebaseio.com',
      storageBucket: 'bitlock-ca838.appspot.com',
      authDomain: 'bitlock-ca838.firebaseapp.com',
      messagingSenderId: '407548990441',
      projectId: 'bitlock-ca838',
    },
  },
  dev: {
    storageTemporary: 'bitlock-dev-temporary',
    serviceAccount: {
      apiKey: 'AIzaSyDOeJReFrdBFhQ4Jszry-fFDk-yoeFa_oM',
      databaseURL: 'https://bitlock-dev.firebaseio.com',
      storageBucket: 'bitlock-dev.appspot.com',
      authDomain: 'bitlock-dev.firebaseapp.com',
      messagingSenderId: '720301866479',
      projectId: 'bitlock-dev',
    },
  },
  local: {
    storageTemporary: 'bitlock-dev-temporary',
    serviceAccount: {
      apiKey: 'AIzaSyDOeJReFrdBFhQ4Jszry-fFDk-yoeFa_oM',
      databaseURL: 'https://bitlock-dev.firebaseio.com',
      storageBucket: 'bitlock-dev.appspot.com',
      authDomain: 'bitlock-dev.firebaseapp.com',
      messagingSenderId: '720301866479',
      projectId: 'bitlock-dev',
    },
  },
  staging: {
    storageTemporary: 'bitlock-staging-temporary', // Not defined.
    serviceAccount: {
      apiKey: 'AIzaSyDoEc5jEsJDXoS3R9dI61fOorgEuTpd2YY',
      databaseURL: 'https://bitlock-staging.firebaseio.com',
      storageBucket: 'bitlock-staging.appspot.com',
      authDomain: 'bitlock-staging.firebaseapp.com',
      messagingSenderId: '327190950488',
      projectId: 'bitlock-staging',
    },
  },
  local_staging: {
    storageTemporary: 'bitlock-staging-temporary', // Not defined.
    serviceAccount: {
      apiKey: 'AIzaSyDoEc5jEsJDXoS3R9dI61fOorgEuTpd2YY',
      databaseURL: 'https://bitlock-staging.firebaseio.com',
      storageBucket: 'bitlock-staging.appspot.com',
      authDomain: 'bitlock-staging.firebaseapp.com',
      messagingSenderId: '327190950488',
      projectId: 'bitlock-staging',
    },
  },
  production: {
    storageTemporary: 'bitlock-prd-temporary',
    serviceAccount: {
      apiKey: 'AIzaSyAVNbsIHE3zOeVHrDI5Qgky00C6HqXGOso',
      databaseURL: 'https://bitlock-production.firebaseio.com',
      storageBucket: 'bitlock-production.appspot.com',
      authDomain: 'bitlock-production.firebaseapp.com',
      messagingSenderId: '161743225194',
      projectId: 'bitlock-production',
    },
  },
  local_production: {
    storageTemporary: 'bitlock-prd-temporary',
    serviceAccount: {
      apiKey: 'AIzaSyAVNbsIHE3zOeVHrDI5Qgky00C6HqXGOso',
      databaseURL: 'https://bitlock-production.firebaseio.com',
      storageBucket: 'bitlock-production.appspot.com',
      authDomain: 'bitlock-production.firebaseapp.com',
      messagingSenderId: '161743225194',
      projectId: 'bitlock-production',
    },
  },
  mirror: {
    storageTemporary: 'bitlock-mirror-temporary',
    serviceAccount: {
      apiKey: 'AIzaSyDPR_EYkddWT_wchCFks1SOzJVrYD-zr78',
      databaseURL: 'https://bitlock-prd-mirror.firebaseio.com',
      storageBucket: 'bitlock-prd-mirror.appspot.com',
      authDomain: 'bitlock-prd-mirror.firebaseapp.com',
      messagingSenderId: '529551368901',
      projectId: 'bitlock-prd-mirror',
    },
  },
  local_mirror: {
    storageTemporary: 'bitlock-mirror-temporary',
    serviceAccount: {
      apiKey: 'AIzaSyDPR_EYkddWT_wchCFks1SOzJVrYD-zr78',
      databaseURL: 'https://bitlock-prd-mirror.firebaseio.com',
      storageBucket: 'bitlock-prd-mirror.appspot.com',
      authDomain: 'bitlock-prd-mirror.firebaseapp.com',
      messagingSenderId: '529551368901',
      projectId: 'bitlock-prd-mirror',
    },
  },
  dev_hub: {
    storageTemporary: 'bitlock-dev-temporary',
    serviceAccount: {
      apiKey: 'AIzaSyDOeJReFrdBFhQ4Jszry-fFDk-yoeFa_oM',
      databaseURL: 'https://bitlock-dev.firebaseio.com',
      storageBucket: 'bitlock-dev.appspot.com',
      authDomain: 'bitlock-dev.firebaseapp.com',
      messagingSenderId: '720301866479',
      projectId: 'bitlock-dev',
    },
  },
};

// サービスイン画面のURL、BKPから返却される値（メンバー招待時）はここでは管理できないのでしない
enum ServiceAuthEnvName {
  develop = 'develop',
  staging = 'staging',
  production = 'production',
}

type ServiceAuthEnvData = {url: string};
const ServiceAuth: {[K in ServiceAuthEnvName]: ServiceAuthEnvData} = {
  develop: {
    url: 'https://bitlock-auth-dev.web.app/',
  },
  staging: {
    url: 'https://bitlock-auth-stg.web.app/',
  },
  production: {
    url: 'https://bitlock-service-auth.web.app/',
  },
};

// サービスイン画面のURL、BKPから返却される値（メンバー招待時）はここでは管理できないのでしない
enum TeleconferenceEnvName {
  develop = 'develop',
  staging = 'staging',
  production = 'production',
}

type TeleconferenceEnvData = {url: string};
const Teleconference: {
  [K in TeleconferenceEnvName]: TeleconferenceEnvData;
} = {
  develop: {
    url: 'https://conference.develop-homehub.site/',
  },
  staging: {
    url: 'https://conference.staging-homehub.site/',
  },
  production: {
    url: 'https://conference.homehub.site/',
  },
};

// Customer Web画面のURL
enum CustomerEnvName {
  local = 'local',
  develop = 'develop',
  staging = 'staging',
  production = 'production',
}

type CustomerEnvData = {url: string; origin: string};
const Customer: {
  [K in CustomerEnvName]: CustomerEnvData;
} = {
  local: {
    url: 'http://localhost:3001/',
    origin: 'http://localhost:3001',
  },
  develop: {
    url: 'https://account.develop-homehub.site/',
    origin: 'https://account.develop-homehub.site',
  },
  staging: {
    url: 'https://account.staging-homehub.site/',
    origin: 'https://account.staging-homehub.site',
  },
  production: {
    url: 'https://account.homehub.site/',
    origin: 'https://account.homehub.site',
  },
};

// Homehub Business画面のURL
enum BusinessEnvName {
  local = 'local',
  develop = 'develop',
  staging = 'staging',
  production = 'production',
}

type BusinessEnvData = {url: string; origin: string};
const Business: {
  [K in BusinessEnvName]: BusinessEnvData;
} = {
  local: {
    url: 'http://localhost:3000/',
    origin: 'http://localhost:3000',
  },
  develop: {
    url: 'https://admin.develop-homehub.site/',
    origin: 'https://admin.develop-homehub.site',
  },
  staging: {
    url: 'https://admin.staging-homehub.site/',
    origin: 'https://admin.staging-homehub.site',
  },
  production: {
    url: 'https://admin.homehub.site/',
    origin: 'https://admin.homehub.site',
  },
};

enum DomainEnvName {
  local = 'local',
  develop = 'develop',
  staging = 'staging',
  production = 'production',
}

type DomainEnvData = {url: string};
const Domain: {[K in DomainEnvName]: DomainEnvData} = {
  local: {
    url: 'http://localhost:3000/',
  },
  develop: {
    url: 'https://bitlock-dev.web.app/',
  },
  staging: {
    url: 'https://bitlock-staging.web.app/',
  },
  production: {
    url: 'https://bitlock-production.web.app/',
  },
};

interface EnvData {
  bkpUrl: string;
  firebase: FirebaseEnv;
  domain: DomainEnvData;
  serviceAuth: ServiceAuthEnvData;
  teleconference: TeleconferenceEnvData;
  customer: CustomerEnvData;
  business: BusinessEnvData;
  useScreenSearchBar?: boolean;
}

const envSelection: {[k in EnvDef]: EnvData} = {
  [EnvDef.LOCAL]: {
    bkpUrl: BkpUrl.edge,
    firebase: firebase.local,
    domain: Domain.local,
    serviceAuth: ServiceAuth.develop,
    teleconference: Teleconference.develop,
    customer: Customer.local,
    business: Business.local,
    useScreenSearchBar: true,
  },
  [EnvDef.DEVELOPMENT]: {
    bkpUrl: BkpUrl.edge,
    firebase: firebase.dev,
    domain: Domain.develop,
    serviceAuth: ServiceAuth.develop,
    teleconference: Teleconference.develop,
    customer: Customer.develop,
    business: Business.develop,
    useScreenSearchBar: true,
  },
  [EnvDef.PRODUCTION]: {
    bkpUrl: BkpUrl.production,
    firebase: firebase.production,
    domain: Domain.production,
    serviceAuth: ServiceAuth.production,
    teleconference: Teleconference.production,
    customer: Customer.production,
    business: Business.production,
  },
  [EnvDef.STAGING]: {
    bkpUrl: BkpUrl.edge,
    firebase: firebase.staging,
    domain: Domain.staging,
    serviceAuth: ServiceAuth.staging,
    teleconference: Teleconference.staging,
    customer: Customer.staging,
    business: Business.staging,
    useScreenSearchBar: true,
  },
  [EnvDef.LOCAL_STAGING]: {
    bkpUrl: BkpUrl.edge,
    firebase: firebase.local_staging,
    domain: Domain.local,
    serviceAuth: ServiceAuth.staging,
    teleconference: Teleconference.staging,
    customer: Customer.staging,
    business: Business.staging,
  },
  [EnvDef.LOCAL_PRODUCTION]: {
    bkpUrl: BkpUrl.production,
    firebase: firebase.local_production,
    domain: Domain.local,
    serviceAuth: ServiceAuth.production,
    teleconference: Teleconference.production,
    customer: Customer.production,
    business: Business.production,
  },
  [EnvDef.MIRROR]: {
    bkpUrl: BkpUrl.edge,
    firebase: firebase.mirror,
    domain: Domain.production,
    serviceAuth: ServiceAuth.production,
    teleconference: Teleconference.production,
    customer: Customer.production,
    business: Business.production,
  },
  [EnvDef.LOCAL_MIRROR]: {
    bkpUrl: BkpUrl.edge,
    firebase: firebase.local_mirror,
    domain: Domain.local,
    serviceAuth: ServiceAuth.production,
    teleconference: Teleconference.production,
    customer: Customer.production,
    business: Business.production,
  },
  [EnvDef.DEV_HUB]: {
    bkpUrl: BkpUrl.edge,
    firebase: firebase.dev_hub,
    domain: Domain.develop,
    serviceAuth: ServiceAuth.develop,
    teleconference: Teleconference.develop,
    customer: Customer.develop,
    business: Business.develop,
  },
};

export default class Env {
  private static env: EnvDef;
  private static currentEnv: EnvData;
  public static getCurrentEnv = () => Env.currentEnv;

  public static init = () => {
    const envDef = (process.env.REACT_APP_ENV as EnvDef) || EnvDef.DEVELOPMENT;
    const envData = envSelection[envDef];
    BitkeyPlatformSDK.init((process.env.REACT_APP_BKP_HOST as string) + '/v1/');
    // クライアントサイドのfirebaseはenvによって分岐が入るのでinitの中で分岐する
    Firebase.init(envDef, envData.firebase);
    Env.env = envDef;
    Env.currentEnv = envData;
  };

  public static getEnv = () => {
    const envData = envSelection[Env.env];
    return envData;
  };

  public static getCurrentEnvDef = () => Env.env;
}
