import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../../algolia';
import {
    FirestoreOrganizationSiteAnnounceStatus,
    FirestoreOrganizationSiteAnnounceType,
} from '../../../../firebase/firestore/references/organizations/site/announce/firestore-organization-site-announce';
import AlgoliaBase from '../../../algolia-base';

export enum AlgoliaSiteAnnounceAttributes {
  id = 'id',
  type = 'type',
  title = 'title',
  body = 'body',
  publisherUid = 'publisherUid',
  publishStartAt = 'publishStartAt',
  publishEndAt = 'publishEndAt',
  status = 'status',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export interface AlgoliaSiteAnnounceData extends AlgoliaObject {
  type: FirestoreOrganizationSiteAnnounceType;
  title: string;
  body: string;
  publisherUid: string;
  publishStartAt: number;
  publishEndAt: number;
  status: FirestoreOrganizationSiteAnnounceStatus;
  createdAt: number;
  updatedAt: number;
}

export default class AlgoliaSiteAnnounce extends AlgoliaBase<AlgoliaSiteAnnounceData> {
  public static readonly facets = () => ({
    id: AlgoliaSiteAnnounceAttributes.id,
    type: AlgoliaSiteAnnounceAttributes.type,
    status: AlgoliaSiteAnnounceAttributes.status,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaSiteAnnounce(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.siteAnnounce;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaSiteAnnounce.facets();
}
