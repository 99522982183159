import firebase from 'firebase/compat/app';
import {EnvDef} from '../env';
import FirebaseAdminFunctions from './functions/firebase-admin-functions';
import {CloudrunFunctions} from './functions/cloudrun-functions';
import {V2FirebaseHomehubOldApiFunctions} from './functions/v2-firebase-homehub-old-api-functions';
import {V2FirebaseHomehubBackendFunctions} from './functions/v2-firebase-homehub-backend-functions';
import {V2HomehubElasticSearchIndex} from './functions/v2-firebase-homehub-elastic-search-index';

export default class Firebase {
  public static init = (
    env: EnvDef,
    envData: {
      serviceAccount: any;
    },
  ) => {
    // v2 NOTE: FirebaseFunctions は V2FirebaseHomehubOldApiFunctionsに置き換え
    // FirebaseFunctions.init(envData.apiHost);
    // FirebaseHomehubBackendFunctions.init(envData.hubApiHost);

    const v1OldApiHost = process.env.REACT_APP_V1_BITLOCK_API_DOMAIN;
    const v1HubApiHost = process.env.REACT_APP_V1_HOMEHUB_BACKEND_API_DOMAIN;
    const cloudRunHost = process.env.REACT_APP_V1_HOMEHUB_CLOUD_RUN_DOMAIN;

    V2FirebaseHomehubOldApiFunctions.init(v1OldApiHost || '');
    V2FirebaseHomehubBackendFunctions.init(v1HubApiHost || '');
    V2HomehubElasticSearchIndex.init(v1HubApiHost || '');
    FirebaseAdminFunctions.init(v1OldApiHost || '');
    CloudrunFunctions.init(cloudRunHost || '');
    const app = firebase.initializeApp(envData.serviceAccount);
    if (env === EnvDef.LOCAL || env === EnvDef.LOCAL_PRODUCTION || env === EnvDef.LOCAL_STAGING) {
      app.functions().useFunctionsEmulator('http://localhost:5000');
    }
  };

  /**
   * APIの向き先をv2に変更する処理
   */
  public static changeApiToV2 = () => {
    V2FirebaseHomehubOldApiFunctions.changeToV2();
    V2FirebaseHomehubBackendFunctions.changeToV2();
  };
}
