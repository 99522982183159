import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {PersonType} from '../../../firebase/firestore/references/organizations/space-types/firestore-spaceTypes';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaSpaceTypeAttributes {
  id = 'id',
  name = 'name',
  defaultTicketAccessibility = 'defaultTicketAccessibility',
  defaultKeyAccessibility = 'defaultKeyAccessibility',
  maximumContracts = 'maximumContracts',
}

export interface AlgoliaSpaceTypeData extends AlgoliaObject {
  name: string;
  defaultTicketAccessibility: PersonType[];
  defaultKeyAccessibility: PersonType[];
  maximumContracts: number;
}

export default class AlgoliaSpaceTypes extends AlgoliaBase<AlgoliaSpaceTypeData> {
  public static readonly facets = () => ({
    // for facet
    defaultTicketAccessibility: AlgoliaSpaceTypeAttributes.defaultTicketAccessibility,
    defaultKeyAccessibility: AlgoliaSpaceTypeAttributes.defaultKeyAccessibility,
    maximumContracts: AlgoliaSpaceTypeAttributes.maximumContracts,
    // for filter
    id: AlgoliaSpaceTypeAttributes.id,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaSpaceTypes(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.spaceTypes;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaSpaceTypes.facets();
}
