import type {AlgoliaSearchCondition} from '@algolia/v1/algolia';
import {AlgoliaNfcCard} from '@algolia/v1/references/nfc-cards/algolia-nfc-cards';
import {AlgoliaOrganizationNfcCard} from '@algolia/v1/references/organization-nfc-cards/algolia-organizationNfcCards';
import {V2HomehubElasticSearchIndex} from '@firebase/v1/functions/v2-firebase-homehub-elastic-search-index';
import {Response} from 'algoliasearch';
import {toAlgoliaResponseFromElasticSearch} from '../utils/to-algolia-response-from-elastic-search-converter';
import {toElasticSearchRequestFromAlgolia} from '../utils/to-elastic-search-request-from-algolia-converter';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type {searchSimplyElasticSearchLegacyIndex} from '../simple-template/elastic-search-index-search-simple-template';

/**
 * @deprecated
 * {@link searchSimplyElasticSearchLegacyIndex}を利用してください
 */
export const searchElasticSearchNfcCardIndex = async (
  condition: AlgoliaSearchCondition,
): Promise<Response<AlgoliaOrganizationNfcCard & AlgoliaNfcCard>> => {
  const res = await V2HomehubElasticSearchIndex.post(
    `homehubCoreBusinessIndex/${V2HomehubElasticSearchIndex.getVersion()}/legacies/organizations/nfc-cards/search-simply`,
    toElasticSearchRequestFromAlgolia(condition),
  );
  return toAlgoliaResponseFromElasticSearch(res.data, condition);
};
