import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';
import {
    OrganizationAddOnSettingsResponse
} from '../../firebase/firestore/references/organizations/add-ons/firestore-organization-add-ons';

/**
 * add-on開発用のサービスクラス
 */
export default class AddOnsService {
  public static loadAddOnSettings = async (): Promise<OrganizationAddOnSettingsResponse> => {
    return await V2FirebaseHomehubOldApiFunctions.call('org-addOns-loadAddOnSettings').catch(e => {
      console.log('failed to load add on settings', e);
      throw e;
    });
  };
}
