import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {AlgoliaGuestAttributes, AlgoliaGuestData} from './algolia-guests';
import {EpochMillis} from '../../../common-types/numbers';

export interface AlgoliaGuestDeletedData extends AlgoliaObject, AlgoliaGuestData {
  deletedAt: EpochMillis;
}

export default class AlgoliaGuestsDeleted extends AlgoliaBase<AlgoliaGuestDeletedData> {
  public static readonly facets = () => ({
    // for facet
    personType: AlgoliaGuestAttributes.personType,
    guestType: AlgoliaGuestAttributes.guestType,
    status: AlgoliaGuestAttributes.status,
    // for filter
    id: AlgoliaGuestAttributes.id,
    email: AlgoliaGuestAttributes.email,
    externalId: AlgoliaGuestAttributes.externalId,
    customerIds: AlgoliaGuestAttributes.customerIds,
    spaceIds: AlgoliaGuestAttributes.spaceIds,
    spaceId: AlgoliaGuestAttributes.spaceId,
    deviceId: AlgoliaGuestAttributes.deviceId,
    inChargeOfSpaceIds: AlgoliaGuestAttributes.inChargeOfSpaceIds,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaGuestsDeleted(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.guestsDeleted;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaGuestDeletedData>(this.index, condition);
  public facets = () => AlgoliaGuestsDeleted.facets();
}
