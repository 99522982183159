// どのサービスにおける予約データであるかを扱うもの
export enum ReservationServiceType {
  PrivateView = 'PrivateView',
  Facility = 'Facility',
  Equipment = 'Equipment'
}

// どのClient画面からの予約データであるかを扱うもの
export enum ReservationClientType {
  bitlockManager = 'bitlockManager',
  homehubBusiness = 'homehubBusiness',
  privateViewReservationSite = 'privateViewReservationSite',
  homehubConsumerWeb = 'homehubConsumerWeb',
  homehubConsumerApp = 'homehubConsumerApp',
}
