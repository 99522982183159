import {Theme} from '@material-ui/core';
import {createUseStyles} from '../../styles/styles';
import {HHColor} from '../../styles/hhColor';

export const styles = createUseStyles((theme: Theme) => ({
  success: {
    backgroundColor: theme.palette.primary.dark,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  warning: {
    backgroundColor: HHColor.Surface.warning,
  },
}));
