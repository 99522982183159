import {EpochMillis} from '@bitkey-service/functions-common-types/lib/common/commonTypes';

export class DateUtils {
  private static isSameYear = (date1: Date, date2: Date): boolean => {
    return date1.getFullYear() === date2.getFullYear();
  };

  private static isSameMonth = (date1: Date, date2: Date): boolean => {
    return DateUtils.isSameYear(date1, date2) && date1.getMonth() === date2.getMonth();
  };

  public static isSameDay = (date1: EpochMillis, date2: EpochMillis): boolean => {
    const {d1, d2} = {d1: new Date(date1), d2: new Date(date2)};

    return DateUtils.isSameMonth(d1, d2) && d1.getDate() === d2.getDate();
  };

  /**
   * 時間単位に当たる時刻で直近の時刻を返す。
   */
  public static recentDateByUnit(now: Date, unitInMinutes: number) {
    const div = unitInMinutes < 30 ? unitInMinutes : unitInMinutes < 60 ? 30 : 60;
    const minute = Math.floor(now.getMinutes() / div) * div;

    const ret = new Date(now);
    ret.setMinutes(minute);
    ret.setSeconds(0);
    ret.setMilliseconds(0);

    return ret;
  }
}
