import {EpochMillis} from '../../../common-types/numbers';
import {PublishStatus} from '../../../firebase/functions/api-types/organizations/faqs/api-types-organizations-faqs';
import {ContentOpenType} from '../../../enums/contentOpenType';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';
import {AlgoliaObject, BrowseCondition, InitialBrowseData} from '@algolia/v1/legacy-index-type';
import {searchElasticSearchFaqsIndex} from '@next/repository/indexes/elastic/faqs/elastic-search-index-search-faqs';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';

export enum AlgoliaFaqAttributes {
  id = 'id',
  title = 'title',
  categoryId = 'categoryId',
  contentImages = 'contentImages',
  attachments = 'attachments',
  openType = 'openType',
  openTargetDetectIds = 'openTargetDetectIds',
  status = 'status',
  publishScheduledAt = 'publishScheduledAt',
  closedScheduledAt = 'closedScheduledAt',
  contactId = 'contactId',
  orderByCategory = 'orderByCategory',
  createdPersonaId = 'createdPersonaId',
  createdUserName = 'createdUserName',
  createdAt = 'createdAt',
  updatedPersonaId = 'updatedPersonaId',
  updatedUserName = 'updatedUserName',
  updatedAt = 'updatedAt',
  createdUserAuthorityIds = 'createdUserAuthorityIds',
  contentsAuthoritySettings = 'contentsAuthoritySettings',
  defaultHtmlContent = 'defaultHtmlContent',
  htmlContentsPerTypes = 'htmlContentsPerTypes',
  // 500程度保存するとAlgoliaの1Documentあたりの上限に引っかかるため利用しない、
  openTargetBuildingIds = 'openTargetBuildingIds',
  tagIds = 'tagIds',
}

export enum FacetAttributes {
  categoryId = 'categoryId',
  status = 'status',
  publishScheduledAt = 'publishScheduledAt',
  createdUserName = 'createdUserName',
}

export interface AlgoliaFaqData extends AlgoliaObject {
  title: string;
  categoryId: string;
  contactId: string;
  createdPersonaId: string;
  attachments: string[];
  publishScheduledAt: EpochMillis;
  closedScheduledAt: EpochMillis;
  status: PublishStatus;
  openType?: ContentOpenType;
  createAt: number;
  openTargetBuildingIds: string[];
  // 500程度保存するとAlgoliaの1Documentあたりの上限に引っかかるため利用しない、
  // openTargetDetectIds: string[];
  tagIds: string[];
}

const searchableAttributes = {
  title: AlgoliaFaqAttributes.title,
};

export default class AlgoliaFaqs extends LegacyIndexBase<AlgoliaFaqData> {
  protected name = LegacyIndexName.faqs;
  protected rawIndexName: string;
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    id: AlgoliaFaqAttributes.id,
    title: AlgoliaFaqAttributes.title,
    categoryId: AlgoliaFaqAttributes.categoryId,
    createdPersonaId: AlgoliaFaqAttributes.createdPersonaId,
    status: AlgoliaFaqAttributes.status,
    openType: AlgoliaFaqAttributes.openType,
    // 500程度保存するとAlgoliaの1Documentあたりの上限に引っかかるため利用しない、
    // openTargetBuildingIds: AlgoliaFaqAttributes.openTargetBuildingIds,
    contactId: AlgoliaFaqAttributes.contactId,
  });

  public static getIndex = (organizationId: string) => new AlgoliaFaqs(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  public facets = () => AlgoliaFaqs.facets();

  public readonly searchableAttributes = AlgoliaFaqs.searchableAttributes;
}
