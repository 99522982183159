import {FirestoreDocumentData, Timestamp} from '../../../firestore';
import {Moment} from 'moment';

export enum DeviceLogActionSource {
  UNKNOWN = 'UNKNOWN',
  BITLOCK_APP_IOS = 'BITLOCK_APP_IOS',
  BITLOCK_APP_ANDROID = 'BITLOCK_APP_ANDROID',
  BITREADER = 'BITREADER',
  BITREADER_G2 = 'BITREADER_G2',
  BITBUTTON_ADULT = 'BITBUTTON_ADULT',
  BITBUTTON_KIDS = 'BITBUTTON_KIDS',
  CONNECTOR = 'CONNECTOR',
  BITLOCK_APP_TEBURA_IOS = 'BITLOCK_APP_TEBURA_IOS',
  BITLOCK_APP_TEBURA_ANDROID = 'BITLOCK_APP_TEBURA_ANDROID',
  APPLE_WATCH = 'APPLE_WATCH',
}

export enum DeviceLogActionType {
  UNKNOWN = 'UNKNOWN',
  AUTO_LOCK = 'AUTO_LOCK',
  UNLOCK_BY_DIGITAL_KEY = 'UNLOCK_BY_DIGITAL_KEY',
  LOCK_BY_DIGITAL_KEY = 'LOCK_BY_DIGITAL_KEY',
  UNLOCK_BY_HAND = 'UNLOCK_BY_HAND',
  LOCK_BY_HAND = 'LOCK_BY_HAND',
  SET_CURRENT_TIME = 'SET_CURRENT_TIME',
  SET_KEY_HOLE_DATA = 'SET_KEY_HOLE_DATA',
  REGISTER_THUMB_TURN_LOCK_POSITION = 'REGISTER_THUMB_TURN_LOCK_POSITION',
  REGISTER_THUMB_TURN_UNLOCK_POSITION = 'REGISTER_THUMB_TURN_UNLOCK_POSITION',
  SET_AUTO_LOCK = 'SET_AUTO_LOCK',
  ADD_BATTERY_LOG = 'ADD_BATTERY_LOG',
  ENTER_DFU_MODE = 'ENTER_DFU_MODE',
  ACTIVATE_DEVICE = 'ACTIVATE_DEVICE',
  FACTORY_RESET = 'FACTORY_RESET',
  UNLOCK_BY_NFC = 'UNLOCK_BY_NFC',
  TOUCH_NFC = 'TOUCH_NFC',
  LOCK_BY_NFC = 'LOCK_BY_NFC',
  READ_NFC_CARD = 'READ_NFC_CARD',
  ADD_TREASURE_DATA = 'ADD_TREASURE_DATA',
  REMOVE_TREASURE_DATA = 'REMOVE_TREASURE_DATA',
}

interface DeviceLogDoc {
  deviceId: string;
  holeIndex?: number;
  bkpHoleId?: string;
  setUseAutolockTo?: boolean;
  actionType: DeviceLogActionType;
  logIndex: number;
  recordedAt: Moment;
  readedAt: Moment;
  toggleLockRecordedAt?: Moment; // 解錠施錠のみ表示する画面からのクエリ用

  actionSource: DeviceLogActionSource;
  uid?: string;
  buttonId?: string; // いずれこんな感じでActionSourceに応じて増やす
  spaceId: string;
}

interface AppLogDevice extends DeviceLogDoc {
  recordedAt: any;
  readedAt: any;
  toggleLockRecordedAt?: any;
}

export interface FirestoreDeviceLogData extends FirestoreDocumentData, AppLogDevice {
  recordedAt: Timestamp;
  readedAt: Timestamp;
  toggleLockRecordedAt?: Timestamp; // 解錠施錠のみ表示する画面からのクエリ用
  // device直下だけだと、triggerのたびにdeviceを取得しないといけないので。
  deviceOrganizationId?: string;
}
