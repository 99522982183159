import {AlgoliaSpace} from '../../algolia/references/spaces/algolia-spaces';
import {SpaceAttribute} from '../../firebase/firestore/references/organizations/spaces/firestore-spaces';
import Algolia from '../../algolia/algolia';

export default class SpecifySpacesService {
  /*
   * inChargeOfSpaceIdsを元に対象のspace情報を取得し返却
   */
  public static loadInChargeOfSpaces = async (spaceIds: string[]): Promise<AlgoliaSpace[]> => {
    const spaceIndex = Algolia.indices().spaces();
    return spaceIndex
      .getAllHitsByBrowseResponse({
        searchWord: '',
        filters: {
          [spaceIndex.facets().id]: spaceIds,
        },
        facets: {},
        offset: 0,
      })
      .then(res => (res && res[0] ? res : []))
      .catch(e => {
        console.log('failed to load in charge of spaces', e);
        return [];
      });
  };

  /*
   * inChargeOfSpaceIdsを元に全ての支店グループ情報を返却
   * 支店グループ配下の支店グループもすべて返却
   * 支店グループが存在しない場合は空配列を返却
   */
  public static loadAllAreaGroupsByInChargeOfSpaceIds = async (spaceIds: string[]): Promise<AlgoliaSpace[]> => {
    const spaceIndex = Algolia.indices().spaces();
    const allAreaGroups = await spaceIndex
      .search({
        filters: {
          [spaceIndex.facets().id]: spaceIds,
          [spaceIndex.facets().attributes]: [SpaceAttribute.areaGroup],
        },
      })
      .then(space => space.hits);

    if (!allAreaGroups.length) {
      return [];
    } else {
      const allAreaGroupFromParentIds = await spaceIndex
        .search({
          filters: {
            [spaceIndex.facets().parents]: spaceIds,
            [spaceIndex.facets().attributes]: [SpaceAttribute.areaGroup],
          },
        })
        .then(areaGroup => {
          return areaGroup.hits;
        });
      allAreaGroups.push(...allAreaGroupFromParentIds);
      const deduplicatedAreaGroups = Array.from(new Set(allAreaGroups));

      return deduplicatedAreaGroups;
    }
  };

  /*
   * inChargeOfSpaceIdsを元に親の支店グループ情報を返却
   * 親にさらに支店グループもすべて返却
   * 支店グループが存在しない場合は空配列を返却
   */
  public static loadAllParentAreaGroupsByInChargeOfSpaceIds = async (spaceIds: string[]): Promise<AlgoliaSpace[]> => {
    const spaceIndex = Algolia.indices().spaces();
    const allAreaAndAreaGroups = await spaceIndex
      .search({
        filters: {
          [spaceIndex.facets().id]: spaceIds,
          [spaceIndex.facets().attributes]: [SpaceAttribute.area, SpaceAttribute.areaGroup],
        },
      })
      .then(space => space.hits);

    if (!allAreaAndAreaGroups.length) {
      return [];
    } else {
      const allParentAreaGroup = await spaceIndex
        .search({
          filters: {
            [spaceIndex.facets().id]: allAreaAndAreaGroups.map(space => space.parents).flat(),
            [spaceIndex.facets().attributes]: [SpaceAttribute.areaGroup],
          },
        })
        .then(areaGroup => {
          return areaGroup.hits;
        });

      return allParentAreaGroup;
    }
  };

  /*
   * inChargeOfSpaceIdsを元に全ての支店情報を返却
   * 支店グループが含まれている場合は配下の支店すべてを返却
   * 支店が存在しない場合は空配列を返却
   */
  public static loadAllAreasByInChargeOfSpaceIds = async (spaceIds: string[]): Promise<AlgoliaSpace[]> => {
    const spaceIndex = Algolia.indices().spaces();
    const allAreaGroups = await spaceIndex
      .search({
        filters: {
          [spaceIndex.facets().id]: spaceIds,
          [spaceIndex.facets().attributes]: [SpaceAttribute.areaGroup],
        },
      })
      .then(space => space.hits);
    const allAreas = await spaceIndex
      .search({
        filters: {
          [spaceIndex.facets().id]: spaceIds,
          [spaceIndex.facets().attributes]: [SpaceAttribute.area],
        },
      })
      .then(space => space.hits);

    if (!allAreaGroups.length) {
      return allAreas;
    } else {
      const allAreaFromParentIds = await spaceIndex
        .search({
          filters: {
            [spaceIndex.facets().parents]: spaceIds,
            [spaceIndex.facets().attributes]: [SpaceAttribute.area],
          },
        })
        .then(area => {
          return area.hits;
        });

      allAreas.push(...allAreaFromParentIds);
      const deduplicatedAreas = Array.from(new Set(allAreas));
      return deduplicatedAreas;
    }
  };

  /*
   * inChargeOfSpaceIdsを元に全てのspace情報を返却
   * 支店グループが含まれている場合は対象の支店グループではなく、配下の支店すべてを返却
   * (今後グループ配下のデータをすべて返す必要があるケースが追加された場合はロジックを追加予定)
   *
   * ここはメソッド名と実装が乖離している気がするがv2移行でロジック修正を行うため一旦こちらの実装ですすめる
   */
  public static loadAllSpacesByInChargeOfSpaceIds = async (spaceIds: string[]): Promise<AlgoliaSpace[]> => {
    const spaceIndex = Algolia.indices().spaces();
    const spaces = await spaceIndex
      .search({
        filters: {
          [spaceIndex.facets().id]: spaceIds,
        },
        // FIXME: 本当は1000件を超えうるので、 `getAllHitsByBrowseResponse` を使用するべきだが、影響を加味して目の前これで対応する
        length: 1000,
      })
      .then(space => space.hits);

    const existAreaGroup = !!spaces.filter(space => space.attributes.includes(SpaceAttribute.areaGroup)).length;
    if (!existAreaGroup) {
      return spaces;
    } else {
      const allAreaFromParentIds = await spaceIndex
        .search({
          filters: {
            [spaceIndex.facets().parents]: spaceIds,
            [spaceIndex.facets().attributes]: [SpaceAttribute.area],
          },
          // FIXME: 本当は1000件を超えうるので、 `getAllHitsByBrowseResponse` を使用するべきだが、影響を加味して目の前これで対応する
          length: 1000,
        })
        .then(area => {
          return area.hits;
        });
      const allAreaFromSpaceIds = await spaceIndex
        .search({
          filters: {
            [spaceIndex.facets().id]: spaceIds,
            [spaceIndex.facets().attributes]: [SpaceAttribute.area],
          },
          // FIXME: 本当は1000件を超えうるので、 `getAllHitsByBrowseResponse` を使用するべきだが、影響を加味して目の前これで対応する
          length: 1000,
        })
        .then(area => {
          return area.hits;
        });
      const allAreas: AlgoliaSpace[] = [];
      allAreaFromParentIds.map(area => allAreas.push(area));
      allAreaFromSpaceIds.map(area => allAreas.push(area));

      const deduplicatedAreas = Array.from(new Set(allAreas));
      return deduplicatedAreas;
    }
  };
}
