import {Client} from 'algoliasearch';
import {
    BoardMemberRoles,
    BoardMemberStatus,
} from '../../../firebase/firestore/references-types/organizations/borad-members/firestore-types-organizations-board-members';
import {PersonType} from '../../../firebase/firestore/references/organizations/space-types/firestore-spaceTypes';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaBoardMembersAttributes {
  name = 'name',
  branchIds = 'branchIds',
  buildingNames = 'buildingNames',
  email = 'email',
  phoneNumber = 'phoneNumber',
  inChargeOfSpaceIds = 'inChargeOfSpaceIds',
  spaceGroupId = 'spaceGroupId',
  roles = 'roles',
}

export interface AlgoliaBoardMemberData extends AlgoliaObject {
  name: string;
  email: string;
  phoneNumber: string;
  roles: (BoardMemberRoles | string)[];
  personaId: string;
  defaultPersonaId: string;
  status: BoardMemberStatus;
  customerId?: string;
  inChargeOfSpaceIds: string[];
  spaceGroupId?: string;
  branchIds: string[];
  buildingNames: string[];
  personType: PersonType;
  numberOfVote?: number;
  startDate?: string;
  endDate?: string;
  remark?: string;
}

const searchableAttributes = {
  name: AlgoliaBoardMembersAttributes.name,
  email: AlgoliaBoardMembersAttributes.email,
  phoneNumber: AlgoliaBoardMembersAttributes.phoneNumber,
  buildingNames: AlgoliaBoardMembersAttributes.buildingNames,
  spaceGroupId: AlgoliaBoardMembersAttributes.spaceGroupId,
  roles: AlgoliaBoardMembersAttributes.roles,
};

export default class AlgoliaBoardMembers extends AlgoliaBase<AlgoliaBoardMemberData> {
  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;
  public static readonly facets = () => ({
    branchIds: AlgoliaBoardMembersAttributes.branchIds,
    buildingNames: AlgoliaBoardMembersAttributes.buildingNames,
    spaceGroupId: AlgoliaBoardMembersAttributes.spaceGroupId,
    roles: AlgoliaBoardMembersAttributes.roles,
  });
  public static getIndex = (client: Client, organizationId: string) => new AlgoliaBoardMembers(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.boardMembers;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaBoardMemberData>(this.index, condition);
  public searchableAttributes = AlgoliaBoardMembers.searchableAttributes;
  public facets = () => AlgoliaBoardMembers.facets();
}
