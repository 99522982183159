import {MergedBaseUserAuthority} from '../../business-types/types/core/common/merged-base-user-authority';
import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';
import DictError from '../../errors/dict-error';
import {ErrorDictKey} from '../../dictionary/error-dictionary';
import {ServiceType} from '../../business-types/core/common/service-type';
import {BusinessType} from '../../domain-base/business-types';

export default class UserAuthorityService {
  public static getMergedDataForClient = async (
    authorityIds?: Array<string>,
    serviceType?: ServiceType,
    businessType?: BusinessType,
  ): Promise<MergedBaseUserAuthority | undefined> => {
    if (!serviceType || !businessType) {
      return undefined;
    }
    return V2FirebaseHomehubOldApiFunctions.call('org-userAuthorities-getMergedDataForClient', {
      authorityIds: authorityIds && authorityIds.length ? authorityIds : ['INITIAL_ADMIN_AUTHORITY_ID'],
      serviceType,
      businessType,
    }).catch(e => {
      console.log('failed to publish application url to occupant.', e);
      throw new DictError(ErrorDictKey.failedToGetUserAuthorities);
    });
  };
}
