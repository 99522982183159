import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';
import DictError from '../../errors/dict-error';
import {ErrorDictKey} from '../../dictionary/error-dictionary';
import {FirestoreResponseData} from '../../firebase/firestore/firestore';
import {
    FirestoreOrganizationBaseNoticeData,
    FirestoreOrganizationDepartureNoticeData,
    FirestoreOrganizationNotificationSettingData,
} from '../../firebase/firestore/references/organizations/secrets/firestore-notification-settings';
import Redux from '../../redux/ReduxConnector';
import CommonError from '../../errors/common-error';
import StorageAnnouncements from '../../firebase/storage/references/announcements/storage-announcements';
import {
    ResponseMemberNotificationData
} from '../../firebase/firestore/references/organizations/members/notifications/firestore-organization-members-notifications';

export enum KindNotificationSettings {
  general = 'general',
  row_battery = 'row_battery',
  departure_notice = 'departure_notice',
  auto_logging_failure = 'auto_logging_failure',
}

export default class NotificationService {
  // FIXME bmToHomehub: スマモル賃貸プランのメアド変更や、退去予定通知設定などバグっているので、ここらへん要修正。
  public static registerNotificationSettings = async (data: {
    notificationEmail: string;
    // 今後、通知設定ごとに異なる設定やメールアドレスを持たせることが増えていくと思ったので
    kindNotificationSettings?: KindNotificationSettings;
    departureNoticeConfig?: FirestoreOrganizationDepartureNoticeData;
    rowBatteryNoticeConfig?: FirestoreOrganizationBaseNoticeData;
  }) => {
    await V2FirebaseHomehubOldApiFunctions.call('organizations-registerNotificationSettings', {
      notificationEmail: data.notificationEmail,
      departureNoticeConfig: data.departureNoticeConfig ? data.departureNoticeConfig : undefined,
      kindNotificationSettings: data.kindNotificationSettings,
      rowBatteryNoticeConfig: data.rowBatteryNoticeConfig || undefined,
    })
      .then(() => {
        switch (data.kindNotificationSettings) {
          case KindNotificationSettings.auto_logging_failure:
            Redux.actions.settings.setAutoLoggingFailureEmail(data.notificationEmail);
            break;
          case KindNotificationSettings.row_battery:
            Redux.actions.settings.setRowBatteryNotificationEmail(data.notificationEmail);
            if (data.rowBatteryNoticeConfig) {
              Redux.actions.settings.setRowBatteryNoticeConfig(data.rowBatteryNoticeConfig);
            }
            if (data.departureNoticeConfig) {
              Redux.actions.settings.setDepartureNoticeConfig(data.departureNoticeConfig);
            }
            break;
          // もともとのやつは全部こっち。
          case KindNotificationSettings.general:
          case KindNotificationSettings.departure_notice:
            // 成功したらReduxを変更する
            Redux.actions.settings.setNotificationEmail(data.notificationEmail);
            if (data.departureNoticeConfig) {
              Redux.actions.settings.setDepartureNoticeConfig(data.departureNoticeConfig);
            }
            break;
          default:
            break;
        }
      })
      .catch(e => {
        throw new DictError(ErrorDictKey.failedToRegisterNotificationSettings, e);
      });
  };

  public static loadNotificationSettings = async (): Promise<
    FirestoreResponseData<FirestoreOrganizationNotificationSettingData> | undefined
  > => {
    return await V2FirebaseHomehubOldApiFunctions.call('organizations-getNotificationSettings').catch(e => {
      throw new DictError(ErrorDictKey.failedToGetNotificationSettings, e);
    });
  };

  public static sendTestMailToNotificationMail = async (notificationEmail: string) => {
    await V2FirebaseHomehubOldApiFunctions.call('organizations-sendTestMailToNotificationMail', {
      notificationEmail: notificationEmail,
    }).catch(e => {
      throw new CommonError(e.code, e.message, e);
    });
  };

  /**
   * お知らせ情報の既読処理
   * @param announcementId
   */
  public static readAnnouncement = async (announcementId: string) => {
    await V2FirebaseHomehubOldApiFunctions.call('announcements-readAnnouncement', {
      announcementId: announcementId,
    }).catch(e => {
      console.error('Failed to update read announcement.', e);
    });
  };
  /**
   * 通知情報の全取得
   */
  public static loadNotifications = async (): Promise<ResponseMemberNotificationData[]> => {
    const notifications: ResponseMemberNotificationData[] = await V2FirebaseHomehubOldApiFunctions.call(
      'org-notifications-loadNotifications',
    );
    if (!notifications || !notifications[0]) {
      return [];
    } else {
      return notifications
        .map(n => ({
          ...n,
          n: n.createdAt ? n.createdAt : n.updatedAt ? n.updatedAt : 0,
        }))
        .sort((a, b) => {
          return a.createdAt! < b.createdAt! ? 1 : -1;
        });
    }
  };
  /**
   * 通知情報の既読処理
   * @param notificationId
   */
  public static readNotification = async (notificationId: string) => {
    await V2FirebaseHomehubOldApiFunctions.call('org-notifications-readMemberNotification', {
      notificationId: notificationId,
    }).catch(e => {
      console.error('Failed to update read notification.', e);
    });
  };
  public static uploadPhoto = async (file: any): Promise<{url: string; name: string}> => {
    if (!file) {
      throw new Error('need to photo upload announcement photo');
    }
    const result = await StorageAnnouncements.uploadPhoto(file.name, file);
    if (!result) {
      throw new Error('failed to upload announcement photo');
    }
    const url = await result.ref.getDownloadURL();
    return {
      url: url,
      name: file.name,
    };
  };
}
