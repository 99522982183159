import {v4 as uuidv4} from 'uuid';

export default class FileUtil {
  /**
   * ファイル名を受け取り、拡張子を返す
   * @param fileName
   */
  public static getExtension(fileName: string): string {
    const pos = fileName.lastIndexOf('.');
    if (pos === -1) return '';

    return fileName.slice(pos + 1);
  }

  /**
   * @param limitSize limit file size(MB)
   * @return boolean
   */
  public static isFileSizeExceed = (file: File, limitSize: number) => {
    return Boolean(file.size / 1024 / 1024 > limitSize);
  };

  /**
   * return file list renamed to uuidv4.extensions.
   * @param originalFiles
   */
  public static renameFiles = (originalFiles: FileList) => {
    const files: Array<File> = [];
    for (const originalFile of Array.from(originalFiles)) {
      const fileName = originalFile.name;
      const type = fileName.substring(fileName.lastIndexOf('.') + 1);
      const name = uuidv4() + '.' + type;
      const newFile = new File([originalFile], name, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
      files.push(newFile);
    }
    return files;
  };

  public static getSizeKB = (size: number | undefined) => {
    if (size) {
      return Math.round(size / 1024);
    }

    return 0;
  };

  public static displayByte = (size: number) => {
    if (!size) return;

    if (size >= 1048576) {
      return (size / 1024 / 1024).toFixed(1) + 'MB';
    }

    if (size >= 1024) {
      if (size) {
        return Math.round(size / 1024) + 'KB';
      }

      return 0 + 'KB';
    }

    return size + 'B';
  };
}
