import {DashboardWidgetProps} from '../../components/dashboard/DashboardWidget';
import {
    ResidenceDashboardContentType
} from '../../business-types/residence/organizations/dashboard/enums/residenceDashboardContentType';
import {
    AlgoliaDashboardContentData,
    AlgoliaDashboardContentElement,
} from '../../algolia/references/dashbord/algolia-dashboard-contents';
import {History} from 'history';
import {DashboardSummaryRowProps} from '../../components/dashboard/DashboardSummaryRow';
import {V2FirebaseHomehubOldApiFunctions} from '../../firebase/functions/v2-firebase-homehub-old-api-functions';
import CommonError from '../../errors/common-error';
import {
    FirestoreOrganizationDashboardSettingData
} from '../../firebase/firestore/references/organizations/secrets/firestore-dashboard-settings';
import Redux from '../../redux/ReduxConnector';

export interface DashboardListPageOptions {
  initialTabIndex?: number;
  showHiddenMenu?: boolean;
  showScheduledHiddenButtons?: boolean;
  filterName?: string;
}

const toListPage = (
  history: History<any>,
  content: AlgoliaDashboardContentData,
  path: string,
  options?: DashboardListPageOptions,
) => () =>
  history.push(path, {
    dashboardContent: content,
    options,
  });

enum DashboardWidgetKey {
  SET_DEVICE = 'SET_DEVICE',
  LEFT = 'LEFT',
  OCCUPIED = 'OCCUPIED',
}

const DashboardWidgetParams: {[key in DashboardWidgetKey]} = {
  [DashboardWidgetKey.SET_DEVICE]: {
    title: '設置',
    dashboardContentTypes: [ResidenceDashboardContentType.SET_DEVICE_RECENTLY],
  },
  [DashboardWidgetKey.LEFT]: {
    title: '退去',
    dashboardContentTypes: [
      ResidenceDashboardContentType.ALREADY_LEFT,
      ResidenceDashboardContentType.OCCUPANT_DELETED_KEY,
      ResidenceDashboardContentType.CAST_DELETED_KEY,
    ],
  },
  [DashboardWidgetKey.OCCUPIED]: {
    title: '入居',
    dashboardContentTypes: [ResidenceDashboardContentType.CONTRACT_END_WARNING],
  },
};

const DashboardSummaryRowPropCreator = {
  [ResidenceDashboardContentType.SET_DEVICE_RECENTLY]: (
    content: AlgoliaDashboardContentData,
    history: History<any>,
  ) => ({
    text: 'カギ未発行の新しいbitlockがあります。',
    subText: 'カギを発行しましょう。',
    number: (content.summaryValue && content.summaryValue.number) || 0,
    numberUnit: '件',
    onClick: toListPage(history, content, '/buildings-spaces', {
      initialTabIndex: 1,
      filterName: 'カギが発行されていない新しいbitlock設置物件',
    }), // 物件一覧は、建物・物件一覧の２つ目のTab
  }),
  [ResidenceDashboardContentType.ALREADY_LEFT]: (content: AlgoliaDashboardContentData, history: History<any>) => ({
    text: '退去後にカギが発行されていない物件があります。',
    subText: 'カギを発行しましょう。',
    number: (content.summaryValue && content.summaryValue.number) || 0,
    numberUnit: '件',
    onClick: toListPage(history, content, '/buildings-spaces', {
      initialTabIndex: 1,
      showHiddenMenu: true,
      filterName: '退去後にカギが発行されていない物件',
    }), // 物件一覧は、建物・物件一覧の２つ目のTab
  }),
  [ResidenceDashboardContentType.OCCUPANT_DELETED_KEY]: (
    content: AlgoliaDashboardContentData,
    history: History<any>,
  ) => ({
    text: '入居者がカギを返却した物件があります。',
    subText: '物件ステータスを「退去処理中」に変更しましょう。',
    number: (content.summaryValue && content.summaryValue.number) || 0,
    numberUnit: '件',
    onClick: toListPage(history, content, '/buildings-spaces', {
      initialTabIndex: 1,
      showHiddenMenu: true,
      filterName: '入居者がカギを返却した物件があります。',
    }), // 物件一覧は、建物・物件一覧の２つ目のTab
  }),
  [ResidenceDashboardContentType.CONTRACT_END_WARNING]: (
    content: AlgoliaDashboardContentData,
    history: History<any>,
  ) => ({
    text: '契約満了30日前の入居中物件があります。',
    subText: '契約更新または退去予定の登録をしましょう。',
    number: (content.summaryValue && content.summaryValue.number) || 0,
    numberUnit: '件',
    onClick: toListPage(history, content, '/buildings-spaces', {
      initialTabIndex: 1,
      showHiddenMenu: true,
      filterName: '契約満了30日前の入居中物件',
    }), // 物件一覧は、建物・物件一覧の２つ目のTab
  }),
  [ResidenceDashboardContentType.CAST_DELETED_KEY]: (content: AlgoliaDashboardContentData, history: History<any>) => ({
    text: '業者がカギを返却した物件があります。',
    subText: '次の業者へのカギ発行または物件の公開をしましょう。',
    number: (content.summaryValue && content.summaryValue.number) || 0,
    numberUnit: '件',
    onClick: toListPage(history, content, '/buildings-spaces', {
      initialTabIndex: 1,
      showHiddenMenu: true,
      filterName: '業者がカギを返却した物件',
    }), // 物件一覧は、建物・物件一覧の２つ目のTab
  }),
};

export class DashboardService {
  public static createDashboardWidgetProps(history: History<any>, contents: AlgoliaDashboardContentData[]) {
    const widgetPropsMap = DashboardService.mapWidgetPropsByWidgetKey(contents, history);
    return [
      widgetPropsMap[DashboardWidgetKey.OCCUPIED],
      widgetPropsMap[DashboardWidgetKey.LEFT],
      widgetPropsMap[DashboardWidgetKey.SET_DEVICE],
    ];
  }

  public static mapWidgetPropsByWidgetKey = (contents: AlgoliaDashboardContentData[], history: History<any>) => {
    const widgetKeys = Object.keys(DashboardWidgetParams) as DashboardWidgetKey[];
    const widgets = {};
    widgetKeys.forEach(widgetKey => {
      widgets[widgetKey] = {
        title: DashboardWidgetParams[widgetKey].title,
        summaryRows: DashboardService.createSummaryProps(contents, widgetKey, history),
      };
    });
    return widgets as {[key in DashboardWidgetKey]: DashboardWidgetProps}; // FIXME typescriptエラーの 暫定対応
  };

  public static createSummaryProps = (
    contents: AlgoliaDashboardContentData[],
    dashboardWidgetKey: DashboardWidgetKey,
    history: History<any>,
  ): DashboardSummaryRowProps[] => {
    return contents
      .filter(content =>
        DashboardWidgetParams[dashboardWidgetKey].dashboardContentTypes.includes(content.dashboardType),
      )
      .map(content => DashboardSummaryRowPropCreator[content.dashboardType](content, history));
  };

  public static ableToUseDashboard = (): boolean => {
    if (!Redux || !Redux.getStore()) {
      return false;
    }
    const dashboardSettings = Redux.getStore().settings.dashboardSettings;
    return (
      !dashboardSettings || dashboardSettings.ableToUseDashboard || dashboardSettings.ableToUseDashboard === undefined
    ); // defaultを使える状態にするためにundefinedもtrue。
  };

  public static hideElements = async (
    elements: AlgoliaDashboardContentElement[],
    options?: {scheduledDays?: number},
  ) => {
    const body: {elements: AlgoliaDashboardContentElement[]; options?: {scheduledDays?: number}} = {
      elements,
    };
    if (options) {
      body.options = options;
    }
    try {
      const res = await V2FirebaseHomehubOldApiFunctions.patch(
        `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/dashboard/elements/hide`,
        body,
      );

      if (!res || !res.data) {
        return;
      }
    } catch (e) {
      throw new CommonError(e);
    }
  };

  public static loadDashboardSettings = async () => {
    try {
      const res = await V2FirebaseHomehubOldApiFunctions.get(
        `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/dashboard/settings`,
      );

      if (!res || !res.data) {
        return;
      }

      return res.data as FirestoreOrganizationDashboardSettingData;
    } catch (e) {
      throw new CommonError(e);
    }
  };

  public static registerDashboardSettings = async (data: {ableToUseDashboard: boolean}) => {
    const {ableToUseDashboard} = data;
    try {
      await V2FirebaseHomehubOldApiFunctions.put(
        `api-${V2FirebaseHomehubOldApiFunctions.getVersion()}-organizations/dashboard/settings`,
        {ableToUseDashboard},
      );
      Redux.actions.settings.setDashboardSettings({
        ...data,
        id: 'dashboardSettings',
      });
    } catch (e) {
      throw new CommonError(e);
    }
  };
}
