import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {Client} from 'algoliasearch';
import {EpochMillis} from '../../../common-types/numbers';
import {AlgoliaMember, AlgoliaMembersAttributes} from './algolia-members';

export interface AlgoliaMemberDeleted extends AlgoliaObject, AlgoliaMember {
  deletedAt: EpochMillis;
}

export default class AlgoliaMembersDeleted extends AlgoliaBase<AlgoliaMemberDeleted> {
  public static readonly facets = () => ({
    // for facet
    status: AlgoliaMembersAttributes.status,
    // for filter
    id: AlgoliaMembersAttributes.id,
    email: AlgoliaMembersAttributes.email,
    spaceIds: AlgoliaMembersAttributes.spaceIds,
    spaceId: AlgoliaMembersAttributes.spaceId,
    deviceId: AlgoliaMembersAttributes.deviceId,
    defaultPersonaId: AlgoliaMembersAttributes.defaultPersonaId,
    inChargeOfSpaceIds: AlgoliaMembersAttributes.inChargeOfSpaceIds,
    roles: AlgoliaMembersAttributes.roles,
    userAuthorityIds: AlgoliaMembersAttributes.userAuthorityIds,
  });

  public static getIndex = (client: Client, organizationId: string) =>
    new AlgoliaMembersDeleted(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.membersDeleted;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaMembersDeleted.facets();
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaMemberDeleted>(this.index, condition);
}
