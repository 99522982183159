import firebase from 'firebase/compat/app';
import {v4 as uuidV4} from 'uuid';
import FileUtil from '../../../../basic-utils/file-utils';
import {FileExtension} from '../../../../business-types/file-extension-types';
import FirebaseStorage from '../../firebase-storage';
import StorageTemporaryUsers from '../organizations/storage-temporary-users';

export default class StorageOrgEvent {
  public static uploadFile = (fileName: string, data: any, uri?: string) => {
    let fileRef: firebase.storage.Reference;
    if (uri) {
      fileRef = FirebaseStorage.refFromURL(uri);
    } else {
      const dataId = uuidV4();
      const extension = FileUtil.getExtension(fileName);
      if (
        extension === FileExtension.Jpeg ||
        extension === FileExtension.Png ||
        extension === FileExtension.Gif ||
        extension === FileExtension.Jpg
      ) {
        fileRef = StorageTemporaryUsers.getRef().child('images').child(dataId);
      } else {
        fileRef = StorageTemporaryUsers.getRef().child('documents').child(dataId);
      }
    }
    return FirebaseStorage.upload(fileRef, data);
  };

  public static deleteFile = (uri: string) => {
    const fileRef = FirebaseStorage.refFromURL(uri);
    return FirebaseStorage.delete(fileRef);
  };
}
