import {Client, Response} from 'algoliasearch';
import Algolia, {AlgoliaFilters, AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import {
    BuildingStructureType,
    SpaceAttribute,
} from '../../../firebase/firestore/references/organizations/spaces/firestore-spaces';
import {EpochMillis} from '../../../common-types/numbers';
import {
    SpaceStatus
} from '../../../firebase/firestore/references/organizations/spaces/status-histories/firestore-organizations-spaces-status-histories';
import SpaceStatusUtil from '../../../business-utils/space-status/space-status-util';
import AlgoliaBase from '../../algolia-base';
import {AlgoliaSpace, AlgoliaSpacesAttributes} from './algolia-spaces';
import {SumamoruProjectType} from '../../../business-types/residence/organizations/add-ons/sumamoru-project-type';
import {SumamoruPlan} from '../../../business-types/residence/organizations/add-ons/SumamoruPlan';

export interface AlgoliaScheduledSpaceStatus {
  id: string;
  status: SpaceStatus;
  startAt: EpochMillis;
  endAt: EpochMillis;
  createdAt: EpochMillis;
}

export interface SpaceAddOns {
  sumamoruData?: {
    sumamoruLinkable: boolean; // 標準採用建物かどうか
    sumamoruProjectTypes?: Array<SumamoruProjectType>; // どのスマモルプロジェクトに該当するものか、原則1つ、ただしゆくゆくは複数となる可能性が0でないため配列として保持する
    sumamoruCode: string; // 標準採用建物No.
    sumamoruPlans?: Array<SumamoruPlan>;
  };
}

export interface AlgoliaSpaceDeletedData extends AlgoliaObject, AlgoliaSpace {
  id: string;
  name: string;
  phoneticName?: string;
  code?: string;
  postcode?: string; // 郵便番号
  prefecture?: string; // 都道府県コード
  city?: string; // 市区町村
  afterCity?: string; // 市区町村以降（番地など）
  address: string;
  bitlockIds: string[];
  photoUri: string;
  parents: string[];
  isGroup: boolean;
  parentNames?: string[];
  parentId: string;
  attributes: (SpaceAttribute | string)[];

  status?: SpaceStatus; // 空間ステータス
  scheduledStatuses?: AlgoliaScheduledSpaceStatus[]; // 未来の空間ステータス一覧
  equipments?: string[]; // 設備情報, RESIDENCEの場合ここにリフォーム物件、バストイレ別など○×で表現できる項目を持つ
  memo?: string; // 備考

  photoUri02?: string;
  photoUri03?: string;
  photoUri04?: string;
  photoUri05?: string;
  photoUri06?: string;
  photoUri07?: string;
  photoUri08?: string;
  photoUri09?: string;
  photoUri10?: string;

  // 入居者の契約一覧
  occupantContractIds?: string[];
  // 入居者の契約代表者一覧
  occupantCustomerIds?: string[];
  // 入居中の契約代表者の名前一覧（表示用）
  occupantRepresentativeNames?: string[];

  // RESIDENCE property
  charge?: number; // 賃料
  deposit?: number; // 敷金
  keyMoney?: number; // 礼金
  managementFee?: number; // 管理費
  brokerageFee?: number; // 仲介手数料
  ownerName?: string; // オーナー名
  ownerPhoneNumber?: string; // オーナー連絡先電話番号
  layout?: string; // 間取り
  exclusiveArea?: number; // 専有面積
  floorNumber?: number; // 部屋の階
  // RESIDENCE building
  constructDate?: number; // 築年月日
  nearestStation?: string; // 最寄駅
  minutesByWalkingFromStation?: number; // 駅徒歩(分)
  minutesByBusFromStation?: number; // 駅からバスでかかる時間(分)
  minutesByCarFromStation?: number; // 駅から車でかかる時間(分)
  storyNumber?: number; // 建物の階数
  structure?: BuildingStructureType; // 構造
  totalPropertyCount?: number; // 総物件数
  occupiedCount?: number; // 入居者数
  showingPropertyCount?: number; // 公開中物件数
  applicationWithinPropertyCount?: number; // 申し込み中物件数
  closedPropertyCount?: number; // 停止中物件数
  occupancyRate?: number; // 入居率
  privateViewFormat?: string; // 内見フォーマット
  vacantStartDate?: EpochMillis; // 空室期間の開始日

  // アドオン用定義
  addOns?: SpaceAddOns;
}

export default class AlgoliaSpacesDeleted extends AlgoliaBase<AlgoliaSpaceDeletedData> {
  public static readonly facets = () => ({
    id: AlgoliaSpacesAttributes.id,
    bitlockIds: AlgoliaSpacesAttributes.bitlockIds,
    parents: AlgoliaSpacesAttributes.parents,
    isGroup: AlgoliaSpacesAttributes.isGroup,
    parentId: AlgoliaSpacesAttributes.parentId,
    attributes: AlgoliaSpacesAttributes.attributes,
    status: AlgoliaSpacesAttributes.status,
    code: AlgoliaSpacesAttributes.code,
    sumamoruLinkable: AlgoliaSpacesAttributes.sumamoruLinkable,
    sumamoruProjectTypes: AlgoliaSpacesAttributes.sumamoruProjectTypes,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaSpacesDeleted(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.spacesDeleted;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public search = async condition => {
    const res: Response<AlgoliaSpaceDeletedData> = await Algolia.search<AlgoliaSpaceDeletedData>(this.index, condition);
    if (!res || !res.hits) {
      return res;
    }
    res.hits = res.hits.map(h => ({
      ...h,
      status: SpaceStatusUtil.convertToCurrentStatus(h),
    }));
    return res;
  };
  public relation = async (filters: AlgoliaFilters) => {
    const res: Response<AlgoliaSpaceDeletedData> = await Algolia.relation<AlgoliaSpaceDeletedData>(this.index, filters);
    if (!res || !res.hits) {
      return res;
    }
    res.hits = res.hits.map(h => ({
      ...h,
      status: SpaceStatusUtil.convertToCurrentStatus(h),
    }));
    return res;
  };
  // public browse = async (condition) => Algolia.browse<AlgoliaSpacesDeleted>(this.index, condition);
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaSpaceDeletedData>(this.index, condition);
  public facets = () => AlgoliaSpacesDeleted.facets();
}
