import {searchSimplyElasticSearchLegacyIndex} from '@next/repository/indexes/elastic/simple-template/elastic-search-index-search-simple-template';
import {LegacyIndexName, toApiPathFromIndexName} from './legacy-index-name';
import type {AlgoliaObject, AlgoliaSearchCondition, InitialBrowseData, LegacyIndex} from './legacy-index-type';
import {Response} from 'algoliasearch';
import {AlgoliaFilters} from './legacy-index-type';

const EMPTY_RESPONSE: Response = {
  hits: [],
  page: 0,
  nbHits: 0,
  nbPages: 0,
  hitsPerPage: 100,
  processingTimeMS: 0,
  query: '',
  params: '',
  exhaustiveNbHits: false,
  exhaustiveFacetsCount: false,
};

const FORCE_EMPTY_FILTER = ['xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'];

export abstract class LegacyIndexBase<T extends AlgoliaObject> implements LegacyIndex<T> {
  protected abstract name: LegacyIndexName;
  protected abstract rawIndexName: string;

  private ALL_HITS_BROWSE_SEARCH_LIMIT = 10000;
  private SINGLE_SEARCH_LIMIT = 1000;

  public abstract facets: () => {[p: string]: string};

  public browse = async (condition: AlgoliaSearchCondition): Promise<InitialBrowseData<T>> => {
    const searchResult = await searchSimplyElasticSearchLegacyIndex<T>({
      apiPath: toApiPathFromIndexName(this.name),
      condition,
    });
    return {searchData: searchResult, browseData: searchResult};
  };

  /**
   * ESでは未使用
   * @param cursor
   * @deprecate
   * @returns
   */
  public browseNext = async (cursor: string) => ({
    params: '',
    query: '',
    processingTimeMS: -1,
    hits: [],
  });

  /**
   * 全件検索(max10000件)
   * サーバーサイドでメモリリークを起こす可能性があるので分割して検索し、最後に結合を行う
   * @param condition
   * @returns
   */
  public getAllHitsByBrowseResponse = async condition => {
    const splitCount = this.ALL_HITS_BROWSE_SEARCH_LIMIT / this.SINGLE_SEARCH_LIMIT;
    const results: Response<T>[] = [];
    for (let i = 0; i < splitCount; i++) {
      const pointerResult = await searchSimplyElasticSearchLegacyIndex<T>({
        apiPath: toApiPathFromIndexName(this.name),
        condition: this.browseAddCondition(condition, this.SINGLE_SEARCH_LIMIT * i, this.SINGLE_SEARCH_LIMIT),
      });
      results[i] = pointerResult;
      if (pointerResult.hits.length < this.SINGLE_SEARCH_LIMIT) break;
    }

    // hitsを結合して返却
    return results.reduce((acc, current) => [...acc, ...current.hits], [] as T[]);
  };

  // ESの場合に何もする必要なし
  public clearCache = () => {};

  public getName = () => this.name;
  public getRawIndexName = () => this.rawIndexName;

  public search = (condition: AlgoliaSearchCondition): Promise<Response<T>> =>
    searchSimplyElasticSearchLegacyIndex<T>({
      apiPath: toApiPathFromIndexName(this.name),
      condition: this.prepareSearchOperands(condition),
    });

  /**
   * 検索結果から何らかのidでリレーションしたいときに。
   * やってることはsearchと同じ。
   * algolia / elasticsearch の仕様上、filterが空の場合は全部返ってくるが、ここでやりたいことはリレーションなので
   * filterが空の場合は何も返さないようにする
   * @param filters
   */
  public relation = async (filters: AlgoliaFilters): Promise<Response<T>> => {
    if (!filters) filters = {};
    let filterExists = false;
    for (const k of Object.keys(filters)) {
      if (filters[k] && filters[k].length) {
        filterExists = true;
        break;
      }
    }
    if (!filterExists) {
      console.log('[LegacyIndexBase][relation]return empty response, because filter is empty. filters: ', filters);
      return EMPTY_RESPONSE;
    }
    return await searchSimplyElasticSearchLegacyIndex<T>({
      apiPath: toApiPathFromIndexName(this.name),
      condition: {
        filters: filters,
      },
    });
  };

  /**
   * browserメソッド類のconditionを装飾する
   * @param condition
   * @param offset
   * @param length defaultとしたいlength
   * @returns BrowseCondition
   */
  private browseAddCondition = (
    condition: AlgoliaSearchCondition,
    offset: number,
    length: number,
  ): AlgoliaSearchCondition => ({
    ...condition,
    offset,
    length,
  });

  /**
   * searchでのみ利用。condition.filterを掘ってoperandsを整形する
   * @param condition
   * @returns AlgoliaSearchCondition
   */
  private prepareSearchOperands = (condition: AlgoliaSearchCondition): AlgoliaSearchCondition => {
    return {
      ...condition,
      filters: condition.filters
        ? Object.entries(condition.filters)
            .map(([key, value]) => ({[key]: this.toFilterOperands(value)}))
            .reduce((result, current): AlgoliaFilters => Object.assign(result, current), {})
        : undefined,
    };
  };

  /**
   * filter に利用する operands を整形する
   * value の中身が空の場合は検索対象が1件も釣れないように調整する
   * algolia.ts の FORCE_EMPTY_FILTER と同じ動作にする
   *
   * 調整対象は condition.filters のみ
   * @param value
   */
  private toFilterOperands = (value: (string | number | boolean)[]): (string | number | boolean)[] => {
    if (!value.length) {
      return FORCE_EMPTY_FILTER;
    }
    return value;
  };
}
