import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName} from '../../algolia';
import AlgoliaBase from '../../algolia-base';
import {AlgoliaOnsiteReportSettingData} from '../onsite-report-settings/algolia-onsite-report-settings';

export enum AlgoliaOnsiteReportAttributes {
  id = 'id',
  title = 'title',
  code = 'code',
  authorPersonaId = 'authorPersonaId',
}

export interface AlgoliaOnsiteReportData extends AlgoliaOnsiteReportSettingData {
  submittedDate?: string;
  authorPersonaId: string;
}

export default class AlgoliaOnsiteReports extends AlgoliaBase<AlgoliaOnsiteReportData> {
  public static readonly facets = () => ({
    id: AlgoliaOnsiteReportAttributes.id,
    title: AlgoliaOnsiteReportAttributes.title,
    code: AlgoliaOnsiteReportAttributes.code,
    authorPersonaId: AlgoliaOnsiteReportAttributes.authorPersonaId,
  });

  public static getIndex = (client: Client, organizationId: string) => new AlgoliaOnsiteReports(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.onsiteReports;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaOnsiteReportData>(this.index, condition);
  public facets = () => AlgoliaOnsiteReports.facets();
}
