import {Client} from 'algoliasearch';
import Algolia, {AlgoliaIndexName, AlgoliaObject} from '../../algolia';
import AlgoliaBase from '../../algolia-base';

export enum AlgoliaReceptionsAttributes {
  title = 'title',
  memberName = 'memberName',
  startTime = 'startTime',
  endTime = 'endTime',
  spaceName = 'spaceName',
  accepted = 'accepted',
  deleted = 'deleted',
  expired = 'expired',
}

export interface AlgoliaReceptionData extends AlgoliaObject {
  title: string;
  memberName?: string;
  startTime?: string;
  endTime?: string;
  spaceName?: string;
  accepted?: boolean;
  deleted?: boolean;
  expired?: boolean;
}

export default class AlgoliaReceptions extends AlgoliaBase<AlgoliaReceptionData> {
  public static readonly facets = () => ({
    title: AlgoliaReceptionsAttributes.title,
    startTime: AlgoliaReceptionsAttributes.startTime,
    spaceName: AlgoliaReceptionsAttributes.spaceName,
    hostName: AlgoliaReceptionsAttributes.memberName,
    accepted: AlgoliaReceptionsAttributes.accepted,
    expired: AlgoliaReceptionsAttributes.expired,
  });
  public static getIndex = (client: Client, organizationId: string) => new AlgoliaReceptions(client, organizationId);

  private constructor(client: Client, organizationId: string) {
    super();
    this.name = AlgoliaIndexName.receptions;
    this.rawIndexName = Algolia.toIndexName(organizationId, this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public facets = () => AlgoliaReceptions.facets();
  public getAllHitsByBrowseResponse = async condition =>
    Algolia.getAllHitsByBrowseResponse<AlgoliaReceptionData>(this.index, condition);
}
