import StorageOrganizations from '../storage-organizations';
import FirebaseStorage from '../../../firebase-storage';
import {v4 as uuidv4} from 'uuid';

const dirRef = (spaceId: string) => StorageOrganizations.getRef().child('spaces').child(spaceId);
const ref = (spaceId: string) => StorageOrganizations.getRef().child('spaces').child(spaceId).child(`${spaceId}-1`);
const photoRef = (spaceId: string, photoUri?: string) =>
  photoUri ? getPhotoRefWithUri(photoUri) : ref(spaceId).child(uuidv4());
const getPhotoRefWithUri = (photoUri: string) => FirebaseStorage.refFromURL(photoUri);

export default class StorageSpaces {
  public static getRef = (spaceId: string) => dirRef(spaceId);
  public static uploadPhoto = (spaceId: string, data: File, photoUri?: string) =>
    FirebaseStorage.upload(photoRef(spaceId, photoUri), data);
  public static deletePhoto = (spaceId: string, photoUri: string) =>
    FirebaseStorage.delete(photoRef(spaceId, photoUri)).catch(e => {
      // 存在しなければ、削除済と判断する。
      if (e.serverResponse && e.serverResponse.error && e.serverResponse.error.code !== 404) {
        throw e;
      }
    });
  public static getDownloadUrl = (path: string): Promise<string> =>
    FirebaseStorage.getUrl(FirebaseStorage.rootRef(path))
}
